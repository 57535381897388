/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import './dropdown.scss';

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  displayInput: {
    height:"auto",
    opacity: "1",
    transition: "opacity 1.7s linear",
    marginBottom:"5px",
    marginTop: "5px"
  },
  noDisplay: {
    height: "0",
    overflow: "hidden",
    opacity: "0",
    transition: "opacity 1.7s linear"
  },
  addIconStyle:{
    width:'10px'
  }
}));


const Dropdown = ({ label, options, selected, setSelected, labelWidth, categoryName, setCategoryName, addCategory, departmentName, setDepartmentName, addDepartment, type }) => {
  const { t } = useTranslation()
  const classes = useStyles();
  const [selections, setSelections] = useState([]);
  const [openTextfield, setOpenTextfield] = useState(false);
  const handleChange = (event) => {
    setSelections(event.target.value);
    setSelected(event.target.value);
  };

  const AddButton = () => (
    <IconButton onClick={label === `${t('quiz_category')}`  || label === `${t('article_category')}` || label === `${t('course_category')}` ? addCategory : addDepartment} >
      <AddIcon className={classes.addIconStyle} />
    </IconButton>
  )
  // const stopImmediatePropagation = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  // };

  useEffect(() => {
    if (selected?.length) {
      setSelections(options.filter((o) => selected.includes(o.value)));
      setSelected(options.filter((o) => selected.includes(o.value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selected?.length) {
      setSelections(options.filter((o) => selected.includes(o.value)));
      setSelected(options.filter((o) => selected.includes(o.value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      {type && type === 'simple' ? (<Select
        value={selections}
        onChange={handleChange}
        className="dropdown-select"
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="age"
            id="outlined-age-simple"
          />
        }
      >
        {!options.length && <div className="dropdown__nothing-found">{t('nothing_found')}</div>}
        {options.length > 0 && 
          options.map((op) => (
            <MenuItem key={op.value} value={op}>
              {op.label}
            </MenuItem>
          ))
        }

      </Select>):
      (<Select
        labelWidth={labelWidth}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        IconComponent={ExpandMoreIcon}
        className="dropdown-select"
        value={selections}
        onChange={handleChange}
        multiple
        renderValue={(selected) => selected.map((op) => op.label).join(', ')}   
      >
        <>
          {label === `${t('quiz_category')}` || label === `${t('article_category')}` || label === `${t('course_category')}` ? (
            <div>
              <div className="div-click-create" onClick={() => setOpenTextfield(!openTextfield)} >{t('click_to_create_new_location')}</div>
            </div>
          ) : (
            <div>
                <div className="div-click-create" onClick={() => setOpenTextfield(!openTextfield)}>{t('click_to_create_new_department')}</div>
            </div>
          )}
          <div style={{ marginLeft: '25px', marginRight: '25px', display: openTextfield ? 'block':"none"}}  className="textfield-wrapper">
            <TextField
              id="text-field-for-create-category-department"
              className={openTextfield ? classes.displayInput : classes.noDisplay}
              // label={label === `${t('article_category')}` || label === `${t('course_category')}` ? (`Category Name`) : (`Department Name`)}
              placeholder={label === `${t('quiz_category')}` || label === `${t('article_category')}` || label === `${t('course_category')}` ? (`${t('category_name')}`) : (`${t('department_name')}`)}
              value={label === `${t('quiz_category')}` || label === `${t('article_category')}` || label === `${t('course_category')}` ? (categoryName) : (departmentName)}
              onChange={({ target }) => 
              label === `${t('quiz_category')}`  || label === `${t('article_category')}` || label === `${t('course_category')}` 
                  ? 
                (
                  setCategoryName(target.value)
                ) 
                  : 
                (
                  setDepartmentName(target.value)
                )}
              fullWidth
              InputProps={{ endAdornment: <AddButton /> }}
              variant= 'outlined'
              // onClickCapture={stopImmediatePropagation}
              onKeyDown={e => e.stopPropagation()}
            />
          </div>
        </>
        {!options.length ? (
          <>
            <div className="dropdown__nothing-found">{t('nothing_found')}</div>
          </>
        ) : (
          options.map((op) => (
            <MenuItem key={op.value} value={op}>
              <Checkbox checked={selections.indexOf(op) > -1} />
              <ListItemText primary={op.label} />
            </MenuItem>
          ))
        )
        }
      </Select>)}
    </FormControl>
  );
};

export default Dropdown;
