import React, { useState, useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Close from "@material-ui/icons/Close";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import Select from "react-select";
import ATT from "array-to-tree";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { connect, useDispatch } from "react-redux";
import {
  searchUsers,
  assignUserToProcessTask,
  updateUserProcessTask,
} from "../../redux/users/action";
import TaskCard from "../../components/card/TaskCard";
import {
  getTaskById,
  changeTaskDeadline,
  changeTaskResponsible,
} from "../../redux/task/action";
import {
  updateProcessStatus,
  getProcessById,
  getProcessTasks,
} from "../../redux/process/action";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import auth from "../../auth";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CustomCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    showSuccessMsg,
    showErrorMsg,
    setOpenAlert,
    BackToAssignTask,
  } = props;

  const routeState = history.location.state;
  const [editmode, setEditmode] = useState(false);
  const [members, setMembers] = useState([]);
  const [membersData, setMembersData] = useState(
    routeState.assignUser.membersData
  );
  const [selTask, setSeltask] = useState();
  const [editModal, setEditModal] = useState(false);
  const [isAllTasksAssigned, setIsAllTasksAssigned] = useState(false);
  const [process, setProcess] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openActivatedAlert, setOpenActivatedAlert] = useState(false);
  const [isProcessActivated, setIsProcessActivated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isReassignAllowed, setIsReassignAllowed] = useState(false);
  const [allowUserToEdit, setAllowUserToEdit] = useState(false);

  const StyledNode = ({ text, des, keepId, color, filled }) => {
    const cardClickHandler = () => {
      const _task = taskOfProcess?.filter((item) => {
        return item.id === keepId;
      });
      setSelectedTaskCard(_task);
      if (
        (!isProcessActivated && allowUserToEdit) ||
        (isProcessActivated &&
          (!_task[0]?.responsible || !_task[0]?.compeleted) &&
          allowUserToEdit)
      ) {
        setIsReassignAllowed(true);
      } else {
        setIsReassignAllowed(false);
      }

      if (filled === false) {
        setOpenModal(true);
        setSeltask(keepId);
        getSingleTask(keepId);
      } else {
        if (process.isActivated) {
          if (allowUserToEdit) {
            if (_task[0]?.compeleted) {
              setOpenModal(true);
            } else {
              setEditModal(true);
            }
          } else {
            setOpenModal(true);
          }
        } else {
          setEditModal(true);
        }
        setSeltask(keepId);
        let x = _task[0];

        setSelectedUserR({
          add: members.find((item) => item.value === x.responsible),
        });
        setSelectedUser({
          add: members.find((item) => item.value === x.benefactor),
        });
        setStartDate(new Date(x.dateBeDone));

        let filteredArray = members.filter(function (n) {
          for (var i = 0; i < x.notifies?.length; i++) {
            if (n.value === x.notifies[i]) {
              return true;
            }
          }
          return false;
        });

        let mdsArr = [];
        filteredArray.map((item) => {
          mdsArr.push({ id: item.value, name: item.label });
        });
        setPersons(mdsArr);
        setEditmode(true);
        setTaskDetail(x);
        setExecuteConcurrently(_task[0]?.toggle);
        /* } */
      }
    };
    return (
      <TaskCard
        color={color}
        text={text}
        des={des}
        onCardClick={cardClickHandler}
      />
    );
  };
  const StyledNodeR = () => <div></div>;

  const CustomCard = (propss) => {
    return (
      <>
        {propss.data.map((item, index) => {
          let _temp = taskOfProcess?.find((item2) => item2.id === item.id);

          let color = _temp?.responsible ? "#B4D264" : "red";
          let _filled = _temp?.responsible ? true : false;

          return (
            <TreeNode
              key={index}
              label={
                <StyledNode
                  keepId={item.id}
                  text={item.name}
                  des={item.description}
                  key={index}
                  color={color}
                  filled={_filled}
                />
              }
            >
              {item.children?.length && (
                <CustomCard data={item.children} key={index} />
              )}
            </TreeNode>
          );
        })}
      </>
    );
  };

  const [success, setSuccess] = useState(false);
  const [name, setName] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [taskDetail, setTaskDetail] = useState(null);
  const [tasks, setTasks] = useState(
    routeState.assignUser.processTasks.map((loc) => {
      return {
        value: loc.id,
        label: loc.name,
        pre: loc.preTask,
        des: loc.description,
      };
    })
  );
  const [selectedTask, setSelectedTask] = useState({
    search: { value: null, label: "All" },
    add: null,
  });

  const [sort, setSort] = useState(
    ATT(routeState.assignUser.processTasks, {
      parentProperty: "preTask",
      customID: "id",
    })
  );

  const [selectedUserR, setSelectedUserR] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [selectedUser, setSelectedUser] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [selectedUser2, setSelectedUser2] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [persons, setPersons] = useState([]);
  const [arts, setArts] = useState([]);
  const [taskOfProcess, setTaskOfProcess] = useState([]);
  const [selectedTaskCard, setSelectedTaskCard] = useState([]);
  const [executeConcurrently, setExecuteConcurrently] = useState(false);

  const getSingleTask = async (idd) => {
    let _task = [];
    if (props.taskIds.length > 0 && props.tasks[idd]) {
      _task = props.tasks[idd];
    } else {
      let res = await dispatch(getTaskById({ id: idd })).catch((err) => {
        showErrorMsg("Fehler bei Laden der Aufgabe.");
      });
      if (res.value?.data) {
        _task = res.value.data;
      }
    }
    setTaskDetail(_task);
  };
  const editSingleTask = async () => {
    let personsArr = [];
    persons.map((item) => {
      personsArr.push(item.id);
    });
    let obj = {
      id: selTask,
      name: taskDetail.name,
      description: taskDetail.description,
      preTask: taskDetail.preTask,
      responsible: selectedTaskCard[0].responsible,
      // responsible: selectedUserR.add.value,
      creator: taskDetail.creator,
      articles: taskDetail.articles,
      medias: taskDetail.medias,
      notifies: personsArr,
      benefactor: selectedUser.add === null ? null : selectedUser.add?.value,
      compeleted: false,
      dateBeDone: selectedTaskCard[0].dateBeDone,
      // dateBeDone: startDate === null ? Date.now : startDate,
      processId: taskDetail.processId,
      files: taskDetail.files,
      active: false,
      prevUser: taskDetail.responsible,
      toggle: executeConcurrently,
      dateCreated: taskDetail.dateCreated,
    };

    await dispatch(updateUserProcessTask({ task: obj }))
      .then(async () => {
        if (
          isProcessActivated &&
          !moment(selectedTaskCard[0].dateBeDone).isSame(startDate)
        ) {
          let oldDate = moment(selectedTaskCard[0].dateBeDone).format(
            `${t("momentjs_datum")}`
          );
          let newDate = moment(startDate).format(`${t("momentjs_datum")}`);
          let _comment =
            props.members[auth.getId()].firstName +
            " " +
            props.members[auth.getId()].lastName +
            " hat Fälligkeitsdatum verschoben von " +
            oldDate +
            " auf " +
            newDate;
          await dispatch(
            changeTaskDeadline({
              taskId: selTask,
              processId: taskDetail.processId,
              responsible: selectedTaskCard[0].responsible,
              userId: auth.getId(),
              deadline: startDate ? startDate : Date.now,
              comment: _comment,
            })
          );
        }

        if (
          isProcessActivated &&
          selectedUserR.add.value !== selectedTaskCard[0].responsible
        ) {
          let _comment =
            props.members[auth.getId()].firstName +
            " " +
            props.members[auth.getId()].lastName +
            " hat Namen für verantwortliche Person zu " +
            props.members[selectedUserR.add.value]?.firstName +
            " " +
            props.members[selectedUserR.add.value]?.lastName +
            " geändert";
          await dispatch(
            changeTaskResponsible({
              taskId: selTask,
              processId: taskDetail.processId,
              userId: auth.getId(),
              responsible: selectedUserR.add.value,
              prevRes: selectedTaskCard[0].responsible,
              comment: _comment,
            })
          );
        }

        setSuccess(true);
        getMembers();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        setOpenModal(false);
        setPersons([]);
        setArts([]);
        setSeltask(null);
        setStartDate(null);
        setTaskDetail(null);
        setSelectedUserR({
          search: { value: null, label: "All" },
          add: null,
        });
        setSelectedUser({
          search: { value: null, label: "All" },
          add: null,
        });
        setSelectedTask({
          search: { value: null, label: "All" },
          add: null,
        });
        setExecuteConcurrently(false);
        setName(
          members.find((item) => item.value === selectedUserR.add.value).label
        );
        setTaskName(tasks.find((item) => item.value === selTask).label);
        showSuccessMsg("Aufgabe erfolgreich bearbeitet.");
      })
      .catch((err) => {
        logError(err);
        let errMsg = "Fehler bei Bearbeitung der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
  };

  const setSingleTask = async () => {
    let personsArr = [];
    persons.map((item) => {
      personsArr.push(item.id);
    });
    let artsArr = [];
    arts.map((item) => {
      artsArr.push(item.id);
    });
    let obj = {
      id: selTask,
      name: tasks.find((item) => item.value === selTask).label,
      description: tasks.find((item) => item.value === selTask).des,
      preTask: tasks.find((item) => item.value === selTask).pre,
      responsible: selectedUserR.add?.value,
      creator: taskDetail.creator,
      articles: taskDetail.articles,
      medias: taskDetail.medias,
      notifies: personsArr,
      benefactor: selectedUser.add === null ? null : selectedUser.add?.value,
      compeleted: false,
      dateBeDone: startDate === null ? Date.now : startDate,
      processId: routeState.assignUser.process,
      files: taskDetail.files,
      active: false,
      userId: selectedUserR.add?.value,
      toggle: executeConcurrently,
      dateCreated: selectedTaskCard[0].dateCreated,
    };

    await dispatch(assignUserToProcessTask({ task: obj }))
      .then(async () => {
        setSuccess(true);
        getMembers();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        setOpenModal(false);
        setPersons([]);
        setArts([]);
        setSeltask(null);
        setStartDate(null);
        setTaskDetail(null);
        setSelectedUserR({
          search: { value: null, label: "All" },
          add: null,
        });
        setSelectedUser({
          search: { value: null, label: "All" },
          add: null,
        });
        setSelectedTask({
          search: { value: null, label: "All" },
          add: null,
        });
        setExecuteConcurrently(false);
        setName(
          members.find((item) => item.value === selectedUserR.add.value).label
        );

        setTaskName(tasks.find((item) => item.value === selTask).label);
        showSuccessMsg("Aufgabe erfolgreich zugewiesen.");
      })
      .catch((err) => {
        logError(err);
        let errMsg = "Fehler bei Zuweisung der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
  };

  const getMembers = async () => {
    const _member = [];
    const _memberTasks = [];
    if (props.memberIds.length > 0) {
      props.memberIds.forEach((id) => {
        _member.push({
          value: props.members[id].userId,
          label: props.members[id].firstName + " " + props.members[id].lastName,
          tasks: props.members[id].tasks,
        });
        _memberTasks.push(props.members[id].tasks);
      });
      setMembersData(_memberTasks);
      setMembers(_member);
    } else {
      setMembersData(routeState.assignUser.membersData);
      setMembers([]);
    }
  };

  const DeleteFromPersons = (id) => {
    const newArrr = persons.filter((item) => {
      return item.id !== id;
    });
    setPersons(newArrr);
  };

  useEffect(async () => {
    if (props.memberIds.length === 0) {
      await dispatch(searchUsers({ GetAll: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {
    await getMembers();
    await getProcess();
    let processTasks = await getTasksOfProcess();
    setTaskOfProcess(processTasks);
  }, [props.memberIds.length]);

  useEffect(() => {
    if (
      routeState?.assignUser?.processTasks?.length > 0 &&
      membersData?.length > 0
    ) {
      let ProcessTaskCount = routeState.assignUser.processTasks.length;
      let assignedTaskCount = 0;
      routeState.assignUser.processTasks.map((processTask) => {
        membersData.map((memberTask) => {
          if (memberTask && memberTask.length > 0) {
            let _task = memberTask.find(
              (item) =>
                item.id === processTask.id &&
                item.processId === routeState.assignUser.process
            );
            if (_task) {
              assignedTaskCount = assignedTaskCount + 1;
            }
          }
        });
      });

      if (ProcessTaskCount === assignedTaskCount) {
        setIsAllTasksAssigned(true);
      } else {
        setIsAllTasksAssigned(false);
      }
    }
  }, [routeState.assignUser.processTasks, membersData]);

  useEffect(() => {
    if (props.currentUser?.role === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [props.currentUser]);

  const activateProcessHandler = async () => {
    setOpenAlert(false);
    if (!process.isActivated) {
      await setButtonClicked(true);
      await dispatch(
        updateProcessStatus({ processId: routeState.assignUser.process })
      )
        .then((res) => {
          setButtonClicked(false);
          if (res.value?.data === true) {
            getProcess();
            setOpenActivatedAlert(true);
            showSuccessMsg("Prozess wurde erfolgreich aktiviert.");
          } else {
            showErrorMsg("Fehler bei Aktivierung des Prozesses.");
          }
        })
        .catch((err) => {
          setButtonClicked(false);
          logError(err);
          let errMsg = "Fehler bei Aktivierung des Prozesses.";
          if (err.response?.data?.errors) {
            errMsg =
              err.response.data.errors[
                Object.keys(err.response.data.errors)[0]
              ];
          }
          showErrorMsg(errMsg);
        });
    }
  };

  const getProcess = async () => {
    let _process = [];
    if (props.processes && props.processes[routeState.assignUser.process]) {
      _process = props.processes[routeState.assignUser.process];
    } else {
      await dispatch(getProcessById({ id: routeState.assignUser.process }))
        .then((res) => {
          if (res.value?.data) {
            _process = res.value.data;
          }
        })
        .catch((err) => logError(err));
    }

    if (_process) {
      setIsProcessActivated(_process.isActivated);
      setProcess(_process);
    }

    if (
      _process.responsible === auth.getId() ||
      _process.creator === auth.getId() ||
      props.currentUser?.role === "Admin"
    ) {
      setAllowUserToEdit(true);
    } else {
      setAllowUserToEdit(false);
    }
  };

  const getTasksOfProcess = async () => {
    let _tasks = [];
    await dispatch(getProcessTasks({ processId: routeState.process.id }))
      .then((res) => {
        if (res.value?.data) {
          _tasks = res.value.data.tasks;
        }
      })
      .catch((err) => {
        logError(err);
        let errMsg = "Fehler beim Laden der Prozessaufgaben";
        if (err.response?.data?.errors) {
          errMsg =
            err.response?.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
    return _tasks;
  };

  const ColorSwitch = withStyles({
    switchBase: {
      color: config.colorActive,
      "&$checked": {
        color: config.colorSecondary,
      },
      "&$checked + $track": {
        backgroundColor: config.colorSecondary,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const StyledFormControlLabel = withStyles(() => ({
    label: {
      fontSize: "1rem",
      marginLeft: "0px !important",
      fontWeight: "bolder",
      color: "#212529",
    },
  }))(FormControlLabel);

  const deadLineHandler = async (date) => {
    let preTaskId = selectedTaskCard[0]?.preTask ?? null;
    if (preTaskId) {
      let isValid = await validateDeadline(
        date,
        preTaskId,
        executeConcurrently
      );
      if (isValid) {
        setStartDate(date);
      } else {
        setStartDate("");
        showErrorMsg(
          "Achtung, das Datum liegt vor der vorhergehenden Aufgabe!"
        );
      }
    } else {
      setStartDate(date);
    }
  };

  const validateDeadline = async (date, preTaskId, canExecute) => {
    let isValid = false;
    let preTask = taskOfProcess?.find((item) => item.id === preTaskId);
    if (!canExecute) {
      if (
        preTask?.dateBeDone === "0001-01-01T00:00:00Z" ||
        moment(date).isBefore(preTask?.dateBeDone)
      ) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      if (preTask.preTask) {
        isValid = await validateDeadline(date, preTask.preTask, preTask.toggle);
      } else {
        isValid = true;
      }
    }
    return isValid;
  };

  useEffect(() => {
    deadLineHandler(startDate);
  }, [executeConcurrently]);

  return (
    <>
      <br></br>
      <div style={{ display: "flex" }}>
        <Button
          className="departments__add-new--button"
          onClick={() => BackToAssignTask()}
        >
          Zurück
        </Button>
      </div>
      <Modal
        open={openActivatedAlert}
        closeModal={() => {
          setOpenActivatedAlert(false);
          history.push(`/processes`);
        }}
      >
        <h5>Prozess wurde erfolgreich aktiviert.</h5>
        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenActivatedAlert(false);
              history.push(`/processes`);
            }}
          >
            Weiter
          </OutlinedButton>
        </div>
      </Modal>

      {success === true ? (
        <>
          <br></br>
          <div
            style={{
              padding: 10,
              fontSize: 20,
              backgroundColor: "#D2E298",
              width: "fit-content",
              borderRadius: "3px",
              color: "grey",
            }}
          >
            Aufgabe: {taskName} wurde erfolgreich an {name} vergeben.
            <span
              style={{
                position: "absolute",
                color: "red",
                marginTop: -25,
                marginLeft: -5,
                cursor: "pointer",
              }}
              onClick={() => setSuccess(false)}
            >
              <Close />
            </span>
          </div>
        </>
      ) : null}
      <br></br>
      <br></br>
      <br></br>
      {routeState.assignUser.processTasks.length === 0 ? (
        <>
          <div
            style={{
              width: "100%",
              height: 100,
              display: "flex",
              border: `1px solid ${config.colorSecondary}`,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              color: `${config.colorSecondary}`,
              fontSize: 22,
            }}
          >
            Aktuell sind diesem Prozess keine Aufgaben zugewiesen.
          </div>
        </>
      ) : (
        <>
          <Tree
            lineWidth={"2px"}
            lineColor={"green"}
            lineBorderRadius={"10px"}
            label={<StyledNodeR />}
          >
            <CustomCard data={sort} />
          </Tree>

          <AddButton
            className="departments__modal--add ml-0"
            valid={isAllTasksAssigned}
            onClick={activateProcessHandler}
            buttonClicked={buttonClicked}
            disabled={process?.isActivated}
            style={{
              cursor: process?.isActivated ? "not-allowed" : "pointer",
              float: "right",
              margin: "50px 0 0 0",
            }}
          >
            {process?.isActivated === false && "Prozess aktivieren"}
            {process?.isActivated === true && "Prozess aktiv"}
          </AddButton>
        </>
      )}
      <Modal
        title={isReassignAllowed ? "Aufgabe zuweisen" : "Aufgabendetails"}
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
          setSeltask(null);
          setPersons([]);
          setSelectedUserR({
            search: { value: null, label: "All" },
            add: null,
          });
          setSelectedUser({
            search: { value: null, label: "All" },
            add: null,
          });
          setStartDate(null);
          setEditmode(false);
          setExecuteConcurrently(false);
        }}
      >
        <div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Verantwortlichen eingeben:&nbsp;&nbsp;&nbsp;</strong>
            {!isReassignAllowed && <span>{selectedUserR?.add?.label}</span>}
          </div>
          {isReassignAllowed && (
            <Select
              className="sort-input positions__select-department"
              value={selectedUserR.add}
              placeholder="Verantwortliche Person auswählen"
              onChange={(e) => {
                setSelectedUserR({ add: e });
              }}
              options={members}
              isSearchable={true}
              // isOptionDisabled={() => editmode === true}
            />
          )}

          <div style={{ marginBottom: "10px" }}>
            <strong>Zu Gunsten von:&nbsp;&nbsp;&nbsp;</strong>
            {!isReassignAllowed && <span>{selectedUser?.add?.label}</span>}
          </div>
          {isReassignAllowed && (
            <Select
              className="sort-input positions__select-department"
              value={selectedUser.add}
              placeholder="Person wählen"
              onChange={(e) => {
                setSelectedUser({ add: e });
              }}
              options={members}
              isSearchable={true}
            />
          )}

          <div style={{ marginBottom: "10px" }}>
            <strong>Folgende Leute Informieren:</strong>
          </div>
          {isReassignAllowed && (
            <Select
              className="sort-input positions__select-department"
              value={selectedUser2.add}
              placeholder="Person wählen"
              onChange={(e) => {
                setPersons([...persons, { id: e.value, name: e.label }]);
              }}
              options={members}
              isSearchable={true}
            />
          )}

          {persons.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "fit-content",
              }}
            >
              {persons.map((person, index) => {
                return index !== 0 ? (
                  <div
                    style={{
                      padding: 5,
                      marginLeft: 10,
                      border: "1px solid #c9d2d2",
                      borderRadius: 5,
                    }}
                    key={index}
                  >
                    {person.name}{" "}
                    {isReassignAllowed && (
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => DeleteFromPersons(person.id)}
                        width={30}
                        height={30}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      padding: 5,
                      border: "1px solid #c9d2d2",
                      borderRadius: 5,
                    }}
                    key={index}
                  >
                    {person.name}{" "}
                    {isReassignAllowed && (
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => DeleteFromPersons(person.id)}
                        width={30}
                        height={30}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <span
              style={{
                color: "#c9d2d2",
                fontSize: "1.1rem",
                marginTop: "1.3rem",
              }}
            ></span>
          )}
        </div>

        <div style={{ marginTop: "15px" }}>
          <strong>Erledigen bis:&nbsp;&nbsp;&nbsp;</strong>
          {!isReassignAllowed && (
            <span>{moment(startDate).format(`${t("momentjs_datum")}`)}</span>
          )}
        </div>
        {isReassignAllowed && (
          <div style={{ marginTop: 10 }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => deadLineHandler(date)}
              placeholderText="Datum"
              minDate={new Date()}
            />
          </div>
        )}

        {selectedTaskCard[0]?.preTask && (
          <div style={{ marginTop: "15px" }} className="process-toggle">
            <StyledFormControlLabel
              control={
                <ColorSwitch
                  disabled={!isReassignAllowed ? true : false}
                  checked={executeConcurrently}
                  onChange={() => setExecuteConcurrently((prev) => !prev)}
                />
              }
              labelPlacement="start"
              label="Aufgabe kann parallel bearbeitet werden"
            />
          </div>
        )}

        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenModal(false);
              setSeltask(null);
              setPersons([]);
              setSelectedUserR({
                search: { value: null, label: "All" },
                add: null,
              });
              setSelectedUser({
                search: { value: null, label: "All" },
                add: null,
              });
              setStartDate(null);
              setEditmode(false);
              setExecuteConcurrently(false);
            }}
          >
            Abbrechen
          </OutlinedButton>

          {isReassignAllowed && (
            <AddButton
              className="departments__modal--add"
              onClick={editmode === true ? editSingleTask : setSingleTask}
              valid={selTask && selectedUserR.add ? true : false}
            >
              {isProcessActivated ? "Änderungen freigeben" : "Hinzufügen"}
            </AddButton>
          )}
        </div>
      </Modal>

      <Modal
        title={"Info"}
        open={editModal}
        closeModal={() => {
          setEditModal(false);
          setSeltask(null);
          setPersons([]);
          setSelectedUserR({
            search: { value: null, label: "All" },
            add: null,
          });
          setSelectedUser({
            search: { value: null, label: "All" },
            add: null,
          });
          setStartDate(null);
          setEditmode(false);
        }}
      >
        <h5>
          Diese Aufgabe wurde bereits zugewiesen.
          <br />
          Soll die Zuweisung bearbeitet werden?
        </h5>
        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setEditModal(false);
              setSeltask(null);
              setPersons([]);
              setSelectedUserR({
                search: { value: null, label: "All" },
                add: null,
              });
              setSelectedUser({
                search: { value: null, label: "All" },
                add: null,
              });
              setStartDate(null);
              setEditmode(false);
            }}
          >
            Abbrechen
          </OutlinedButton>

          <AddButton
            className="departments__modal--add"
            valid={true}
            onClick={() => {
              setEditModal(false);
              setOpenModal(true);
            }}
          >
            Bearbeiten
          </AddButton>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  memberIds: state.user.allIds,
  members: state.user.byId,
  taskIds: state.task.allIds,
  tasks: state.task.byId,
  processes: state.process.byId,
});

export default connect(mapStateToProps, null)(CustomCard);
