import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import eventss from "./eventss";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/dashboard.scss";
import "./events.scss";
import App from "../../App";
const DragAndDropCalendar = withDragAndDrop(Calendar);

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Events = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState(eventss);
  const [openModal, setOpenModal] = useState(false);

  function handleAddEvent() {
    setAllEvents([...allEvents, newEvent]);
    setOpenModal(false);
    setNewEvent({ title: "", start: "", end: "" });
  }

  const moveEvent = ({ event, start, end }) => {
    const idx = eventss.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...eventss];

    nextEvents.splice(idx, 1, updatedEvent);

    setAllEvents(nextEvents);
  };

  return (
    <App>
      <div>
        <div className="dashboard-links-wrapper event-variant">
          <ul className="dashboard-links-list">
            <li
              onClick={(event) => history.push(`/dashboard`)}
              className="dashboard-link"
            >
              {t("news")}
            </li>
            <li
              onClick={(event) => history.push(`/events`)}
              className="active-link dashboard-link"
            >
              {t("events")}
            </li>
            <li
              onClick={(event) => history.push(`/knowledge`)}
              className="dashboard-link"
            >
              {t("Knowledge")}
            </li>
          </ul>
        </div>
        <div>
          <div className="events-add-button-wrapper">
            <Button
              className="departments__add-new--button add-button-events-variant"
              onClick={() => setOpenModal(true)}
            >
              <AddIcon />
              {t("add_new_event")}
            </Button>
          </div>
          <Modal
            title={t("new_event")}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setNewEvent({ title: "", start: "", end: "" });
            }}
          >
            <input
              type="text"
              placeholder={t("title")}
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
            />
            <DatePicker
              placeholderText={t("start_date")}
              style={{ marginRight: "10px" }}
              selected={newEvent.start}
              onChange={(start) => setNewEvent({ ...newEvent, start })}
            />
            <DatePicker
              placeholderText={t("end_date")}
              selected={newEvent.end}
              onChange={(end) => setNewEvent({ ...newEvent, end })}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setNewEvent({ title: "", start: "", end: "" });
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={newEvent ? true : false}
                onClick={handleAddEvent}
              >
                {t("add_event")}
              </AddButton>
            </div>
          </Modal>
        </div>
        <div className="events-calender-wrapper">
          {/* <h1 style={{ textAlign: 'center' }}>Calendar</h1> */}
          <DragAndDropCalendar
            selectable
            localizer={localizer}
            events={allEvents}
            onEventDrop={moveEvent}
            startAccessor="start"
            endAccessor="end"
            style={{ width: "95%", height: "900px", margin: "10px 50px 50px" }}
          />
          {/* <h2>Add New Event</h2>
                        <div>
                              <input type="text" placeholder="Add Title" style={{ width: "20%", marginRight: "10px" }} value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
                              <DatePicker 
                                    placeholderText="Start Date" 
                                    style={{ marginRight: "10px" }} 
                                    selected={newEvent.start} onChange={(start) => setNewEvent({ ...newEvent, start })} 
                              />
                              <DatePicker 
                                    placeholderText="End Date" 
                                    selected={newEvent.end} 
                                    onChange={(end) => setNewEvent({ ...newEvent, end })} 
                              />
                              <button style={{ marginTop: "10px" }} onClick={handleAddEvent}>
                                    Add Event
                              </button>
                        </div> */}
        </div>
      </div>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(Events);
