import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import auth from "../../auth";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import { useTranslation } from "react-i18next";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import App from "../../App";
import {
  getProcessesByUser,
  getProcessTasks,
  deleteProcess,
} from "../../redux/process/action";
import { searchUsers } from "../../redux/users/action";
import { useHistory } from "react-router";
import Pagination from "../../components/pagination/pagination";
import SortInput from "../../components/sortInput/sortInput";
import SnackbarError from "../../components/alert/SnackbarError";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import Select from "react-select";
import Search from "../../components/search/search";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";

const MyProcesses = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([
    { value: null, label: "All" },
  ]);
  const [search, setSearch] = useState("");
  const [deadLines, setDeadLines] = useState([]);
  const [loading, setLoading] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [processToDelete, setProcessToDelete] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;

    const _statusOptions = [
      { value: null, label: "Alle" },
      { value: "true", label: "Aktiv" },
      { value: "false", label: "Inaktiv" },
    ];
    setStatuses(_statusOptions);

    const fetchData = async () => {
      if (props.memberIds.length === 0) {
        await dispatch(searchUsers({ GetAll: true }));
      }

      await dispatch(getProcessesByUser({ userId: auth.getId() })).catch(
        (err) => {
          logError(err);
          setErrorMessage("Fehler bei Laden des Prozesseses.");
          setOpenAlert(true);
        }
      );
    };
    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(async () => {
    await getMembers();
  }, [props.memberIds.length]);

  const getMembers = async () => {
    const _memberTasks = [];
    if (props.memberIds.length > 0) {
      props.memberIds.forEach((id) => {
        _memberTasks.push(props.members[id].tasks);
      });
      setMembersData(_memberTasks);
    } else {
      setMembersData([]);
    }
  };

  const getProcessStatus = (process) => {
    let status = "";
    if (!process.isActivated) {
      status = "Inactive";
      return status;
    }
    const processTotalTasks = process.tasks.length;
    let completedTasksCount = 0;
    process.tasks?.map((task) => {
      membersData?.map((memberTask) => {
        if (memberTask && memberTask.length > 0) {
          let _task = memberTask.find(
            (item) =>
              item.id === task.id &&
              item.processId === process.id &&
              item.compeleted === true &&
              item.approved === true
          );
          if (_task) {
            completedTasksCount = completedTasksCount + 1;
          }
        }
      });
    });

    if (process.isActivated && processTotalTasks === completedTasksCount) {
      status = "Done";
    } else {
      status = "Active";
    }
    return status;
  };

  const hanldeProcssClick = (id) => {
    history.push(`/process/${id}`);
  };

  const getPageNumber = (newPage) => {
    dispatch(
      getProcessesByUser({
        userId: auth.getId(),
        PageNumber: newPage,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
        Active: selectedFilter.value,
      })
    );
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      getProcessesByUser({
        userId: auth.getId(),
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
        Active: selectedFilter.value,
      })
    );
  };

  const getProcessByStatus = (e) => {
    setSelectedFilter(e);
    dispatch(
      getProcessesByUser({
        userId: auth.getId(),
        Search: search.replace(/\s+/g, " ").trim(),
        Active: e.value,
        Sort: selectedSort,
        PageNumber: props.pageParams.currentPage,
      })
    ).catch((err) => {
      logError(err);
      setErrorMessage("Fehler bei Laden des Prozesses.");
      setOpenAlert(true);
    });
    setDefaultPage(!defaultPage);
  };

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        getProcessesByUser({
          userId: auth.getId(),
          PageNumber: props.pageParams.currentPage,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
          Active: selectedFilter.value,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  useEffect(async () => {
    let temp = [];
    setLoading(true);
    if (props.assignedProcesses.length > 0) {
      for (const item of props.assignedProcesses) {
        if (item) {
          let _tasks = [];
          await dispatch(getProcessTasks({ processId: item.id })).then(
            (res) => {
              if (res.value?.data) {
                _tasks = res.value.data.tasks;
                let latest = {};
                if (_tasks.length > 0) {
                  latest = _tasks.reduce(function (r, a) {
                    return r.dateBeDone > a.dateBeDone ? r : a;
                  });
                }
                if (Object.keys(latest).length > 0) {
                  temp[item.id] = moment(latest.dateBeDone).format(
                    "DD.MM.YYYY"
                  );
                }
              }
            }
          );
        }
      }
      setDeadLines(temp);
    }
    setLoading(false);
  }, [props.assignedProcesses]);

  const deleteProcessHandler = async () => {
    await setButtonClicked(true);
    await dispatch(
      deleteProcess({
        processId: processToDelete.id,
      })
    )
      .then(async () => {
        setButtonClicked(false);
        setShowConfirmDeleteModal(false);
        setProcessToDelete([]);
        await dispatch(getProcessesByUser({ userId: auth.getId() }));
        setSuccessMessage("Prozess wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Fehler bei Löschen des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Breadcrumbs navigations={["Meine Prozesse"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
        </div>
        <div className="sort department__dropdown">
          <label style={{ marginRight: "3.7rem" }}>{t("status")}: </label>
          <Select
            className="sort-input"
            value={selectedFilter}
            options={statuses}
            onChange={(e) => {
              getProcessByStatus(e);
            }}
            isSearchable={false}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("date_created")}</TableCell>
              <TableCell align="left">{t("deadline")}</TableCell>
              <TableCell align="left">{t("Status")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading || loading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : !props.isLoading &&
              !loading &&
              props.assignedProcesses?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>
                    {t("Aktuell keine Prozesse hinterlegt")}
                  </NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  !loading &&
                  props.assignedProcesses?.length > 0 &&
                  props.assignedProcesses.map((process, index) => {
                    return (
                      <TableRow
                        key={process.id}
                        className="departments__table-row"
                        style={{ backgroundColor: "white" }}
                      >
                        <TableCell component="th" scope="row">
                          {process.no + "."}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => hanldeProcssClick(process.id)}
                        >
                          {process.name}
                        </TableCell>
                        <TableCell align="left">
                          {moment(process.dateCreated).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {deadLines[process.id] ? deadLines[process.id] : ""}
                        </TableCell>
                        <TableCell align="left">
                          {getProcessStatus(process)}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <DeleteIcon
                              className={`${
                                process.isTemplate ? "disabled" : ""
                              }`}
                              onClick={() => {
                                if (!process.isTemplate) {
                                  setShowConfirmDeleteModal(true);
                                  setProcessToDelete(process);
                                }
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.assignedProcesses?.length ? null : (
        <>
          {props.assignedProcesses.length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
      <Modal
        title={""}
        open={showConfirmDeleteModal}
        closeModal={() => {
          setShowConfirmDeleteModal(false);
          setProcessToDelete([]);
        }}
      >
        {processToDelete?.isActivated && (
          <p>
            {t(
              "Das ist ein aktiver Prozess. Wollen Sie diesen Prozess und die Aufgaben für immer löschen?"
            )}{" "}
          </p>
        )}

        {!processToDelete?.isActivated && (
          <p>{t("Wollen Sie diesen Prozess für immer löschen?")} </p>
        )}
        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setShowConfirmDeleteModal(false);
              setProcessToDelete([]);
            }}
          >
            {t("Nein")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={true}
            buttonClicked={buttonClicked}
            onClick={deleteProcessHandler}
          >
            {t("Ja")}
          </AddButton>
        </div>
      </Modal>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  memberIds: state.user.allIds,
  members: state.user.byId,
  pageParams: state.process.assignedProcessesPageParams,
  assignedProcesses: state.process.assignedProcesses,
  isLoading: state.process.isLoading,
});

export default connect(mapStateToProps, null)(MyProcesses);
