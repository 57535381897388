import { 
  SEARCH_TEAMS,
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchTeams = (params) => ({
  type: SEARCH_TEAMS,
  payload: generateAuthRequest('GET','api/Team', params)
});

export const addTeam = (params) => ({
  type: ADD_TEAM,
  payload: generateAuthRequest('POST','api/Team', params)
});

export const updateTeam = (params) => ({
  type: UPDATE_TEAM,
  //payload: generateAuthRequest('PUT',`api/Team/${params.teamId}`, params)
  payload: generateAuthRequest('PUT',`api/Team/update/${params.teamId}`, params)
}); 

export const deleteTeam = (params) => ({
  type: DELETE_TEAM,
  //payload: generateAuthRequest('DELETE',`api/Team/${params.teamId}`, params)
  payload: generateAuthRequest('DELETE',`api/Team/delete/${params.teamId}`, params)
});
