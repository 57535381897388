import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Pagination from "../../components/pagination/pagination";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import ArticleModal from "./articleModal";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import "../../assets/css/pages/articles.scss";
import { useTranslation } from "react-i18next";
import App from "../../App";
import { searchArticles, deleteArticle } from "../../redux/article/action";
import auth from "../../auth";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import SnackbarError from "../../components/alert/SnackbarError";

const Articles = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [articleEditId, setArticleEditId] = useState(null);
  const [openArticleModal, setOpenArticleModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const DeleteArticleModal = (id) => {
    if (!isAdmin && props.articles[id].authorId !== auth.getId()) return;

    setArticleEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteArticle = async () => {
    await setButtonClicked(true);
    await dispatch(
      deleteArticle({
        articleId: articleEditId,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setArticleEditId(null);
        dispatch(
          searchArticles({
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
            Include_owner: true,
          })
        );
        setSuccessMessage("Artikel wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setArticleEditId(null);
        let errMsg = "Fehler bei Löschen des Artikels.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditArticleModal = (id) => {
    if (!isAdmin && props.articles[id].authorId !== auth.getId()) return;

    setArticleEditId(id);
    setOpenArticleModal(true);
  };

  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      dispatch(
        searchArticles({
          PageNumber: 1,
          Search: search.replace(/\s+/g, " ").trim(),
          Include_owner: true,
          Sort: selectedSort,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchArticles({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
        Include_owner: true,
      })
    );
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchArticles({
        PageNumber: newPage,
        Search: search.replace(/\s+/g, " ").trim(),
        Sort: selectedSort,
        Include_owner: true,
      })
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;
    dispatch(searchArticles({ Include_owner: true }));

    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const successMsgHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  const errorMsgHandler = (msg) => {
    setErrorMessage(msg);
    setOpenAlert(true);
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Breadcrumbs navigations={["Artikel"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            onClick={() => {
              return setOpenArticleModal(true);
              // resetValues()
            }}
          >
            <AddIcon /> {t("Neu")}
          </Button>
          <Modal
            title={"Löschen"}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Soll dieser Artikel wirklich gelöscht werden?")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteArticle}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>
          <ArticleModal
            open={openArticleModal}
            closeModal={() => {
              setOpenArticleModal(false);
              setArticleEditId(null);
            }}
            articleId={articleEditId}
            showSuccessMsg={successMsgHandler}
            showErrorMsg={errorMsgHandler}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !props.isLoading && !props.articleIds?.length ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_article")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {props.articleIds?.length > 0 &&
                  props.articleIds?.map((id) => {
                    return (
                      props.articles[id] && (
                        <TableRow key={id} className="departments__table-row">
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={(event) => history.push(`/article/${id}`)}
                          >
                            {props.articles[id].no + "."}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={(event) => history.push(`/article/${id}`)}
                          >
                            {props.articles[id].title}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                className={`${
                                  isAdmin ||
                                  props.articles[id].authorId === auth.getId()
                                    ? ""
                                    : "disabled"
                                }`}
                                onClick={() => EditArticleModal(id)}
                              />
                              <DeleteIcon
                                className={`${
                                  isAdmin ||
                                  props.articles[id].authorId === auth.getId()
                                    ? ""
                                    : "disabled"
                                }`}
                                onClick={() => DeleteArticleModal(id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.articleIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  allArticleIds: state.article.allIds,
  articles: state.article.byId,
  articleIds: state.article.searchIds,
  isLoading: state.article.isLoading,
  pageParams: state.article.pageParams,
});

export default connect(mapStateToProps, null)(Articles);
