import { 
  SEARCH_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  DEPARTMENTS_BY_LOC
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchLocations = (params) => ({
  type: SEARCH_LOCATIONS,
  payload: generateAuthRequest('GET','api/Location', params)
});

export const addLocation = (params) => ({
  type: ADD_LOCATION,
  payload: generateAuthRequest('POST','api/Location', params)
});

export const updateLocation = (params) => ({
  type: UPDATE_LOCATION,
  //payload: generateAuthRequest('PUT',`api/Location/${params.locationId}`, params)
  payload: generateAuthRequest('PUT',`api/Location/update/${params.locationId}`, params)
}); 

export const deleteLocation = (params) => ({
  type: DELETE_LOCATION,
  //payload: generateAuthRequest('DELETE',`api/Location/${params.locationId}`, params)
  payload: generateAuthRequest('DELETE',`api/Location/delete/${params.locationId}`, params)
});

export const getDepartmentsByLoc = (params) => ({
  type: DEPARTMENTS_BY_LOC,
  payload: generateAuthRequest('GET','api/Department', params)
});
