/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// import { ReactComponent as Dot } from '../../assets/images/layout/dot.svg';
// import { ReactComponent as DotColor } from '../../assets/images/layout/dotColor.svg';
import { ReactComponent as MenuIcon } from "../../assets/images/layout/menu.svg";

import { useTranslation } from "react-i18next";

import { MENUITEMS } from "./menu";

import '../../assets/css/layout.scss';


import Logo from "../logo/logo.js";

const drawerWidth = "19.063rem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    borderRight: "none",
  },
  drawerOpen: {
    boxShadow: "0 0 21px 0 rgb(89 102 122 / 10%)",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    boxShadow: "0 0 21px 0 rgb(89 102 122 / 10%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  nestedNoPadding: {
    paddingLeft: theme.spacing(5.2),
  },
}));

const Sidebar = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const [open, setOpen] = useState(true);
  const [openCollapse, setOpenCollapse] = useState({
    Knowledge: false,
    Organization: false,
  });
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const location = useLocation();

  useEffect(() => {
    MENUITEMS.forEach((a) => {
      let item =
        a.Items.find((x) => x.path == location?.pathname) ??
        a.Items.find((x) =>
          x.children?.find((y) => y.path == location?.pathname)
        );
      if (item?.children)
        setOpenCollapse({ ...openCollapse, [item.title]: true });

      toggletNavActive(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggletNavActive = (item) => {
    if (!item?.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
          });
          return Items;
        });
        return a;
      });
    }

    // if (item) item.active = !item.active;
    if (item) item.active = true;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!open)
      setOpenCollapse({
        Knowledge: false,
        Organization: false,
      });
  }, [open]);

  return (
    <div className={classes.root + " sidebar"}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className="sidebar__menu-button">
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
          >
            <MenuIcon className="sidebar__menu-icon" />
          </IconButton>

          {open ? null : null}
        </div>
        {MENUITEMS.map((Item, i) => (
          <List key={i} className="sidebar__menu-list">
            {Item.Items.map((menuItem, i) => (
              <Fragment key={i}>
                {menuItem.type === "sub" ? (
                  <ListItem
                    key={i}
                    button
                    className="sidebar__menu-organization"
                    selected={menuItem.active}
                    onClick={(_) => {
                      // setOpenCollapse(!openCollapse);
                      setOpenCollapse({
                        [!menuItem.title]: false,
                        [menuItem.title]: !openCollapse[menuItem.title],
                      });
                      toggletNavActive(menuItem);
                    }}
                  >
                    <ListItemIcon>
                      {menuItem.active ? (
                        <menuItem.iconColor />
                      ) : (
                        <menuItem.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(menuItem.title)}
                      className="menu__list-text"
                    />
                    {openCollapse[menuItem.title] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                ) : (
                  ""
                )}

                {menuItem.type === "link" ? (
                  <ListItem
                    key={i}
                    button
                    component={Link}
                    to={menuItem.path}
                    selected={menuItem.active}
                    onClick={() => {
                      toggletNavActive(menuItem);
                      setOpenCollapse({
                        Knowledge: false,
                        Organization: false,
                      });
                    }}
                  >
                    <ListItemIcon>
                      {menuItem.active ? (
                        <menuItem.iconColor />
                      ) : (
                        <menuItem.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(menuItem.title)}
                      className="menu__list-text"
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                {menuItem.children ? (
                  <Collapse
                    in={openCollapse[menuItem.title]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      className="sidebar__menu-sublist"
                    >
                      {menuItem.children.map((childrenItem, i) => {
                        return (
                          <ListItem
                            key={i}
                            button
                            component={Link}
                            to={childrenItem.path}
                            className={
                              open ? classes.nested : classes.nestedNoPadding
                            }
                            selected={childrenItem.active}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <ListItemIcon className="menu__sublist--icon">
                              {/* {childrenItem.active ? <DotColor /> : <Dot />} */}
                              {childrenItem.active
                                ? childrenItem.iconColor
                                : childrenItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={t(childrenItem.title)}
                              className="menu__list-text menu__sublist-text"
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                ) : (
                  ""
                )}
              </Fragment>
            ))}
          </List>
        ))}
      </Drawer>
    </div>
  );
};

export default Sidebar;
