import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Pagination from "../../components/pagination/pagination";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import DepartmentMembers from "../Departments/departmentMembers";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import config from "../../config/api.config.js";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import "../../assets/css/pages/positions.scss";
import App from "../../App";
import { useTranslation } from "react-i18next";
import {
  searchTeams,
  addTeam,
  updateTeam,
  deleteTeam,
} from "../../redux/team/action";
import {
  searchLocations,
  getDepartmentsByLoc,
} from "../../redux/location/action";
import {
  searchDepartments,
  getTeamsByDepartment,
} from "../../redux/department/action";
import { searchUsers } from "../../redux/users/action";
import MembersSelect from "../../components/dropdown/membersSelect";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";

const Teams = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState(null);
  const [locationsForAdd, setLocationsForAdd] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [departmentsForAdd, setDepartmentsForAdd] = useState([]);
  const [selectedSort, setSelectedSort] = useState(1);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [openTeamMember, setOpenTeamMember] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamMembers, setTeamMembers] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [teamEditId, setTeamEditId] = useState(null);
  const [positionId, setPositionId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [defaultPage, setDefaultPage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedOptionsLocations, setSelectedOptionsLocations] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [selectedPosition, setSelectedPosition] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [selectedHead, setSelectedHead] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const getDepartments_Drop = async (locid) => {
    if (
      Object.keys(props.departmentsByLoc).length === 0 ||
      !props.departmentsByLoc[locid] ||
      props.departmentsByLoc[locid].length === 0
    ) {
      await dispatch(getDepartmentsByLoc({ LocationId: locid }));
    } else {
      setDepartmentDropdown(locid);
    }
  };

  useEffect(() => {
    if (selectedPosition && selectedPosition.add?.value) {
      setDepartmentDropdown(selectedPosition.add.value);
    }
  }, [props.departmentsByLoc]);

  const setDepartmentDropdown = (locId) => {
    if (props.departmentsByLoc[locId]) {
      setDepartmentsForAdd(
        props.departmentsByLoc[locId].map((dep) => {
          return {
            value: dep.id,
            label: dep.name,
            locationid: dep.locationId,
          };
        })
      );
    } else {
      setDepartmentsForAdd([]);
    }
  };

  const AddTeam = async () => {
    if (!isAdmin) return;

    await setButtonClicked(true);

    let reqData = {
      name: teamName,
      departmentId: selectedOptionsLocations.add.value,
    };
    if (selectedHead) {
      reqData.headId = selectedHead;
    }
    await dispatch(addTeam(reqData))
      .then((res) => {
        updateTeamsByDepartment(selectedOptionsLocations.add.value);
        setButtonClicked(false);
        setOpenModal(false);
        setTeamName("");
        setSelectedPosition({ ...selectedPosition, add: null });
        setSelectedHead("");
        setSelectedOptionsLocations({ ...selectedOptionsLocations, add: null });
        setDepartmentsForAdd([]);
        setSuccessMessage("Team erfolgreich hinzugefügt.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Hinzufügen von Team.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditTeamModal = (id, name, positionId, headId) => {
    if (!isAdmin) return;

    setPositionId(positionId);
    setTeamEditId(id);
    setTeamName(name);
    setSelectedHead(headId);
    setOpenModal(true);
  };

  const EditTeam = async () => {
    let reqData = {
      teamId: teamEditId,
      name: teamName,
      headId: selectedHead,
      departmentId: positionId,
    };
    await setButtonClicked(true);
    await dispatch(updateTeam(reqData))
      .then((res) => {
        updateTeamsByDepartment(positionId);
        setButtonClicked(false);
        setOpenModal(false);
        setTeamEditId(null);
        setTeamName("");
        setSelectedHead("");
        setSuccessMessage("Team wurde erfolgreich bearbeitet.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeitung von Team.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const DeleteTeamModal = (id, positionId) => {
    if (!isAdmin) return;

    setPositionId(positionId);
    setTeamEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteTeam = async () => {
    await setButtonClicked(true);
    dispatch(
      deleteTeam({
        teamId: teamEditId,
      })
    )
      .then((res) => {
        updateTeamsByDepartment(positionId);
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setTeamEditId(null);
        dispatch(
          searchTeams({
            Sort: selectedSort,
            PageNumber: 1,
            Name: search.trim(),
            DepartmentId: null,
          })
        );
        setSuccessMessage("Team wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setTeamEditId(null);
        let errMsg = "Fehler bei Löschen des Teams.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchTeams({
        PageNumber: newPage,
        Name: search,
        Sort: selectedSort,
      })
    );
  };

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchTeams({
          PageNumber: 1,
          Name: search.trim(),
          PositionId: selectedPosition.search.value,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchTeams({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
      })
    );
  };

  const getTeamByDepartment = (e) => {
    setSelectedPosition({ selectedPosition, search: e });
    dispatch(
      searchTeams({
        DepartmentId: e.value,
        Sort: selectedSort,
        Name: search.trim(),
      })
    );
    setDefaultPage(!defaultPage);
  };

  const handleTeamMember = async (
    teamId,
    name,
    positionId,
    search = null,
    pageNumber = 1
  ) => {};

  useEffect(
    (_) => {
      const abortController = new AbortController();
      if (!props.currentUser) return;

      if (props.currentUser.role === "Admin") {
        setIsAdmin(true);
      }

      if (props.allTeamIds.length === 0) {
        dispatch(searchTeams({ GetAll: true }));
      }

      if (props.locationIds.length === 0) {
        dispatch(searchLocations({ GetAll: true }));
      }

      if (props.departmentIds.length === 0) {
        dispatch(searchDepartments({ GetAll: true }));
      }

      if (props.memberIds.length === 0) {
        dispatch(searchUsers({ GetAll: true }));
      }

      dispatch(
        searchTeams({
          Sort: 1,
          PageNumber: 1,
          DepartmentId: null,
        })
      );

      return () => {
        abortController.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentUser]
  );

  useEffect(() => {
    if (props.locationIds.length > 0) {
      const _loc = new Array({ value: null, label: "All" });
      props.locationIds.forEach((id) => {
        _loc.push({
          value: id,
          label: props.locations[id].name,
        });
      });
      setLocations(_loc);
      setLocationsForAdd(
        props.locationIds.map((id) => {
          return {
            value: id,
            label: props.locations[id].name,
          };
        })
      );
    } else {
      setLocations(null);
      setLocationsForAdd([]);
    }
  }, [props.locationIds.length]);

  useEffect(() => {
    if (props.departmentIds.length > 0) {
      const _pos = new Array({ value: null, label: "All" });
      props.departmentIds.forEach((id) => {
        _pos.push({
          value: id,
          label: props.departments[id].name,
          locationid: props.departments[id].locationId,
        });
      });
      setDepartments(_pos);
    } else {
      setDepartments(null);
    }
  }, [props.departmentIds.length, props.departmentIds]);

  useEffect((_) => {
    setSearch("");
  }, []);

  const updateTeamsByDepartment = (depId) => {
    if (
      Object.keys(props.teamsByDepartment).length > 0 &&
      props.teamsByDepartment[depId]
    ) {
      dispatch(getTeamsByDepartment({ DepartmentId: depId }));
    }
  };

  const headChangeHandler = (selectedVal) => {
    setSelectedHead(selectedVal);
  };

  const getHeadName = (id) => {
    let name = "";
    if (id && props.members[id]) {
      name = props.members[id].firstName + " " + props.members[id].lastName;
    }
    return name;
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Teams"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className={`departments__add-new--button ${
              isAdmin && props.departmentIds.length > 0 ? "" : "disabled"
            }`}
            onClick={() => setOpenModal(true)}
            disabled={isAdmin && props.departmentIds.length > 0 ? false : true}
          >
            <AddIcon /> {t("Neu")}
          </Button>

          <Modal
            title={teamEditId ? "Team bearbeiten" : "Neues Team"}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setTeamName("");
              setSelectedPosition({ ...selectedPosition, add: null });
              setSelectedOptionsLocations({
                ...selectedOptionsLocations,
                add: null,
              });
              setTeamEditId(null);
              setSelectedHead("");
              setDepartmentsForAdd([]);
            }}
          >
            {!teamEditId ? (
              <>
                <Select
                  className="sort-input positions__select-department"
                  value={selectedTeam.add}
                  placeholder="Wähle Standort"
                  onChange={(e) => {
                    setSelectedPosition({ add: e });
                    setSelectedOptionsLocations({
                      ...selectedOptionsLocations,
                      add: null,
                    });
                    getDepartments_Drop(e.value);
                  }}
                  options={locationsForAdd}
                  isSearchable={false}
                />

                <Select
                  className="sort-input positions__select-department"
                  value={selectedOptionsLocations.add}
                  placeholder="Wähle Abteilung"
                  onChange={(e) => {
                    setSelectedOptionsLocations({ add: e });
                  }}
                  options={departmentsForAdd}
                  isSearchable={false}
                />
              </>
            ) : null}
            <input
              type="text"
              placeholder="Team"
              value={teamName}
              onChange={({ target }) => setTeamName(target.value)}
              style={{ marginBottom: "1.5rem" }}
            />
            <MembersSelect
              className="sort-input positions__select-department"
              placeholder={"Teamverantwortlich"}
              selectedOption={selectedHead}
              onChange={headChangeHandler}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setTeamName("");
                  setSelectedPosition({ ...selectedPosition, add: null });
                  setSelectedOptionsLocations({
                    ...selectedOptionsLocations,
                    add: null,
                  });
                  setTeamEditId(null);
                  setSelectedHead("");
                  setDepartmentsForAdd([]);
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={
                  (teamName && selectedPosition.add) || (teamName && teamEditId)
                    ? true
                    : false
                }
                buttonClicked={buttonClicked}
                onClick={teamEditId ? EditTeam : AddTeam}
              >
                {teamEditId ? "Bearbeiten" : "Hinzufügen"}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Team löschen?")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteTeam}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <DepartmentMembers
            department={"Teams"}
            departmentName={selectedTeam}
            positionId={positionId}
            teamId={teamEditId}
            departmentMembers={teamMembers}
            getSerachedMembers={handleTeamMember}
            open={openTeamMember}
            closeModal={() => setOpenTeamMember(false)}
          />
        </div>
        <div className="sort department__dropdown">
          <label>{t("Department")}: </label>
          <Select
            className="sort-input"
            value={selectedPosition.search}
            onChange={(e) => {
              getTeamByDepartment(e);
            }}
            options={departments}
            isSearchable={false}
          />
        </div>
        <SortInput className="spe" handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Nr.")}.</TableCell>
              <TableCell align="left">{t("Team")}</TableCell>
              <TableCell align="left">{t("Abteilung")}</TableCell>
              <TableCell align="left">{t("Standort")}</TableCell>
              <TableCell align="left">{t("Verantwortlich")}</TableCell>
              {isAdmin && <TableCell>{t("Datenbank ID")}</TableCell>}
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !props.isLoading &&
              (!props.teamIds?.length ||
                !departments?.length ||
                !locations?.length) ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <NothingFound>{t("nothing_found_team")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  props.teamIds.length > 0 &&
                  props.teamIds?.map((id) => {
                    return (
                      props.teams[id] && (
                        <TableRow key={id} className="departments__table-row">
                          <TableCell component="th" scope="row">
                            {props.teams[id].no + "."}
                          </TableCell>
                          <TableCell align="left">
                            {props.teams[id].name}
                          </TableCell>
                          <TableCell align="left">
                            {
                              departments.find(
                                (p) => p.value === props.teams[id].departmentId
                              )?.label
                            }
                          </TableCell>
                          <TableCell>
                            {
                              locations.find(
                                (p) =>
                                  p.value ===
                                  departments.find(
                                    (p) =>
                                      p.value === props.teams[id].departmentId
                                  )?.locationid
                              )?.label
                            }
                          </TableCell>
                          <TableCell align="left">
                            {getHeadName(props.teams[id].headId)}
                          </TableCell>
                          {isAdmin && (
                            <TableCell component="th" scope="row">
                              {id}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() =>
                                  EditTeamModal(
                                    id,
                                    props.teams[id].name,
                                    props.teams[id].departmentId,
                                    props.teams[id].headId
                                  )
                                }
                              />
                              <DeleteIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() =>
                                  DeleteTeamModal(
                                    id,
                                    props.teams[id].departmentId
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
        {!props.teamIds?.length && search ? null : (
          <>
            {Object.keys(props.pageParams).length > 0 && (
              <Pagination
                params={props.pageParams}
                getPageNumber={getPageNumber}
                defaultPage={defaultPage}
              />
            )}
          </>
        )}
      </TableContainer>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.team.isLoading,
  allTeamIds: state.team.allIds,
  teams: state.team.byId,
  teamIds: state.team.searchIds,
  pageParams: state.team.pageParams,
  locationIds: state.location.allIds,
  locations: state.location.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
  departmentsByLoc: state.location.departments,
  teamsByDepartment: state.department.teams,
  memberIds: state.user.allIds,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(Teams);
