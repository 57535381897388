import axios from "axios";
import auth from "../auth";
import config from "../config/api.config.js";

const generateAuthRequest = (method, url, params = {}, id = null) => {
  return new Promise((resolve, reject) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.accessToken}`,
    };

    if (method.toLowerCase() === "get") {
      axios
        .get(`${config.apiURL}/${url}`, {
          params: params,
          headers 
        })
        .then(({ data }) => {
          resolve({ data, params });
        })
        .catch(reject);
    }
    if (method.toLowerCase() === "post") {
      axios
        .post(`${config.apiURL}/${url}`, params, { headers })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    }
    if (method.toLowerCase() === "delete") {
      axios
      /*.delete(`${config.apiURL}/${url}`, {
        headers: headers,
        data: params
      })*/
      .post(`${config.apiURL}/${url}`, params, { headers: headers }) //changed to post
        .then(({ data }) => {
          resolve({ data, params });
        })
        .catch(reject);
    }
    if (method === "_PUT") {
      axios      
      .put(`${config.apiURL}/${url}`, params, { headers: headers }) 
        .then(({ data }) => {
          resolve({ data, params, id });
        })
        .catch(reject);
      }
    if (method.toLowerCase() === "put") {
      axios
      /*.put(
        `${config.apiURL}/${url}`,
        params,
        {
          headers: headers,
        }
      )*/       
      .post(`${config.apiURL}/${url}`, params, { headers: headers }) //changed to post
        .then(({ data }) => {
          resolve({ data, params, id });
        })
        .catch(reject);
    }
  });
};

export default generateAuthRequest;
