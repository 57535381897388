import React, { useState } from "react";
import Select from "react-select";

import "./sortInput.scss";

import { useTranslation } from "react-i18next";

const SortInput = ({ handleSort }) => {
  const { t } = useTranslation();
  const options = [
    { value: 1, label: `${t("sort_newest_first")}` },
    { value: 2, label: `${t("sort_latest_first")}` },
    { value: 3, label: "A-Z" },
    { value: 4, label: "Z-A" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <div className="sort gt">
      <label style={{ marginRight: "2.5rem" }}>{t("sort_by")}: </label>
      <Select
        className="sort-input"
        defaultValue={selectedOption}
        onChange={(e) => {
          handleSort(e);
          setSelectedOption(e);
        }}
        options={options}
        isSearchable={false}
      />
    </div>
  );
};

export default SortInput;
