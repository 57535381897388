import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from "@material-ui/lab/Skeleton";
import PopupMenu from "../../components/popupMenu/popupMenu";
import { ReactComponent as MenuIcon } from "../../assets/images/layout/menu.svg";
import { setCurrentUser } from "../../redux/users/action";
import auth from "../../auth";

import { useTranslation } from "react-i18next";

import "../../assets/css/layout.scss";
import Logo from "../logo/logo.js";

const Header = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [invisible, setInvisible] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: null,
    roles: null,
  });
  const [logoMargin, setLogoMargin] = useState("");

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        setLogoMargin("245px");
      }, 100);
    } else {
      setLogoMargin("12px");
    }
  }, [props.open]);

  const handleDrawer = () => {
    props.setOpen(!props.open);
  };

  useEffect(() => {
    if (!auth.getId()) return;
    if (!props.currentUser) {
      dispatch(setCurrentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.currentUser) return;
    setUser({
      firstName: props.currentUser.firstName,
      lastName: props.currentUser.lastName,
      email: props.currentUser.email,
      role: props.currentUser.role,
    });
  }, [props.currentUser]);

  return (
    <div className="header">
      <div className="header__left">
        <div className="sidebar__menu-button">
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
          >
            <MenuIcon className="sidebar__menu-icon" />
          </IconButton>
          <div style={{ marginLeft: logoMargin, height:"95%" }}>
            <Logo />
          </div>
        </div>
      </div>
      <div className="header__right">
        {/* <AddMember />
        <LanguageSwitch/> */}
        {/* <Organization /> */}
        {/* <Settings /> */}
        {/* <Badge
          color="error"
          variant="dot"
          overlap="circle"
          invisible={invisible}
        >
          <Notifications />
        </Badge> */}
        {props.isLoading ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "0.5rem" }}>
              <Skeleton animation="wave" width={70} height={10} />
              <Skeleton animation="wave" width={70} height={10} />
            </div>
            <Skeleton variant="rect" width={33} height={33} />
          </div>
        ) : (
          <div className="header__right-user">
            <span>
              {t("hello")}, <strong>{user.firstName}</strong>
            </span>
            <div className="header__right-user-menu">
              <PopupMenu user={user} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.user.isLoading,
});

export default connect(mapStateToProps, null)(Header);
