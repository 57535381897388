/* eslint-disable eqeqeq */
import React, { useState, Fragment } from 'react';
import axios from 'axios';

import NothingFound from '../../components/searchNothingFound/searchNothingFound';
import SnackbarError from '../../components/alert/SnackbarError';
// import Pagination from '../../components/pagination/pagination';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import config from '../../config/api.config.js';
import { logError } from "../../helpers/helpers";

import { useTranslation } from 'react-i18next'

const ThirdStep = (props) => {
  const { t } = useTranslation()
  const { videos, setVideos, editVideos, setEditVideos, courseId } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const deleteVideo = (file) => {
    const temp = videos.filter((f) => f.name != file.name);
    setVideos(temp);
  };

  const deleteEditVideo = async (video) => {
    setEditVideos(editVideos.filter((a) => a.id !== video.id));
    await axios
      .put(`${config.apiURL}/api/Course/delete-video/${courseId}`, {
        videoId: video.id,
        name: video.name,
        url: video.url
      })
      .then((res) => {})
      .catch((err) => logError(err));
  };

  const onFileChange = (event) => {
    if (event.target.files.length <= 0) return;

    if (!event.target.files[0].type.includes('video')) {
      setErrorMessage('Please upload video');
      setOpenAlert(true);
      return;
    }

    if (!videos.find((f) => f.name == event.target.files[0].name))
      setVideos([...videos, event.target.files[0]]);
  };
  return (
    <div className="add-course__third-step">
      <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
        {errorMessage}
      </SnackbarError>
      <div className="add-course__second-step--header add-course__third-step--header">
        <p>Add new video</p>
        <Fragment>
          <label htmlFor="file-upload" className="custom-file-upload">
            <Upload />
            {t('upload_video')}
          </label>
          <input
            id="file-upload"
            className="article-modal__file-upload"
            type="file"
            accept="video/*"
            onChange={onFileChange}
          />
        </Fragment>
      </div>
      <div></div>
      <div className="add-course__second-step--content add-course__third-step--content">
        <TableContainer className="departments__table">
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!videos?.length && !editVideos?.length ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <NothingFound>
                      {t('nothing_found_video')}
                    </NothingFound>
                  </TableCell>
                </TableRow>
              ) : null}
              {editVideos?.map((row, i) => (
                <TableRow key={row.name} className="departments__table-row">
                  <TableCell component="th" scope="row">
                    {++i + '.'}
                  </TableCell>
                  <TableCell align="left">{row.originalName}</TableCell>

                  <TableCell align="center">
                    <div>
                      <DeleteIcon onClick={() => deleteEditVideo(row)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {videos?.map((row, i) => (
                <TableRow key={row.name} className="departments__table-row">
                  <TableCell component="th" scope="row">
                    {editVideos ? editVideos.length + ++i + '.' : ++i + '.'}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>

                  <TableCell align="center">
                    <div>
                      <DeleteIcon onClick={() => deleteVideo(row)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ThirdStep;
