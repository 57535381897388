import { combineReducers } from 'redux';
import userReducer from './users/reducer';
import articleReducer from './article/reducer';
import loginReducer from './login/reducer';
import mediaReducer from './media/reducer';
import locationReducer from './location/reducer';
import departmentReducer from './department/reducer';
import teamReducer from './team/reducer';
import taskReducer from './task/reducer';
import processReducer from './process/reducer';

export default combineReducers({
  user: userReducer,
  article: articleReducer,
  doLogin: loginReducer,
  media: mediaReducer,
  location: locationReducer,
  department: departmentReducer,
  team: teamReducer,
  task: taskReducer,
  process: processReducer
});
