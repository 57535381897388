import { 
  SET_SINGLE_ARTICLE,
  SEARCH_ARTICLES,
  ADD_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  ARTICLE_BY_ID,
  ARTICLE_INSERT_FILE,
  ARTICLE_DELETE_FILE
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const setArticle = (article) => ({
  type: SET_SINGLE_ARTICLE,
  payload: article
});

export const searchArticles = (params) => ({
  type: SEARCH_ARTICLES,
  payload: generateAuthRequest('GET','api/Article', params)
});

export const addArticle = (params) => ({
  type: ADD_ARTICLE,
  payload: generateAuthRequest('POST','api/Article', params)
});

export const updateArticle = (params) => ({
  type: UPDATE_ARTICLE,
  //payload: generateAuthRequest('PUT',`api/Article/${params.articleId}`, params)
  payload: generateAuthRequest('PUT',`api/Article/update/${params.articleId}`, params)
});

export const deleteArticle = (params) => ({
  type: DELETE_ARTICLE,
  //payload: generateAuthRequest('DELETE',`api/Article/${params.articleId}`, params)
  payload: generateAuthRequest('DELETE',`api/Article/delete/${params.articleId}`, params)
});

export const getArticleById = (params) => ({
  type: ARTICLE_BY_ID,
  payload: params.includeOwner ? 
    generateAuthRequest('GET',`api/Article/${params.id}/${params.includeOwner}`, params) :
    generateAuthRequest('GET',`api/Article/${params.id}`, params)
});

export const insertArticleFile = (params) => ({
  type: ARTICLE_INSERT_FILE,
  payload: generateAuthRequest('PUT',`api/Article/insert-file/${params.articleId}`, params.formData, params.articleId)
});

export const deleteArticleFile = (params) => ({
  type: ARTICLE_DELETE_FILE,
  payload: generateAuthRequest('PUT',`api/Article/delete-file/${params.articleId}`, params)
});
