import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SEARCH_PROCESSES,
  ADD_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
  PROCESS_BY_ID,
  GET_PROCESS_TASKS,
  ACTIVATE_PROCESS,
  PROCESSES_BY_USER,
  COMPLETE_PROCESS,
  UPLOAD_PROCESS_IMAGE
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {},
  userProcesses: {},
  tasks: {},
  assignedProcesses: [],
  assignedProcessesPageParams: {},
};

export default typeToReducer(
  {
    [SEARCH_PROCESSES]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { processes, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allProcesses = payload.params?.GetAll ? payload.params.GetAll : false;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let processNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(processes.length > 0){
          processes.forEach(process => {
            process['no'] = ++processNo;
            if(allIds.indexOf(process.id) === -1){
              allIds.push(process.id);
            }
            if(!allProcesses){
              searchIds.push(process.id);     
            }                 
            byId[process.id] = process;
          })
        }

        if(!allProcesses){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        }; 
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_PROCESS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let process = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        process['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: process};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_PROCESS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { processId, name, tasks, description, responsible } = payload.params;
        let byId = state.byId;
        if(payload.data){         
          if(payload.data && byId[processId]){
            byId[processId].name = name;
            byId[processId].description = description;
            byId[processId].tasks = tasks;
            byId[processId].responsible = responsible;
          }
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_PROCESS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { processId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;
        let tasks = state.tasks;

        if(payload.data){
          const index = allIds.indexOf(processId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[processId]){
            delete byId[processId];
          }

          if(tasks[processId]){
            delete tasks[processId];
          }        
        }
     
        return {
          ...state,
          byId,
          allIds,
          tasks,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [PROCESS_BY_ID]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        let process = payload.data;
        let allIds = state.allIds;
        let byId = state.byId;
        if(payload.data){  
          if( allIds.length > 0){
            if(allIds.indexOf(process.id) === -1){
              allIds.push(process.id);
            }
          }              
          if(byId[process.id]){
            process['no'] = byId[process.id].no;           
          }else{
            process['no'] = allIds.length + 1;
          }
          byId[process.id] = process;
        }   
        return {
          ...state,
          allIds,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },   
    [GET_PROCESS_TASKS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {  
        const { processId } = payload.params;
        const { tasks } = payload.data; 
        let _tasks = state.tasks;
        _tasks[processId] = tasks;
        return {
          ...state,
          tasks: _tasks,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,         
          isLoading: false
        };
      },
    },
    [ACTIVATE_PROCESS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {     
        const { processId } = payload.params;
        let byId = state.byId;      
        if(payload.data === true && byId[processId]){
          byId[processId].isActivated = true;
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [PROCESSES_BY_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { process, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        const userId = payload.params.userId
        let userProcesses = state.userProcesses;
        let assignedProcesses = [];
        let assignedProcessesPageParams= {};
        let processNo = currentPage * pageSize - pageSize;

        if(process.length > 0){
          process.forEach((proc, index) => {
            process[index].no = ++processNo;
          })
        }

        assignedProcesses = process;
        assignedProcessesPageParams.hasNext = hasNext;
        assignedProcessesPageParams.hasPrevious = hasPrevious;
        assignedProcessesPageParams.pageSize = pageSize;
        assignedProcessesPageParams.totalCount = totalCount;
        assignedProcessesPageParams.totalPages = totalPages;
        assignedProcessesPageParams.currentPage = currentPage;
        userProcesses[userId] = process;
        return {
          ...state,
          userProcesses: userProcesses,
          assignedProcessesPageParams,
          assignedProcesses,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [COMPLETE_PROCESS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        const { processId } = payload.params;
        let byId = state.byId;      
        if(payload.data === true && byId[processId]){
          byId[processId].compeleted = true;
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPLOAD_PROCESS_IMAGE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const processId = payload.id;
        let byId = state.byId;

        if(payload.data){
          if(byId[processId]){
            byId[processId].image = payload.data;
          }
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },     
  },
  INITIAL_STATE
);
