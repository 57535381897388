import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SEARCH_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  TASK_INSERT_FILE,
  TASK_DELETE_FILE,
  TASK_BY_ID,
  TASK_DONE,
  INSERT_TASK_ATTACHMENT,
  TASKS_WAITING_FOR_APPROVAL,
  APPROVE_TASK,
  REJECT_TASK,
  TASKS_BY_BENEFACTOR,
  ASSIGNED_TASKS,
  DELETE_TASK_ATTACHMENT,
  CHANGE_TASK_RESPONSIBLE,
  CHANGE_TASK_DEADLINE 
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {},
  assignedTasksPageParams: {},
  assignedTasks: [],
  approveTasks: []
};

export default typeToReducer(
  {
    [SEARCH_TASKS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { tasks, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allTasks = payload.params?.GetAll ? payload.params.GetAll : false;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let taskNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(tasks.length > 0){
          tasks.forEach(task => {
            task['no'] = ++taskNo;
            if(allIds.indexOf(task.id) === -1){
              allIds.push(task.id);
            }
            if(!allTasks){
              searchIds.push(task.id);     
            }                 
            byId[task.id] = task;
          })
        }

        if(!allTasks){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        }; 
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_TASK]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let task = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        task['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: task};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_TASK]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { taskId, name, description, articles, medias } = payload.params;
        let byId = state.byId;
        if(payload.data){         
          if(payload.data && byId[taskId]){
            byId[taskId].name = name;
            byId[taskId].description = description;
            byId[taskId].articles = articles;
            byId[taskId].medias = medias;
          }
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_TASK]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { taskId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(taskId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[taskId]){
            delete byId[taskId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASK_INSERT_FILE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const taskId = payload.id;
        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          if(byId[taskId]){
            if(byId[taskId].files){
              byId[taskId].files.push(payload.data);
            }
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASK_DELETE_FILE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { taskId, fileName } = payload.params;
        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          if(byId[taskId]){
            if(byId[taskId].files.length > 0){
              let files = byId[taskId].files;
              const index = files.findIndex(file => file.name === fileName);
              if(index !== -1){
                (byId[taskId].files).splice(index, 1);
              }
            }
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASK_BY_ID]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        let task = payload.data;
        let allIds = state.allIds;
        let byId = state.byId;
        if(payload.data && allIds.length > 0){         
          if(allIds.indexOf(task.id) === -1){
            allIds.push(task.id);
          }
          if(byId[task.id]){
            task['no'] = byId[task.id].no;           
          }else{
            task['no'] = allIds.length + 1;
          }
          byId[task.id] = task;
        }   
        return {
          ...state,
          allIds,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASK_DONE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [INSERT_TASK_ATTACHMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASKS_WAITING_FOR_APPROVAL]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        const { tasks, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let pageParams = {};
        let approveTasks = [];
        let taskNo = currentPage * pageSize - pageSize;

        if(tasks.length > 0){
          tasks.forEach((task, index) => {
            tasks[index].no = ++taskNo;
          })
        }

        approveTasks = tasks;
        pageParams.hasNext = hasNext;
        pageParams.hasPrevious = hasPrevious;
        pageParams.pageSize = pageSize;
        pageParams.totalCount = totalCount;
        pageParams.totalPages = totalPages;
        pageParams.currentPage = currentPage;
        return {
          ...state,
          pageParams,
          approveTasks,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [APPROVE_TASK]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [REJECT_TASK]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TASKS_BY_BENEFACTOR]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [ASSIGNED_TASKS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        const { tasks, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;

        let assignedTasksPageParams = {};
        let assignedTasks = [];
        let taskNo = currentPage * pageSize - pageSize;

        if(tasks.length > 0){
          tasks.forEach((task, index) => {
            tasks[index].no = ++taskNo;
          })
        }

        assignedTasks = tasks;
        assignedTasksPageParams.hasNext = hasNext;
        assignedTasksPageParams.hasPrevious = hasPrevious;
        assignedTasksPageParams.pageSize = pageSize;
        assignedTasksPageParams.totalCount = totalCount;
        assignedTasksPageParams.totalPages = totalPages;
        assignedTasksPageParams.currentPage = currentPage;
        return {
          ...state,
          assignedTasksPageParams,
          assignedTasks,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_TASK_ATTACHMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [CHANGE_TASK_RESPONSIBLE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [CHANGE_TASK_DEADLINE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
  },
  INITIAL_STATE
);
