import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Pagination from "../../components/pagination/pagination";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import DepartmentMembers from "../Departments/departmentMembers";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/departments.scss";
import App from "../../App";
import {
  searchLocations,
  addLocation,
  updateLocation,
  deleteLocation,
} from "../../redux/location/action";
import MembersSelect from "../../components/dropdown/membersSelect";
import { searchUsers } from "../../redux/users/action";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";

const Locations = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [locationEditId, setLocationEditId] = useState(null);
  const [search, setSearch] = useState("");
  const [openDepartmentMember, setOpenDepartmentMember] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [departmentMembers, setDepartmentMembers] = useState(null);
  const [selectedSort, setSelectedSort] = useState(1);
  const [defaultPage, setDefaultPage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedHead, setSelectedHead] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const AddLocation = async () => {
    if (!isAdmin) return;

    await setButtonClicked(true);
    await dispatch(
      addLocation({
        Name: locationName,
        headId: selectedHead,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setLocationName("");
        setSelectedHead("");
        setSuccessMessage("Standort erfolgreich hinzugefügt");
        setOpenAlert(true);
      })
      .catch(function (err) {
        logError(err);
        setButtonClicked(false);
        let errMsg = "Fehler bei Hinzufügen von Standort.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditLocationModal = (id, name, headId) => {
    if (!isAdmin) return;

    setLocationEditId(id);
    setLocationName(name);
    setOpenModal(true);
    setSelectedHead(headId);
  };

  const EditLocation = async () => {
    let reqData = {
      name: locationName,
      headId: selectedHead,
      locationId: locationEditId,
    };
    await setButtonClicked(true);
    await dispatch(updateLocation(reqData))
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setLocationEditId(null);
        setLocationName("");
        setSelectedHead("");
        setSuccessMessage("Standort wurde erfolgreich bearbeitet.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeiten des Standortes.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const DeleteLocationModal = (id) => {
    if (!isAdmin) return;

    setLocationEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteLocation = async () => {
    await setButtonClicked(true);
    dispatch(
      deleteLocation({
        locationId: locationEditId,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setLocationEditId(null);
        dispatch(
          searchLocations({
            Sort: selectedSort,
            PageNumber: 1,
            Name: search.trim(),
          })
        );
        setSuccessMessage("Standort wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setLocationEditId(null);
        let errMsg = "Fehler bei Löschen des Standortes.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const handleDepartmentMember = async (
    locationId,
    name,
    search = null,
    pageNumber = 1
  ) => {};

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchLocations({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
      })
    );
  };

  useEffect(async () => {
    const abortController = new AbortController();

    if (!props.currentUser) return;

    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }

    if (props.allLocationIds.length === 0) {
      await dispatch(searchLocations({ GetAll: true }));
    }
    if (props.memberIds.length === 0) {
      await dispatch(searchUsers({ GetAll: true }));
    }

    dispatch(
      searchLocations({
        Sort: 1,
        PageNumber: 1,
      })
    );

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchLocations({
          PageNumber: 1,
          Name: search.trim(),
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchLocations({
        PageNumber: newPage,
        Name: search,
        Sort: selectedSort,
      })
    );
  };

  const headChangeHandler = (selectedVal) => {
    setSelectedHead(selectedVal);
  };

  const getHeadName = (id) => {
    let name = "";
    if (id && props.members[id]) {
      name = props.members[id].firstName + " " + props.members[id].lastName;
    }
    return name;
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Standorte"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className={`departments__add-new--button ${
              !isAdmin ? "disabled" : ""
            }`}
            style={{ height: "2.8rem" }}
            onClick={() => setOpenModal(true)}
            disabled={!isAdmin}
          >
            <AddIcon />
            {t("Hinzufügen")}
          </Button>
          <Modal
            title={locationEditId ? "Standort ändern" : "Neuer Standort"}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setLocationName("");
              setLocationEditId(null);
              setSelectedHead("");
            }}
          >
            <input
              type="text"
              placeholder={t("Standortname")}
              value={locationName}
              onChange={({ target }) => setLocationName(target.value)}
            />
            <MembersSelect
              className="sort-input positions__select-department"
              placeholder={"Standortverantwortlich"}
              selectedOption={selectedHead}
              onChange={headChangeHandler}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setLocationName("");
                  setLocationEditId(null);
                  setSelectedHead("");
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={locationName ? true : false}
                buttonClicked={buttonClicked}
                onClick={locationEditId ? EditLocation : AddLocation}
              >
                {locationEditId ? "Speichern" : "Hinzufügen"}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Standort löschen?")} </p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteLocation}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <DepartmentMembers
            department={"Departments"}
            locationName={selectedLocation}
            locationId={locationEditId}
            departmentMembers={departmentMembers}
            getSerachedMembers={handleDepartmentMember}
            open={openDepartmentMember}
            closeModal={() => setOpenDepartmentMember(false)}
          />
        </div>

        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Nr.")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("Verantwortlich")}</TableCell>
              {isAdmin && <TableCell>{t("Datenbank ID")}</TableCell>}
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !props.isLoading && !props.locationIds.length ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_location")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  props.locationIds.length &&
                  props.locationIds?.map((id) => {
                    return (
                      props.locations[id] && (
                        <TableRow key={id} className="departments__table-row">
                          <TableCell component="th" scope="row">
                            {props.locations[id].no + "."}
                          </TableCell>
                          <TableCell align="left">
                            {props.locations[id].name}
                          </TableCell>
                          <TableCell align="left">
                            {getHeadName(props.locations[id].headId)}
                          </TableCell>
                          {isAdmin && (
                            <TableCell component="th" scope="row">
                              {id}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() =>
                                  EditLocationModal(
                                    id,
                                    props.locations[id].name,
                                    props.locations[id].headId
                                  )
                                }
                              />
                              <DeleteIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() => DeleteLocationModal(id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.locationIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.location.isLoading,
  allLocationIds: state.location.allIds,
  locations: state.location.byId,
  locationIds: state.location.searchIds,
  pageParams: state.location.pageParams,
  memberIds: state.user.allIds,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(Locations);
