/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import MemberModal from "./MemberModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Pagination from "../../components/pagination/pagination";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import { useHistory } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import "../../assets/css/pages/members.scss";
import { useTranslation } from "react-i18next";
import App from "../../App";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import FilePreview from "../../components/filePreview/filePreview";
import { searchLocations } from "../../redux/location/action";
import { searchDepartments } from "../../redux/department/action";
import { searchTeams } from "../../redux/team/action";
import {
  searchUsers,
  deleteUser,
  uploadUserCsv,
  forgotPassword,
} from "../../redux/users/action";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import { right } from "@popperjs/core";
import KeyIcon from "@material-ui/icons/VpnKey";

const Members = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [memberEditId, setMemberEditId] = useState(null);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState(null);
  const [teams, setTeams] = useState(null);
  const [openMemberModal, setOpenMemberModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [defaultPage, setDefaultPage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [departments, setDepartments] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [typeError, setTypeError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableUpload, setDisableUpload] = useState(false);
  const fileRef = useRef();
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const [resetPassEmail, setResetPassEmail] = useState("");

  const DeleteMemberModal = (id) => {
    if (!isAdmin) return;

    setMemberEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteMember = async () => {
    await setButtonClicked(true);
    dispatch(
      deleteUser({
        userId: memberEditId,
        workspaceId: props.currentUser.workspaceId,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setMemberEditId(null);
        dispatch(
          searchUsers({
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
          })
        );
        setSuccessMessage("Nutzer wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setMemberEditId(null);
        let errMsg = "Fehler bei Löschen des Nutzers.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchUsers({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };
  /////////// Sorting endet hier  /////////////////////////

  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;
    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }
    if (props.allMemberIds.length === 0) {
      dispatch(searchUsers({ GetAll: true }));
    }
    if (props.locationIds.length === 0) {
      dispatch(searchLocations({ GetAll: true }));
    }
    if (props.departmentIds.length === 0) {
      dispatch(searchDepartments({ GetAll: true }));
    }
    if (props.teamIds.length === 0) {
      dispatch(searchTeams({ GetAll: true }));
    }

    dispatch(searchUsers());

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    if (props.locationIds.length > 0) {
      const _loc = new Array();
      props.locationIds.forEach((id) => {
        _loc.push({
          id: id,
          name: props.locations[id].name,
        });
      });
      setLocations(_loc);
    } else {
      setLocations(null);
    }
  }, [props.locationIds.length]);

  useEffect(() => {
    if (props.departmentIds.length) {
      const _dep = new Array({ value: null, label: "All" });
      props.departmentIds.forEach((id) => {
        _dep.push({
          id: id,
          name: props.departments[id].name,
        });
      });
      setDepartments(_dep);
    } else {
      setDepartments(null);
    }
  }, [props.departmentIds.length]);

  useEffect(() => {
    if (props.teamIds.length > 0) {
      const _team = new Array({ value: null, label: "All" });
      props.teamIds.forEach((id) => {
        _team.push({
          id: id,
          name: props.teams[id].name,
        });
      });
      setTeams(_team);
    } else {
      setTeams(null);
    }
  }, [props.teamIds.length]);

  /////////// Search fängt hier an /////////////////////////
  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      dispatch(
        searchUsers({
          PageNumber: 1,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
        })
      );
    }
    // forceCheck()
    setDefaultPage(!defaultPage);
  };

  /////////// Search endet hier /////////////////////////

  /////////// Pagination fängt hier an /////////////////////////
  const getPageNumber = (newPage) => {
    dispatch(
      searchUsers({
        PageNumber: newPage,
        Search: search.replace(/\s+/g, " ").trim(),
        Sort: selectedSort,
      })
    );
  };
  /////////// Pagination endet hier  /////////////////////////

  const onFileChange = (event) => {
    setDisableUpload(false);
    let file = event.target.files[0];
    if (getFileExtension(file.name) !== "xlsx") {
      setTypeError("File type not support");
      return false;
    }
    if (event.target.files.length <= 0) return;
    setSelectedFile(file);
    setDisableUpload(true);
    setTypeError("");
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const deleteFilePreview = () => {
    setSelectedFile(null);
    setDisableUpload(false);
    fileRef.current.value = "";
  };

  const uploadMembersHandler = async () => {
    await setButtonClicked(true);
    setOpenAlert(false);
    const formData = new FormData();
    formData.append("File", selectedFile);
    dispatch(uploadUserCsv(formData))
      .then((res) => {
        setButtonClicked(false);
        resetUploadModalValues();
        dispatch(
          searchUsers({
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
          })
        );
        setSuccessMessage("Nutzer wurden erfolgreich hinzugefügt.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setErrorMessage(
          err.response && err.response.data?.errors?.File[0]
            ? err.response.data.errors.File[0]
            : "Fehler bei Bearbeiten des Nutzerss."
        );
        setOpenAlert(true);
      });
  };

  const resetUploadModalValues = () => {
    fileRef.current.value = "";
    setUploadModalOpen(false);
    setSelectedFile(null);
    setTypeError("");
    setDisableUpload(false);
    setErrorMessage("");
  };

  const EditMemberModal = (id) => {
    if (!isAdmin) return;
    let user = props.members[id];
    if (user.image?.name) {
      user["imageSrc"] = `${config.resourcesUrl+user.image.name}`;
    } else {
      user["imageSrc"] = "";
    }
    setSelectedUser(user);
    setMemberEditId(id);
    setOpenMemberModal(true);
  };

  const onSuccessHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  const ResetPasswordModal = (email) => {
    if (!isAdmin) return;
    setOpenResetPassModal(true);
    setResetPassEmail(email);
  };

  const resetPasswordHandler = async () => {
    await setButtonClicked(true);
    await dispatch(forgotPassword({ email: resetPassEmail }))
      .then((res) => {
        setButtonClicked(false);
        if (res.value) {
          setSuccessMessage("Email wurde versandt.");
          setOpenResetPassModal(false);
          setResetPassEmail("");
        } else {
          setErrorMessage("Something went wrong.");
        }
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Something went wrong.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Mitarbeitende"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search
            getSearchValue={getSearchValue}
            customClass="members__search"
          />
          <Button
            className={`departments__add-new--button ${
              isAdmin ? "" : "disabled"
            }`}
            onClick={() => {
              return setOpenMemberModal(true);
            }}
            disabled={isAdmin ? false : true}
            style={{marginRight:"10px"}}
          >
            <AddIcon /> {t("Neu")}
          </Button>
          <Button
            className={`members__batch-upload--button ${
              isAdmin ? "" : "disabled"
            }`}
            onClick={() => {
              return setUploadModalOpen(true);
            }}
            style={{ width: "120px" }}
            disabled={isAdmin ? false : true}
          >
            <AddIcon /> {t("Hochladen")}
          </Button>
          <MemberModal
            open={openMemberModal}
            closeModal={() => {
              setOpenMemberModal(false);
              setSelectedUser(null);
              setMemberEditId(null);
            }}
            editId={memberEditId}
            selectedMember={selectedUser}
            currentUser={props.currentUser}
            showSuccessMsg={onSuccessHandler}
          />
          {/*++++++++++++++++++ ADD EDIT FORM ENDET HIER  ++++++++++++++++++++++++++++++*/}

          {/*++++++++++++++++++ DELETE MODEL FÄNGT HIER AN ++++++++++++++++++++++++++++++*/}
          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Mitarbeitende löschen?")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                Abbrechen
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteMember}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={t("Mitarbeitende hochladen")}
            open={uploadModalOpen}
            closeModal={resetUploadModalValues}
          >
            <div className="article-modal__content">
              <div className="article-modal__content-input">
                <label
                  htmlFor="file-upload"
                  className="custom-file-upload"
                  style={{ cursor: disableUpload ? "not-allowed" : "pointer" }}
                >
                  <Upload />
                  {"Hier klicken, um CSV Datei hochzuladen"}
                </label>
                <input
                  id="file-upload"
                  className="article-modal__file-upload"
                  type="file"
                  ref={fileRef}
                  onChange={onFileChange}
                  disabled={disableUpload ? "disabled" : ""}
                />
              </div>
            </div>
            <div className="article-modal__uploaded-files">
              {typeError ? (
                <span style={{ color: "red" }}>{typeError}</span>
              ) : null}
              {selectedFile && (
                <FilePreview
                  name={selectedFile?.name}
                  onClick={deleteFilePreview}
                  customClass="modalFilePreview"
                  edit={false}
                />
              )}
            </div>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={resetUploadModalValues}>
                Abbrechen
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={selectedFile}
                buttonClicked={buttonClicked}
                onClick={uploadMembersHandler}
              >
                Hochladen
              </AddButton>
            </div>
          </Modal>

          <Modal
            open={openResetPassModal}
            closeModal={() => {
              setOpenResetPassModal(false);
              setResetPassEmail("");
            }}
          >
            <h5>Password des Users zurücksetzen?</h5>
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenResetPassModal(false);
                  setResetPassEmail("");
                }}
              >
                Abbrechen
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={resetPassEmail}
                buttonClicked={buttonClicked}
                onClick={resetPasswordHandler}
              >
                Rücksetzen
              </AddButton>
            </div>
          </Modal>
        </div>

        {isAdmin && (
          <div>
            <a
              href="/api/Resources/users_data_template.xlsx"
              style={{ color: config.colorSecondary, float: right }}
              download
            >
              Batchvorlage runterladen
            </a>
          </div>
        )}
        {/*++++++++++++++++++  (SORT COMPONENT) ++++++++++++++++++++++++++++++*/}
        <SortInput handleSort={handleSort} />
      </div>
      {/*++++++++++++++++++ FILTERING UND MEMBER CARDS FÄNGT HIER AN ++++++++++++++++++++++++++++++*/}
      <TableContainer className="members__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Nr.")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("Standort")}</TableCell>
              <TableCell align="left">{t("Abteilung")}</TableCell>
              <TableCell align="left">{t("Team")}</TableCell>
              <TableCell align="left">{t("Position")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*: search*/ !props.isLoading && !props.memberIds?.length ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <NothingFound>{t("nothing_found_member")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {props.memberIds?.length > 0 &&
                  props.memberIds?.map((id) => {
                    return (
                      props.members[id] && (
                        <TableRow
                          key={props.members[id].id}
                          className="members__table-row"
                        >
                          <TableCell>{props.members[id].no + "."}</TableCell>
                          <TableCell
                            align="left"
                            onClick={(event) =>
                              history.push(`/member-details/${id}`)
                            }
                          >
                            {props.members[id].firstName +
                              " " +
                              props.members[id].lastName}
                          </TableCell>
                          <TableCell>
                            {props.members[id].locationId
                              ? locations?.find(
                                  (p) => p.id === props.members[id].locationId
                                )?.name
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {props.members[id].departmentId
                              ? departments?.find(
                                  (p) => p.id === props.members[id].departmentId
                                )?.name
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {props.members[id].teamId
                              ? teams?.find(
                                  (p) => p.id === props.members[id].teamId
                                )?.name
                              : "-"}
                          </TableCell>
                          <TableCell>{props.members[id].position}</TableCell>
                          <TableCell align="right">
                            <div>
                              {isAdmin && (
                                <KeyIcon
                                  className={`member-key-icon`}
                                  width="37"
                                  height="36"
                                  id="key-icon"
                                  onClick={() =>
                                    ResetPasswordModal(props.members[id].email)
                                  }
                                />
                              )}
                              <EditIcon
                                className={`member-edit-icon ${
                                  !isAdmin ? "disabled" : ""
                                }`}
                                id="edit-icon"
                                onClick={() => EditMemberModal(id)}
                              />
                              <DeleteIcon
                                className={`member-delete-icon ${
                                  !isAdmin ? "disabled" : ""
                                }`}
                                id="delete-icon"
                                onClick={() => DeleteMemberModal(id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/*++++++++++++++++++ FILTERING UND MEMBER CARDS ENDET HIER ++++++++++++++++++++++++++++++*/}

      {/*++++++++++++++++++ PAGINATION FÄNGT HIER AN ++++++++++++++++++++++++++++++*/}
      {!props.memberIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
      {/*++++++++++++++++++ PAGINATION ENDET HIER ++++++++++++++++++++++++++++++*/}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  locationIds: state.location.allIds,
  locations: state.location.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
  departmentsByLoc: state.location.departments,
  teamIds: state.team.allIds,
  teams: state.team.byId,
  teamsByDepartment: state.department.teams,
  allMemberIds: state.user.allIds,
  members: state.user.byId,
  memberIds: state.user.searchIds,
  isLoading: state.user.isLoading,
  pageParams: state.user.pageParams,
});

export default connect(mapStateToProps, null)(Members);
