import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Player from "../../components/player/player";
import "./MediaDisplayModal.scss";
import config from "../../config/api.config.js";
import { getFileType, getExtension } from "../../helpers/helpers.js";

const MediaDisplayModal = ({ open, mediaUrl, closeModal, name, title }) => {
  const [type, setType] = useState("");

  useEffect(() => {
    setMediaType(name);
  }, [name]);

  const setMediaType = (filename) => {
    setType(getFileType(filename));
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeModal}>
      <Toolbar style={{ backgroundColor: config.colorActive }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <div style={{ marginLeft: "20px" }}>
          Titel: <strong>{title}</strong>
        </div>
      </Toolbar>
      {type === "Image" && (
        <img src={mediaUrl} alt={title} style={{ padding: "10px" }} />
      )}
      {(type === "Video" || type === "Audio") && (
        <Player
          mediaUrl={mediaUrl}
          customClass={`${type === "Audio" ? "audio-display" : ""}`}
        />
      )}
    </Dialog>
  );
};
export default MediaDisplayModal;
