import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Pagination from "../../components/pagination/pagination";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import auth from "../../auth";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/departments.scss";
import App from "../../App";
import Select from "react-select";
import { searchUsers, getUserById } from "../../redux/users/action";
import {
  searchProcesses,
  addProcess,
  updateProcess,
  deleteProcess,
  getProcessTasks,
} from "../../redux/process/action";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import moment from "moment";

const Processes = (props) => {
  const dispatch = useDispatch();
  const [membersData, setMembersData] = useState(null);
  const history = useHistory();
  const [assignModal, setAssignModal] = useState(false);
  const [pros, setPros] = useState(null);
  const [prosName, setProsName] = useState(null);
  const [prosDes, setProsDes] = useState(null);
  const [prosRes, setProsRes] = useState(null);
  const [prosTasks, setProsTasks] = useState(null);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [processName, setProcessName] = useState("");
  const [taskss, setTasks] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [processEditId, setProcessEditId] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedSort, setSelectedSort] = useState(1);
  const [defaultPage, setDefaultPage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState(null);
  const [selectedUser, setSelectedUser] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [deadLines, setDeadLines] = useState([]);
  const [loading, setLoading] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([
    { value: null, label: "All" },
  ]);

  const AddProcess = async () => {
    let obj = {
      name: processName,
      description: description,
      responsible: selectedUser.add.value,
      creator: auth.getId(),
      tasks: [],
    };
    await setButtonClicked(true);
    await dispatch(addProcess(obj))
      .then(() => {
        setButtonClicked(false);
        setOpenModal(false);
        setProcessName("");
        setSelectedUser({
          search: { value: null, label: "All" },
          add: null,
        });
        setDescription("");
        setSuccessMessage("Prozess wurde erfolgreich erstell.");
        setOpenAlert(true);
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeitung des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditProcessModal = (id, name, tasks, description, responsible) => {
    let namew = members.find((item) => item.value === responsible);
    setSelectedUser({ add: namew });
    setDescription(description);
    setProcessEditId(id);
    setProcessName(name);
    setTasks(tasks);
    setOpenModal(true);
  };

  const EditProcess = async () => {
    let reqData = {
      name: processName,
      tasks: taskss,
      description: description,
      responsible: selectedUser.add.value,
      processId: processEditId,
    };
    setOpenAlert(false);
    await setButtonClicked(true);
    await dispatch(updateProcess(reqData))
      .then(() => {
        setButtonClicked(false);
        setOpenModal(false);
        setProcessEditId(null);
        setProcessName("");
        setSelectedUser({
          search: { value: null, label: "All" },
          add: null,
        });
        setDescription("");
        setTasks("");
        setSuccessMessage("Prozess wurde erfolgreich bearbeitet.");
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeiten des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const Continue = async () => {
    history.push("assigntouser", {
      process: pros,
      processName: prosName,
      processTasks: prosTasks,
      des: prosDes,
      responsible: prosRes,
      membersData: membersData,
    });
  };

  const DeleteProcessModal = (id) => {
    setProcessEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteProcess = async () => {
    await setButtonClicked(true);
    await dispatch(
      deleteProcess({
        processId: processEditId,
      })
    )
      .then(() => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setProcessEditId(null);
        dispatch(
          searchProcesses({
            isTemplate: false,
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
          })
        );
        setDefaultPage(!defaultPage);
        //to update user tasks
        const userId = auth.getId();
        if (props.members[userId]) {
          dispatch(getUserById({ id: userId }));
        }
        setSuccessMessage("Prozess wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setProcessEditId(null);
        let errMsg = "Fehler bei Löschen des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchProcesses({
        isTemplate: false,
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
        Active: selectedFilter.value,
      })
    );
  };

  useEffect(async () => {
    const abortController = new AbortController();
    if (!props.currentUser) return;

    if (props.allProcessIds.length === 0) {
      dispatch(searchProcesses({ isTemplate: false, GetAll: true }));
    }

    if (props.memberIds.length === 0) {
      await dispatch(searchUsers({ GetAll: true }));
    }

    const _statusOptions = [
      { value: null, label: "Alle" },
      { value: "true", label: "Aktiv" },
      { value: "false", label: "Inaktiv" },
    ];
    setStatuses(_statusOptions);

    dispatch(
      searchProcesses({
        isTemplate: false,
        Sort: 1,
        PageNumber: 1,
      })
    );

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    const _members = [];
    const _memberTasks = [];
    if (props.memberIds.length > 0) {
      props.memberIds.forEach((id) => {
        _members.push({
          value: props.members[id].userId,
          label: props.members[id].firstName + " " + props.members[id].lastName,
        });
        _memberTasks.push(props.members[id].tasks);
      });
      setMembers(_members);
      setMembersData(_memberTasks);
    }
  }, [props.memberIds.length]);

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchProcesses({
          isTemplate: false,
          PageNumber: 1,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
          Active: selectedFilter.value,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchProcesses({
        isTemplate: false,
        PageNumber: newPage,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
        Active: selectedFilter.value,
      })
    );
  };

  const getResponsiblePerson = (id) => {
    let name = "";
    if (id && props.members[id]) {
      name = props.members[id].firstName + " " + props.members[id].lastName;
    }
    return name;
  };

  const getProcessStatus = (process) => {
    let status = "";
    if (!process.isActivated) {
      status = "Entwurf";
      return status;
    }
    const processTotalTasks = process.tasks.length;
    let completedTasksCount = 0;
    process.tasks?.map((task) => {
      membersData?.map((memberTask) => {
        if (memberTask && memberTask.length > 0) {
          let _task = memberTask.find(
            (item) =>
              item.id === task.id &&
              item.processId === process.id &&
              item.compeleted === true &&
              item.approved === true
          );
          if (_task) {
            completedTasksCount = completedTasksCount + 1;
          }
        }
      });
    });

    if (
      process.isActivated &&
      processTotalTasks === completedTasksCount &&
      process.compeleted
    ) {
      status = "Erledigt";
    } else {
      status = "Aktiv";
    }
    return status;
  };

  useEffect(async () => {
    let temp = [];
    setLoading(true);
    if (props.processIds.length > 0) {
      for (const id of props.processIds) {
        if (props.processes[id]) {
          let _tasks = [];
          await dispatch(getProcessTasks({ processId: id })).then((res) => {
            if (res.value?.data) {
              _tasks = res.value.data.tasks;
              let latest = {};
              if (_tasks.length > 0) {
                latest = _tasks.reduce(function (r, a) {
                  return r.dateBeDone > a.dateBeDone ? r : a;
                });
              }
              if (Object.keys(latest).length > 0) {
                temp[id] = moment(latest.dateBeDone).format("DD.MM.YYYY");
              }
            }
          });
        }
      }
      setDeadLines(temp);
    }
    setLoading(false);
  }, [props.processIds]);

  const getProcessByStatus = (e) => {
    setSelectedFilter(e);
    dispatch(
      searchProcesses({
        isTemplate: false,
        PageNumber: 1,
        Search: search.replace(/\s+/g, " ").trim(),
        Sort: selectedSort,
        Active: e.value,
      })
    ).catch((err) => {
      logError(err);
      setErrorMessage("Fehler bei Laden des Prozesseses.");
      setOpenAlert(true);
    });
    setDefaultPage(!defaultPage);
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Prozesse"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            style={{ height: "2.8rem" }}
            onClick={() =>
              history.push("/add-process", {
                type: "create",
                step: "createProcess",
              })
            }
          >
            <AddIcon />
            {t("Neu")}
          </Button>
          <Modal
            title={
              processEditId ? "Prozess bearbeiten" : "Neuen Prozess anlegen"
            }
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setProcessName("");
              setProcessEditId(null);
              setSelectedUser({
                search: { value: null, label: "All" },
                add: null,
              });
              setDescription("");
              setTasks("");
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <strong>Prozessnamen angeben:</strong>
            </div>
            <input
              type="text"
              placeholder={t("Prozessname")}
              value={processName}
              onChange={({ target }) => setProcessName(target.value)}
            />
            <div style={{ marginBottom: "10px" }}>
              <strong>Kurzbeschreibung angeben:</strong>
            </div>
            <textarea
              type="text"
              placeholder={t("Kurzbeschreibung des Prozesses:")}
              value={description}
              multiple={true}
              rows={5}
              style={{
                borderRadius: "0.625rem",
                border: "solid 1px #cfcfcf",
                width: "100%",
                paddingTop: 10,
                outline: "none",
                paddingLeft: "0.6rem",
                color: "#535353",
                fontSize: "1rem",
                marginBottom: "10px",
              }}
              onChange={({ target }) => setDescription(target.value)}
            />

            <div style={{ marginBottom: "10px" }}>
              <strong>Verantwortlich:</strong>
            </div>
            <Select
              className="sort-input positions__select-department"
              value={selectedUser.add}
              placeholder="Verantwortlichen eingeben"
              onChange={(e) => {
                setSelectedUser({ add: e });
              }}
              options={members}
              isSearchable={true}
            />

            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setProcessName("");
                  setTasks("");
                  setSelectedUser({
                    search: { value: null, label: "All" },
                    add: null,
                  });
                  setDescription("");
                  setProcessEditId(null);
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={
                  processName && description && selectedUser.add ? true : false
                }
                buttonClicked={buttonClicked}
                onClick={processEditId ? EditProcess : AddProcess}
              >
                {processEditId ? "Bearbeiten" : "Hinzufügen"}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Prozess löschen?")} </p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteProcess}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={"Bestätigung"}
            open={assignModal}
            closeModal={() => {
              setAssignModal(false);
            }}
          >
            <h6>Wurden dem Prozess alle notwendigen Aufgaben zugewiesen?</h6>
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setAssignModal(false);
                  setSelectedUser({
                    search: { value: null, label: "All" },
                    add: null,
                  });
                  setDescription("");
                  setProcessName("");
                }}
              >
                Abbrechen
              </OutlinedButton>

              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={Continue}
              >
                Weiter
              </AddButton>
            </div>
          </Modal>
        </div>
        <div className="sort department__dropdown">
          <label style={{ marginRight: "3.7rem" }}>{t("status")}: </label>
          <Select
            className="sort-input"
            value={selectedFilter}
            options={statuses}
            onChange={(e) => {
              getProcessByStatus(e);
            }}
            isSearchable={false}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              {/* <TableCell align="left">{t("Aufgaben")}</TableCell>
              <TableCell align="left">{t("Zuweisen")}</TableCell> */}
              <TableCell align="left">{t("responsible_person")}</TableCell>
              <TableCell align="left">{t("status")}</TableCell>
              <TableCell align="left">{t("deadline")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading || loading ? (
              <TableRow style={{ backgroundColor: "white" }}>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : !props.isLoading && !loading && !props.processIds?.length ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <NothingFound>
                    {t("Aktuell keine Prozesse hinterlegt")}
                  </NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  !loading &&
                  props.processIds.length > 0 &&
                  props.processIds?.map((id) => {
                    return (
                      props.processes[id] && (
                        <TableRow
                          key={id}
                          className="departments__table-row"
                          style={{ backgroundColor: "white" }}
                        >
                          <TableCell component="th" scope="row">
                            {props.processes[id].no + "."}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => history.push(`/process/${id}`)}
                          >
                            {props.processes[id].name}
                          </TableCell>
                          <TableCell align="left">
                            {getResponsiblePerson(
                              props.processes[id].responsible
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getProcessStatus(props.processes[id])}
                          </TableCell>
                          <TableCell align="left">
                            {deadLines[id] ? deadLines[id] : ""}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                onClick={() =>
                                  history.push(`/edit-process/${id}`, {
                                    type: "edit",
                                    step: "editProcess",
                                  })
                                }
                              />
                              <DeleteIcon
                                onClick={() => DeleteProcessModal(id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.processIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.process.isLoading,
  allProcessIds: state.process.allIds,
  processes: state.process.byId,
  processIds: state.process.searchIds,
  pageParams: state.process.pageParams,
  memberIds: state.user.allIds,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(Processes);
