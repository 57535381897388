export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SINGLE_ARTICLE = 'SET_SINGLE_ARTICLE';
export const ENTER_LOGIN ='ENTER_LOGIN';
export const START_LOADING ='START_LOADING';
export const END_LOADING='END_LOADING';
export const FETCH_BY_SEARCH='FETCH_BY_SEARCH';
export const FETCH_LOGIN='FETCH_LOGIN';
export const SET_LOGIN_INFO='SET_LOGIN_INFO';
export const SEARCH_MEDIAS= 'SEARCH_MEDIAS';
export const ADD_MEDIA='ADD_MEDIA';
export const DELETE_MEDIA='DELETE_MEDIA';
export const UPDATE_MEDIA='UPDATE_MEDIA';
export const SEARCH_ARTICLES="SEARCH_ARTICLES";
export const ADD_ARTICLE="ADD_ARTICLE";
export const SEARCH_LOCATIONS="SEARCH_LOCATIONS";
export const ADD_LOCATION="ADD_LOCATION";
export const UPDATE_LOCATION="UPDATE_LOCATION";
export const DELETE_LOCATION="DELETE_LOCATION";
export const SEARCH_DEPARTMENTS="SEARCH_DEPARTMENTS";
export const ADD_DEPARTMENT="ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT="UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT="DELETE_DEPARTMENT";
export const SEARCH_TEAMS="SEARCH_TEAMS";
export const ADD_TEAM="ADD_TEAM";
export const UPDATE_TEAM="UPDATE_TEAM";
export const DELETE_TEAM="DELETE_TEAM";
export const DEPARTMENTS_BY_LOC="DEPARTMENTS_BY_LOC";
export const SET_CHART_DATA="SET_CHART_DATA";
export const TEAMS_BY_DEPARTMENT="TEAMS_BY_DEPARTMENT";
export const SEARCH_USERS="SEARCH_USERS";
export const ADD_USER="ADD_USER";
export const UPDATE_USER="UPDATE_USER";
export const DELETE_USER="DELETE_USER";
export const USER_BY_ID="USER_BY_ID";
export const USER_CSV_UPLOAD="USER_CSV_UPLOAD";
export const UPDATE_ARTICLE="UPDATE_ARTICLE";
export const DELETE_ARTICLE="DELETE_ARTICLE";
export const ARTICLE_BY_ID="ARTICLE_BY_ID";
export const ARTICLE_INSERT_FILE="ARTICLE_INSERT_FILE";
export const ARTICLE_DELETE_FILE="ARTICLE_DELETE_FILE";
export const SEARCH_TASKS="SEARCH_TASKS";
export const ADD_TASK="ADD_TASK";
export const UPDATE_TASK="UPDATE_TASK";
export const DELETE_TASK="DELETE_TASK";
export const TASK_INSERT_FILE="TASK_INSERT_FILE";
export const TASK_DELETE_FILE="TASK_DELETE_FILE";
export const SEARCH_PROCESSES="SEARCH_PROCESSES";
export const ADD_PROCESS="ADD_PROCESS";
export const UPDATE_PROCESS="UPDATE_PROCESS";
export const DELETE_PROCESS="DELETE_PROCESS";
export const PROCESS_BY_ID="PROCESS_BY_ID";
export const ASSIGN_TASK_TO_USER="ASSIGN_TASK_TO_USER";
export const REASSIGN_TASK_TO_USER="REASSIGN_TASK_TO_USER";
export const TASK_BY_ID="TASK_BY_ID";
export const GET_PROCESS_TASKS="GET_PROCESS_TASKS";
export const ACTIVATE_PROCESS="ACTIVATE_PROCESS";
export const PROCESSES_BY_USER="PROCESSES_BY_USER";
export const TASK_DONE="TASK_DONE";
export const INSERT_TASK_ATTACHMENT="INSERT_TASK_ATTACHMENT";
export const TASKS_WAITING_FOR_APPROVAL="TASKS_WAITING_FOR_APPROVAL";
export const APPROVE_TASK="APPROVE_TASK";
export const REJECT_TASK="REJECT_TASK";
export const COMPLETE_PROCESS="COMPLETE_PROCESS";
export const TASKS_BY_BENEFACTOR="TASKS_BY_BENEFACTOR";
export const UPLOAD_PROFILE_PIC="UPLOAD_PROFILE_PIC";
export const ASSIGNED_TASKS="ASSIGNED_TASKS";
export const UPLOAD_MEMBER_PROFILE_PIC="UPLOAD_MEMBER_PROFILE_PIC";
export const FORGOT_PASSWORD="FORGOT_PASSWORD";
export const DELETE_TASK_ATTACHMENT="DELETE_TASK_ATTACHMENT";
export const UPLOAD_PROCESS_IMAGE="UPLOAD_PROCESS_IMAGE";
export const CHANGE_TASK_RESPONSIBLE="CHANGE_TASK_RESPONSIBLE";
export const CHANGE_TASK_DEADLINE="CHANGE_TASK_DEADLINE";
export const SCHEDULE_SENDING_TASK="SCHEDULE_SENDING_TASK";
