import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Pagination from "../../components/pagination/pagination";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import { getFileType, getFileExtension } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import App from "../../App";
import MediaModal from "./MediaModal";
import { searchMedias, deleteMedia } from "../../redux/media/action";
import SnackbarError from "../../components/alert/SnackbarError";
import MediaDisplayModal from "./MediaDisplayModal";
import Download from "@material-ui/icons/ArrowDownwardOutlined";
import auth from "../../auth";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";

const Medias = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [mediaEditId, setMediaEditId] = useState(null);
  const [mediaDeleteId, setMediaDeleteId] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;
    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }
    if (props.allMediaIds.length === 0) {
      dispatch(searchMedias({ GetAll: true }));
    }
    dispatch(
      searchMedias({
        Sort: 1,
        PageNumber: 1,
      })
    );
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      dispatch(
        searchMedias({
          PageNumber: 1,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
        })
      );
    }
    // forceCheck()
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchMedias({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const deleteMediaHandler = (id) => {
    if (!isAdmin && props.medias[id].creator !== auth.getId()) return;
    setMediaDeleteId(id);
    setConfirmDeleteModal(true);
  };

  const deleteMediaFile = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);

    await dispatch(
      deleteMedia({
        mediaId: mediaDeleteId,
        name: props.medias[mediaDeleteId].name,
      })
    )
      .then((res) => {
        resetDeleteModal(false);
        dispatch(searchMedias());
        setSuccessMessage("Datei wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Löschen der Datei.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const resetDeleteModal = () => {
    setMediaDeleteId(null);
    setConfirmDeleteModal(false);
    setButtonClicked(false);
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchMedias({
        PageNumber: newPage,
        Name: search,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const handleMediaDisplay = (id) => {
    let media = props.medias[id];
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl+media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  const downloadMediaHandler = (id) => {
    let media = props.medias[id];
    let href = `${config.resourcesUrl+media.name}`;
    const link = document.createElement("a");
    link.href = href;
    link.download = media.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const editMediaHandler = (id) => {
    if (!isAdmin && props.medias[id].creator !== auth.getId()) return;
    setMediaEditId(id);
    setOpenMediaModal(true);
  };

  const successMsgHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  const formatSize = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1000,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Breadcrumbs navigations={["Medien"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            onClick={() => {
              return setOpenMediaModal(true);
            }}
          >
            <AddIcon /> {t("Neu")}
          </Button>

          <MediaModal
            open={openMediaModal}
            closeModal={() => {
              setOpenMediaModal(false);
              setMediaEditId(null);
            }}
            mediaId={mediaEditId}
            selectedMedia={props.medias[mediaEditId]}
            showSuccessMsg={successMsgHandler}
          />

          <MediaDisplayModal
            open={displayMedia}
            closeModal={() => {
              setDisplayMedia(false);
              setDisplayUrl("");
              setDisplayMediaName("");
              setDisplayMediaTitle("");
            }}
            mediaUrl={displayUrl}
            title={displayMediaTitle}
            name={displayMediaName}
          />

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={resetDeleteModal}
          >
            <p>{t("sure_delete_media")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={resetDeleteModal}>
                Abbrechen
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={deleteMediaFile}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>
        </div>
        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("Medientyp")}</TableCell>
              <TableCell align="left">{t("Size")}</TableCell>
              <TableCell align="left">{t("Duration")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!props.isLoading && !props.mediaIds.length && (
              <TableRow>
                <TableCell colSpan={7}>
                  <NothingFound>{t("nothing_found_media")}</NothingFound>
                </TableCell>
              </TableRow>
            )}
            {!props.isLoading &&
              props.mediaIds.length > 0 &&
              props.mediaIds?.map((id) => {
                return (
                  props.medias[id] && (
                    <TableRow key={id} className="members__table-row">
                      <TableCell>{props.medias[id].no + "."}</TableCell>
                      <TableCell
                        align="left"
                        onClick={(event) => handleMediaDisplay(id)}
                      >
                        {props.medias[id].title}
                      </TableCell>
                      <TableCell align="left">
                        {getFileType(props.medias[id].name)}
                      </TableCell>
                      <TableCell align="left">
                        {formatSize(props.medias[id].size)}
                      </TableCell>
                      <TableCell align="left">
                        {props.medias[id].duration}
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <Download onClick={() => downloadMediaHandler(id)} />
                          <EditIcon
                            className={`${
                              isAdmin ||
                              props.medias[id].creator === auth.getId()
                                ? ""
                                : "disabled"
                            }`}
                            onClick={() => editMediaHandler(id)}
                          />
                          <DeleteIcon
                            className={`${
                              isAdmin ||
                              props.medias[id].creator === auth.getId()
                                ? ""
                                : "disabled"
                            }`}
                            onClick={() => deleteMediaHandler(id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {!props.mediaIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.media.isLoading,
  allMediaIds: state.media.allIds,
  mediaIds: state.media.searchIds,
  medias: state.media.byId,
  pageParams: state.media.pageParams,
});

export default connect(mapStateToProps, null)(Medias);
