import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import NewButton from "../../components/buttons/NewButton.js";
import Error from "../../components/alert/error";

import Logo from "../../layout/logo/logo.js";
import Superhero from "../../assets/images/superhero.png";
import { useTranslation } from "react-i18next";
import NewInput from "../../components/inputField/NewInput.js";

const ForgotPasswordFirstPage = ({
  firstPage,
  secondPage,
  email,
  setEmail,
}) => {
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = async () => {
    await setButtonClicked(true);

    await axios
      .post(`${config.apiURL}/api/Account/forgot-password`, { email })
      .then((res) => {
        if (res) {
          setButtonClicked(false);
          firstPage(false);
          secondPage(true);
        }
      })
      .catch((err) => {
        setButtonClicked(false);
        logError(err.response);
        setMessageVisible(true);
        setErrorResponse("Email is not verified or doesn't exist.");
      });
  };

  useEffect(() => {
    if (email) setValid(true);
    else setValid(false);
  }, [email]);

  return (
    <div className="login2">
      <div className="headerp">
        <Logo />
      </div>
      <div className="main2">
        <h2
          style={{ marginTop: "0", marginBottom: "3.5rem" }}
          className="forgotPassword__first--heading"
        >
          {t("Keine Panik, wir helfen weiter!")}
        </h2>
        <div
          className="login__right--img"
          style={{ width: "11.875rem", height: "9.938rem" }}
        >
          <img src={Superhero} alt="Super-hero" />
        </div>
        {messageVisible ? (
          <Error
            setOpen={setMessageVisible}
            style={{ marginTop: "2.8rem" }}
            className="forgotPassword__error"
          >
            {errorResponse}
          </Error>
        ) : null}
        <form
          className="login__form forgotPassword__form"
          style={{ marginTop: "7.5rem" }}
        >
          <NewInput
            label="Email"
            placeholder="Deine E-mail Adresse"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p>{t("Wir senden dir eine Email.")}</p>
        </form>

        <NewButton
          name={t("Abschicken")}
          onClick={handleClick}
          valid={valid}
          buttonClicked={buttonClicked}
        ></NewButton>
        {/* <p className="f-linkLabel">
          <span style={{ color: config.colorPrimary }}>{t("any_account")}</span>
          <Link to="/register">{t("signup")}</Link>
        </p> */}
      </div>
    </div>
  );
};

export default ForgotPasswordFirstPage;
