import React /* , { useState } */ from "react";
import { Dialog } from "@material-ui/core";
// import Button from '@material-ui/core/Button';
// import Pagination from '../../components/pagination/pagination';
// import Tooltip from '@material-ui/core/Tooltip';
import AbbrechenIcon from "@material-ui/icons/Cancel";

import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
// import Search from '../../components/search/search';

import { useTranslation } from "react-i18next";

const CategoryModal = (props) => {
  const { t } = useTranslation();
  const {
    categoryName,
    // categoryId,
    open,
    closeModal,
  } = props;
  // const [defaultPage, setDefaultPage] = useState(false);
  // const [search, setSearch] = useState('');

  // const searchCategory = (search) => {
  //   setDefaultPage(!defaultPage);

  //   //   getSerachedCategory(categoryName, categoryId, search);
  // };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members"
    >
      <div className="department-members__header">
        <Breadcrumbs navigations={[`${t("Categories")}`, `${categoryName}`]} />
        <AbbrechenIcon
          style={{ fill: "#ee5555", cursor: "pointer" }}
          onClick={closeModal}
        />
      </div>

      <div className="nothingFound" style={{ marginTop: "6rem" }}>
        <p style={{ marginBottom: "5rem" }}>{t("nothing_found_category")}</p>
      </div>
      {/*    
          <div
            className="departments__top--serach"
            style={{ marginTop: '1.875rem' }}
          >
            <Search getSearchValue={searchCategory} />
          </div>

          <div>
            {/* <div className="department-members__list">{renderCategories()}</div> 
    
            {/* <Pagination
              params={params}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            /> 
          </div> */}
    </Dialog>
  );
};

export default CategoryModal;
