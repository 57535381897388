// import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#ebebeb'
  },
  tooltip: {
    backgroundColor: '#ebebeb',
    color: '#7a7a7a',
    fontSize: 11,
    padding: '0.1rem 1.1rem'
  }
}))(Tooltip);
