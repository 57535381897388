import React, { useState, useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import Select from "react-select";
import ATT from "array-to-tree";
import { useHistory } from "react-router-dom";
import TaskCard from "../../components/card/TaskCard";
import { connect, useDispatch } from "react-redux";
import {
  getProcessById,
  updateProcess,
  getProcessTasks,
} from "../../redux/process/action";
import { searchTasks } from "../../redux/task/action";
import AddIconCircle from "@material-ui/icons/AddCircleRounded";
import { LightTooltip } from "../../components/tooltip/tooltip";
import auth from "../../auth";
import "./styles.css";
import moment from "moment";

const AssignTasks = (props) => {
  const history = useHistory();
  const {
    assignUserHandler,
    saveAsTemplateHandler,
    showSuccessMsg,
    showErrorMsg,
  } = props;
  const routeState = history.location.state;

  const StyledNode = ({ text, des, preTask, id }) => {
    let temp = taskOfProcess?.filter((item) => {
      return item.id === id;
    });
    let status = "";
    let color = "red";
    if (temp.length > 0) {
      if (!temp[0].responsible) {
        color = "red";
        status = "Status: Aufgabe hat keine Zuweisung!";
      } else if (temp[0].compeleted && temp[0].approved) {
        color = "#B4D264";
        status = "Status: Aufgabe erledigt und genehmigt!";
      } else if (temp[0].compeleted && !temp[0].approved) {
        color = "#FF6FCC";
        status = "Status: Aufgabe erledigt aber nicht genehmigt!";
      } else if (
        !temp[0].compeleted &&
        !temp[0].approved &&
        temp[0].responsible
      ) {
        if (moment().isAfter(temp[0].dateBeDone)) {
          color = "red";
          status = "Status: Kritisch! Termine überschritten.";
        } else {
          color = "#D076FF";
          status = "Status: Aufgabe wurde noch nicht erledigt.";
        }
      }
    }
    const cardClickHandler = () => {
      setSelectedTaskCard(temp);
      if (process.isActivated && process.compeleted) {
        setOpenWarningModal(true);
      } else {
        if (preTask) {
          setIsRootTask(false);
          if (
            (!process.isActivated ||
              (process.isActivated && !process.compeleted)) &&
            allowUserToEdit
          ) {
            if (temp[0]?.compeleted) {
              setWarningMsg(
                "Diese Aufgabe ist bereits fertig gemeldet. Sie kann nicht mehr geändert werden"
              );
              setOpenWarningModal(true);
            } else {
              setOpenModalEdit(true);
              setActionType("");
            }
          } else {
            setOpenWarningModal(true);
          }
        } else {
          setIsRootTask(true);
          if (
            (!process.isActivated ||
              (process.isActivated && !process.compeleted)) &&
            allowUserToEdit
          ) {
            if (temp[0]?.compeleted) {
              setWarningMsg(
                "Diese Aufgabe ist bereits fertig gemeldet. Sie kann nicht mehr geändert werden"
              );
              setOpenWarningModal(true);
            } else {
              if (taskOfProcess.length === 1 && !taskOfProcess[0].preTask) {
                setAllowDeleteRoot(true);
                setActionType("delete");
                setModalTitle("Aufgabe entfernen");
                setR2({
                  add: {
                    value: temp[0].id,
                    label: temp[0].name,
                    pre: temp[0].preTask,
                  },
                });
                setOpenModalEdit(true);
              } else {
                setAllowDeleteRoot(false);
                setActionType("changeRoot");
                setModalTitle("Startaufgabe ändern");
                setOpenModalEdit(true);
              }
            }
          } else {
            setOpenWarningModal(true);
          }
        }
      }
    };
    return (
      <TaskCard
        color={color}
        text={text}
        shortDes={"Eine Beschreibung der Aufgabe"}
        onCardClick={cardClickHandler}
        status={status}
      />
    );
  };
  const StyledNodeR = () => <div></div>;

  const CustomCard = (props) => {
    return (
      <>
        {props.data.map((item, index) => (
          <TreeNode
            key={index}
            label={
              <StyledNode
                text={item.name}
                des={item.description}
                key={index}
                preTask={item.preTask}
                id={item.id}
              />
            }
          >
            {item.children?.length && (
              <CustomCard data={item.children} key={index} />
            )}
          </TreeNode>
        ))}
      </>
    );
  };

  const dispatch = useDispatch();
  const [prosDes, setProsDes] = useState(null);
  const [prosRes, setProsRes] = useState(null);
  const [prosName, setProsName] = useState(null);
  const [propTasks, setPropTasks] = useState(routeState.process.tasks);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [sort, setSetsort] = useState(
    ATT(propTasks, {
      parentProperty: "preTask",
      customID: "id",
    })
  );
  const [process, setProcess] = useState(routeState.process);
  const [tasksForAdd, setTasksForAdd] = useState([]);
  const [presForAdd, setPresForAdd] = useState(
    propTasks.map((loc) => {
      return {
        value: loc.id,
        label: loc.name,
      };
    })
  );

  const [buttonClicked, setButtonClicked] = useState(false);
  const [pre, setPre] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [selectedTask, setSelectedTask] = useState({
    search: { value: null, label: "All" },
    add: null,
  });

  const [noroot, setNoroot] = useState([]);
  const [R1, setR1] = useState({
    search: { value: null, label: "All" },
    add: null,
  });

  const [norootForDelete, setNorootForDelete] = useState(null);
  const [R2, setR2] = useState({
    search: { value: null, label: "All" },
    add: null,
  });

  const [bottomList, setBottomList] = useState([]);
  const [bottom, setBottom] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openSaveAsTemplateModal, setOpenSaveAsTemplateModal] = useState(false);
  const [openContinueModal, setOpenContinueModal] = useState(false);
  const [isTemplateProcess, setIsTemplateProcess] = useState("");
  const [isAllTasksCompleted, setIsAllTasksCompleted] = useState(false);

  const [isRootTask, setIsRootTask] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [selectedTaskCard, setSelectedTaskCard] = useState([]);
  const [taskOfProcess, setTaskOfProcess] = useState([]);
  const [isUserAssigned, setIsUserAssigned] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [allowDeleteRoot, setAllowDeleteRoot] = useState(false);
  const [allowUserToEdit, setAllowUserToEdit] = useState(false);

  const getBottom_Drop = (id) => {
    const newArr = presForAdd.filter((item) => {
      return item.value !== id;
    });
    setBottomList(newArr);
  };

  const getProcess = async () => {
    let _process = [];
    if (props.processes && props.processes[routeState.process.id]) {
      _process = props.processes[routeState.process.id];
    } else {
      await dispatch(getProcessById({ id: routeState.process.id }))
        .then((res) => {
          if (res.value?.data) {
            _process = res.value.data;
          }
        })
        .catch((err) => {
          logError(err);
          let errMsg = "Fehler bei Laden des Prozesses.";
          if (err.response?.data?.errors) {
            errMsg =
              err.response.data.errors[
                Object.keys(err.response.data.errors)[0]
              ];
          }
          showErrorMsg(errMsg);
        });
    }

    if (_process) {
      setProcess(_process);
      setProsDes(_process.description);
      setProsRes(_process.responsible);
      setProsName(_process.name);
      setIsTemplateProcess(_process.isTemplate);

      if (
        _process.responsible === auth.getId() ||
        _process.creator === auth.getId() ||
        props.currentUser?.role === "Admin" ||
        _process.isTemplate
      ) {
        setAllowUserToEdit(true);
      } else {
        setAllowUserToEdit(false);
      }

      setSetsort(
        ATT(_process.tasks, {
          parentProperty: "preTask",
          customID: "id",
        })
      );
      setPresForAdd(
        _process.tasks.map((task) => {
          return {
            value: task.id,
            label: task.name,
          };
        })
      );
      const box = [];
      _process.tasks.forEach((task) => {
        box.push({
          value: task.id,
          label: task.name,
          pre: task.preTask,
        });
      });
      const newArr = box.filter((item) => {
        return item.pre !== null;
      });
      setNoroot(newArr);
      if (_process.tasks.length !== 1) {
        setNorootForDelete(newArr);
      } else {
        setNorootForDelete(box);
      }
    }
  };

  const getTasks = async () => {
    if (process && props.taskIds.length > 0) {
      const _tasks = [];
      const _processTasks = [];

      process.tasks.forEach((task) => {
        _processTasks.push({
          value: task.id,
          label: task.name,
          des: task.description,
        });
      });

      props.taskIds.forEach((id) => {
        _tasks.push({
          value: id,
          label: props.tasks[id].name,
          des: props.tasks[id].description,
        });
      });

      var newPre = _tasks.filter(function (objFromA) {
        return !_processTasks.find(function (objFromB) {
          return objFromA.value === objFromB.value;
        });
      });

      setTasksForAdd(newPre);
    } else {
      setTasksForAdd([]);
    }
  };

  const AddTask = async () => {
    await setButtonClicked(true);
    let temp = process.tasks;

    const task = {
      id: selectedTask.add.value,
      name: selectedTask.add.label,
      description: selectedTask.add.des,
      preTask: sort.length === 0 ? null : pre.add.value,
      processId: routeState.process.id,
      dateCreated: new Date(),
    };

    temp.push(task);
    setPropTasks(temp);

    await dispatch(
      updateProcess({
        name: prosName,
        description: prosDes,
        responsible: prosRes,
        tasks: temp,
        processId: routeState.process.id,
        isTemplate: isTemplateProcess,
      })
    )
      .then(async () => {
        setButtonClicked(false);
        setOpenModal(false);
        setSelectedTask({ ...selectedTask, add: null });
        setPre({ ...pre, add: null });
        getProcess();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        getTasks();
        showSuccessMsg("Aufgabe wurde erfolgreich hinzugefügt.");
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Fehler bei Hinzufügen der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
        setOpenModal(false);
        setSelectedTask({ ...selectedTask, add: null });
        setPre({ ...pre, add: null });
        getProcess();
        getTasks();
      });
  };

  const Replace = async () => {
    await setButtonClicked(true);
    let objIndex = process.tasks.findIndex((obj) => obj.id === R1.add.value);
    let keep_pre = process.tasks[objIndex].preTask;

    process.tasks.map((item, index) => {
      return item.preTask === R1.add.value
        ? (process.tasks[index].preTask = keep_pre)
        : null;
    });
    process.tasks[objIndex].preTask = bottom.add.value;

    await dispatch(
      updateProcess({
        name: prosName,
        description: prosDes,
        responsible: prosRes,
        tasks: process.tasks,
        processId: routeState.process.id,
        isTemplate: isTemplateProcess,
      })
    )
      .then(async () => {
        setButtonClicked(false);
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        setR1({ ...R1, add: null });
        setBottom({ ...bottom, add: null });
        getProcess();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        getTasks();
        showSuccessMsg("Aufgabe erfolgreich bearbeitet.");
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeitung der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        setR1({ ...R1, add: null });
        setBottom({ ...bottom, add: null });
        getProcess();
        getTasks();
      });
  };

  const Change = async () => {
    await setButtonClicked(true);
    let objIndex = process.tasks.findIndex((obj) => obj.id === R1.add.value);
    let pre = process.tasks[objIndex].preTask;

    let rootIndex = process.tasks.findIndex((obj) => obj.preTask === null);

    process.tasks[rootIndex].preTask = pre;

    process.tasks[objIndex].preTask = null;
    process.tasks.forEach((element, index) => {
      if (element.preTask === process.tasks[rootIndex].id) {
        element.preTask = R1.add.value;
      }
    });

    await dispatch(
      updateProcess({
        name: prosName,
        description: prosDes,
        responsible: prosRes,
        tasks: process.tasks,
        processId: routeState.process.id,
        isTemplate: isTemplateProcess,
      })
    )
      .then(async (res) => {
        setButtonClicked(false);
        // setOpenModalChange(false);
        setR1({ ...R1, add: null });
        getProcess();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        getTasks();
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        showSuccessMsg("Aufgabe wurde erfolgreich geändert.");
      })
      .catch((err) => {
        setButtonClicked(false);
        // setOpenModalChange(false);
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        setR1({ ...R1, add: null });
        getProcess();
        getTasks();
        let errMsg = "Fehler bei Bearbeitung der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
  };

  const DeleteTask = async () => {
    await setButtonClicked(true);
    let objIndex = process.tasks.findIndex((obj) => obj.id === R2.add.value);
    let keep_pre2 = process.tasks[objIndex].preTask;

    process.tasks.map((item, index) => {
      return item.preTask === R2.add.value
        ? (process.tasks[index].preTask = keep_pre2)
        : null;
    });
    if (keep_pre2 === null) {
      setPropTasks([]);
    }

    var newSet = process.tasks.filter(function (obj) {
      return obj.id !== R2.add.value;
    });

    await dispatch(
      updateProcess({
        name: prosName,
        description: prosDes,
        responsible: prosRes,
        tasks: newSet,
        processId: routeState.process.id,
        isTemplate: isTemplateProcess,
      })
    )
      .then(async (res) => {
        setButtonClicked(false);
        // setOpenModalDelete(false);
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        setR2({ ...R2, add: null });
        getProcess();
        let processTasks = await getTasksOfProcess();
        setTaskOfProcess(processTasks);
        getTasks();
        showSuccessMsg("Aufgabe wurde erfolgreich gelöscht.");
        setAllowDeleteRoot(false);
      })
      .catch((err) => {
        setButtonClicked(false);
        // setOpenModalDelete(false);
        setOpenModalEdit(false);
        setActionType(null);
        setModalTitle(null);
        setR2({ ...R2, add: null });
        getProcess();
        getTasks();
        let errMsg = "Fehler bei Löschen der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
  };

  useEffect(async (_) => {
    await getProcess();
    let processTasks = await getTasksOfProcess();
    setTaskOfProcess(processTasks);

    if (processTasks?.length > 0) {
      const newArr = processTasks.filter((item) => {
        return item.responsible !== null && item.responsible !== "";
      });

      if (newArr.length > 0) {
        //disable updates if task is assigned to a user
        setIsUserAssigned(true);
      } else {
        setIsUserAssigned(false);
      }

      const _incompleteTasks = processTasks.filter((item) => {
        return !item.compeleted;
      });

      if (_incompleteTasks.length > 0) {
        setIsAllTasksCompleted(false);
      } else {
        setIsAllTasksCompleted(true);
      }
    } else {
      setIsAllTasksCompleted(false);
    }

    if (props.taskIds.length == 0) {
      await dispatch(searchTasks({ GetAll: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasks();
  }, [props.taskIds.length, process]);

  const getTasksOfProcess = async () => {
    let _tasks = [];
    await dispatch(getProcessTasks({ processId: routeState.process.id }))
      .then((res) => {
        if (res.value?.data) {
          _tasks = res.value.data.tasks;
        }
      })
      .catch((err) => {
        logError(err);
        let errMsg = "Fehler bei Laden des Prozesses Tasks.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response?.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        showErrorMsg(errMsg);
      });
    return _tasks;
  };

  const assignTaskContinueHandler = () => {
    setOpenContinueModal(false);
    assignUserHandler();
  };

  const continueClickHandler = () => {
    if (process.isActivated || isUserAssigned) {
      assignUserHandler();
    } else {
      setOpenContinueModal(true);
    }
  };

  const backBtnHandler = () => {
    if (process.isTemplate) {
      history.push(`/master-processes`);
    } else {
      history.push(`/processes`);
    }
  };

  const deleteInput = () => {
    return (
      <>
        <h5>Soll die Aufgabe vom Prozess entfernt werden?</h5>

        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenModalEdit(false);
              setActionType(null);
              setModalTitle(null);
              setSelectedTask({
                search: { value: null, label: "All" },
                add: null,
              });
              setR2({ ...R2, add: null });
              setAllowDeleteRoot(false);
            }}
          >
            Abbrechen
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={R2.add ? true : false}
            onClick={DeleteTask}
            buttonClicked={buttonClicked}
          >
            Entfernen
          </AddButton>
        </div>
      </>
    );
  };

  const changeRootTask = () => {
    return (
      <>
        <Select
          className="sort-input positions__select-department"
          value={R1.add}
          placeholder="Neue Startaufgabe wählen"
          onChange={(e) => {
            setR1({ add: e });
          }}
          options={noroot}
          isSearchable={true}
        />

        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenModalEdit(false);
              setActionType(null);
              setModalTitle(null);
              setSelectedTask({
                search: { value: null, label: "All" },
                add: null,
              });
            }}
          >
            Abbrechen
          </OutlinedButton>

          <AddButton
            className="departments__modal--add"
            valid={R1.add ? true : false}
            onClick={Change}
            buttonClicked={buttonClicked}
          >
            Ändern
          </AddButton>
        </div>
      </>
    );
  };

  const deleteHandler = () => {
    const temp = norootForDelete?.filter((item) => {
      return item.value === selectedTaskCard[0]?.id;
    });
    if (temp.length > 0) {
      setR2({ add: temp[0] });
    }
    setActionType("delete");
    setModalTitle("Aufgabe entfernen");
  };

  const reAssignHandler = () => {
    const temp = noroot?.filter((item) => {
      return item.value === selectedTaskCard[0]?.id;
    });
    if (temp.length > 0) {
      setR1({ add: temp[0] });
      getBottom_Drop(temp[0].value);
    } else {
      getBottom_Drop([]);
    }

    setActionType("reassign");
    setModalTitle("Aufgabe bearbeiten");
  };

  return (
    <>
      <br></br>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <Button
            className="departments__add-new--button mr-2"
            onClick={backBtnHandler}
          >
            Zurück
          </Button>
          {!process.isTemplate && (
            <AddButton
              className="departments__modal--add ml-0 mr-2"
              valid={process.tasks?.length > 0 ? true : false}
              style={{
                height: "2.8rem !important",
                cursor: process.tasks?.length === 0 ? "not-allowed" : "pointer",
              }}
              disabled={process.tasks?.length === 0}
              onClick={() => {
                setOpenSaveAsTemplateModal(true);
              }}
            >
              Als Masterprozess festlegen
            </AddButton>
          )}
        </div>
        {!process.isTemplate && (
          <Button
            className="departments__add-new--button"
            disabled={process.tasks?.length === 0}
            style={{
              float: "right",
              cursor:
                process.tasks?.length && process.tasks?.length > 0
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={continueClickHandler}
          >
            Weiter
          </Button>
        )}
      </div>
      <br></br>
      <br></br>

      {process?.tasks?.length === 0 ? (
        <div
          style={{
            width: "100%",
            height: 100,
            display: "flex",
            border: "1px solid black",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            color: `${config.colorSecondary}`,
            fontSize: 22,
          }}
        >
          Aktuell sind diesem Prozess keine Aufgaben zugewiesen!
        </div>
      ) : (
        <Tree
          lineWidth={"2px"}
          lineColor={"green"}
          lineBorderRadius={"10px"}
          label={<StyledNodeR />}
        >
          <CustomCard data={sort} />
        </Tree>
      )}
      <div
        className=""
        style={{ float: "right", margin: "100px 0 0 0", paddingBottom: "20px" }}
      >
        <LightTooltip arrow title={"Hinzufügen"} placement="top">
          <AddIconCircle
            style={{
              fontSize: " 64px",
              color: config.colorSecondary,
              cursor:
                (!process.isActivated || !process.compeleted) && allowUserToEdit
                  ? "pointer"
                  : "not-allowed",
            }}
            className={`${
              (!process.isActivated || !process.compeleted) && allowUserToEdit
                ? ""
                : "disabled"
            }`}
            onClick={() => {
              if (
                (!process.isActivated || !process.compeleted) &&
                allowUserToEdit
              ) {
                setOpenModal(true);
              }
            }}
          />
        </LightTooltip>
      </div>
      <Modal
        title={"Aufgabe hinzufügen"}
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <Select
          className="sort-input positions__select-department"
          value={selectedTask.add}
          placeholder="Aufgabe wählen"
          onChange={(e) => {
            setSelectedTask({ add: e });
          }}
          options={tasksForAdd}
          isSearchable={true}
        />
        {process?.tasks?.length === 0 ? null : (
          <Select
            className="sort-input positions__select-department"
            value={pre.add}
            placeholder="Verbinde mit"
            onChange={(e) => {
              setPre({ add: e });
            }}
            options={presForAdd}
            isSearchable={true}
          />
        )}

        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenModal(false);
              setSelectedTask({
                search: { value: null, label: "All" },
                add: null,
              });
            }}
          >
            Abbrechen
          </OutlinedButton>
          {process?.tasks?.length === 0 ? (
            <AddButton
              className="departments__modal--add"
              valid={selectedTask.add ? true : false}
              onClick={AddTask}
              buttonClicked={buttonClicked}
            >
              Hinzufügen
            </AddButton>
          ) : (
            <AddButton
              className="departments__modal--add"
              valid={selectedTask.add && pre.add ? true : false}
              onClick={AddTask}
              buttonClicked={buttonClicked}
            >
              Hinzufügen
            </AddButton>
          )}
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title={modalTitle ? modalTitle : "Aufgabe bearbeiten"}
        open={openModalEdit}
        closeModal={() => {
          setOpenModalEdit(false);
          setR1({ ...R1, add: null });
          setBottom({ ...bottom, add: null });
          setBottomList([]);
          setIsRootTask(false);
          setActionType(null);
          setModalTitle(null);
          setAllowDeleteRoot(false);
        }}
      >
        {isRootTask && actionType === "changeRoot" && changeRootTask()}

        {isRootTask &&
          allowDeleteRoot &&
          actionType === "delete" &&
          deleteInput()}

        {!isRootTask && !actionType && (
          <div className="task-action-btns">
            <AddButton
              className="departments__modal--add ml-0 mr-4 delete-article-preview"
              valid={
                !process.isActivated ||
                (process.isActivated &&
                  !selectedTaskCard[0]?.compeleted &&
                  !process.compeleted &&
                  allowUserToEdit)
                  ? true
                  : false
              }
              onClick={deleteHandler}
              buttonClicked={buttonClicked}
            >
              <Delete />
              Aufgabe entfernen
            </AddButton>
            <AddButton
              className="departments__modal--add ml-0"
              valid={
                (!process.isActivated ||
                  (process.isActivated && !process.compeleted)) &&
                allowUserToEdit
                  ? true
                  : false
              }
              onClick={reAssignHandler}
              buttonClicked={buttonClicked}
            >
              Neu zuordnen
            </AddButton>
          </div>
        )}

        {!isRootTask && actionType === "delete" && deleteInput()}

        {!isRootTask && actionType === "reassign" && (
          <>
            <Select
              className="sort-input positions__select-department"
              value={R1.add}
              placeholder="Aufgabe wählen"
              onChange={(e) => {
                setR1({ add: e });
                getBottom_Drop(e.value);
              }}
              options={noroot}
              isSearchable={false}
              isOptionDisabled={() => true}
            />

            <Select
              className="sort-input positions__select-department"
              value={bottom.add}
              placeholder="Verbinden mit"
              onChange={(e) => {
                setBottom({ add: e });
              }}
              options={bottomList}
              isSearchable={true}
            />

            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModalEdit(false);
                  setSelectedTask({
                    search: { value: null, label: "All" },
                    add: null,
                  });
                  setR1({ ...R1, add: null });
                  setBottom({ ...bottom, add: null });
                  setBottomList([]);
                  setActionType(null);
                  setModalTitle(null);
                }}
              >
                Abbrechen
              </OutlinedButton>

              <AddButton
                className="departments__modal--add"
                valid={bottom.add && R1.add ? true : false}
                onClick={Replace}
                buttonClicked={buttonClicked}
              >
                Zuordnen
              </AddButton>
            </div>
          </>
        )}
      </Modal>

      <Modal
        open={openWarningModal}
        closeModal={() => {
          setOpenWarningModal(false);
          setWarningMsg("");
        }}
      >
        {warningMsg ? (
          <h5>{warningMsg}</h5>
        ) : (
          <h5>
            Prozess wurde bereits aktiviert, somit sind keine Änderungen mehr
            möglich.
          </h5>
        )}
        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenWarningModal(false);
              setWarningMsg("");
            }}
          >
            Abbrechen
          </OutlinedButton>
        </div>
      </Modal>
      <Modal
        open={openSaveAsTemplateModal}
        closeModal={() => {
          setOpenSaveAsTemplateModal(false);
        }}
      >
        <h5>Diesen Prozess als Master speichern?</h5>
        <div className="departments__modal--buttons">
          <AddButton
            className="departments__modal--add delete-article-preview"
            valid={true}
            onClick={() => {
              setOpenSaveAsTemplateModal(false);
            }}
          >
            Abbrechen
          </AddButton>
          <AddButton
            className="departments__modal--add"
            valid={true}
            onClick={() => {
              setOpenSaveAsTemplateModal(false);
              saveAsTemplateHandler();
            }}
            buttonClicked={buttonClicked}
          >
            Speichern
          </AddButton>
        </div>
      </Modal>
      <Modal
        open={openContinueModal}
        closeModal={() => {
          setOpenContinueModal(false);
        }}
      >
        <h5>Wurden alle Aufgaben dem Prozess zugewiesen?</h5>
        <div className="departments__modal--buttons">
          <AddButton
            className="departments__modal--add delete-article-preview"
            valid={true}
            onClick={() => {
              setOpenContinueModal(false);
            }}
          >
            Abbrechen
          </AddButton>
          <AddButton
            className="departments__modal--add"
            valid={true}
            onClick={assignTaskContinueHandler}
            buttonClicked={buttonClicked}
          >
            Weiter
          </AddButton>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  processes: state.process.byId,
  taskIds: state.task.allIds,
  tasks: state.task.byId,
});

export default connect(mapStateToProps, null)(AssignTasks);
