import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../config/api.config.js";
import { /* Link */ Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import "../../assets/css/pages/memberDetails.scss";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import App from "../../App";
import MemberModal from "./MemberModal";
import { searchDepartments } from "../../redux/department/action";
import { searchTeams } from "../../redux/team/action";
import { getUserById } from "../../redux/users/action";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import SnackbarError from "../../components/alert/SnackbarError";

const useStyles = makeStyles((theme) => ({
  title: {
    // color: config.colorSecondary,
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  memberDetails: {
    fontSize: "1.5rem",
    color: "#667e80",
    letterSpacing: "0.01071em",
  },
  memberSmall: {
    fontSize: ".8rem",
    color: "#cccccc",
    letterSpacing: "1px",
  },
  memberTitle: {
    fontSize: "1rem",
    color: "#c7c7c7",
    letterSpacing: "1px",
  },
}));
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#4AAEE7",
    color: "#4AAEE7",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}))(Badge);

const MemberDetails = (props) => {
  const dispatch = useDispatch();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(false);
  const [memberEditId, setMemberEditId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [openMemberModal, setOpenMemberModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const history = useHistory()
  const classes = useStyles();

  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;
    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }

    if (props.departmentIds.length === 0) {
      dispatch(searchDepartments({ GetAll: true }));
    }
    if (props.teamIds.length === 0) {
      dispatch(searchTeams({ GetAll: true }));
    }
    getMember();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const getMember = async () => {
    const id = props.match.params.id;
    let user = [];
    setLoading(true);
    if (props.memberIds.length > 0 && props.members[id]) {
      setLoading(false);
      user = props.members[id];
      if (props.members[id]?.image) {
        user["profileImg"] = `${config.resourcesUrl+props.members[id]?.image?.name}`;
      } else {
        user["profileImg"] = "";
      }
    } else {
      await dispatch(getUserById({ id: id }))
        .then((res) => {
          setLoading(false);
          if (res.value?.data) {
            user = res.value.data;
            user["id"] = res.value.data.userId;
            if (user.image?.name) {
              user["profileImg"] = `${config.resourcesUrl+user.image.name}`;
            } else {
              user["profileImg"] = "";
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage("Fehler bei Laden der Nutzerdetails.");
          setOpenAlert(true);
        });
    }
    if (user) {
      setMember(user);
      setMemberEditId(user.userId);
      setEditData({
        id: user.userId,
        name: user.firstName,
        firstName: user.firstName,
        lastName: user.lastName,
        teamId: user.teamId,
        departmentId: user.departmentId,
        locationId: user.locationId,
        email: user.email,
        phoneNumber: user.phoneNumber,
        role: user.role,
        imageSrc: user.profileImg,
        supervisorId: user.supervisorId,
        position: user.position,
      });
    }
  };

  const getDepartmentName = (depId) => {
    let depName = "";
    if (depId && props.departmentIds.length > 0 && props.departments[depId]) {
      depName = props.departments[depId].name;
    }
    return depName;
  };

  const getTeamName = (teamId) => {
    let teamName = "";
    if (teamId && props.teamIds.length > 0 && props.teams[teamId]) {
      teamName = props.teams[teamId].name;
    }
    return teamName;
  };

  const editMemberHandler = (id) => {
    if (!isAdmin) return;
    setOpenMemberModal(true);
    setMemberEditId(id);
  };

  const onSuccessHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  return (
    <App>
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {loading ? (
        <div>
          <CircularProgress style={{ color: config.colorPrimary }} />
        </div>
      ) : (
        member && (
          <>
            <MemberModal
              open={openMemberModal}
              closeModal={() => {
                setOpenMemberModal(false);
                getMember();
              }}
              editId={memberEditId}
              selectedMember={editData}
              getMembers={getMember}
              currentUser={props.currentUser}
              showSuccessMsg={onSuccessHandler}
            />
            <div className="member-details-header">
              <Link to="/chart" color="inherit" className="previous-link">
                <ArrowBackIosIcon
                  color="inherit"
                  size="small"
                  // onClick={history.goBack()}
                />
              </Link>
              <Breadcrumbs
                navigations={["Personendetails"]}
                className={classes.title}
              />
            </div>
            <div className="member-details-card">
              <Grid container wrap="wrap">
                <Grid item sm={12} md={4} className="member-profile-foto">
                  <Avatar
                    alt="profile-foto"
                    src={member.profileImg ? member.profileImg : "/profile.jpg"}
                    className={classes.large}
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <div className="member-details-card-body">
                    <div>
                      <div>
                        <Typography className={classes.memberDetails}>
                          {member.firstName + " " + member.lastName}
                        </Typography>
                      </div>
                      <EditIcon
                        className={`member-details-edit-icon ${
                          !isAdmin ? "disabled" : ""
                        }`}
                        id="icon"
                        onClick={() => {
                          editMemberHandler(member.userId);
                        }}
                      />
                    </div>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        E-mail :
                      </span>
                      <span className="pl-1">
                        {member.email ? member.email : ""}
                      </span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Telefon :
                      </span>
                      <span className="pl-1">
                        {member.phoneNumber ? member.phoneNumber : ""}
                      </span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Abteilung :
                      </span>
                      <span className="pl-1">
                        {getDepartmentName(member.departmentId)}
                      </span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Team :
                      </span>
                      <span className="pl-1">{getTeamName(member.teamId)}</span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Position :
                      </span>
                      <span className="pl-1">
                        {member.position ? member.position : ""}
                      </span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Rolle :
                      </span>
                      <span className="pl-1">
                        {member.role ? member.role : ""}
                      </span>
                    </Typography>
                    <Typography gutterBottom>
                      <span className={`${classes.memberTitle} pr-1`}>
                        Supervisor :
                      </span>
                      <span className="pl-1">
                        {member.supervisorId ? member.supervisorId : ""}
                      </span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        )
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  memberIds: state.user.allIds,
  members: state.user.byId,
  teamIds: state.team.allIds,
  teams: state.team.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
});

export default connect(mapStateToProps, null)(MemberDetails);
