import React, { useState, useEffect } from 'react';

import FirstPage from './firstPage';
import SecondPage from './secondPage';
import config from '../../config/api.config.js';



const ResetPassword = () => {
  const [firstPageVisible, setFirstPageVisible] = useState(true);
  const [secondPageVisible, setSecondPageVisible] = useState(true);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      config.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      config.colorSecondary
    );
    root?.style.setProperty(
      "--color-active",
      config.colorActive
    );
    root?.style.setProperty(
      "--color-hover",
      config.colorHover
    );
    setReadyToRender(true);
  }, []);

  return (
    <div>     
      {readyToRender && <div className="main2">
        {firstPageVisible ? (
          <FirstPage
            firstPage={setFirstPageVisible}
            secondPage={setSecondPageVisible}
          />
        ) : secondPageVisible ? (
          <SecondPage />
        ) : (
          <FirstPage
            firstPage={setFirstPageVisible}
            secondPage={setSecondPageVisible}
          />
        )}
      </div>}
    </div>
  );
};

export default ResetPassword;
