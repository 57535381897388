import LocalStorageService from './LocalStorageService';
import { history } from './index';

class Auth {
  constructor() {
    this.load();
  }

  login(accessToken, expToken, userId, type) {
    const _config = {
      access_token: accessToken,
      exp_token: expToken,
      loggedUserId: userId,
      type
    };
    LocalStorageService.set(_config);
    this.load(_config);

    history.push('/dashboard');
    window.location.reload();
  }
  
  load(_config) {
    const storage = _config ? _config : LocalStorageService.all();
    const { access_token, exp_token, loggedUserId, type } = storage;
    this.authenticated = !!access_token;
    this.type = type;
    this.userId = loggedUserId;
    this.accessToken = access_token;
    this.expToken = exp_token;
  }

  dispose() {
    this.authenticated = false;
    this.type = this.userId = this.accessToken = this.expToken = null;
  }

  getId() {
    return this.userId;
  }

  getType() {
    return this.type;
  }

  isAdmin() {
    return this.type === 'Admin';
  }

  isEmployee() {
    return this.type === 'Employee';
  }

  isIntern() {
    return this.type === 'Intern';
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isTokenExpired() {
    return new Date().getTime() / 1000 > this.expToken;
  }
  logout(logoutRedirect) {
     
     LocalStorageService.clear();
     this.dispose();
     logoutRedirect();
  }
}

export default new Auth();
