import React, { useState, useEffect, Fragment } from 'react';

import Header from './layout/header/header';
import Sidebar from './layout/sidebar/sidebar';
import './assets/css/_scss-variables.scss';
import './App.scss';
import config from '../src/config/api.config.js'


const App = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(null);
  // const [open, setOpen] = useState(width>500?true:false);
  const [open, setOpen] = useState(true);
  const [readyToRender, setReadyToRender] = useState(false);

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      config.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      config.colorSecondary
    );
    root?.style.setProperty(
      "--color-active",
      config.colorActive
    );
    root?.style.setProperty(
      "--color-hover",
      config.colorHover
    );
    setReadyToRender(true);
  }, []);
   
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      {readyToRender && <div className="wrapper">
        <Header open={open} setOpen={setOpen} />
        <div className="wrapper__page">
          <Sidebar open={open} setOpen={setOpen} />
          <div className="page__body">
            <div className="body__content">{children}</div>
          </div>
        </div>
      </div>}
    </Fragment>
  );
};

export default App;
