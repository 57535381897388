import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SET_SINGLE_ARTICLE, 
  SEARCH_ARTICLES,
  ADD_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  ARTICLE_BY_ID,
  ARTICLE_INSERT_FILE,
  ARTICLE_DELETE_FILE
} from '../actionTypes';

const INITIAL_STATE = {
  article: null,
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {}
};

export default typeToReducer(
  {
    [SET_SINGLE_ARTICLE]: (state, action) => {
      return {
        ...state,
        article: action.payload,
      };
    },

    [SEARCH_ARTICLES]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { articles, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;

        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let articleNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(articles.length > 0){
          articles.forEach((article) => {
            article['no'] = ++articleNo;
            if(allIds.indexOf(article.id) === -1){
              allIds.push(article.id);
            }
            searchIds.push(article.id);
            byId[article.id] = article;     
          });
        }
        pageParams.hasNext = hasNext;
        pageParams.hasPrevious = hasPrevious;
        pageParams.pageSize = pageSize;
        pageParams.totalCount = totalCount;
        pageParams.totalPages = totalPages;
        pageParams.currentPage = currentPage;

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        };  
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,        
          isLoading: false,
        };
      },
    },
    [ADD_ARTICLE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let article = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        article['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: article};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_ARTICLE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { articleId, title, readable, content, commentable, rateable, medias, coverImage } = payload.params;
        let byId = state.byId;
        if(payload.data){         
          if(payload.data && byId[articleId]){
            byId[articleId].title = title;
            byId[articleId].readable = readable;
            byId[articleId].content = content;
            byId[articleId].rateable = commentable;
            byId[articleId].commentable = rateable;
            byId[articleId].medias = medias;
            byId[articleId].coverImage = coverImage;
          }
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_ARTICLE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { articleId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(articleId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[articleId]){
            delete byId[articleId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [ARTICLE_INSERT_FILE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const articleId = payload.id;
        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          if(byId[articleId]){
            if(byId[articleId].files){
              byId[articleId].files.push(payload.data);
            }
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [ARTICLE_DELETE_FILE]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { articleId, fileId } = payload.params;
        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          if(byId[articleId]){
            if(byId[articleId].files.length > 0){
              let files = byId[articleId].files;
              const index = files.findIndex(file => file.id === fileId);
              if(index !== -1){
                (byId[articleId].files).splice(index, 1);
              }
            }
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [ARTICLE_BY_ID]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        let article = payload.data;
        let allIds = state.allIds;
        let byId = state.byId;
        if(payload.data && allIds.length > 0){         
          if(allIds.indexOf(article.id) === -1){
            allIds.push(article.id);
          }
          if(byId[article.id]){
            article['no'] = byId[article.id].no;           
          }else{
            article['no'] = allIds.length + 1;
          }
          byId[article.id] = article;
        }   
        return {
          ...state,
          allIds,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    }
  },
  INITIAL_STATE
);

