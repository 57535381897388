import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useTranslation } from 'react-i18next'
import './dropdown.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  displayInput: {
    height:"auto",
    opacity: "1",
    transition: "opacity 1.7s linear",
    marginBottom:"5px",
    marginTop: "5px"
  },
  noDisplay: {
    height: "0",
    overflow: "hidden",
    opacity: "0",
    transition: "opacity 1.7s linear"
  },
  addIconStyle:{
    width:'10px'
  }
}));

const SingleSelectDropdown = ({labelWidth, options, selected, setSelected, label, disabled}) => {
  const { t } = useTranslation()
  const classes = useStyles();
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return(
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        value={selected}
        onChange={handleChange}
        className="dropdown-select"
        disabled={disabled}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="age"
            id="outlined-age-simple"
          />
        }
      >
        {!options.length && <div className="dropdown__nothing-found">{t('nothing_found')}</div>}
        {options.length > 0 && 
          options.map((op) => (
            <MenuItem key={op.value} value={op}>
              {op.label}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default SingleSelectDropdown;
