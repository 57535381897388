import { ReactComponent as Dashboard } from "../../assets/images/layout/dashboard.svg";
import { ReactComponent as DashboardColor } from "../../assets/images/layout/dashboardColor.svg";
import { ReactComponent as Knowledge } from "../../assets/images/layout/knowledge.svg";
import { ReactComponent as KnowledgeColor } from "../../assets/images/layout/knowledgeColor.svg";
import { ReactComponent as Organization } from "../../assets/images/layout/organization.svg";
import { ReactComponent as OrganizationColor } from "../../assets/images/layout/organizationColor.svg";
import { ReactComponent as Categories } from "../../assets/images/layout/categories.svg";
import { ReactComponent as CategoriesColor } from "../../assets/images/layout/categoriesColor.svg";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// import AutoStoriesIcon from '@material-ui/icons/AutoStories';
import GroupIcon from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
// import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ApartmentIcon from "@material-ui/icons/Apartment";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CreateIcon from "@material-ui/icons/Create";
// import CategoryIcon from '@material-ui/icons/Category';
import FavoriteIcon from "@material-ui/icons/Favorite";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import GroupWork from "@material-ui/icons/GroupWork";
import LocationOn from "@material-ui/icons/LocationOn";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import DeveloperBoard from "@material-ui/icons/DeveloperBoard";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import config from "../../config/api.config.js";
import MovieIcon from "@material-ui/icons/PlayArrow";

const libraryIcon = () => {
  return <LocalLibraryIcon />;
};
const libraryIconColor = () => {
  return <LocalLibraryIcon style={{ fill: config.colorPrimary }} />;
};
const DeveloperBoardColor = () => {
  return <DeveloperBoard style={{ fill: config.colorPrimary }} />;
};
const AssignmentTurnedInIconColor = () => {
  return <AssignmentTurnedInIcon style={{ fill: config.colorPrimary }} />;
};

export const MENUITEMS = [
  {
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Dashboard,
        iconColor: DashboardColor,
        title: "Dashboard",
        type: "link",
      },
      {
        title: "Organization",
        icon: Organization,
        iconColor: OrganizationColor,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/locations`,
            type: "link",
            title: "Standorte",
            active: false,

            icon: <LocationOn style={{ fontSize: "1rem" }} />,
            iconColor: (
              <LocationOn
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },

          {
            path: `${process.env.PUBLIC_URL}/departments`,
            type: "link",
            title: "Abteilungen",
            active: false,

            icon: <ApartmentIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <ApartmentIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/teams`,
            type: "link",
            title: "Team",
            active: false,

            icon: <GroupWork style={{ fontSize: "1rem" }} />,
            iconColor: (
              <GroupWork
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/members`,
            type: "link",
            title: "Mitarbeitende",
            active: false,

            icon: <GroupIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <GroupIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/chart`,
            type: "link",
            title: "Org Chart",
            active: false,

            icon: <AccountTreeIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <AccountTreeIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
        ],
      },
      {
        icon: Knowledge,
        iconColor: KnowledgeColor,
        active: false,
        title: "Wissen",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/articles`,
            type: "link",
            title: "Artikel",
            active: false,

            icon: <MenuBookIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <MenuBookIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/medias`,
            type: "link",
            title: "Medien",
            active: false,
            icon: <MovieIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <MovieIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/courses`,
          //   type: "link",
          //   title: "Kurse",
          //   active: false,

          //   icon: <SchoolIcon style={{ fontSize: "1rem" }} />,
          //   iconColor: (
          //     <SchoolIcon
          //       style={{ fill: config.colorPrimary, fontSize: "1rem" }}
          //     />
          //   ),
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/quizzes`,
          //   type: "link",
          //   title: "Quiz",
          //   active: false,

          //   icon: <CreateIcon style={{ fontSize: "1rem" }} />,
          //   iconColor: (
          //     <CreateIcon
          //       style={{ fill: config.colorPrimary, fontSize: "1rem" }}
          //     />
          //   ),
          // },
        ],
      },
      {
        icon: DeveloperBoard,
        iconColor: DeveloperBoardColor,
        active: false,
        title: "Onboarding",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/my-tasks`,
            type: "link",
            title: "Meine Aufgaben",
            active: false,
            icon: <FavoriteIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <FavoriteIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/my-processes`,
            type: "link",
            title: "Meine Prozesse",
            active: false,
            icon: <FavoriteIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <FavoriteIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/my-approval`,
            type: "link",
            title: "Zum Genehmigen",
            active: false,
            icon: <CheckCircleIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <CheckCircleIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
        ],
      },
      {
        icon: AccountTreeIcon,
        iconColor: DeveloperBoardColor,
        active: false,
        title: "Prozesse",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/tasks`,
            type: "link",
            title: "Aufgaben",
            active: false,
            icon: <CreateIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <CreateIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/processes`,
            type: "link",
            title: "Prozesse",
            active: false,
            icon: <AccountTreeIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <AccountTreeIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
          {
            path: `${process.env.PUBLIC_URL}/master-processes`,
            type: "link",
            title: "Masterprozesse",
            active: false,
            icon: <AccountTreeIcon style={{ fontSize: "1rem" }} />,
            iconColor: (
              <AccountTreeIcon
                style={{ fill: config.colorPrimary, fontSize: "1rem" }}
              />
            ),
          },
        ],
      },

      // {
      //   path: `${process.env.PUBLIC_URL}/categories`,
      //   icon: Categories,
      //   iconColor: CategoriesColor,
      //   title: "Kategorien",
      //   type: "link",
      // },
      // {
      //   title: "Library",
      //   icon: libraryIcon,
      //   iconColor: libraryIconColor,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/favourites`,
      //       type: "link",
      //       title: "Favoriten",
      //       active: false,

      //       icon: <FavoriteIcon style={{ fontSize: "1rem" }} />,
      //       iconColor: (
      //         <FavoriteIcon
      //           style={{ fill: config.colorPrimary, fontSize: "1rem" }}
      //         />
      //       ),
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/watch_later`,
      //       type: "link",
      //       title: "read_watch_later",
      //       active: false,

      //       icon: <WatchLaterIcon style={{ fontSize: "1rem" }} />,
      //       iconColor: (
      //         <WatchLaterIcon
      //           style={{ fill: config.colorPrimary, fontSize: "1rem" }}
      //         />
      //       ),
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/last_done`,
      //       type: "link",
      //       title: "last_done",
      //       active: false,

      //       icon: <DoneAllIcon style={{ fontSize: "1rem" }} />,
      //       iconColor: (
      //         <DoneAllIcon
      //           style={{ fill: config.colorPrimary, fontSize: "1rem" }}
      //         />
      //       ),
      //     },
      //   ],
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/outlook`,
      //   icon: Categories,
      //   iconColor: CategoriesColor,
      //   title: 'Outlook',
      //   type: 'link',
      //   icon: <lightOutlook style={{ fontSize: '1rem' }} />,

      //   iconColor: <lightOutlook style={{ fill: var(--color-primary), fontSize: '1rem' }} />      },
    ],
  },
];
