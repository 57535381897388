import "babel-polyfill";
import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { Editor } from "@tinymce/tinymce-react";
import SnackbarError from "../../components/alert/SnackbarError";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import InputField from "../../components/inputField/inputField";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import FilePreview from "../../components/filePreview/filePreview";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import { useTranslation } from "react-i18next";
import {
  searchLocations,
  getDepartmentsByLoc,
} from "../../redux/location/action";
import {
  searchDepartments,
  getTeamsByDepartment,
} from "../../redux/department/action";
import { searchTeams } from "../../redux/team/action";
import {
  updateArticle,
  insertArticleFile,
  deleteArticleFile,
} from "../../redux/article/action";
import { searchMedias } from "../../redux/media/action";
import { useHistory } from "react-router";
import auth from "../../auth";
import config from "../../config/api.config.js";
import { getFileExtension, logError } from "../../helpers/helpers";

var content = '';

const ArticleModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { open, closeModal, articleId, showSuccessMsg, showErrorMsg } = props;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);
  const [typeError, setTypeError] = useState(null);
  const [sizeError, setSizeError] = useState(null);
  const [departmentName, setDepartmentName] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  /*const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );*/
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [readable, setReadable] = useState([]);
  const [readable2, setReadable2] = useState([]);
  const [selectedOptionsLocations, setSelectedOptionsLocations] = useState(
    null
  );
  const [selectedOptionsDepartments, setSelectedOptionsDepartments] = useState(
    null
  );
  const [selectedOptionsTeams, setSelectedOptionsTeams] = useState(null);
  const [commentable, setCommentable] = useState(false);
  const [rateable, setRateable] = useState(false);
  const handleCommentable = (e) => {
    setCommentable(e.target.checked);
  };
  const handleRateable = (e) => {
    setRateable(e.target.checked);
  };
  const [selectedOptionsCategories, setSelectedOptionsCategories] = useState(
    []
  );

  const [values, setValues] = useState({
    name: "",
  });
  const [selectedFile, setSelectedFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [tempSelectedFile, setTempSelectedFile] = useState([]);
  const [allUploadedFiles, setAllUploadedFiles] = useState([]);
  const [medias, setMedias] = useState([]);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [imageOptions, setImageOptions] = useState([]);
  const [articleMainImage, setArticleMainImage] = useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onFileChange = (event) => {
    let file = event.target.files[0];

    if (file.size > 20 * 1024 * 1024) {
      setSizeError("Please upload a file smaller than 20 MB");
      return false;
    }
    
    if (!config.allowedFileExtensions.includes(getFileExtension(file.name))) {
      setTypeError("File type not support");
      return false;
    }

    if (event.target.files.length <= 0) return;

    // eslint-disable-next-line eqeqeq
    if (!selectedFile.find((f) => f.name == event.target.files[0].name)) {
      let uploadedFiles = [...tempSelectedFile, event.target.files[0]];
      setSelectedFile([]);
      sortFilesByType(uploadedFiles).then((data) => {
        setTempSelectedFile(uploadedFiles);
        setSelectedFile(data);
        updateAllUploadedFiles(files, data);
      });
    }
    setSizeError(null);
    setTypeError(null);
  };

  const resetValues = () => {
    setValues({ ...values, name: "" });
    content = '';
    setSelectedLocations([]);
    setSelectedDepartments([]);
    setSelectedTeams([]);
    setSelectedFile([]);
    setTempSelectedFile([]);
    setFiles([]);
    setAllUploadedFiles([]);
    setSelectedOptionsLocations(null);
    setSelectedOptionsDepartments(null);
    setSelectedOptionsTeams(null);
    setReadable([]);
    setReadable2([]);
    setSelectedMedias([]);
    setMediaOptions(medias);
    setArticleMainImage([]);
  };

  const getDepartments = async (locid) => {
    if (
      locid &&
      (!props.departmentsByLoc ||
        Object.keys(props.departmentsByLoc)?.length === 0 ||
        !props.departmentsByLoc[locid] ||
        props.departmentsByLoc[locid].length === 0)
    ) {
      await dispatch(getDepartmentsByLoc({ LocationId: locid }));
    } else {
      setDepartmentDropdown(locid);
    }
  };

  useEffect(() => {
    if (selectedOptionsLocations?.value) {
      setDepartmentDropdown(selectedOptionsLocations.value);
    }
  }, [props.departmentsByLoc]);

  const setDepartmentDropdown = (locId) => {
    if (props.departmentsByLoc[locId]) {
      setDepartments(
        props.departmentsByLoc[locId].map((dep) => {
          return {
            value: dep.id,
            label: dep.name,
          };
        })
      );
    } else {
      setDepartments([]);
    }
  };

  const getTeams = async (depid) => {
    if (
      depid &&
      (!props.teamsByDepartment ||
        Object.keys(props.teamsByDepartment)?.length === 0 ||
        !props.teamsByDepartment[depid] ||
        props.teamsByDepartment[depid].length === 0)
    ) {
      await dispatch(getTeamsByDepartment({ DepartmentId: depid }));
    } else {
      setTeamsDropdown(depid);
    }
  };

  useEffect(() => {
    if (selectedOptionsDepartments?.value) {
      setTeamsDropdown(selectedOptionsDepartments.value);
    }
  }, [props.teamsByDepartment]);

  const setTeamsDropdown = (depId) => {
    if (depId && props.teamsByDepartment[depId]) {
      setTeams(
        props.teamsByDepartment[depId].map((team) => {
          return {
            value: team.id,
            label: team.name,
          };
        })
      );
    } else {
      setTeams([]);
    }
  };

  const getLocations = async () => {
    if (props.locationIds.length === 0) {
      dispatch(searchLocations({ GetAll: true }));
    }
  };

  const onEditorStateChange = (editorState) => {
	  content = editorRef.current.getContent();
  };

  const PreviewArticle = () => {
    const readbalesfinal = [];
    if (readable.length > 0) {
      readable.forEach((department, i) => {
        readbalesfinal.push({
          location: readable[i].locationId,
          department: readable[i].departmentId,
          team: readable[i].teamId,
        });
      });
    }

    let mdsArr = [];
    selectedMedias.map((item) => {
      mdsArr.push(item.id);
    });

    let reqData = {
      title: values.name,
      readable: readbalesfinal,
      content: editorRef.current.getContent(),
      commentable: commentable,
      rateable: rateable,
      medias: mdsArr,
      authorId: auth.getId(),
      coverImage: articleMainImage
    };

    resetValues();
    if (props.type && props.type === "preview-edit") {
      history.replace(`/article/preview`, {
        article: reqData,
        attachments: tempSelectedFile,
      });
      closeModal();
    } else {
      history.push("article/preview", {
        article: reqData,
        attachments: tempSelectedFile,
      });
    }
  };

  const EditArticle = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);
    const readbalesfinal = [];
    let filesToUpload = tempSelectedFile;
    if (readable.length > 0) {
      readable.forEach((department, i) => {
        readbalesfinal.push({
          location: readable[i].locationId,
          department: readable[i].departmentId,
          team: readable[i].teamId,
        });
      });
    }

    let mdsArr = [];
    selectedMedias.map((item) => {
      mdsArr.push(item.id);
    });

    let _coverImg = null;
    if (articleMainImage?.id) {
      _coverImg = articleMainImage;
    } else {
      //set newly selected picture as cover image
      if (articleMainImage) {
        filesToUpload = tempSelectedFile.filter(
          (file) => file !== articleMainImage
        );
        const coverImgData = new FormData();
        coverImgData.append("File", articleMainImage);
        await dispatch(
          insertArticleFile({
            articleId: articleId,
            formData: coverImgData,
          })
        )
          .then((res) => {
            _coverImg = res.value?.data;
          })
          .catch((err) => {
            setErrorMessage("Failed to upload cover image.");
            setOpenAlert(true);
          });
        //getArticleById();
      } else {
        _coverImg = null;
      }
    }

    dispatch(
      updateArticle({
        articleId: articleId,
        title: values.name,
        readable: readbalesfinal,
        content: editorRef.current.getContent(),
        commentable: commentable,
        rateable: rateable,
        medias: mdsArr,
        coverImage: _coverImg,
      })
    )
      .then((res) => {
        let _errMsg = "";
        if (filesToUpload.length > 0) {
          Promise.all([
            filesToUpload?.forEach(async (file) => {
              const formData = new FormData();
              formData.append("File", file);
              return await dispatch(
                insertArticleFile({
                  articleId: articleId,
                  formData: formData,
                })
              ).catch((err) => {
                _errMsg = "Fehler beim Hochladen der Anhänge.";
              });
            }),
          ])
            .then(() => {
              setButtonClicked(false);
              closeModal();
              resetValues();
              setTimeout(() => {
                if (_errMsg) {
                  showErrorMsg(_errMsg);
                } else {
                  showSuccessMsg("Artikel wurde erfolgreich bearbeitet.");
                  setOpenAlert(true);
                }
              }, 1500);
            })
            .catch((err) => {
              logError(err);
              setButtonClicked(false);
              resetValues();
            });
        } else {
          setButtonClicked(false);
          closeModal();
          resetValues();
          showSuccessMsg("Artikel wurde erfolgreich bearbeitet.");
          setOpenAlert(true);
        }
      })
      .catch(function (err) {
        logError(err);
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeiten des Artikels.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const getArticleById = async () => {
    if (props.articles[articleId]) {
      let articleDetails = props.articles[articleId];
      setFiles([]);
      if (articleDetails.files && articleDetails.files.length > 0) {
        sortFilesByType(articleDetails.files).then((data) => {
          setFiles(data);
          updateAllUploadedFiles(data, selectedFile);
        });
      }
      formatArticleEditData(articleDetails);
    }
  };

  useEffect(() => {
    let _existingImgs = files[0]?.Images ?? [];
    let _selectedImgs = selectedFile[0]?.Images ?? [];
    let _images = [..._existingImgs, ..._selectedImgs];

    const _options = [];
    if (_images.length > 0) {
      _images.forEach((img) => {
        let type = getFileExtension(img.name);
        if (config.allowedImageExtensions.includes(type)) {
          _options.push({
            value: img.id ?? `'new_image_'${img.name}`,
            label: img.originalName ?? img.name,
            data: img,
          });
        }
      });
      setImageOptions(_options);
    } else {
      setImageOptions(_options);
    }
  }, [files, selectedFile]);

  const formatArticleEditData = (articleDetails) => {
    setValues({ name: articleDetails.title });
    content = articleDetails.content;
    setSelectedOptionsDepartments(articleDetails.visibleDepartments);
    setSelectedOptionsTeams(articleDetails.visibleTeams);
    setSelectedOptionsLocations(articleDetails.visibleLocations);
    setCommentable(articleDetails.commentable);
    setRateable(articleDetails.rateable);
    setArticleMainImage(articleDetails.coverImage);

    if (articleDetails.readable.length > 0) {
      let temp1 = [];
      let temp2 = [];

      articleDetails.readable.forEach((item, i) => {
        let tempdata = {};
        let tempdata2 = {};
        tempdata.locationId = item.location;
        tempdata.departmentId = item.department;
        tempdata.teamId = item.team;
        tempdata2.locationId = item.location
          ? props.locations?.[item.location]?.name
          : "";
        tempdata2.departmentId = item.department
          ? props.departments?.[item.department]?.name
          : "";
        tempdata2.teamId = item.team ? props.teams?.[item.team]?.name : "";
        temp2.push(tempdata2);
        temp1.push(tempdata);
      });
      setReadable2([...readable2, ...temp2]);
      setReadable([...readable, ...temp1]);
    }

    let mediasFilteredArray = medias.filter(function (n) {
      for (var i = 0; i < articleDetails?.medias?.length; i++) {
        if (n.value === articleDetails.medias[i]) {
          return true;
        }
      }
      return false;
    });

    let mdsArr = [];
    mediasFilteredArray.map((item) => {
      mdsArr.push({ id: item.value, name: item.label });
    });
    setSelectedMedias(mdsArr);
  };

  const sortFilesByType = async (files) => {
    let sortedFiles = {};
    let images = [];
    let documents = [];
    let medias = [];
    files.forEach((file, index) => {
      let type = getFileExtension(file.name);
      if (config.allowedImageExtensions.includes(type)) {
        images.push(file);
      } else if (config.allowedDocumentExtensions.includes(type)) {
        documents.push(file);
      } else if (config.allowedVideoExtensions.includes(type)
        || config.allowedAudioExtensions.includes(type)) {
        medias.push(file);
      }
    });

    if (images.length > 0 || documents.length > 0 || medias.length > 0) {
      sortedFiles.Images = images;
      sortedFiles.Documents = documents;
      sortedFiles.Medias = medias;
      return Object.keys(sortedFiles).map((key) => {
        return { [key]: sortedFiles[key] };
      });
    } else {
      return [];
    }
  };

  const deleteFile = async (file) => {
    await dispatch(
      deleteArticleFile({
        articleId: articleId,
        fileId: file.id,
        name: file.name,
        url: file.url,
      })
    )
      .then((res) => {
        if (articleMainImage?.id && articleMainImage.id === file.id) {
          setArticleMainImage(null);
        }
        if (props.articles?.[articleId]) {
          let _files = props.articles?.[articleId].files;
          setFiles([]);
          if (_files && _files.length > 0) {
            sortFilesByType(_files).then((data) => {
              setFiles(data);
              updateAllUploadedFiles(data, selectedFile);
            });
          }
        }
      })
      .catch((err) => {
        logError(err);
        let errMsg = "Fehler bei Löschen der Anhänge.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const deleteFilePreview = (file) => {
    // eslint-disable-next-line eqeqeq
    const tempFiles = tempSelectedFile.filter((f) => f.name != file.name);
    if (articleMainImage === file) {
      setArticleMainImage(null);
    }

    sortFilesByType(tempFiles).then((data) => {
      setTempSelectedFile(tempFiles);
      setSelectedFile(data);
      updateAllUploadedFiles(files, data);
    });
  };

  const handleReadable = () => {
    let tempdata = {
      locationId: "",
      departmentId: "",
      teamId: "",
    };
    let tempdata2 = {
      locationId: "",
      departmentId: "",
      teamId: "",
    };

    tempdata.locationId = selectedOptionsLocations.value;
    tempdata.departmentId = selectedOptionsDepartments
      ? selectedOptionsDepartments.value
      : null;
    tempdata.teamId = selectedOptionsTeams ? selectedOptionsTeams.value : null;

    tempdata2.locationId = selectedOptionsLocations.label;
    tempdata2.departmentId = selectedOptionsDepartments
      ? selectedOptionsDepartments.label
      : "Null";
    tempdata2.teamId = selectedOptionsTeams
      ? selectedOptionsTeams.label
      : "Null";

    setReadable2([...readable2, tempdata2]);

    setReadable([...readable, tempdata]);

    setSelectedOptionsLocations(null);
    setSelectedOptionsDepartments(null);
    setSelectedOptionsTeams(null);
    getLocations();
    setDepartments([]);
    setTeams([]);
  };

  const handleDelete = (i) => {
    var array = [...readable];
    array.splice(i, 1);
    setReadable(array);

    var array2 = [...readable2];
    array2.splice(i, 1);
    setReadable2(array2);
  };

  useEffect(() => {
    if (props.currentUser) {
      if (props.locationIds.length === 0 && open) {
        dispatch(searchLocations({ GetAll: true }));
      }
      if (props.mediaIds.length === 0) {
        dispatch(searchMedias({ GetAll: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser, open]);

  useEffect(() => {
    if (props.locationIds.length > 0) {
      setLocations(
        props.locationIds.map((id) => {
          return {
            value: id,
            label: props.locations[id].name,
          };
        })
      );
    } else {
      setLocations([]);
    }
  }, [props.locationIds.length]);

  useEffect(async () => {
    if (open) {
      if (articleId) {
        if (props.locationIds.length === 0) {
          await dispatch(searchLocations({ GetAll: true }));
        }
        if (props.departmentIds.length === 0) {
          await dispatch(searchDepartments({ GetAll: true }));
        }
        if (props.teamIds.length === 0) {
          await dispatch(searchTeams({ GetAll: true }));
        }
        getArticleById();
      } else {
        if (
          props.type &&
          props.type === "preview-edit" &&
          Object.keys(props.previewData)?.length
        ) {
          let articleDetails = props.previewData.article;
          formatArticleEditData(articleDetails);
          setSelectedFile([]);
          sortFilesByType(props.previewData.attachments).then((data) => {
            setTempSelectedFile(props.previewData.attachments);
            setSelectedFile(data);
            updateAllUploadedFiles(files, data);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, open]);

  useEffect(() => {
    if (props.mediaIds.length > 0) {
      const _medias = [];
      props.mediaIds.forEach((id) => {
        _medias.push({
          value: id,
          label: props.medias[id].title,
          url: props.medias[id].name,
        });
      });
      setMedias(_medias);
      setMediaOptions(_medias);
    } else {
      setMediaOptions([]);
      setMedias([]);
    }
  }, [props.mediaIds.length, open]);

  useEffect(() => {
    if (selectedMedias.length > 0) {
      let _mediaOptons = medias;
      selectedMedias.forEach((selectedMedias) => {
        _mediaOptons = _mediaOptons?.filter((item) => {
          return item.value !== selectedMedias.id;
        });
      });
      setMediaOptions(_mediaOptons);
    } else {
      setMediaOptions(medias);
    }
  }, [selectedMedias]);

  const updateAllUploadedFiles = (existingFiles, newFiles) => {
    let temp = [];
    setAllUploadedFiles([]);
    if (existingFiles.length > 0 && newFiles.length > 0) {
      existingFiles.map(function (existingFile, i) {
        let type = Object.keys(existingFile)[0];
        if (type === "Images") {
          temp.push({
            Images: [...existingFiles[i]["Images"], ...newFiles[i]["Images"]],
          });
        } else if (type === "Documents") {
          temp.push({
            Documents: [
              ...existingFiles[i]["Documents"],
              ...newFiles[i]["Documents"],
            ],
          });
        } else if (type === "Medias") {
          temp.push({
            Medias: [...existingFiles[i]["Medias"], ...newFiles[i]["Medias"]],
          });
        }
      });
      setAllUploadedFiles(temp);
    }
  };

  const DeleteSelectedMedia = (id) => {
    const newArrr = selectedMedias.filter((item) => {
      return item.id !== id;
    });
    setSelectedMedias(newArrr);
  };

  const editorRef = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetValues();
        closeModal();
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members articles-modal"
    >
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      <div className="department-members__header article-modal__header">
        <Breadcrumbs
          navigations={[
            `Artikel`,
            articleId ? `${t("Artikel editieren")}` : `${t("Hinzufügen")}`,
          ]}
        />
        <div className="article-modal__buttons">
          <OutlinedButton
            style={{ height: "40px" }}
            onClick={() => {
              resetValues();
              closeModal();
            }}
          >
            {t("Abbrechen")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={
              values.name /*&&
              (editorRef.current != null && editorRef.current.getContent().trim().length>0)
                ? true
                : false*/
            }
            buttonClicked={buttonClicked}
            onClick={articleId ? EditArticle : PreviewArticle}
          >
            {articleId ? `${t("Speichern")}` : `${t("preview")}`}
          </AddButton>
        </div>
      </div>
      <div className="article-modal__content">
        <div className="article-modal__content-input">
          <InputField
            label={t("Titel")}
            value={values.name}
            onChange={handleChange("name")}
            buttonClicked={buttonClicked}
          />
        </div>

        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          id='tinymce_article'
          initialValue={content || '<p></p>'}
          onEditorChange={onEditorStateChange}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />

        <div className="article-modal__content-input">
          <label htmlFor="file-upload" className="custom-file-upload">
            <Upload />
            {t("Hier klicken um Datei hochzuladen")}
          </label>
          <input
            id="file-upload"
            className="article-modal__file-upload"
            type="file"
            onChange={onFileChange}
          />
        </div>

        <div className="article-modal__uploaded-files">
          {typeError ? <span style={{ color: "red" }}>{typeError}</span> : null}
          {sizeError ? <span style={{ color: "red" }}>{sizeError}</span> : null}
          <p>{t("Hinzugefügte Dateien")}</p>
          {allUploadedFiles.length > 0 && (
            <div>
              {allUploadedFiles?.map(
                (type, index) =>
                  type[Object.keys(type)[0]].length > 0 && (
                    <div key={index}>
                      <span className="righttop">{Object.keys(type)[0]}</span>
                      {type[Object.keys(type)[0]].map((file, indx) => (
                        <FilePreview
                          key={file.id ? file.id : file?.name}
                          name={file.id ? file.originalName : file?.name}
                          onClick={
                            file.id
                              ? () => deleteFile(file)
                              : () => deleteFilePreview(file)
                          }
                          customClass="modalFilePreview"
                          edit={file.id ? true : false}
                        />
                      ))}
                    </div>
                  )
              )}
            </div>
          )}
          {(selectedFile.length || (articleId && files.length !== 0)) &&
          allUploadedFiles.length === 0 ? (
            <div>
              {files?.map(
                (type, index) =>
                  type[Object.keys(type)[0]].length > 0 && (
                    <div key={index}>
                      <span className="righttop">{Object.keys(type)[0]}</span>
                      {type[Object.keys(type)[0]].map((file, indx) => (
                        <FilePreview
                          key={file.id}
                          name={file.originalName}
                          onClick={() => deleteFile(file)}
                          customClass="modalFilePreview"
                          edit={true}
                        />
                      ))}
                    </div>
                  )
              )}

              {selectedFile?.map(
                (type, index) =>
                  type[Object.keys(type)[0]].length > 0 && (
                    <div key={index}>
                      <span className="righttop">{Object.keys(type)[0]}</span>
                      {type[Object.keys(type)[0]].map((file, indx) => (
                        <FilePreview
                          key={file?.name}
                          name={file?.name}
                          customClass="modalFilePreview"
                          onClick={() => deleteFilePreview(file)}
                        />
                      ))}
                    </div>
                  )
              )}
            </div>
          ) : (
            allUploadedFiles.length === 0 && <span>{t("nothing_uploaded")}</span>
          )}
        </div>

        <div className="tit">Coverbild festlegen</div>
        <div className="article-modal__content-input">
          <Select
            className="sort-input positions__select-department"
            value={imageOptions?.filter(
              (option) =>
                option.value ===
                (articleMainImage?.id ??
                  `'new_image_'${articleMainImage?.name}`)
            )}
            placeholder="Coverbild festlegen"
            onChange={(e) => {
              setArticleMainImage(e?.data);
            }}
            options={imageOptions}
            isSearchable={true}
            isClearable={true}
            style={{ marginTop: "10px" }}
          />
        </div>

        <div className="tit">Medien hinzufügen</div>
        <div className="article-modal__content-input">
          <Select
            className="sort-input positions__select-department"
            value=""
            placeholder="Medien auswählen"
            onChange={(e) => {
              setSelectedMedias([
                ...selectedMedias,
                { id: e.value, name: e.label, url: e.url },
              ]);
            }}
            options={mediaOptions}
            isSearchable={true}
            style={{ marginTop: "10px" }}
          />
        </div>
        {selectedMedias.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "fit-content",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {selectedMedias.map((mediaa, index) => {
              return index !== 0 ? (
                <div
                  key={index}
                  style={{
                    padding: 5,
                    marginLeft: 10,
                    border: "1px solid #c9d2d2",
                    borderRadius: 5,
                  }}
                >
                  {mediaa.name}{" "}
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteSelectedMedia(mediaa.id)}
                    width={30}
                    height={30}
                  />
                </div>
              ) : (
                <div
                  key={index}
                  style={{
                    padding: 5,
                    border: "1px solid #c9d2d2",
                    borderRadius: 5,
                  }}
                >
                  {mediaa.name}{" "}
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteSelectedMedia(mediaa.id)}
                    width={30}
                    height={30}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <span
            style={{
              color: "#c9d2d2",
              fontSize: "1.1rem",
              marginTop: "1.3rem",
            }}
          ></span>
        )}

        <div
          className="tit"
          style={{ marginBottom: "15px", marginTop: "30px" }}
        >
          Wer darf diesen Artikel lesen?
        </div>

        <div className="article-modal__content-input">
          <Select
            className="sort-input positions__select-department lefttop"
            value={selectedOptionsLocations}
            placeholder={t("Standorte auswählen")}
            onChange={(e) => {
              setSelectedOptionsLocations(e);
              getDepartments(e.value);
              setTeams([]);
              setSelectedOptionsDepartments(null);
              setSelectedOptionsTeams(null);
            }}
            options={locations}
            isSearchable={true}
          />

          <Select
            className="sort-input positions__select-department righttop"
            value={selectedOptionsDepartments}
            placeholder={t("Abteilungen auswählen")}
            onChange={(e) => {
              setSelectedOptionsDepartments(e);
              getTeams(e.value);
              setSelectedOptionsTeams(null);
            }}
            options={departments}
            isSearchable={true}
          />
        </div>

        <div className="article-modal__content-input" style={{ width: "50%" }}>
          <Select
            className="sort-input positions__select-department lefttop"
            value={selectedOptionsTeams}
            placeholder={t("Teams auswählen")}
            onChange={(e) => {
              setSelectedOptionsTeams(e);
            }}
            options={teams}
            isSearchable={true}
          />
        </div>

        <div className="addquestionbutton">
          <AddButton
            className="departments__modal--add"
            onClick={handleReadable}
            valid={selectedOptionsLocations ? true : false}
          >
            {t("Regel hinzufügen")}
          </AddButton>
        </div>
        <br></br>

        <div className="tit">Hinzugefügte Regeln:</div>
        <br></br>
        {readable2.length > 0 ? (
          readable2.map((p, i) => (
            <div
              className="article-modal__content-input"
              style={{ paddingLeft: "15px" }}
              key={i}
            >
              <div>
                {i + 1}. Location: {p.locationId} / Department: {p.departmentId}{" "}
                / Team: {p.teamId} &nbsp;&nbsp;&nbsp;
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(i)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="article-modal__content-input">
            <div>Keine Regeln. Artikel ist für alle zugänglich.</div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  locationIds: state.location.allIds,
  locations: state.location.byId,
  departmentsByLoc: state.location.departments,
  teamsByDepartment: state.department.teams,
  articleIds: state.article.allIds,
  articles: state.article.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
  teamIds: state.team.allIds,
  teams: state.team.byId,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
});

export default connect(mapStateToProps, null)(ArticleModal);
