import React from 'react';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './modal.scss';

import { useTranslation } from 'react-i18next'

const CustomModal = ({ title, open, closeModal, children }) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="dialog"
    >
      <DialogTitle id="dialog-title">{t(title)}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
