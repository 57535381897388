import { 
  SEARCH_DEPARTMENTS,
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  TEAMS_BY_DEPARTMENT
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchDepartments = (params) => ({
  type: SEARCH_DEPARTMENTS,
  payload: generateAuthRequest('GET','api/Department', params)
});

export const addDepartment = (params) => ({
  type: ADD_DEPARTMENT,
  payload: generateAuthRequest('POST','api/Department', params)
});

export const updateDepartment = (params) => ({
  type: UPDATE_DEPARTMENT,
  //payload: generateAuthRequest('PUT',`api/Department/${params.departmentId}`, params)
  payload: generateAuthRequest('PUT',`api/Department/update/${params.departmentId}`, params)
}); 

export const deleteDepartment = (params) => ({
  type: DELETE_DEPARTMENT,
  //payload: generateAuthRequest('DELETE',`api/Department/${params.departmentId}`, params)
  payload: generateAuthRequest('DELETE',`api/Department/delete/${params.departmentId}`, params)
});

export const getTeamsByDepartment = (params) => ({
  type: TEAMS_BY_DEPARTMENT,
  payload: generateAuthRequest('GET','api/Team', params)
});
