import { SET_CURRENT_USER,
  SET_CHART_DATA,
  SEARCH_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  USER_CSV_UPLOAD,
  USER_BY_ID,
  ASSIGN_TASK_TO_USER,
  REASSIGN_TASK_TO_USER,
  UPLOAD_PROFILE_PIC,
  UPLOAD_MEMBER_PROFILE_PIC,
  FORGOT_PASSWORD
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";
import auth from "../../auth";

export const setCurrentUser = (params) => ({
  type: SET_CURRENT_USER,
  payload: generateAuthRequest('GET',`api/User/${auth.getId()}`, params)
});

export const setChartData = (params) => ({
  type: SET_CHART_DATA,
  payload: generateAuthRequest('GET',`api/User/chart/${params.id}`, params)
});

export const searchUsers = (params) => ({
  type: SEARCH_USERS,
  payload: generateAuthRequest('GET','api/User', params)
});

export const addUser = (params) => ({
  type: ADD_USER,
  payload: generateAuthRequest('POST','api/Account/register', params)
});

export const updateUser = (params) => ({
  type: UPDATE_USER,
  //payload: generateAuthRequest('PUT',`api/User/${params.userId}`, params)
  payload: generateAuthRequest('PUT',`api/User/update/${params.userId}`, params)
});

export const deleteUser = (params) => ({
  type: DELETE_USER,
  //payload: generateAuthRequest('DELETE',`api/User/${params.userId}`, params)
  payload: generateAuthRequest('DELETE',`api/User/delete/${params.userId}`, params)
});

export const uploadUserCsv = (params) => ({
  type: USER_CSV_UPLOAD,
  payload: generateAuthRequest('PUT','api/User/csv', params)
});

export const getUserById = (params) => ({
  type: USER_BY_ID,
  payload: generateAuthRequest('GET',`api/User/${params.id}`, params)
});

export const assignUserToProcessTask = (params) => ({
  type: ASSIGN_TASK_TO_USER,
  payload: generateAuthRequest('PUT',`api/User/add-task/${params.task.userId}`, params)
});

export const updateUserProcessTask = (params) => ({
  type: REASSIGN_TASK_TO_USER,
  payload: generateAuthRequest('PUT',`api/User/edit-task`, params)
});

export const uploadProfilePic = (params) => ({
  type: UPLOAD_PROFILE_PIC,
  payload: generateAuthRequest('PUT',`api/User/upload-file`, params.formData, params.userId)
});

export const uploadMemberProfilePic = (params) => ({
  type: UPLOAD_MEMBER_PROFILE_PIC,
  payload: generateAuthRequest('PUT',`api/User/upload-file/image/${params.userId}`, params.formData, params.userId)
});

export const forgotPassword = (params) => ({
  type: FORGOT_PASSWORD,
  payload: generateAuthRequest('POST','api/Account/forgot-password', params)
});


