import React from 'react';
import NoData from '../../assets/images/noData.png';

import './searchNothingFound.scss';

const SearchNothingFound = ({ children }) => {
  return (
    <div className="nothingFound">
      <p>{children}</p>
      <div>
        <img src={NoData} alt="no-data"/>
      </div>
    </div>
  );
};

export default SearchNothingFound;
