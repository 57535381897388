import React, { useState, useEffect } from 'react';

import FirstPage from './firstPage';
import SecondPage from './secondPage';
import ThirdPage from './thirdPage';

import '../../assets/css/pages/login.scss';
import '../../assets/css/pages/register.scss';
import config from '../../config/api.config.js';
import '../../assets/css/_scss-variables.scss';

const Register = () => {
  const [firstPageVisible, setFirstPageVisible] = useState(true);
  const [secondPageVisible, setSecondPageVisible] = useState(false);
  const [thirdPageVisible, setThirdPageVisible] = useState(false);

  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    locationId:"61cd8a46633a959038832bd4",
    departmentId:"61cd8a46633a959038832bd4",
    teamId:"61cd8a46633a959038832bd4"  
  });
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      config.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      config.colorSecondary
    );
    root?.style.setProperty(
      "--color-active",
      config.colorActive
    );
    root?.style.setProperty(
      "--color-hover",
      config.colorHover
    );
    setReadyToRender(true);
  }, []);

  return (
    <>
      {readyToRender && <div className="">
        <div className="">
          <div className="">
          
          </div>
        </div>
        <div className="">
          {firstPageVisible ? (
            <FirstPage
              firstPage={setFirstPageVisible}
              secondPage={setThirdPageVisible}
              values={values}
              setValues={setValues}
            />
          ) : secondPageVisible ? (
            <SecondPage
              firstPage={setFirstPageVisible}
              secondPage={setThirdPageVisible}
              thirdPage={setSecondPageVisible}
              values={values}
              setValues={setValues}
            />
          ) : thirdPageVisible ? (
            <ThirdPage email={values.email} />
          ) : (
            <FirstPage
              firstPage={setFirstPageVisible}
              secondPage={setSecondPageVisible}
              values={values}
              setValues={setValues}
            />
          )}
        </div>
      </div>}
    </>
  );
};

export default Register;
