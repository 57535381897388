import React, { useState, useEffect } from 'react';
/* import Button from '@material-ui/core/Button'; */
import { useTranslation } from 'react-i18next'

import './search.scss';

const Search = ({ getSearchValue, customClass }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('');
  const [searchTouched, setSearchTouched] = useState(false);
  useEffect(() =>{
    if(getSearchValue && searchTouched){
      const timer = setTimeout(() => {
        getSearchValue(search.trim());
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    } 
  },[search]);

  return (
    <div className={`search ${customClass ? customClass : ''}`}>
      <input
        type="text"
        placeholder={t('')}
        value={search}
        onChange={(e) => { 
          setSearch(e.target.value);
          setSearchTouched(true);
        }}
      />
      {/* <Button
        variant="outlined"
        className="search__button"
        onClick={() => {
          getSearchValue(search);
        }}
      >
        Search
      </Button> */}
    </div>
  );
};

export default Search;
