import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import CardSlider from '../../components/card/CardSlider'
import { makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArticleCard from '../../components/card/ArticleCard'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import moment from 'moment';
import '../../assets/css/pages/dashboard.scss'
import '../../assets/css/pages/knowledge.scss'
import config from '../../config/api.config';
import { logError } from "../../helpers/helpers";


const useStyles = makeStyles((theme) => ({
      yellow: {
            // color: '#ffab2c',
            fontSize: '5rem'
      },
      green: {
            // color: '#5FC682',
            fontSize: '5rem'
      },
      blue: {
            // color: '#30AAEF',
            fontSize: '5rem'
      },
      statType: {
            paddingLeft: '12px',
            fontSize: '2rem'
      },
      showAllArrow: {
            fontSize: '1.2rem',
            paddingBottom: "2px"
      }
}));

const WORDS_PER_MINUTE = 250;

const Knowledge = (props) => {
      const history = useHistory();
      const classes = useStyles()
      const [members, ] = useState(null)
      const [articles, setArticles] = useState(null);
      const [ setCourses] = useState(null);
      const [ setLoading] = useState(true);
      const { t } = useTranslation()


      const ReadTime = (text) => {
            const words = text.split(" ");
            const time = Math.ceil(words.length / WORDS_PER_MINUTE);
            return time;
      };

      const getMembers = async (params) => {
            setLoading(true);
            // await axios
            //       .get(`${config.apiURL}/api/User`, {
            //             params: {
            //                   WorkspaceId: props.currentUser.workspaceId,
            //                   GetAll: true,
            //                   ...params
            //             }
            //       })
            //       .then((res) => {
            //             setMembers(
            //                   res.data.users.map((mem, i) => {
            //                         return {
            //                               id: mem.userId,
            //                               name: mem.firstName + ' ' + mem.lastName,
            //                               image: mem.image ? mem.image.url : null,
            //                               email: mem.email
            //                         };
            //                   })
            //             );
            //             setLoading(false);
            //       })
            //       .catch((err) => {
            //             setLoading(false);
            //             logError(err);
            //       });
      };

      const getArticles = async (params) => {
            setLoading(true);
            await axios
                  .get(`${config.apiURL}/api/Article`, {
                        params: {
                              WorkspaceId: props.currentUser.workspaceId,
                              ...params
                        }
                  })
                  .then((res) => {
                        setArticles(
                              res.data.articles.map((dep, i) => {
                                    return {
                                          id: dep.id,
                                          name: dep.title,
                                          categories: dep.categories,
                                          authorId: dep.authorId,
                                          createdDate: moment(dep.dateCreated,).format(`${t('momentjs_datum')}`),
                                          lastModified: dep.dateModified,
                                          content: dep.content,
                                          readTime: ReadTime(dep.content)
                                    };
                              })
                        );
                        setLoading(false);
                  })
                  .catch((err) => {
                        setLoading(false);
                        logError(err);
                  });
      };

      const getCourses = async (params) => {
            setLoading(true);
            await axios
                  .get(`${config.apiURL}/api/Course`, {
                        params: {
                              WorkspaceId: props.currentUser.workspaceId,
                              ...params
                        }
                  })
                  .then((res) => {
                        setCourses(
                              res.data.courses.map((dep, i) => {
                                    return {
                                          id: dep.id,
                                          name: dep.title,
                                          authorId: dep.author.authorId,
                                          authorImg: dep.author.imageUrl,
                                          authorName: dep.author.firstName + ' ' + dep.author.lastName,
                                          date: moment(dep.dateCreated).format('MMMM DD, YYYY'),
                                          description: dep.description
                                    };
                              })
                        );

                        setLoading(false);
                  })
                  .catch((err) => {
                        setLoading(false);
                        logError(err);
                  });
      };


      useEffect(() => {
            const abortController = new AbortController();

            if (!props.currentUser) return;
            getMembers();
            getArticles();
            getCourses();

            return () => {
                  abortController.abort();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.currentUser]);


      return (

            
 <div>
                  <div className="dashboard-links-wrapper">
                        <ul className="dashboard-links-list">
                              <li onClick={(event) => history.push(`/dashboard`)} className='dashboard-link'>{t('news')}</li>
                              <li onClick={(event) => history.push(`/events`)} className='dashboard-link'>{t('events')}</li>
                              <li onClick={(event) => history.push(`/knowledge`)} className='active-link dashboard-link'>{t('Knowledge')}</li>
                        </ul>
                  </div>
                  <div className='knowledge-slider-wrapper'>
                        <CardSlider />
                        <div className='right-side-component'>
                              <div>
                                    <div className='knowledge-favourites-heading-wrapper'>
                                          <Breadcrumbs navigations={['favourites']} />
                                          <div className="all-articles-wrapper">
                                                <span className="all-articles-link" onClick={(event) => history.push(`/favourites`)}>{t('show_all')} <ArrowForwardIcon className={classes.showAllArrow} /></span>
                                          </div>
                                    </div>
                                    <div className="knowledge-favourites-wrapper">
                                          {articles === null || articles.length === 0 ? (
                                                <div>
                                                      {t('nothing_found_article')}
                                                </div>
                                          ) : (articles.sort((a, b) => {
                                                let datea = moment(a.createdDate, 'x')
                                                let dateb = moment(b.createdDate, 'x')
                                                return dateb - datea
                                          }).slice(0, 4).map((article, i) => {
                                                return (
                                                      <ArticleCard
                                                            key={i}
                                                            articleKlein='article-card-klein'
                                                            id={article.id}
                                                            name={article.name}
                                                            authorName={members && members.filter(mem => mem.id === article.authorId)[0].name}
                                                            authorId={article.authorId}
                                                            date={article.createdDate}
                                                            categories={article.categories}
                                                            content={article.content}
                                                            readTime={article.readTime}
                                                      />
                                                )
                                          }))}
                                    </div>
                              </div>
                              <div>
                                    <div className='knowledge-later-heading-wrapper'>
                                          <Breadcrumbs navigations={['read_watch_later']} />
                                          <div className="all-articles-wrapper">
                                                <span className="all-articles-link" onClick={(event) => history.push(`/watch_later`)}>{t('show_all')} <ArrowForwardIcon className={classes.showAllArrow} /></span>
                                          </div>
                                    </div>
                                    <div className="knowledge-favourites-wrapper">
                                          {articles === null || articles.length === 0 ? (
                                                <div>
                                                      {t('nothing_found_article')}
                                                </div>
                                          ) : (articles.sort((a, b) => {
                                                let datea = moment(a.createdDate, 'x')
                                                let dateb = moment(b.createdDate, 'x')
                                                return dateb - datea
                                          }).slice(0, 4).map((article, i) => {
                                                return (
                                                      <ArticleCard
                                                            key={i}
                                                            articleKlein='article-card-klein'
                                                            id={article.id}
                                                            name={article.name}
                                                            authorName={members && members.filter(mem => mem.id === article.authorId)[0].name}
                                                            authorId={article.authorId}
                                                            date={article.createdDate}
                                                            categories={article.categories}
                                                            content={article.content}
                                                            readTime={article.readTime}
                                                      />
                                                )
                                          }))}
                                    </div>
                              </div>
                        </div>                 
                  </div>
            </div>
           
           
      )
};

const mapStateToProps = (state) => ({
      currentUser: state.user.currentUser
});

export default connect(mapStateToProps, null)(Knowledge);