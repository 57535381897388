import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InputField from '../../components/inputField/inputField';
import Dropdown from '../../components/dropdown/dropdown';
import SnackbarError from '../../components/alert/SnackbarError';

import { ReactComponent as Upload } from '../../assets/images/upload.svg';

import config from '../../config/api.config.js';
import { logError } from "../../helpers/helpers";
import '../../assets/css/pages/courses.scss';

import { useTranslation } from 'react-i18next'
import auth from '../../auth';

const FirstStep = (props) => {
  const { t } = useTranslation()
  const {
    currentUser,
    values,
    setValues,
    setSelectedDepartments,
    setSelectedCategories,
    // selectedDepartments,
    // selectedCategories,
    selectedFile,
    setSelectedFile,
    selectedOptionsCategories,
    selectedOptionsDepartments
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [buttonClicked, setButtonClicked] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [departmentName, setDepartmentName] = useState('');
  const [categoryName, setCategoryName] = useState('');

  const AddDepartment = async () => {
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Department`, {
        name: departmentName,
        workspaceId: props.currentUser.workspaceId
      })
      .then((res) => {
        setButtonClicked(false);
        setDepartmentName('');
        getDepartments();
      })
      .catch((err) => {
        setButtonClicked(false);
        setDepartmentName('');
        setErrorMessage(err.response.data.errors.Name[0]);
        setOpenAlert(true);
      });
  };

  const AddCategory = async () => {
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Category`, {
        name: categoryName,
        workspaceId: props.currentUser.workspaceId
      })
      .then((res) => {
        setButtonClicked(false);
        setCategoryName('');
        getCategories();
      })
      .catch((err) => {
        setButtonClicked(false);
        setCategoryName('');
      });
  };

  const getDepartments = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${auth.accessToken}`
    };
    await axios
      .get(`${config.apiURL}/api/Department`, {
        params: {
          WorkspaceId: currentUser.workspaceId,
          GetAll: true
        },
        headers: headers
      })
      .then((res) => {
        setDepartments(
          res.data.departments.map((dep) => {
            return {
              value: dep.id,
              label: dep.name
            };
          })
        );
      })
      .catch((err) => logError(err));
  };
  const getCategories = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${auth.accessToken}`
    };
    await axios
      .get(`${config.apiURL}/api/Category`, {
        params: {
          WorkspaceId: currentUser.workspaceId,
          GetAll: true
        },
        headers: headers
      })
      .then((res) => {
        setCategories(
          res.data.categories.map((dep) => {
            return {
              value: dep.id,
              label: dep.name
            };
          })
        );
      })
      .catch((err) => logError(err));
  };

  const onFileChange = (event) => {
    setOpenAlert(false);
    if (
      event.target.files.length <= 0 ||
      selectedFile?.name === event.target.files[0].name
    )
      return;

    if (!event.target.files[0].type.includes('image')) {
      setErrorMessage(`${ t('course_image_error_message') }`);
      setOpenAlert(true);
      return;
    }

    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (currentUser) {
      getDepartments();
      getCategories();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="add-course__first-step">
      <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
        {errorMessage}
      </SnackbarError>
      <div className="first-step--left">
        <InputField
          label={t('course_name')}
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          buttonClicked={buttonClicked}
        />
        <InputField
          label={t('description')}
          value={values.description}
          onChange={(e) =>
            setValues({ ...values, description: e.target.value })
          }
          buttonClicked={buttonClicked}
        />
        <Dropdown
          labelWidth={115}
          options={categories}
          selected={selectedOptionsCategories}
          setSelected={setSelectedCategories}
          label={t('course_category')}
          addCategory={AddCategory}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
        />
        <Dropdown
          labelWidth={215}
          options={departments}
          selected={selectedOptionsDepartments}
          setSelected={setSelectedDepartments}
          label={t('article_canread_departments')}
          addDepartment={AddDepartment}
          departmentName={departmentName}
          setDepartmentName={setDepartmentName}
        />
      </div>
      <div className="first-step--right">
        <div>
          {!selectedFile ? (
            <label
              htmlFor="file-upload"
              className="custom-file-upload course-add__image--upload"
            >
              <Upload />
            </label>
          ) : (
            <img
              style={{ width: '16rem', height: '14rem', objectFit: 'cover' }}
              src={selectedFile.url ?? URL.createObjectURL(selectedFile)}
              alt='photos' // change
            />
          )}
          <label className="upload-file__label-text" htmlFor="file-upload">
            {t('image_upload_course')}
          </label>
          <input
            id="file-upload"
            className="article-modal__file-upload"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
