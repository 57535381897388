import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Pagination from "../../components/pagination/pagination";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import DepartmentMembers from "../Departments/departmentMembers";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import config from "../../config/api.config.js";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import "../../assets/css/pages/positions.scss";
import App from "../../App";
import { useTranslation } from "react-i18next";
import {
  searchDepartments,
  addDepartment,
  updateDepartment,
  deleteDepartment,
} from "../../redux/department/action";
import {
  searchLocations,
  getDepartmentsByLoc,
} from "../../redux/location/action";
import { searchUsers } from "../../redux/users/action";
import MembersSelect from "../../components/dropdown/membersSelect";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";

const Departments = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState(null);
  const [locationsForAdd, setLocationsForAdd] = useState(null);
  const [selectedSort, setSelectedSort] = useState(1);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [openDepartmentMember, setOpenDepartmentMember] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departmentMembers, setDepartmentMembers] = useState(null);
  const [departmentName, setDepartmentName] = useState("");
  const [departmentEditId, setDepartmentEditId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [defaultPage, setDefaultPage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedHead, setSelectedHead] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const AddDepartment = async () => {
    if (!isAdmin) return;

    await setButtonClicked(true);
    let reqParams = {
      name: departmentName,
      locationId: selectedLocation.add.value,
    };
    if (reqParams.headId) {
      reqParams.headId = selectedHead;
    }
    await dispatch(addDepartment(reqParams))
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setDepartmentName("");
        setSelectedLocation({
          ...selectedLocation,
          add: null,
        });
        updateDepartmentsByLoc(locationId);
        setSelectedHead("");
        setSuccessMessage("Abteilung erfolgreich hinzugefügt.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Hinzufügen von Abteilung.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditDepartmentModal = (id, name, locationId, headId) => {
    if (!isAdmin) return;

    setLocationId(locationId);
    setDepartmentEditId(id);
    setDepartmentName(name);
    setOpenModal(true);
    setSelectedHead(headId);
  };

  const EditDepartment = async () => {
    let reqData = {
      departmentId: departmentEditId,
      name: departmentName,
      headId: selectedHead,
      locationId: locationId,
    };
    await setButtonClicked(true);
    dispatch(updateDepartment(reqData))
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setDepartmentEditId(null);
        setDepartmentName("");
        setSelectedHead("");
        updateDepartmentsByLoc(locationId);
        setSuccessMessage("Abteilung erfolgreich bearbeitet.");
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setButtonClicked(false);
        let errMsg = "Fehler beim Bearbeiten der Abteilung.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const DeleteDepartmentModal = (id, locationId) => {
    if (!isAdmin) return;

    setLocationId(locationId);
    setDepartmentEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteDepartment = async () => {
    await setButtonClicked(true);

    dispatch(
      deleteDepartment({
        departmentId: departmentEditId,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setDepartmentEditId(null);
        dispatch(
          searchDepartments({
            Sort: selectedSort,
            PageNumber: 1,
            Name: search.trim(),
            LocationId: null,
          })
        );
        setSuccessMessage("Abteilung wurde erfolgreich gelöscht.");
        setOpenAlert(true);
        updateDepartmentsByLoc(locationId);
      })
      .catch(function (err) {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setDepartmentEditId(null);
        let errMsg = "Fehler bei Löschen der Abteilung.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchDepartments({
        PageNumber: newPage,
        Name: search,
        Sort: selectedSort,
      })
    );
  };

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchDepartments({
          PageNumber: 1,
          Name: search.trim(),
          DepartmentId: selectedLocation.search.value,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchDepartments({
        Sort: e.value,
      })
    );
  };

  const getDeparmentsByLocation = (e) => {
    setSelectedLocation({ add: selectedLocation, search: e });
    dispatch(searchDepartments({ LocationId: e.value }));
    setDefaultPage(!defaultPage);
  };

  const handlePositionMember = async (
    departmentId,
    name,
    locationId,
    search = null,
    pageNumber = 1
  ) => {};

  useEffect(
    () => {
      const abortController = new AbortController();
      if (!props.currentUser) return;

      if (props.currentUser.role === "Admin") {
        setIsAdmin(true);
      }

      if (props.allDepartmentIds.length === 0) {
        dispatch(searchDepartments({ GetAll: true }));
      }

      if (props.allLocationIds.length === 0) {
        dispatch(searchLocations({ GetAll: true }));
      }

      dispatch(
        searchDepartments({
          Sort: 1,
          PageNumber: 1,
          LocationId: null,
        })
      );

      if (props.memberIds.length === 0) {
        dispatch(searchUsers({ GetAll: true }));
      }

      return () => {
        abortController.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentUser]
  );

  useEffect(() => {
    if (props.allLocationIds.length > 0) {
      const _loc = new Array({ value: null, label: "All" });
      props.allLocationIds.forEach((id) => {
        _loc.push({
          value: id,
          label: props.allLocations[id].name,
        });
      });
      setLocations(_loc);
      setLocationsForAdd(
        props.allLocationIds.map((id) => {
          return {
            value: id,
            label: props.allLocations[id].name,
          };
        })
      );
    } else {
      setLocations(null);
      setLocationsForAdd(null);
    }
  }, [props.allLocationIds, props.departmentIds]);

  useEffect((_) => {
    setSearch("");
  }, []);

  const updateDepartmentsByLoc = (locId) => {
    if (
      Object.keys(props.departmentsByLoc).length > 0 &&
      props.departmentsByLoc[locId]
    ) {
      dispatch(getDepartmentsByLoc({ LocationId: locId }));
    }
  };

  const headChangeHandler = (selectedVal) => {
    setSelectedHead(selectedVal);
  };

  const getHeadName = (id) => {
    let name = "";
    if (id && props.members[id]) {
      name = props.members[id].firstName + " " + props.members[id].lastName;
    }
    return name;
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Abteilungen"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className={`departments__add-new--button ${
              isAdmin && locations?.length > 0 ? "" : "disabled"
            }`}
            onClick={() => setOpenModal(true)}
            disabled={isAdmin && locations?.length > 0 ? false : true}
          >
            <AddIcon /> {t("Neu")}
          </Button>

          <Modal
            title={departmentEditId ? "Abteilung bearbeiten" : "Neue Abteilung"}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setDepartmentName("");
              setSelectedLocation({ ...selectedLocation, add: null });
              setDepartmentEditId(null);
              setSelectedHead("");
            }}
          >
            {!departmentEditId ? (
              <Select
                className="sort-input positions__select-department"
                value={selectedLocation.add}
                placeholder="Wähle Standort"
                onChange={(e) => {
                  setSelectedLocation({ add: e });
                }}
                options={locationsForAdd}
                isSearchable={true}
              />
            ) : null}
            <input
              type="text"
              placeholder="Abteilungsname"
              value={departmentName}
              style={{ marginBottom: "1.5rem" }}
              onChange={({ target }) => setDepartmentName(target.value)}
            />
            <MembersSelect
              className="sort-input positions__select-department"
              placeholder={"Abteilungsverantwortlich"}
              selectedOption={selectedHead}
              onChange={headChangeHandler}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setDepartmentName("");
                  setDepartmentEditId(null);
                  setSelectedHead("");
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={
                  (departmentName && selectedLocation.add) ||
                  (departmentName && departmentEditId)
                    ? true
                    : false
                }
                buttonClicked={buttonClicked}
                onClick={departmentEditId ? EditDepartment : AddDepartment}
              >
                {departmentEditId ? "Bearbeiten" : "Hinzufügen"}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("Abteilung löschen?")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteDepartment}
              >
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <DepartmentMembers
            department={"Positions"}
            departmentName={selectedDepartment}
            locationId={locationId}
            departmentId={departmentEditId}
            departmentMembers={departmentMembers}
            getSerachedMembers={handlePositionMember}
            open={openDepartmentMember}
            closeModal={() => setOpenDepartmentMember(false)}
          />
        </div>
        <div className="sort department__dropdown">
          <label style={{ marginRight: "2.85rem" }}>{t("Location")}: </label>
          <Select
            className="sort-input"
            value={selectedLocation.search}
            onChange={(e) => {
              getDeparmentsByLocation(e);
            }}
            options={locations}
            isSearchable={true}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Nr.")}.</TableCell>
              <TableCell align="left">{t("Abteilungsname")}</TableCell>
              <TableCell align="left">{t("Standort")}</TableCell>
              <TableCell align="left">{t("Verantwortlich")}</TableCell>
              {isAdmin && <TableCell>{t("Datenbank ID")}</TableCell>}
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !props.isLoading &&
              (!props.departmentIds.length || !locations?.length) ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <NothingFound>{t("nothing_found_department")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  props.departmentIds.length > 0 &&
                  props.departmentIds?.map((id) => {
                    return (
                      props.departments[id] && (
                        <TableRow key={id} className="departments__table-row">
                          <TableCell component="th" scope="row">
                            {props.departments[id].no + "."}
                          </TableCell>
                          <TableCell align="left">
                            {props.departments[id].name}
                          </TableCell>
                          <TableCell align="left">
                            {
                              locations?.find(
                                (p) =>
                                  p.value === props.departments[id].locationId
                              )?.label
                            }
                          </TableCell>
                          <TableCell align="left">
                            {getHeadName(props.departments[id].headId)}
                          </TableCell>
                          {isAdmin && (
                            <TableCell component="th" scope="row">
                              {id}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() =>
                                  EditDepartmentModal(
                                    id,
                                    props.departments[id].name,
                                    props.departments[id].locationId,
                                    props.departments[id].headId
                                  )
                                }
                              />
                              <DeleteIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() =>
                                  DeleteDepartmentModal(
                                    id,
                                    props.departments[id].locationId
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
        {!props.departmentIds?.length && search ? null : (
          <>
            {Object.keys(props.pageParams).length > 0 && (
              <Pagination
                params={props.pageParams}
                getPageNumber={getPageNumber}
                defaultPage={defaultPage}
              />
            )}
          </>
        )}
      </TableContainer>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.department.isLoading,
  allDepartmentIds: state.department.allIds,
  departments: state.department.byId,
  departmentIds: state.department.searchIds,
  pageParams: state.department.pageParams,
  allLocationIds: state.location.allIds,
  allLocations: state.location.byId,
  departmentsByLoc: state.location.departments,
  memberIds: state.user.allIds,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(Departments);
