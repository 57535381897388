import { 
  SEARCH_MEDIAS, 
  ADD_MEDIA, 
  DELETE_MEDIA, 
  UPDATE_MEDIA 
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchMedias = (params) => ({
  type: SEARCH_MEDIAS,
  payload: generateAuthRequest('GET','api/Media', params)
});

export const addMedia = (params) => ({
  type: ADD_MEDIA,
  payload: generateAuthRequest('POST','api/Media/insert-media', params)
});

export const deleteMedia = (params) => ({
  type: DELETE_MEDIA,
  payload: generateAuthRequest('DELETE',`api/Media/delete-media/${params.name}`, {"videoId": params.mediaId})
});

export const updateMedia = (params) => ({
  type: UPDATE_MEDIA,
  payload: generateAuthRequest('PUT',`api/Media/update/${params.name}`, params)
});
  
