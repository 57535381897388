import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const SnackbarSuccess = ({ open, onClose, children }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert 
        onClose={onClose} 
        severity="success" 
      >
        {children}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarSuccess;
