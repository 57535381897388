import React from 'react'
import './newButton.scss';

const NewButton=({name,onClick,otherProps,buttonClicked,valid})=>{
    return(
        <div>
            <button className='btn-styles' onClick={onClick}>
                {name}
                {valid}
                {buttonClicked}
            </button>
        </div>
       
    )
}
export default NewButton;