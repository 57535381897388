import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/login.scss";
import NewInput from "../../components/inputField/NewInput";
import NewLabel from "../../components/label/NewLabel";
import NewButton from "../../components/buttons/NewButton";
import NewError from "../../components/alert/NewError";
import { loginUserAction } from "../../redux/login/actions";
import { useLocation } from "react-router-dom";
import config from "../../config/api.config.js";
import "../../assets/css/_scss-variables.scss";
import Logo from "../../layout/logo/logo.js";

const Login = (props) => {
  const [error, setError] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [loginState, setLoginState] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [readyToRender, setReadyToRender] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClick = async () => {
    await setButtonClicked(true);
    setError(false);
    await props.makeLogin(setLoginState, values);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      if(values.email !== "" && values.password != "" ){
        handleClick();
      }     
    }
  };

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--color-primary", config.colorPrimary);
    root?.style.setProperty("--color-secondary", config.colorSecondary);
    root?.style.setProperty("--color-active", config.colorActive);
    root?.style.setProperty("--color-hover", config.colorHover);
    setReadyToRender(true);
  }, []);

  return (
    <>
      {readyToRender && (
        <div className="login2">
          <div className="headerp">
            <div>
              <Logo />
            </div>
          </div>
          <div className="main2">
            <h2 className="welcomemsg">
              {t('Willkommen zu '+config.company+' Onboarder')}
            </h2>
            <form>
              <div>
                <NewLabel name={"Email"} />
                <NewInput
                  className="inputform1"
                  type="text"
                  value={values.email}
                  onChange={handleChange("email")}
                  onKeyUp={handleKeyUp}
                />
              </div>
              <div>
                <NewLabel name={"Password"} />
                <NewInput
                  className="inputform1"
                  type="password"
                  valid={true}
                  value={values.password}
                  onChange={handleChange("password")}
                  onKeyUp={handleKeyUp}
                />
              </div>
            </form>
            <div className="f-passwordLabel f-linkLabel">
              <Link to="/forgot-password">Passwort vergessen ? </Link>
            </div>
            <div>
              <NewButton name={"Login"} onClick={handleClick} valid={true} buttonClicked={buttonClicked} />
            </div>
            {/* <div className="f-linkLabel">
              <Link to="/register">Don't you have an accont? Sign Up </Link>
            </div> */}
            <div>{loginState === false ? <NewError /> : <h6 />}</div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ logininfo: state.doLogin.logininfo });

const mapDispatchToProps = (dispatch) => {
  return {
    makeLogin: (loginStateSetter, values) =>
      dispatch(loginUserAction(loginStateSetter, values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// const mapStateToProps = (state) => ({
//   currentUser: state.user.currentUser
// });

// export default connect(mapStateToProps, null)(Login);
