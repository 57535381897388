import "babel-polyfill";
import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SnackbarError from "../../components/alert/SnackbarError";
import QuestionBox from "./QuestionBox";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import InputField from "../../components/inputField/inputField";
import Dropdown from "../../components/dropdown/dropdown";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import FilePreview from "../../components/filePreview/filePreview";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import auth from "../../auth";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";

const QuizModal = (props) => {
  const { t } = useTranslation();
  const { open, closeModal, currentUser, getArticles, articleId } = props;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [addQBOXClicked, setaddQBOXClicked] = useState([]);

  function addComponent() {
    const newId = nanoid();
    setaddQBOXClicked([...addQBOXClicked, newId]);
  }

  const [departments, setDepartments] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [selectedOptionsDepartments, setSelectedOptionsDepartments] = useState(
    []
  );
  const [selectedOptionsCategories, setSelectedOptionsCategories] = useState(
    []
  );

  const [values, setValues] = useState({
    name: "",
  });
  const [selectedFile, setSelectedFile] = useState([]);
  const [files, setFiles] = useState([]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const onFileChange = (event) => {
    if (event.target.files.length <= 0) return;

    // eslint-disable-next-line eqeqeq
    if (!selectedFile.find((f) => f.name == event.target.files[0].name))
      setSelectedFile([...selectedFile, event.target.files[0]]);
  };

  const resetValues = () => {
    setValues({ ...values, name: "" });
    setSelectedCategories([]);
    setSelectedDepartments([]);
    setSelectedFile([]);
    setFiles([]);
    setSelectedOptionsCategories([]);
    setSelectedOptionsDepartments([]);
    setDepartmentName("");
    setCategoryName("");
  };

  const AddDepartment = async () => {
    if (categoryName) {
      await setButtonClicked(true);
      await axios
        .post(`${config.apiURL}/api/Department`, {
          name: departmentName,
          workspaceId: props.currentUser.workspaceId,
        })
        .then((res) => {
          setButtonClicked(false);
          setDepartmentName("");
          getDepartments();
        })
        .catch((err) => {
          setButtonClicked(false);
          setDepartmentName("");
          setErrorMessage(err.response.data.errors.Name[0]);
          setOpenAlert(true);
        });
    }
  };

  const AddCategory = async () => {
    if (categoryName) {
      await setButtonClicked(true);
      await axios
        .post(`${config.apiURL}/api/Category`, {
          name: categoryName,
          workspaceId: props.currentUser.workspaceId,
        })
        .then((res) => {
          setButtonClicked(false);
          setCategoryName("");
          getCategories();
        })
        .catch((err) => {
          setButtonClicked(false);
          setCategoryName("");
        });
    }
  };

  const getDepartments = async () => {
    await axios
      .get(`${config.apiURL}/api/Department`, {
        params: {
          WorkspaceId: currentUser.workspaceId,
          GetAll: true,
        },
      })
      .then((res) => {
        setDepartments(
          res.data.departments.map((dep) => {
            return {
              value: dep.id,
              label: dep.name,
            };
          })
        );
      })
      .catch((err) => logError(err));
  };
  const getCategories = async () => {
    await axios
      .get(`${config.apiURL}/api/Category`, {
        params: {
          WorkspaceId: currentUser.workspaceId,
          GetAll: true,
        },
      })
      .then((res) => {
        setCategories(
          res.data.categories.map((dep) => {
            return {
              value: dep.id,
              label: dep.name,
            };
          })
        );
      })
      .catch((err) => logError(err));
  };

  const AddArticle = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Quiz`, {
        title: values.name,
        visibleDepartments: selectedDepartments.map((dep) => dep.value),
        categories: selectedCategories.map((cat) => {
          return { id: cat.value, name: cat.label };
        }),
        workspaceId: props.currentUser.workspaceId,
        authorId: auth.getId(),
      })
      .then((res) => {
        Promise.all([
          selectedFile.forEach(async (file) => {
            const formData = new FormData();
            formData.append("File", file);

            return await axios.put(
              `${config.apiURL}/api/Article/insert-file/${res.data.id}`,
              formData
            );
          }),
        ])
          .then((res) => {
            setButtonClicked(false);
            closeModal();
            resetValues();
            getArticles();
          })
          .catch((err) => {
            logError(err);
            setButtonClicked(false);
            resetValues();
            setErrorMessage(
              err.response
                ? err.response.data.errors.Title[0]
                : "Error while adding quiz."
            );
            setOpenAlert(true);
            setTimeout(() => closeModal(), 2500);
          });
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        resetValues();
        setErrorMessage(
          err.response
            ? err.response.data.errors.Title[0]
            : "Error while adding quiz."
        );
        setOpenAlert(true);
        setTimeout(() => closeModal(), 2500);
      });
  };

  const EditArticle = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);
    await axios
      .put(`${config.apiURL}/api/Article/${articleId}`, {
        title: values.name,
        visibleDepartments: selectedDepartments.map((dep) => dep.value),
        categories: selectedCategories.map((cat) => {
          return { id: cat.value, name: cat.label };
        }),
        workspaceId: props.currentUser.workspaceId,
        authorId: auth.getId(),
      })
      .then((res) => {
        Promise.all([
          selectedFile.forEach(async (file) => {
            const formData = new FormData();
            formData.append("File", file);

            return await axios.put(
              `${config.apiURL}/api/Article/insert-file/${articleId}`,
              formData
            );
          }),
        ])
          .then((res) => {
            setButtonClicked(false);
            closeModal();
            resetValues();
            getArticles();
          })
          .catch((err) => {
            logError(err);
            setButtonClicked(false);
            resetValues();
            setErrorMessage(
              err.response
                ? err.response.data.errors.Title[0]
                : "Error while adding article."
            );
            setOpenAlert(true);
            setTimeout(() => closeModal(), 2500);
          });
        getArticleById();
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        resetValues();
        setErrorMessage(
          err.response
            ? err.response.data.errors.Title[0]
            : "Error while adding article."
        );
        setOpenAlert(true);
        setTimeout(() => closeModal(), 2500);
      });
  };

  const getArticleById = async () => {
    await axios
      .get(`${config.apiURL}/api/Article/${articleId}`)
      .then((res) => {
        setValues({ name: res.data.title });
        setFiles(res.data.files);
        // const blocksFromHTML = convertFromHTML(res.data.content);
        // const state = ContentState.createFromBlockArray(
        //   blocksFromHTML.contentBlocks,
        //   blocksFromHTML.entityMap
        // );

        // const blocksFromHtml = htmlToDraft(res.data.content);
        // const { contentBlocks, entityMap } = blocksFromHtml;
        // const state = ContentState.createFromBlockArray(
        //   contentBlocks,
        //   entityMap
        // );

        setSelectedOptionsDepartments(res.data.visibleDepartments);
        setSelectedOptionsCategories(res.data.categories.map((cat) => cat.id));
      })
      .catch((err) => logError(err));
  };

  const deleteFile = async (file) => {
    await axios
      .put(`${config.apiURL}/api/Article/delete-file/${articleId}`, {
        fileId: file.id,
        name: file.name,
        url: file.url,
      })
      .then((res) => {
        getArticleById();
      })
      .catch((err) => logError(err));
  };

  const deleteFilePreview = (file) => {
    // eslint-disable-next-line eqeqeq
    const tempFiles = selectedFile.filter((f) => f.name != file.name);
    setSelectedFile(tempFiles);
  };

  useEffect(() => {
    if (currentUser) {
      getDepartments();
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (articleId) getArticleById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId]);
  const deleteHandeler = (removeId) =>
    setaddQBOXClicked((addQBOXClicked) =>
      addQBOXClicked.filter((id) => id !== removeId)
    );
  return (
    <Dialog
      open={open}
      onClose={() => {
        resetValues();
        closeModal();
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members articles-modal"
    >
      <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
        {errorMessage}
      </SnackbarError>
      <div className="department-members__header article-modal__header">
        <Breadcrumbs
          navigations={[
            `Quizzes`,
            articleId ? `${t("edit_article")}` : `${t("add_new")}`,
          ]}
        />
      </div>
      <div className="article-modal__content">
        <div className="article-modal__content-input">
          <InputField
            label={t("quiz_name")}
            value={values.name}
            onChange={handleChange("name")}
            buttonClicked={buttonClicked}
          />
          <Dropdown
            labelWidth={115}
            options={categories}
            selected={selectedOptionsCategories}
            setSelected={setSelectedCategories}
            label={t("quiz_category")}
            addCategory={AddCategory}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
          />
        </div>
        <div className="article-modal__content-input">
          <Dropdown
            labelWidth={215}
            options={departments}
            selected={selectedOptionsDepartments}
            setSelected={setSelectedDepartments}
            label={t("article_canread_departments")}
            addDepartment={AddDepartment}
            departmentName={departmentName}
            setDepartmentName={setDepartmentName}
          />
          <label htmlFor="file-upload" className="custom-file-upload">
            <Upload />
            {t("upload_image")}
          </label>
          <input
            id="file-upload"
            className="article-modal__file-upload"
            type="file"
            onChange={onFileChange}
          />
        </div>
        <div className="article-modal__uploaded-files">
          <p>{t("uploaded_files")}</p>
          {selectedFile.length || (articleId && files.length) ? (
            <div>
              {files?.map((file) => (
                <FilePreview
                  key={file.id}
                  name={file.originalName}
                  onClick={() => deleteFile(file)}
                  edit={true}
                />
              ))}
              {selectedFile?.map((file) => (
                <FilePreview
                  key={file?.name}
                  name={file?.name}
                  onClick={() => deleteFilePreview(file)}
                />
              ))}
            </div>
          ) : (
            <span>{t("nothing_uploaded")}</span>
          )}
        </div>

        {/* question and answer feilds */}

        <QuestionBox data={"no"} />
        {addQBOXClicked.map((id) => (
          <QuestionBox
            key={id}
            data={id}
            deleteHandeler={() => deleteHandeler(id)}
          />
        ))}

        {/* add new question button */}
        <div className="addquestionbutton">
          <Button
            className="departments__add-new--button"
            onClick={addComponent}
          >
            <AddIcon /> {t("add_new")}
          </Button>
        </div>

        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              resetValues();
              closeModal();
            }}
          >
            {t("Abbrechen")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={
              values.name &&
              selectedDepartments.length &&
              selectedCategories.length
            }
            buttonClicked={buttonClicked}
            onClick={articleId ? EditArticle : AddArticle}
          >
            {articleId ? `${t("save")}` : `${t("add_new")}`}
          </AddButton>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(QuizModal);
