import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SEARCH_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  DEPARTMENTS_BY_LOC
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {},
  departments: {},
};
  
export default typeToReducer(
  {
    [SEARCH_LOCATIONS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { locations, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allLocations = payload.params?.GetAll ? payload.params.GetAll : false;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let locationNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(locations.length > 0){
          locations.forEach(location => {
            location['no'] = ++locationNo;
            if(allIds.indexOf(location.id) === -1){
              allIds.push(location.id);
            }
            if(!allLocations){
              searchIds.push(location.id); 
            }          
            byId[location.id] = location;
          })
        }

        if(!allLocations){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_LOCATION]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let location = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        location['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: location};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_LOCATION]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { locationId, name, headId } = payload.params;
        let byId = state.byId;
        if(byId[locationId]){
          byId[locationId].name = name;
          byId[locationId].headId = headId;
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_LOCATION]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { locationId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(locationId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[locationId]){
            delete byId[locationId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },

    [DEPARTMENTS_BY_LOC]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { departments } = payload.data;
        const { LocationId } = payload.params;

        let dep = {
          ...state.departments, 
          [LocationId]: departments
        };

        return {
          ...state,
          departments: dep,
          isLoading: false,
        };       
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },

  },
  INITIAL_STATE
);
  