import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import SortInput from "../../components/sortInput/sortInput";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Pagination from "../../components/pagination/pagination";
import SnackbarError from "../../components/alert/SnackbarError";

import CategoryModal from "./categoryModal";

import config from "../../config/api.config.js";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

import { useTranslation } from "react-i18next";
import App from "../../App";
import { logError } from "../../helpers/helpers";

const Categories = (props) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState(null);
  const [search, setSearch] = useState("");
  const [defaultPage, setDefaultPage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryEditId, setCategoryEditId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [params, setParams] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalCount: 0,
    hasNext: false,
    hasPrevious: false,
  });

  const getCategories = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Category`, {
        params: {
          WorkspaceId: props.currentUser.workspaceId,
          PageSize: 10,
          ...params,
        },
      })
      .then((res) => {
        const {
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        } = res.data;
        setParams({
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        });

        let categoryNo = currentPage * 5 - 5;
        setCategories(
          res.data.categories.map((dep, i) => {
            return {
              no: ++categoryNo,
              id: dep.id,
              name: dep.name,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  const AddCategory = async () => {
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Category`, {
        name: categoryName,
        workspaceId: props.currentUser.workspaceId,
      })
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setCategoryName("");
        getCategories();
      })
      .catch((err) => {
        setButtonClicked(false);
        setOpenModal(false);
        setCategoryName("");
      });
  };

  const EditCategoryModal = (id, name) => {
    setCategoryEditId(id);
    setCategoryName(name);
    setOpenModal(true);
  };

  const EditCategory = async () => {
    await setButtonClicked(true);
    await axios
      .put(`${config.apiURL}/api/Category/${categoryEditId}`, {
        name: categoryName,
        workspaceId: props.currentUser.workspaceId,
      })
      .then((res) => {
        setButtonClicked(false);
        setOpenModal(false);
        setCategoryName("");
        getCategories();
      })
      .catch((err) => {
        setButtonClicked(false);
        setOpenModal(false);
        setCategoryName("");
      });
  };

  const DeleteCategoryModal = (id) => {
    setCategoryEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteCategory = async () => {
    await setButtonClicked(true);
    await axios
      .delete(`${config.apiURL}/api/Category/${categoryEditId}`, {
        data: {
          workspaceId: props.currentUser.workspaceId,
        },
      })
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setCategoryEditId(null);
        getCategories();
      })
      .catch((err) => {
        setErrorMessage(err.response.data.ERROR[0]);
        setOpenAlert(true);
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setCategoryEditId(null);
      });
  };

  const getPageNumber = (newPage) => {
    getCategories({
      PageNumber: newPage,
      Name: search,
      Sort: selectedSort,
    });
  };

  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      getCategories({
        PageNumber: 1,
        Name: search,
      });
    }
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    getCategories({ Sort: e.value });
  };

  const handleCategoryModal = async (
    categoryId,
    name,
    search = null,
    pageNumber = 1
  ) => {
    setSelectedCategory(name);
    setCategoryEditId(categoryId);

    setOpenCategoryModal(true);
  };

  useEffect(
    (_) => {
      const abortController = new AbortController();

      if (!props.currentUser) return;
      getCategories();

      return () => {
        abortController.abort();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [props.currentUser]
  );

  useEffect((_) => {
    setSearch("");
  }, []);

  return (
    <App>
      <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
        {errorMessage}
      </SnackbarError>
      <Breadcrumbs navigations={["Categories"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            onClick={() => setOpenModal(true)}
          >
            <AddIcon /> {t("add_new")}
          </Button>

          <Modal
            title={categoryEditId ? "Edit category" : "New category"}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              setCategoryName("");
              setCategoryEditId(null);
            }}
          >
            <input
              type="text"
              placeholder={t("category_name")}
              value={categoryName}
              onChange={({ target }) => setCategoryName(target.value)}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setCategoryEditId(null);
                  setCategoryName("");
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={categoryName ? true : false}
                buttonClicked={buttonClicked}
                onClick={categoryEditId ? EditCategory : AddCategory}
              >
                {categoryEditId ? "Edit" : "Add"}
              </AddButton>
            </div>
          </Modal>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("sure_delete_category")} </p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteCategory}
              >
                {t("delete")}
              </AddButton>
            </div>
          </Modal>

          <CategoryModal
            categoryName={selectedCategory}
            open={openCategoryModal}
            closeModal={() => setOpenCategoryModal(false)}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Category")}</TableCell>
              <TableCell align="right">{t("Options")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !loading && !categories?.length ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <NothingFound>{t("nothing_found_category")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              categories?.map((row) => (
                <TableRow key={row.no} className="departments__table-row">
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={(event) => handleCategoryModal(row.id, row.name)}
                  >
                    {row.no + "."}
                  </TableCell>

                  <TableCell
                    align="left"
                    onClick={(event) => handleCategoryModal(row.id, row.name)}
                  >
                    {row.name}
                  </TableCell>

                  <TableCell align="right">
                    <div>
                      <EditIcon
                        onClick={() => EditCategoryModal(row.id, row.name)}
                      />
                      <DeleteIcon onClick={() => DeleteCategoryModal(row.id)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {!categories?.length && search ? null : (
          <Pagination
            params={params}
            getPageNumber={getPageNumber}
            defaultPage={defaultPage}
          />
        )}
      </TableContainer>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(Categories);
