import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Arrived from "../../assets/images/arrived.png";
import { useTranslation } from "react-i18next";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import NewButton from "../../components/buttons/NewButton";
import Logo from "../../layout/logo/logo.js";

const VerifyEmail = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--color-primary", config.colorPrimary);
    root?.style.setProperty("--color-secondary", config.colorSecondary);
    root?.style.setProperty("--color-active", config.colorActive);
    root?.style.setProperty("--color-hover", config.colorHover);
    setReadyToRender(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleBackLogin = () => {
    history.push("/login");
  };
  const handleResend = async () => {
    //send Verification Email
    let userEmail = localStorage.getItem("email");

    await axios
      .post(`${config.apiURL}/api/Account/resend-verification-email`, {
        email: userEmail,
      })
      .then((res) => {
        setOpenAlert(true);
      })
      .catch((err) => {
        logError(err.response.data.ERROR);
      });

    //End Verification Email
  };

  return (
    <>
      {readyToRender && (
        <div className="main2">
          <div className="login__left"></div>
          <div className="login__right">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openAlert}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                {t("Erfolgreich versendet")}
              </Alert>
            </Snackbar>

            <div className="login__right--content">
              <Logo />
              <h2 style={{ marginTop: "0", marginBottom: "2.4rem" }}>
                {t("email_a_c")}
              </h2>
              <div
                className="login__right--img"
                style={{ width: "329px", height: "262px" }}
              >
                <img src={Arrived} alt="Arrived" />
              </div>

              <div className="register__thirdPage--bottom">
                <p>{t("E-mail erfolgreich versendet")}</p>
                <p>
                  {t("Keine Email bekommen?")}
                  <Link onClick={handleResend}>{t("Noch mal senden")}</Link>
                </p>
              </div>

              <div className="main2">
                <NewButton
                  name={t("Zurück zur Anmeldung")}
                  className="emailVerified__button"
                  valid={true}
                  onClick={handleBackLogin}
                ></NewButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
