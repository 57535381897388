import { 
  SEARCH_PROCESSES,
  ADD_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
  PROCESS_BY_ID,
  GET_PROCESS_TASKS,
  ACTIVATE_PROCESS,
  PROCESSES_BY_USER,
  COMPLETE_PROCESS,
  UPLOAD_PROCESS_IMAGE
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchProcesses = (params) => ({
  type: SEARCH_PROCESSES,
  payload: generateAuthRequest('GET','api/Process', params)
});

export const addProcess = (params) => ({
  type: ADD_PROCESS,
  payload: generateAuthRequest('POST','api/Process', params)
});

export const updateProcess = (params) => ({
  type: UPDATE_PROCESS,
  //payload: generateAuthRequest('PUT',`api/Process/${params.processId}`, params)
  payload: generateAuthRequest('PUT',`api/Process/update/${params.processId}`, params)
}); 

export const deleteProcess = (params) => ({
  type: DELETE_PROCESS,
  //payload: generateAuthRequest('DELETE',`api/Process/${params.processId}`, params)
  payload: generateAuthRequest('DELETE',`api/Process/delete/${params.processId}`, params)
});

export const getProcessById = (params) => ({
  type: PROCESS_BY_ID,
  payload: generateAuthRequest('GET',`api/Process/${params.id}`) 
});

export const getProcessTasks = (params) => ({
  type: GET_PROCESS_TASKS,
  payload: generateAuthRequest('GET',`api/Process/allTasks/${params.processId}`, params) 
});

export const updateProcessStatus = (params) => ({
  type: ACTIVATE_PROCESS,
  payload: generateAuthRequest('PUT',`api/Process/changeStatus/${params.processId}`, params) 
});

export const getProcessesByUser = (params) => ({
  type: PROCESSES_BY_USER,
  payload: generateAuthRequest('GET',`api/Process/getResponsibles/${params.userId}`, params) 
});

export const completeProcess = (params) => ({
  type: COMPLETE_PROCESS,
  payload: generateAuthRequest('PUT',`api/Process/submitProcess/${params.processId}`, params) 
});

export const uploadProcessImage = (params) => ({
  type: UPLOAD_PROCESS_IMAGE,
  payload: generateAuthRequest('PUT',`api/Process/upload-file/${params.processId}`, params.formData, params.processId)
});

