import React from "react";
import { useHistory } from "react-router-dom";
import NewButton from "../../components/buttons/NewButton";
import "../../assets/css/pages/login.scss";
import Logo from "../../layout/logo/logo.js";
import { useTranslation } from "react-i18next";

const ResetPasswordSecondPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push("/login");
  };

  return (
    <div>
      <div className="main2">
        <div className="headerp">
          <Logo />
        </div>
      </div>
      <div className="main2">
        <h2
          style={{
            marginTop: "0",
            marginBottom: "3.5rem",
            textAlign: "center",
          }}
        >
          {t("Dein Passwort wurde erfolgreich geändert!")}
        </h2>
        <div className="main2">
          <p>
            {t("Bitte nutze ab jetzt dein neues Passwort, um dich anzumelden.")}
          </p>

          <NewButton
            style={{ marginTop: "24px" }}
            name={t("Zur Anmeldung")}
            onClick={handleClick}
            className="login__button forgotPassword__secondPage--button"
            valid={true}
          ></NewButton>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSecondPage;
