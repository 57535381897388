import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect, useDispatch } from "react-redux";
import { searchUsers } from "../../redux/users/action"; 

const MembersSelect = (props) => {
  const dispatch = useDispatch();
  const {className, placeholder, onChange, selectedOption = ''} = props;
  const [selectedVal, setSelectedVal] = useState(selectedOption);
  const [memberOptions, setMemberOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const abortController = new AbortController();
      if(props.allMemberIds.length === 0){
        await dispatch(searchUsers({ GetAll: true }))
      }
      return () => {
        abortController.abort();
      };
    }
    fetchData();
  }, []);
  
  useEffect(() => {
    if(props.allMemberIds.length > 0){    
      setMemberOptions(
        props.allMemberIds.map((id) => {
          return {
            value: id,
            label: props.members[id].firstName + ' ' + props.members[id].lastName
          };
        })
      );
    }else{
      setMemberOptions([]);
    }
    
  }, [props.allMemberIds.length, props.memberIds])

  const optionChangeHandler = (e) => {
    setSelectedVal(e.value);
    onChange(e.value);  
  };

  return(
    <Select
      className={className}
      placeholder={placeholder}
      onChange={optionChangeHandler}
      options={memberOptions}
      isSearchable={true}
      value = {
        memberOptions.filter(option => 
        option.value === selectedVal)
      }
    />
  );
};

const mapStateToProps = (state) => ({
  allMemberIds: state.user.allIds,
  members: state.user.byId,
  memberIds: state.user.searchIds
});

export default connect(mapStateToProps, null)(MembersSelect);
