/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from "react";
import { Dialog } from "@material-ui/core";
import Pagination from "../../components/pagination/pagination";
import AbbrechenIcon from "@material-ui/icons/Cancel";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import { LightTooltip } from "../../components/tooltip/tooltip";

const DepartmentMembers = (props) => {
  const {
    department,
    departmentName,
    departmentId,
    open,
    closeModal,
    departmentMembers,
    getSerachedMembers,
    positionId,
  } = props;

  const [defaultPage, setDefaultPage] = useState(false);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 5,
    totalCount: 0,
    hasNext: false,
    hasPrevious: false,
  });

  useEffect(() => {
    if (departmentMembers)
      setParams({
        currentPage: departmentMembers.currentPage,
        totalPages: departmentMembers.totalPages,
        pageSize: departmentMembers.pageSize,
        totalCount: departmentMembers.totalCount,
        hasNext: departmentMembers.hasNext,
        hasPrevious: departmentMembers.hasPrevious,
      });
  }, [departmentMembers]);

  const getPageNumber = (newPage) => {
    if (positionId)
      getSerachedMembers(
        positionId,
        departmentName,
        departmentId,
        search,
        newPage
      );
    else getSerachedMembers(departmentId, departmentName, search, newPage);
  };

  const searchMember = (search) => {
    setDefaultPage(!defaultPage);
    setSearch(search);

    if (positionId)
      getSerachedMembers(positionId, departmentName, departmentId, search);
    else getSerachedMembers(departmentId, departmentName, search);
  };

  const renderMembers = () =>
    departmentMembers?.users?.map((user) => {
      return (
        <div className="department-members__single" key={user.userId}>
          <div className="members-single__img">
            <img
              src="/profile.jpg"
              /* {user.image.url ? user.image.url : './profile.jpg'} */ alt="profile-pic"
            />
          </div>

          <div className="members-single__content">
            <span>
              <p>{user.firstName + " " + user.lastName}</p>
              <LightTooltip
                arrow
                title={
                  user.departments.find((d) => d.departmentId == departmentId)
                    ?.roles
                }
                placement="right"
              >
                <div className="members-single__tooltip"></div>
              </LightTooltip>
            </span>
            <span>{user.email}</span>
            <span>
              {
                user.departments.find((d) => d.departmentId == departmentId)
                  ?.positionName
              }
            </span>
          </div>
        </div>
      );
    });

  const closeDialogModal = () => {
    closeModal(true);
    setSearch("");
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialogModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members"
    >
      <div className="department-members__header">
        <Breadcrumbs navigations={[`${department}`, `${departmentName}`]} />
        <AbbrechenIcon
          style={{ fill: "#ee5555", cursor: "pointer" }}
          onClick={closeDialogModal}
        />
      </div>
      <div
        className="departments__top--serach"
        style={{ marginTop: "1.875rem" }}
      >
        <Search getSearchValue={searchMember} />
      </div>
      {!departmentMembers?.users.length ? (
        <div className="nothingFound" style={{ marginTop: "6rem" }}>
          <p style={{ marginBottom: "5rem" }}>
            Momentan keine Einträge vorhanden{" "}
            {positionId ? "position" : "department"}
          </p>
        </div>
      ) : (
        <Fragment>
          <p className="department-members__numbers">
            Anzahl Mitarbeiter: <span>{departmentMembers?.users.length}</span>
          </p>
          <div>
            <div className="department-members__list">{renderMembers()}</div>

            <Pagination
              params={params}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          </div>
        </Fragment>
      )}
    </Dialog>
  );
};

export default DepartmentMembers;
