import React from 'react';
import Alert from '@material-ui/lab/Alert';

import './alert.scss';

const Error = ({ children, style, className, setOpen }) => {
  return (
    <div className={`alert ${className}`}>
      <Alert severity="error" style={style} onClose={() => setOpen(false)}>
        {children}
      </Alert>
    </div>
  );
};

export default Error;
