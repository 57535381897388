/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";

import Logo from "../../layout/logo/logo.js";
import VerifyEmail from "../../assets/images/verifyEmail.png";
import { Link } from "@material-ui/core";
import NewButton from "../../components/buttons/NewButton.js";
import Alert from "@material-ui/lab/Alert";

const RegisterThirdPage = ({ email }) => {
  const [resend, setResend] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = ({ values }) => {
    let path = `/login`;
    history.push(path);
  };

  const ResendVerificationEmail = async () => {
    await axios
      .post(`${config.apiURL}/api/Account/resend-verification-email`, { email })
      .then((res) => {
        setResend(true);
      })
      .catch((err) => logError(err));
  };

  return (
    <div>
      <div className="headerp">
        <Logo />
      </div>
      <div className="main2">
        <div className="main2">
          <h2 style={{}}>Verify your email address</h2>
          <div className="main2" style={{}}>
            <img src={VerifyEmail} alt="Verify-Email" />
          </div>
        </div>
        <div className="">
          <p>Ein Aktivierungslink wurde an dich versendet.</p>
          <p className="f-linkLabel">
            <span style={{ color: config.colorPrimary }}>
              Keine Email erhalten?
            </span>
            <Link onClick={ResendVerificationEmail}> Noch mal senden </Link>
            {resend ? (
              <Alert
                style={{ borderRadius: "25px", width: "435px", height: "56px" }}
              >
                {t("Erfolgreich versendet")}
              </Alert>
            ) : null}
          </p>
        </div>
        <NewButton
          name={t("Zurück zur Anmeldung")}
          onClick={routeChange}
          className="thirdPage__button"
          valid={true}
        ></NewButton>
      </div>
      <div></div>
    </div>
  );
};

export default RegisterThirdPage;
