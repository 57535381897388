import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import auth from "../../auth";
import App from "../../App";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import { useTranslation } from "react-i18next";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import { getProcessById, getProcessTasks } from "../../redux/process/action";
import { getAssignedTasks } from "../../redux/task/action";
import Pagination from "../../components/pagination/pagination";
import SortInput from "../../components/sortInput/sortInput";
import SnackbarError from "../../components/alert/SnackbarError";
import Search from "../../components/search/search";
import moment from "moment";
import { getUserById } from "../../redux/users/action";
import { useHistory } from "react-router-dom";

const MyTasks = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");
  const [member, setMember] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;

    const userId = auth.getId();

    const fetchData = async () => {
      await dispatch(getAssignedTasks({ userId: userId })).catch((err) => {
        logError(err);
        setErrorMessage("Fehler bei Laden der Aufgabes.");
        setOpenAlert(true);
      });

      if (props.members[userId]) {
        setMember(props.members[userId]);
      } else {
        let _member = await getMember(process.responsible);
        setMember(_member);
      }
    };
    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    if (props.assignedTasks.length > 0) {
      formatTasks(props.assignedTasks);
    } else {
      setAssignedTasks([]);
    }
  }, [props.assignedTasks]);

  const formatTasks = async (tasks) => {
    let _tasks = [];
    for (const task of tasks) {
      let process = await getProcess(task.processId);
      task.processName = process && process.name ? process.name : "";
      if (process && process.responsible) {
        await getMember(process.responsible);
      }
      if (task.preTask === null) {
        task.waitForPreTask = false;
      } else {
        if (!task.toggle) {
          let isCompleted = await checkIfPreTaskIsCompleted(
            task.processId,
            task.preTask
          );
          task.waitForPreTask = !isCompleted;
        } else {
          const processTasks = await getTasksOfProcess(task.processId);
          const canRun = await checkIfTaskCanExecuteConcurrently(
            processTasks,
            task.preTask,
            task.name
          );
          task.waitForPreTask = !canRun;
        }
      }
      _tasks.push(task);
    }
    setAssignedTasks(_tasks);
  };

  const getProcess = async (id) => {
    if (props.processes[id]) {
      return props.processes[id];
    } else {
      return await dispatch(getProcessById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            return res.value.data;
          }
        })
        .catch((err) => {
          logError(err);
          setErrorMessage("Fehler bei Laden des Prozesses.");
          setOpenAlert(true);
        });
    }
  };

  const getTaskStatus = (task) => {
    let status = "";

    let process = props.processes[task.processId]
      ? props.processes[task.processId]
      : [];
    if (!process?.isActivated) {
      status = "Inactive";
      return status;
    }

    if (task.waitForPreTask) {
      status = "Waiting for preTask to finish";
    } else if (task.compeleted && task.approved) {
      status = "Completed";
    } else if (task.compeleted && !task.approved) {
      status = "Waiting for Approval";
    } else if (!task.compeleted && !task.approved) {
      status = "Active";
    }
    return status;
  };

  const getPageNumber = (newPage) => {
    dispatch(
      getAssignedTasks({
        userId: auth.getId(),
        PageNumber: newPage,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      getAssignedTasks({
        userId: auth.getId(),
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const getTasksOfProcess = async (processId) => {
    if (props.processTasks[processId]) {
      return props.processTasks[processId];
    } else {
      return await dispatch(getProcessTasks({ processId: processId })).then(
        (res) => {
          if (res.value?.data) {
            return res.value.data.tasks;
          }
        }
      );
    }
  };

  const checkIfPreTaskIsCompleted = async (processId, taskId) => {
    const processTasks = await getTasksOfProcess(processId);
    const task = processTasks?.filter((item) => item.id === taskId);
    if (task?.length > 0 && task[0].compeleted && task[0].approved) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfTaskCanExecuteConcurrently = (processTasks, taskId, name) => {
    const preTask = processTasks?.find((item) => item.id === taskId);
    let preTaskParent = [];

    if (taskId === null || preTask.preTask === null) {
      return true;
    } else {
      preTaskParent = processTasks?.find((item) => item.id === preTask.preTask);
    }

    if (!preTask.toggle) {
      if (preTaskParent.compeleted && preTaskParent.approved) {
        return true;
      } else {
        return false;
      }
    } else {
      return checkIfTaskCanExecuteConcurrently(
        processTasks,
        preTaskParent.preTask,
        name
      );
    }
  };

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        getAssignedTasks({
          userId: auth.getId(),
          PageNumber: props.pageParams.currentPage,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const getMember = async (userId) => {
    let _member = [];
    if (props.members[userId]) {
      _member = props.members[userId];
    } else {
      await dispatch(getUserById({ id: userId }))
        .then((res) => {
          if (res.value?.data) {
            let user = res.value.data;
            user["id"] = res.value.data.userId;
            _member = user;
          }
        })
        .catch((err) => logError(err));
    }
    return _member;
  };

  const showTaskDetailView = (task, userType, status) => {
    history.push(`/task/${task.id}`, {
      task: task,
      member: member,
      userType: userType,
      isActive: status === "Active" ? true : false,
    });
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      <Breadcrumbs navigations={["Meine Aufgaben"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
        </div>
        <SortInput handleSort={handleSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("Process")}</TableCell>
              <TableCell align="left">{t("Prozessverantortlich")}</TableCell>
              <TableCell align="left">{t("Fälligkeitsdatum")}</TableCell>
              <TableCell align="left">{t("Status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : !props.isLoading && assignedTasks?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_task")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  assignedTasks?.length > 0 &&
                  assignedTasks?.map((task, index) => {
                    return (
                      <TableRow
                        key={task.no}
                        className="departments__table-row"
                        style={{ backgroundColor: "white" }}
                      >
                        <TableCell component="th" scope="row">
                          {task.no + "."}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() =>
                            showTaskDetailView(
                              task,
                              "task_owner",
                              getTaskStatus(task)
                            )
                          }
                        >
                          {task.name}
                        </TableCell>
                        <TableCell align="left">
                          {props.processes[task.processId]
                            ? props.processes[task.processId].name
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {props.processes[task.processId]
                            ? props.members[
                                props.processes[task.processId].responsible
                              ]?.firstName +
                              " " +
                              props.members[
                                props.processes[task.processId].responsible
                              ]?.lastName
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {moment(task.dateBeDone).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {getTaskStatus(task)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!assignedTasks?.length ? null : (
        <>
          {assignedTasks.length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  members: state.user.byId,
  processIds: state.process.allIds,
  processes: state.process.byId,
  pageParams: state.task.assignedTasksPageParams,
  assignedTasks: state.task.assignedTasks,
  isLoading: state.task.isLoading,
  processTasks: state.process.tasks,
});

export default connect(mapStateToProps, null)(MyTasks);
