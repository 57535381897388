import React, { useState } from "react";

const Logo = () => {
  const [logo, setLogo] = useState("");

  import(`../../assets/images/${process.env.REACT_APP_LOGO}`).then((module) => {
    setLogo(module.default);
  });

  return <img src={logo} alt="logo" style={{width:"auto", height:"100%"}}/>;
};
export default Logo;
