import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import config from '../../config/api.config.js';
import { logError } from "../../helpers/helpers";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import Backdrop from '../../components/backdrop/backdrop';
import NothingFound from '../../components/searchNothingFound/searchNothingFound';

import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';

import { useTranslation } from 'react-i18next';
import App from '../../App';

import {
  Player,
  BigPlayButton,
  ControlBar,
  ForwardControl,
  ReplayControl,
  PlaybackRateMenuButton
} from 'video-react';
import 'video-react/dist/video-react.css';

const CourseDetails = (props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [details, setDetails] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [articles, setArticles] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    nextArrow: <KeyboardArrowRightIcon />,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  };

  const GetCourseDetails = async () => {
    setOpenBackdrop(true);
    await axios
      .get(`${config.apiURL}/api/Course/${props.match.params.id}`)
      .then((res) => {
        setDetails(res.data);
        setArticles(res.data.articles);
        setOpenBackdrop(false);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        logError(err);
      });
  };

  useEffect(() => {
    GetCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <App>
      {openBackdrop ? (
        <Backdrop open={openBackdrop} />
      ) : (
        <div className="article-details course-details">
          <div className="article-details__header">
            <div>
              <KeyboardArrowLeftIcon
                //  onClick={() => history.push('/courses')} 
                onClick={() => history.goBack()}
              />
              <span>{t("Courses")}</span>
            </div>
            <Breadcrumbs navigations={[`${details?.title}`]} />
            <EditIcon
              onClick={() =>
                history.push(`/edit-course/${props.match.params.id}`)
              }
            />
          </div>
          <div className="article-details__content course-details__content">
            <p>
              <span>{t('Categories')}:</span>{' '}
              {details?.categories.map((cat) => cat.name).join(', ')}
            </p>
            <p>
              <span>{t('created_by')}: </span> {details ? details.author.firstName + ' ' + details.author.lastName : null}
            </p>
            <div
              className="add-course__second-step--content add-course__third-step--content"
              style={{ maxHeight: '24.9rem' }}
            >
              <TableContainer className="departments__table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">{t('Category')}</TableCell>
                      <TableCell align="center">{t('Date')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!articles?.length ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <NothingFound>
                            {t('nothing_found_article')}
                          </NothingFound>
                        </TableCell>
                      </TableRow>
                    ) : (
                      articles?.map((row, i) => (
                        <TableRow
                          key={row.id}
                          className="departments__table-row"
                          onClick={(event) => history.push(`/article/${row.id}`)}
                        >
                          <TableCell component="th" scope="row">
                            {i + 1 + '.'}
                          </TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">
                            {row.categories
                              .map((cat, i) => (i < 2 ? cat.name : '...'))
                              .join(', ')}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.dateCreated).format('DD.MM.yyyy')}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="add-course__second-step--content course-details__videos-content">
              <Breadcrumbs navigations={[`Videos`]} />
              {details?.videos.length > 2 ? (
                <Slider {...settings}>
                  {details?.videos.map((video) => (
                    <div key={video.id} className="course-details__videos">
                      <p>{video.originalName}</p>
                      <Player src={video.url}>
                        <BigPlayButton position="center" />
                        <ControlBar autoHide={false}>
                          <ReplayControl seconds={5} order={2.1} />
                          <ForwardControl seconds={5} order={3.1} />
                          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                        </ControlBar>
                      </Player>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="course-details__videos-list">
                  {details?.videos.map((video) => (
                    <div key={video.id} className="course-details__videos course-details__videos-no-slider">
                      <p>{video.originalName}</p>
                      <Player src={video.url}>
                        <BigPlayButton position="center" />
                        <ControlBar autoHide={false}>
                          <ReplayControl seconds={5} order={2.1} />
                          <ForwardControl seconds={5} order={3.1} />
                          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                        </ControlBar>
                      </Player>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </App>
  );
};

export default CourseDetails;
