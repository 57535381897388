import React from 'react';

const NotFound = () => {
  return (
    <h3 style={{display:"flex",justifyContent:"center"}}>404 - NotFound Component</h3>
  )
};



export default NotFound;