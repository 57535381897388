import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config/api.config.js';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import './inputField.scss';

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    '& input + fieldset': {
      borderColor: config.colorSecondary,
      borderWidth: 1.5,
      borderRadius : '25px'
    },
    '& label': {
      color: `${config.colorSecondary} !important`
    }
  }
}));

const InputField = ({
  className,
  onChange,
  value,
  label,
  onKeyUp,
  buttonClicked = false,
  valid = true,
  errorMessage = null,
  rows,
  type
}) => {
 const classes = useStyles();
  const { t } = useTranslation()

  const [emptyInput, setEmptyInput] = useState(false);
  useEffect(() => {
    if (value) setEmptyInput(true);
    else setEmptyInput(false);
  }, [value]);

  return (
    <>
      {type && type === 'multiline' ? (
        <TextField
          className={className}
          error={valid ? false : true}
          label={t(label)}
          value={value}
          variant="outlined"
          rows={rows}
          rowsMax={rows}
          multiline
          helperText={!valid ? errorMessage : null}
          onChange={onChange}
          disabled={buttonClicked ? true : false}
          onKeyUp={onKeyUp && onKeyUp}
        />
      ): 
      (<TextField
        className={className}
        error={valid ? false : true}
        label={t(label)}
        value={value}
        variant="outlined"
        helperText={!valid ? errorMessage : null}
        onChange={onChange}
        disabled={buttonClicked ? true : false}
        onKeyUp={onKeyUp && onKeyUp}
      />)}
    </>
  );
};

export default InputField;
