import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";

import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Pagination from "../../components/pagination/pagination";
import { LightTooltip } from "../../components/tooltip/tooltip";

import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import App from "../../App";

import { useTranslation } from "react-i18next";

const Courses = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [courses, setCourses] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSort, setSelectedSort] = useState(1);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [courseId, setCourseId] = useState(null);

  const [defaultPage, setDefaultPage] = useState(false);
  const [params, setParams] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalCount: 0,
    hasNext: false,
    hasPrevious: false,
  });
  const [buttonClicked, setButtonClicked] = useState(false);

  const getCourses = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Course`, {
        params: {
          WorkspaceId: props.currentUser.workspaceId,
          PageSize: 10,
          ...params,
        },
      })
      .then((res) => {
        const {
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        } = res.data;
        setParams({
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        });

        let courseNo = currentPage * 5 - 5;

        setCourses(
          res.data.courses.map((dep, i) => {
            return {
              no: ++courseNo,
              id: dep.id,
              name: dep.title,
              authorId: dep.author.authorId,
              authorImg: dep.author.imageUrl,
              authorName: dep.author.firstName + " " + dep.author.lastName,
              date: moment(dep.dateCreated).format("DD.MM.yyyy"),
            };
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const getUserImage = async (id) => {
    // await axios
    //   .get(`${config.apiURL}/api/User/${id}`)
    //   .then((res) => {
    //     setImg(res.data.image.url);
    //   })
    //   .catch((err) => logError(err));
    // return img;
  };

  const DeleteCourseModal = (id) => {
    setCourseId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteCourse = async () => {
    await setButtonClicked(true);
    await axios
      .delete(`${config.apiURL}/api/Course/${courseId}`, {
        data: { workspaceId: props.currentUser.workspaceId },
      })
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        getCourses();
      })
      .catch((err) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        // setErrorMessage(err.response.data.ERROR[0]);
        // setOpenAlert(true);
      });
  };

  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      getCourses({ PageNumber: 1, Title: search });
    }
    setDefaultPage(!defaultPage);
  };
  const handleSort = (e) => {
    setSelectedSort(e.value);
    getCourses({ PageNumber: params.currentPage, Sort: e.value });
  };
  const getPageNumber = (newPage) => {
    getCourses({
      PageNumber: newPage,
      Name: search,
      Sort: selectedSort,
    });
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;
    getCourses();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  return (
    <App>
      <Breadcrumbs navigations={["Courses"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            onClick={() => history.push("/add-course")}
          >
            <AddIcon /> {t("add_new")}
          </Button>

          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("sure_delete_course")} </p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteCourse}
              >
                {t("delete")}
              </AddButton>
            </div>
          </Modal>
        </div>
        <SortInput handleSort={handleSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">{t("Author")}</TableCell>
              <TableCell align="center">{t("Date")}</TableCell>
              <TableCell align="right">{t("Options")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !loading && !courses?.length ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <NothingFound>{t("nothing_found_course")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              courses?.map((row) => (
                <TableRow key={row.id} className="departments__table-row">
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={(event) => history.push(`/course/${row.id}`)}
                  >
                    {row.no + "."}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(event) => history.push(`/course/${row.id}`)}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(event) => history.push(`/course/${row.id}`)}
                  >
                    <LightTooltip
                      arrow
                      title={row.authorName}
                      placement="right"
                    >
                      <Avatar alt="" src={row.authorImg} />
                    </LightTooltip>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(event) => history.push(`/course/${row.id}`)}
                  >
                    {row.date}
                  </TableCell>

                  <TableCell align="right">
                    <div>
                      <EditIcon
                        onClick={() => history.push(`/edit-course/${row.id}`)}
                      />
                      <DeleteIcon onClick={() => DeleteCourseModal(row.id)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!courses?.length && search ? null : (
        <Pagination
          params={params}
          getPageNumber={getPageNumber}
          defaultPage={defaultPage}
        />
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(Courses);
