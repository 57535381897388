import React, {useState, useEffect} from 'react';
import {  useHistory } from 'react-router-dom';
import NewButton from '../../components/buttons/NewButton';
import Logo from '../../layout/logo/logo.js';
import Birthday from '../../assets/images/birthday.png';
import '../../assets/css/pages/login.scss';
import { useTranslation } from 'react-i18next'
import config from '../../config/api.config.js'

const EmailVerification = () => {
  const { t } = useTranslation()
  const history = useHistory();

  const routeChange = () => {
    let path = `/login`;
    history.push(path);
  };
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      config.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      config.colorSecondary
    );
    root?.style.setProperty(
      "--color-active",
      config.colorActive
    );
    root?.style.setProperty(
      "--color-hover",
      config.colorHover
    );
    setReadyToRender(true);
  }, []);

  return (
    <>
      {readyToRender && <div className="login2">
        <div className="">
          <div className="headerp">
          <Logo />
          </div>
        </div>
        <div className="main2">
          
          
            <h2 style={{display:'flex', justifyContent:'center',marginTop:'10px' }}>
              {t('email_success_verified')}
            </h2>
            <div
              className="main2"
              style={{ width: '24.625rem', height: '20.313rem', display: 'flex',justifyContent:'center'}}
            >
              <img src={Birthday} alt="Birthday" style={{marginTop:'30px'}} />
            </div>

            <div className="main2">
              <p style={{marginTop:'80px', fontSize:'large'}}>{t('now_can_login')}</p>
            </div>
            <div className='main2' >
            <NewButton
              style={{marginTop:'40px'}}
              name={t('back_to_login')}
              onClick={routeChange}
              className="emailVerified__button"
              valid={true}
            >
            </NewButton>
            </div>
          
        </div>
      </div>}
    </>
  );
};

export default EmailVerification;
