import "babel-polyfill";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SnackbarError from "../../components/alert/SnackbarError";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/members.scss";
import Modal from "../../components/modal/modal";
import {
  searchLocations,
  getDepartmentsByLoc,
} from "../../redux/location/action";
import { getTeamsByDepartment } from "../../redux/department/action";
import {
  addUser,
  updateUser,
  uploadMemberProfilePic,
} from "../../redux/users/action";

const defaultImageSrc = "/profile.jpg";

const MemberModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    open,
    closeModal,
    currentUser,
    editId,
    selectedMember,
    showSuccessMsg,
  } = props;

  const [buttonClicked, setButtonClicked] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);
  const [supervisorName, setSupervisorName] = useState("");
  const [memberEditId, setMemberEditId] = useState(null);
  const [memberEmail, setMemberEmail] = useState("");
  const [memberTel, setMemberTel] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");
  const [imageSrc, setImageSrc] = useState(defaultImageSrc);
  const [roles, setRoles] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [supervisorId, setSupervisorId] = useState("");
  const [member, setMember] = useState(null);
  const [values, setValues] = useState({
    name: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [position, setPosition] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  useEffect(() => {
    if (currentUser) {
      if (props.locationIds.length === 0) {
        dispatch(searchLocations({ GetAll: true }));
      }
      setDepartments([]);
      setTeams([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (props.locationIds.length > 0) {
      setLocations(
        props.locationIds.map((id) => {
          return {
            value: id,
            label: props.locations[id].name,
          };
        })
      );
    } else {
      setLocations([]);
    }
  }, [props.locationIds.length]);

  useEffect(() => {
    if (open && editId && selectedMember && editId === selectedMember.id) {
      setMemberEditId(editId);
      setMember(selectedMember);
      setMemberName(selectedMember.firstName);
      setMemberLastName(selectedMember.lastName);
      setMemberEmail(selectedMember.email);
      setMemberTel(selectedMember.phoneNumber);
      setLocationId(selectedMember.locationId);
      setDepartmentId(selectedMember.departmentId);
      setTeamId(selectedMember.teamId);
      setRoles(selectedMember.role);
      setSupervisorId(selectedMember.supervisorId);
      setPosition(selectedMember.position);
      if (selectedMember.locationId) {
        getDepartments(selectedMember.locationId);
      }
      if (selectedMember.departmentId) {
        getTeams(selectedMember.departmentId);
      }
      setImageSrc(selectedMember.imageSrc);
    }

    const roleOpt = [
      { value: "Admin", label: "Admin" },
      { value: "Coach", label: "Coach" },
      { value: "Intern", label: "Intern" },
      { value: "Moderator", label: "Moderator" },
      { value: "Subscriber", label: "Subscriber" },
    ];
    setRoleOptions(roleOpt);
  }, [editId, selectedMember, open]);

  const resetValues = () => {
    setValues({ ...values, name: "" });

    setLocationId("");
    setDepartmentId("");
    setTeamId("");
    setSelectedFile(null);
    setMemberEditId(null);
    setMemberName("");
    setMemberLastName("");
    setMemberEmail("");
    setMemberTel("");
    setImageSrc("");
    setRoles("");
    setDepartments([]);
    setTeams([]);
    setSupervisorId("");
    setMember(null);
    setSelectedFile(null);
    setSupervisorName("");
    setPosition("");
    setSelectedImageUrl("");
  };

  const getDepartments = async (locid) => {
    if (
      locid &&
      (!props.departmentsByLoc ||
        Object.keys(props.departmentsByLoc)?.length === 0 ||
        !props.departmentsByLoc[locid] ||
        props.departmentsByLoc[locid].length === 0)
    ) {
      await dispatch(getDepartmentsByLoc({ LocationId: locid }));
    } else {
      setDepartmentDropdown(locid);
    }
  };

  useEffect(() => {
    if (locationId) {
      setDepartmentDropdown(locationId);
    }
  }, [props.departmentsByLoc]);

  const setDepartmentDropdown = (locId) => {
    if (props.departmentsByLoc[locId]) {
      setDepartments(
        props.departmentsByLoc[locId].map((dep) => {
          return {
            value: dep.id,
            label: dep.name,
          };
        })
      );
    } else {
      setDepartments([]);
    }
  };

  const getTeams = async (depid) => {
    if (
      depid &&
      (!props.teamsByDepartment ||
        Object.keys(props.teamsByDepartment)?.length === 0 ||
        !props.teamsByDepartment[depid] ||
        props.teamsByDepartment[depid].length === 0)
    ) {
      await dispatch(getTeamsByDepartment({ DepartmentId: depid }));
    } else {
      setTeamsDropdown(depid);
    }
  };

  useEffect(() => {
    if (departmentId) {
      setTeamsDropdown(departmentId);
    }
  }, [props.teamsByDepartment]);

  const setTeamsDropdown = (depId) => {
    if (depId && props.teamsByDepartment[depId]) {
      setTeams(
        props.teamsByDepartment[depId].map((team) => {
          return {
            value: team.id,
            label: team.name,
          };
        })
      );
    } else {
      setTeams([]);
    }
  };

  const AddMember = async () => {
    await setButtonClicked(true);
    dispatch(
      addUser({
        firstName: memberName,
        lastName: memberLastName,
        email: memberEmail,
        phoneNumber: memberTel,
        locationId: locationId,
        departmentId: departmentId ? departmentId : null,
        teamId: teamId ? teamId : null,
        position: position,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        resetValues();
        setImageSrc("");
        closeModal();
        showSuccessMsg("Neuer Nutzer erfolgreich hinzugefügt.");
      })
      .catch(function (err) {
        if (err.response) {
          let msg;
          if (err.response.data?.includes("Passwords do not match")) {
            msg = "Passwörter stimmen nicht überein";
          } else if (err.response.data?.includes("Email already exists")) {
            msg = "Email-Adresse existiert bereits";
          } else {
            msg = "Fehler bei Hinzufügen von Nutzer.";
          }
          setButtonClicked(false);
          setErrorMessage(msg);
          setOpenAlert(true);
        }
      });
  };

  const EditMember = async () => {
    const reqData = {
      userId: memberEditId,
      workspaceId: currentUser.workspaceId,
      firstName: memberName,
      lastName: memberLastName,
      phoneNumber: memberTel,
      locationId: locationId,
      departmentId: departmentId ? departmentId : null,
      teamId: teamId ? teamId : null,
      role: roles,
      supervisorId: supervisorId,
      position: position,
    };
    await setButtonClicked(true);

    if (memberEmail != member.email) {
      reqData.email = memberEmail;
    }

    await dispatch(updateUser(reqData))
      .then(async (res) => {
        let uploadErr = false;
        if (selectedFile) {
          const formData = new FormData();
          formData.append("File", selectedFile);
          await dispatch(
            uploadMemberProfilePic({ formData: formData, userId: memberEditId })
          ).catch(function (err) {
            logError(err);
            uploadErr = true;
          });
        }
        setButtonClicked(false);
        if (uploadErr) {
          setErrorMessage("Fehler beim Hochladen des Fotos.");
        } else {
          showSuccessMsg("Nutzer wurde efolgreich bearbeitet.");
          resetValues();
          closeModal();
        }
        setOpenAlert(true);
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler beim Bearbeiten des Nutzers.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const onFileChange = (event) => {
    setOpenAlert(false);
    if (event.target.files.length <= 0) return;

    if (!event.target.files[0].type.includes("image")) {
      setErrorMessage("Please upload image");
      setOpenAlert(true);
      return;
    }
    setSelectedImageUrl(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  return (
    <Modal
      title={memberEditId ? "Bearbeiten" : "Mitarbeitende hinzufügen"}
      open={open}
      closeModal={() => {
        resetValues();
        closeModal();
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members articles-modal"
    >
      {errorMessage && (
        <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
          {errorMessage}
        </SnackbarError>
      )}
      <div className="article-modal__content pr-2">
        <form
          action={
            memberEditId
              ? `${config.apiURL}/api/User/${memberEditId}`
              : `${config.apiURL}/api/Account/register`
          }
          method={memberEditId ? "put" : "post"}
        >
          {memberEditId && (
            <div>
              {!selectedFile && !imageSrc && (
                <label
                  htmlFor="file-upload"
                  className="custom-file-upload member-edit__image--upload"
                >
                  <Upload />
                </label>
              )}
              {selectedFile && selectedImageUrl && (
                <img
                  style={{
                    width: "auto",
                    maxWidth:"100%",
                    height: "14rem",
                    objectFit: "contain",
                  }}
                  src={selectedImageUrl}
                  alt="profile-pic"
                />
              )}
              {!selectedFile && imageSrc && (
                <img
                  style={{
                    width: "auto",
                    maxWidth:"100%",
                    height: "14rem",
                    objectFit: "contain",
                  }}
                  src={imageSrc}
                  alt="profile-pic"
                />
              )}
              <label
                className="edit-upload-file__label-text"
                htmlFor="file-upload"
              >
                Profilbild hochladen
              </label>
              <input
                id="file-upload"
                className="article-modal__file-upload"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
            </div>
          )}
          <input
            className="memberm-modal-input"
            type="text"
            placeholder={t("Vorname")}
            value={memberName ? memberName : ""}
            onChange={({ target }) => setMemberName(target.value)}
          />
          <input
            className="memberm-modal-input"
            type="text"
            placeholder={t("Nachname")}
            value={memberLastName ? memberLastName : ""}
            onChange={({ target }) => setMemberLastName(target.value)}
          />
          <input
            className="memberm-modal-input"
            type="email"
            placeholder="E-mail"
            value={memberEmail ? memberEmail : ""}
            onChange={({ target }) => setMemberEmail(target.value)}
          />
          {memberEditId && (
            <input
              className="memberm-modal-input"
              type="text"
              placeholder="Telefonnummer"
              value={memberTel ? memberTel : ""}
              onChange={({ target }) => isNaN(target.value) ?  setMemberTel("") : setMemberTel(target.value)}
            />
          )}
          <div className="article-modal__content-input">
            <Select
              className="sort-input positions__select-department lefttop"
              placeholder={t("Standort")}
              onChange={(e) => {
                setLocationId(e.value);
                getDepartments(e.value);
                setTeams([]);
                setDepartmentId("");
                setTeamId("");
              }}
              options={locations}
              isSearchable={true}
              value={locations.filter((option) => option.value === locationId)}
            />
            <Select
              className="sort-input positions__select-department righttop"
              placeholder={t("Abteilung")}
              onChange={(e) => {
                setDepartmentId(e?.value ? e.value : null);
                getTeams(e?.value ? e.value : null);
                setTeamId("");
              }}
              options={departments}
              isSearchable={true}
              isClearable={true}
              value={departments.filter(
                (option) => option.value === departmentId
              )}
            />
          </div>
          <div
            className="article-modal__content-input"
            style={{ width: memberEditId ? "100%" : "50%" }}
          >
            <Select
              className="sort-input positions__select-department lefttop"
              placeholder={t("Team")}
              onChange={(e) => {
                setTeamId(e?.value ? e.value : null);
              }}
              options={teams}
              isSearchable={true}
              isClearable={true}
              value={teams.filter((option) => option.value === teamId)}
            />
            {memberEditId && (
              <Select
                className="sort-input positions__select-department righttop"
                placeholder={t("roles")}
                onChange={(e) => {
                  setRoles(e.value);
                }}
                options={roleOptions}
                isSearchable={true}
                value={roleOptions.filter((option) => option.value === roles)}
              />
            )}
          </div>
          <input
            className="memberm-modal-input"
            type="text"
            placeholder="Position"
            value={position ? position : ""}
            onChange={({ target }) => setPosition(target.value)}
          />
          {memberEditId && (
            <>
              <input
                className="memberm-modal-input"
                type="text"
                placeholder="Vorgesetzter"
                value={supervisorName ? supervisorName : ""}
                onChange={({ target }) => setSupervisorName(target.value)}
              />
            </>
          )}
          <div className="departments__modal--buttons">
            <OutlinedButton
              style={{ height: "40px" }}
              onClick={() => {
                resetValues();
                closeModal();
              }}
            >
              {t("Abbrechen")}
            </OutlinedButton>
            <AddButton
              className="departments__modal--add"
              valid={
                memberEditId
                  ? memberName && memberLastName && memberEmail && locationId
                    ? true
                    : false
                  : memberName && memberLastName && memberEmail && locationId
                  ? true
                  : false
              }
              buttonClicked={buttonClicked}
              onClick={memberEditId ? EditMember : AddMember}
            >
              {memberEditId ? t("Bearbeiten") : t("Hinzufügen")}
            </AddButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  locationIds: state.location.allIds,
  locations: state.location.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
  departmentsByLoc: state.location.departments,
  teamIds: state.team.allIds,
  teams: state.team.byId,
  teamsByDepartment: state.department.teams,
  isLoading: state.user.isLoading,
});

export default connect(mapStateToProps, null)(MemberModal);
