import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Error from "../../components/alert/error";
import "../../assets/css/pages/login.scss";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";

import Logo from "../../layout/logo/logo.js";

import { useTranslation } from "react-i18next";
import NewButton from "../../components/buttons/NewButton";
// import NewPopup from '../../components/popupMenu/NewPopup';
import NewLabel from "../../components/label/NewLabel";
import NewInput from "../../components/inputField/NewInput";

const RegisterFirstPage = ({ firstPage, secondPage, values, setValues }) => {
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);
  const history = useHistory();
  const [nameValid, setNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [error, setError] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  /* const  sendEmail=()=> {  axios
    .post(`${config.apiURL}/api/Account/verify-email`,{email : values.email})
    .then (async (res)=>{
      history.push({
        pathname : '/confirm-your-email',
        state : {
          showNotification: true
        }
      })
    })

  }   */
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (
      values.email &&
      values.firstName &&
      values.lastName &&
      values.password &&
      values.confirmPassword
    )
      setValid(true);
    else setValid(false);

    setError(false);
  }, [
    values.email,
    values.firstName,
    values.lastName,
    values.password,
    values.confirmPassword,
  ]);

  useEffect(() => {
    setNameValid(true);
  }, [values.firstName]);

  useEffect(() => {
    setLastNameValid(true);
  }, [values.lastName]);

  useEffect(() => {
    setEmailValid(true);
  }, [values.email]);

  useEffect(() => {
    setPasswordValid(true);
  }, [values.password]);

  useEffect(() => {
    setConfirmPasswordValid(true);
  }, [values.confirmPassword]);

  // const routeChange = () => {
  //   let path = `/VerifyEmail`;
  //   history.push(path);
  // };

  const handleSubmit = async () => {
    let valid = true;

    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      locationId: "61cd8a46633a959038832bd4",
      departmentId: "61cd8a46633a959038832bd4",
      teamId: "61cd8a46633a959038832bd4",
    };
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Account/register`, body)
      .then((res) => {
        if (res.data) setError(false);
        setButtonClicked(true);
        // sendEmail(true);
      })
      .catch((err) => {
        logError(err.response.data.ERROR);
        valid = false;
        // eslint-disable-next-line eqeqeq
        if (err.response.data.ERROR == "Email already exists") setError(true);
        setButtonClicked(false);
      });

    if (values.firstName.length < 3) {
      setNameValid(false);
      valid = false;
    }
    if (values.lastName.length < 3) {
      setLastNameValid(false);
      valid = false;
    }

    if (!/\S+@\S+\.\S+/.test(values.email)) {
      setEmailValid(false);
      valid = false;
    }

    if (values.password.length < 6) {
      setPasswordValid(false);
      valid = false;
    }

    if (values.password !== values.confirmPassword) {
      setConfirmPasswordValid(false);
      valid = false;
    }

    if (valid) {
      firstPage(false);
      localStorage.setItem("email", values.email);
      secondPage(true);
    }
  };

  return (
    <div className="login2">
      <div className="headerp">
        <div>
          <Logo />
        </div>
      </div>
      {error ? (
        <Error
          setOpen={setError}
          style={{ marginTop: "-1.2rem" }}
          className="register__error"
        >
          {t("user_already_exist")}
        </Error>
      ) : null}
      <div className="main2">
        <form className="login2">
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            {t("welcome_message")}
          </h2>
          <div className="f-password"></div>
          <NewLabel name={"Vorname"} />
          <NewInput
            label={t("firstname")}
            value={values.firstName}
            onChange={handleChange("firstName")}
            valid={nameValid}
            errorMessage={"Firstname must be at least 3 characters long."}
            buttonClicked={buttonClicked}
          />
          <NewLabel name={"Nachname"} />
          <NewInput
            label={t("lastname")}
            value={values.lastName}
            onChange={handleChange("lastName")}
            valid={lastNameValid}
            errorMessage={"Lastname must be at least 3 characters long."}
            buttonClicked={buttonClicked}
          />
          <NewLabel name={"Email"} />
          <NewInput
            label="Email-Adresse"
            value={values.email}
            onChange={handleChange("email")}
            valid={emailValid}
            errorMessage={"Please enter a valid email (example@email.com)."}
            buttonClicked={buttonClicked}
          />
          <NewLabel name={"Passwort"} />
          <NewInput
            type={"password"}
            onChange={handleChange("password")}
            value={values.password}
            label={"Password"}
            valid={passwordValid}
            errorMessage={"Your password must be at least 6 characters long."}
            buttonClicked={buttonClicked}
          />
          <NewLabel name={"Passwort bestätigen"} />
          <NewInput
            type={"password"}
            onChange={handleChange("confirmPassword")}
            value={values.confirmPassword}
            label={"Confirm password"}
            valid={confirmPasswordValid}
            errorMessage={"Password confirmation and Password must match."}
            buttonClicked={buttonClicked}
          />
        </form>
      </div>
      <div className="main2" style={{ marginTop: "20px" }}>
        <NewButton
          name={t("Weiter")}
          onClick={handleSubmit}
          className="btn-styles"
          valid={valid}
          buttonClicked={buttonClicked}
        ></NewButton>
      </div>
      <div className="main2">
        <p className="f-linkLabel">
          <span style={{ color: config.colorPrimary }}>
            {t("Konto vorhanden?")}
          </span>
          <Link to="/login"> {t("login")}</Link>
        </p>
      </div>
      {/* <div className="login__left--icons login__left--iconsMobile">
      
      </div> */}
    </div>
  );
};

export default RegisterFirstPage;
