import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import "./filePreview.scss";

const FilePreview = ({
  name,
  onClick,
  details = false,
  customClass = "",
  allowDelete = true,
}) => {
  return (
    <div
      className={`${customClass === "" ? "filePreview" : customClass}`}
      style={{ width: "150px", minHeight: "110px" }}
    >
      {!details && allowDelete ? <HighlightOffIcon onClick={onClick} /> : null}
      {customClass === "" && <p>{name?.split(".")[1]}</p>}
      <label>{name}</label>
    </div>
  );
};

export default FilePreview;
