import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Markup } from "interweave";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Backdrop from "../../components/backdrop/backdrop";
import FilePreview from "../../components/filePreview/filePreview";
import ArticleModal from "./articleModal";
import config from "../../config/api.config.js";
import { getFileType, logError } from "../../helpers/helpers";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import "../../assets/css/pages/articles.scss";
import App from "../../App";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ArticleOverviewCard from "../../../src/components/card/ArticleOverviewCard";
import {
  searchArticles,
  addArticle,
  insertArticleFile,
  getArticleById,
  updateArticle,
} from "../../redux/article/action";
import { searchLocations } from "../../redux/location/action";
import { searchDepartments } from "../../redux/department/action";
import { searchTeams } from "../../redux/team/action";
import { getUserById } from "../../redux/users/action";
import { searchMedias } from "../../redux/media/action";
import MediaDisplayModal from "../Medias/MediaDisplayModal";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import SnackbarError from "../../components/alert/SnackbarError";
import AddButton from "../../components/auth/button";
import auth from "../../auth";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
    width: "450px",
    height: "80vh",
    overflow: "auto",
  },
}));

const ArticleDetails = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [details, setDetails] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [author, setAuthor] = useState("");
  const [files, setFiles] = useState([]);
  const [openArticleModal, setOpenArticleModal] = useState(false);
  const [articleId, setArticleId] = useState(props.match.params.id);
  const [permissions, setPermissions] = useState([]);
  const articleItemsRef = useRef();
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [enablePublish, setEnablePublish] = useState(false);
  const [previewData, setpreviewData] = useState([]);
  const [allowEdit, setAllowEdit] = useState(false);
  const [articleMainImage, setArticleMainImage] = useState(null);

  const GetArticleDetails = async (id = null) => {
    id = id !== null ? id : props.match.params.id;
    setPermissions([]);
    setOpenBackdrop(true);
    let articleDetails = [];
    if (id && props.articleIds.length > 0 && props.articles[id]) {
      articleDetails = props.articles[id];
    } else {
      if (id) {
        await dispatch(getArticleById({ id: id, includeOwner: true }))
          .then((res) => {
            if (res.value?.data) {
              articleDetails = res.value.data;
            }
          })
          .catch((err) => {
            errorMsgHandler("Fehler bei Laden des Artikels.");
          });
      }
    }
    setFiles(articleDetails.files);
    setDetails(articleDetails);
    getAuthor(articleDetails.authorId);
    setOpenBackdrop(false);
    setArticleMainImage(articleDetails.coverImage);
    if (articleDetails?.readable?.length > 0) {
      formatReadables(articleDetails.readable).then((data) => {
        setPermissions(data);
      });
    }
    if (
      (props.currentUser.role === "Admin" ||
        articleDetails.authorId === auth.getId()) &&
      props?.match?.params?.id &&
      props.match.params.id !== "preview"
    ) {
      setAllowEdit(true);
    } else {
      setAllowEdit(false);
    }
  };

  const getPreviewDetails = async () => {
    let articleDetails = props.location?.state?.article
      ? props.location.state.article
      : [];
    let attachments = props.location?.state?.attachments
      ? props.location.state.attachments
      : [];
    setPermissions([]);
    setpreviewData([]);
    setOpenBackdrop(true);
    setAllowEdit(false);

    if (Object.keys(articleDetails).length > 0) {
      setpreviewData(props.location.state);
      setEnablePublish(true);
    } else {
      setpreviewData([]);
      setEnablePublish(false);
    }
    setArticleId(null);
    setFiles(attachments);
    setDetails(articleDetails);
    getAuthor(articleDetails.authorId);
    setOpenBackdrop(false);
    setArticleMainImage(articleDetails.coverImage);
    if (articleDetails?.readable?.length > 0) {
      formatReadables(articleDetails.readable).then((data) => {
        setPermissions(data);
      });
    }
  };

  const getAuthor = async (id) => {
    if (props.members && props.members[id]) {
      setAuthor(props.members[id].firstName + " " + props.members[id].lastName);
    } else {
      await dispatch(getUserById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            setAuthor(res.value.data.firstName + " " + res.value.data.lastName);
          }
        })
        .catch((err) => logError(err));
    }
  };

  const handleDownload = (file) => {
    if (file?.name && props.match.params.id !== "preview") {
      window.open(`${config.resourcesUrl+file.name}`, "_blank");
    }

    if (file && props.match.params.id === "preview") {
      window.open(URL.createObjectURL(file));
    }
  };

  useEffect(async () => {
    const abortController = new AbortController();
    if (!props.currentUser) return;
    if (props.articleIds.length === 0) {
      await dispatch(searchArticles({ Include_owner: true, GetAll: true }));
    }
    if (props.mediaIds.length === 0) {
      dispatch(searchMedias({ GetAll: true }));
    }
    if (props.match.params.id !== "preview") {
      GetArticleDetails();
      setArticleId(props.match.params.id);
    } else {
      getPreviewDetails();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    showActiveCard();
  }, [props.articleIds.length, props.articles]);

  const showActiveCard = () => {
    if (props.articleIds.length > 0 && props.match.params.id) {
      props.articleIds.map((id, index) => {
        if (props.articles[id].id === props.match.params.id) {
          scrollToActiveArticleCard(index);
        }
      });
    }
  };

  const articleItemSelectHandler = (id, index) => {
    history.replace(`/article/${id}`);
    GetArticleDetails(id);
    setArticleId(id);
    scrollToActiveArticleCard(index);
  };

  const scrollToActiveArticleCard = (index) => {
    setTimeout(() => {
      let cardCount = Math.floor(articleItemsRef.current.offsetHeight / 150);
      let cardHeight = cardCount > index ? 0 : (index - cardCount) * 150;
      articleItemsRef.current.scrollTop = cardHeight;
    }, 500);
  };

  const formatReadables = async (readableItems) => {
    const readables = [];
    if (props.locationIds.length === 0) {
      await dispatch(searchLocations({ GetAll: true }));
    }
    if (props.departmentIds.length === 0) {
      await dispatch(searchDepartments({ GetAll: true }));
    }
    if (props.teamIds.length === 0) {
      await dispatch(searchTeams({ GetAll: true }));
    }

    for (const item of readableItems) {
      readables.push({
        location: props.locations[item.location]
          ? props.locations[item.location].name
          : "",
        department: props.departments[item.department]
          ? props.departments[item.department].name
          : "",
        team: props.teams[item.team] ? props.teams[item.team].name : "",
      });
    }
    return readables;
  };

  const handleMediaDisplay = (id) => {
    let media = props.medias[id];
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl+media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  const successMsgHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  const errorMsgHandler = (msg) => {
    setErrorMessage(msg);
    setOpenAlert(true);
  };

  const handleBackBtnClick = () => {
    if (history.action !== "POP") {
      history.goBack();
    } else {
      history.push(`/articles`);
    }
  };

  const AddArticle = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);

    let articleDetails = props.location?.state?.article
      ? props.location.state.article
      : [];
    let attachments = props.location?.state?.attachments
      ? props.location.state.attachments
      : [];
    if (Object.keys(articleDetails).length > 0) {
      await dispatch(
        addArticle({
          title: articleDetails.title,
          readable: articleDetails.readable,
          content: articleDetails.content,
          commentable: articleDetails.commentable,
          rateable: articleDetails.rateable,
          medias: articleDetails.medias,
        })
      )
        .then((res) => {
          let _errMsg = "";
          if (attachments.length > 0) {
            Promise.all([
              attachments?.forEach(async (file) => {
                const formData = new FormData();
                formData.append("File", file);
                return await dispatch(
                  insertArticleFile({
                    articleId: res.value.id,
                    formData: formData,
                  })
                )
                  .then((uploadRes) => {
                    //update cover image
                    if (articleMainImage && articleMainImage === file) {
                      dispatch(
                        updateArticle({
                          articleId: res.value.id,
                          title: articleDetails.title,
                          readable: articleDetails.readable,
                          content: articleDetails.content,
                          coverImage: uploadRes.value?.data,
                        })
                      );
                    }
                  })
                  .catch((err) => {
                    _errMsg = "Fehler bei Bearbeiten des Artikels.";
                  });
              }),
            ])
              .then((res) => {
                setEnablePublish(false);
                setButtonClicked(false);
                setTimeout(() => {
                  if (_errMsg) {
                    errorMsgHandler(_errMsg);
                  } else {
                    successMsgHandler("Artikel erfolgreich hinzugefügt.");
                    setTimeout(() => {
                      history.push(`/articles`);
                    }, 500);
                  }
                }, 1500);
              })
              .catch((err) => {
                logError(err);
                setButtonClicked(false);
              });
          } else {
            setButtonClicked(false);
            successMsgHandler("Artikel erfolgreich hinzugefügt.");
            setTimeout(() => {
              history.push(`/articles`);
            }, 500);
          }
        })
        .catch(function (err) {
          logError(err);
          setButtonClicked(false);
          let errMsg = "Fehler bei Hinzufügen des Artikels.";
          if (err.response?.data?.errors) {
            errMsg =
              err.response.data.errors[
                Object.keys(err.response.data.errors)[0]
              ];
          }
          errorMsgHandler(errMsg);
        });
    }
  };

  const EditPreview = () => {
    if (props.match.params.id === "preview") {
      setOpenArticleModal(true);
      setArticleId(null);
    }
  };

  const closeArticleModal = () => {
    setOpenArticleModal(false);
    if (props.match.params.id !== "preview") {
      GetArticleDetails(articleId);
      showActiveCard();
      setArticleId(null);
    } else if (props.match.params.id === "preview") {
      getPreviewDetails();
      setArticleId(null);
    }
  };

  useEffect(() => {
    if (props.match.params.id === "preview") {
      getPreviewDetails();
    }
  }, [props.location.state]);

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <MediaDisplayModal
        open={displayMedia}
        closeModal={() => {
          setDisplayMedia(false);
          setDisplayUrl("");
          setDisplayMediaName("");
          setDisplayMediaTitle("");
        }}
        mediaUrl={displayUrl}
        title={displayMediaTitle}
        name={displayMediaName}
      />
      <ArticleModal
        open={openArticleModal}
        closeModal={closeArticleModal}
        articleId={articleId}
        type={props.match.params.id === "preview" ? "preview-edit" : ""}
        previewData={previewData}
        showSuccessMsg={successMsgHandler}
        showErrorMsg={errorMsgHandler}
      />
      {openBackdrop ? (
        <Backdrop open={openBackdrop} />
      ) : (
        <div
          className="article-details-wrapper px-2"
          style={{ backgroundColor: "white" }}
        >
          <div className="article-details" style={{ paddingBottom: "20px" }}>
            <div className="article-details__header">
              <div>
                <KeyboardArrowLeftIcon onClick={() => handleBackBtnClick()} />
                <span>{t("Artikel")}</span>
              </div>
              <Breadcrumbs navigations={[`${details?.title}`]} />
              <EditIcon
                className={`${allowEdit ? "" : "disabled"}`}
                onClick={() => {
                  if (allowEdit) {
                    setOpenArticleModal(true);
                    setArticleId(props.match.params.id);
                  }
                }}
              />
            </div>
            <div className="article-details__content">
              <p>
                <span>{t("Author")}:</span> {author}
              </p>
              <p>
                <span>{t("date_created")}:</span>{" "}
                {moment(details?.dateCreated).format(`${t("momentjs_datum")}`)}
              </p>
              <p>
                <span>{t("permissions")}:</span>{" "}
                {permissions.length > 0 &&
                  permissions?.map((p, i) => (
                    <React.Fragment key={i}>
                      <br />
                      {i + 1}. Location: {p.location} / Department:{" "}
                      {p.department} / Team: {p.team}
                      &nbsp;&nbsp;&nbsp;
                    </React.Fragment>
                  ))}
              </p>
              <p>
                <span>{t("article_cover_image")}:</span>{" "}
                {articleMainImage?.originalName ?? articleMainImage?.name}
              </p>
              <div className="article-details__files">
                {files?.map((file) => (
                  <React.Fragment key={file?.name}>
                    {props.match.params.id !== "preview" ? (
                      <div
                        onClick={() => handleDownload(file)}
                        key={file?.name}
                        style={{ cursor: "pointer" }}
                      >
                        <FilePreview
                          key={file?.name}
                          name={file?.originalName}
                          onClick={() => {}}
                          details={true}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => handleDownload(file)}
                        style={{ cursor: "pointer" }}
                      >
                        <FilePreview
                          key={file?.name}
                          name={file?.name}
                          onClick={() => {}}
                          details={true}
                        />
                      </div>
                    )}
                  </React.Fragment>
                ))}

                {details?.medias?.map((mediaId) => {
                  return (
                    <React.Fragment key={mediaId}>
                      {props.medias[mediaId] && (
                        <div
                          onClick={() => handleMediaDisplay(mediaId)}
                          style={{ cursor: "pointer" }}
                        >
                          <FilePreview
                            name={
                              props.medias[mediaId].title +
                              "--" +
                              props.medias[mediaId].name
                            }
                            onClick={() => {}}
                            details={true}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="article-markup">
                <Markup content={details?.content} />
              </div>
            </div>
            {props.match.params.id === "preview" && (
              <AddButton
                className="departments__modal--add publish-article"
                valid={enablePublish}
                buttonClicked={buttonClicked}
                onClick={AddArticle}
              >
                {t("publish")}
              </AddButton>
            )}
            {props.match.params.id === "preview" && (
              <AddButton
                className="departments__modal--add publish-article"
                valid={true}
                onClick={EditPreview}
              >
                Bearbeiten
              </AddButton>
            )}

            {props.match.params.id === "preview" && (
              <AddButton
                className="departments__modal--add delete-article-preview"
                valid={true}
                onClick={() => {
                  history.push(`/articles`);
                }}
              >
                {t("Abbrechen")}
              </AddButton>
            )}
          </div>
          {props.match?.params?.id !== "preview" && (
            <div className="articles-scrollable-sidebar">
              <div className="article-card-list__header">
                <h3
                  style={{
                    paddingLeft: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Artikel
                </h3>
              </div>
              <Paper
                className={classes.root}
                elevation={0}
                ref={articleItemsRef}
                style={{
                  borderRadius: "0",
                  backgroundColor: "rbg(0,0,0)",
                }}
              >
                <List className="p-2 bg-inherit">
                  {props.articleIds.length > 0 &&
                    props.articleIds?.map((id, index) => (
                      <li key={index} className="mb-2">
                        <ArticleOverviewCard
                          key={index}
                          index={index}
                          id={id}
                          customClass={
                            props.location.pathname === `/article/${id}`
                              ? "sidebar-article-wrapper active-article"
                              : "sidebar-article-wrapper"
                          }
                          title={props.articles[id].title}
                          authorId={props.articles[id].authorId}
                          author={author}
                          content={props.articles[id].content}
                          hasMarkUp={true}
                          onClick={articleItemSelectHandler}
                        />
                      </li>
                    ))}
                </List>
              </Paper>
            </div>
          )}
        </div>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  articleIds: state.article.allIds,
  articles: state.article.byId,
  locationIds: state.location.allIds,
  locations: state.location.byId,
  departmentIds: state.department.allIds,
  departments: state.department.byId,
  teamIds: state.team.allIds,
  teams: state.team.byId,
  isLoading: state.article.isLoading,
  memberIds: state.user.allIds,
  members: state.user.byId,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
});

export default connect(mapStateToProps, null)(ArticleDetails);
