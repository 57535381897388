import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArticleModal from '../Articles/articleModal';
import NothingFound from '../../components/searchNothingFound/searchNothingFound';
import Pagination from '../../components/pagination/pagination';
import { GreenCheckbox } from '../../components/checkbox/checkbox';

import config from '../../config/api.config.js';
import { logError } from "../../helpers/helpers";

import { useTranslation } from 'react-i18next'

const SecondStep = (props) => {
  const { t } = useTranslation()
  const {
    currentUser,
    selectedArticles,
    setSelectedArticles,
    editArticles,
    setEditArticles,
    courseId
  } = props;

  const [openArticleModal, setOpenArticleModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [params, setParams] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 5,
    totalCount: 0,
    hasNext: false,
    hasPrevious: false
  });
  const [articles, setArticles] = useState(null);

  const getArticles = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Article`, {
        params: {
          WorkspaceId: currentUser.workspaceId,
          PageSize: 3,
          ...params
        }
      })
      .then((res) => {
        const {
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious
        } = res.data;
        setParams({
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious
        });
        let articleNo = currentPage * 3 - 3;
        setArticles(
          res.data.articles.map((dep, i) => {
            return {
              no: ++articleNo,
              id: dep.id,
              name: dep.title,
              categories: dep.categories
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };
  const getPageNumber = (newPage) => {
    getArticles({
      PageNumber: newPage
    });
  };

  const handleChecked = async (event, id) => {
    if (!event.target.checked && editArticles.includes(id)) {
      setEditArticles(editArticles.filter((a) => a !== id));
      await axios
        .put(`${config.apiURL}/api/Course/delete-article/${courseId}`, {
          articleId: id
        })
        .then((res) => {})
        .catch((err) => logError(err));
      return;
    }

    if (event.target.checked) setSelectedArticles([...selectedArticles, id]);
    else setSelectedArticles(selectedArticles.filter((a) => a !== id));
  };
  useEffect(() => {
    const abortController = new AbortController();

    if (!currentUser) return;
    getArticles();

    return () => {
      abortController.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="add-course__second-step">
      <ArticleModal
        open={openArticleModal}
        closeModal={() => {
          setOpenArticleModal(false);
        }}
        getArticles={getArticles}
        articleId={null}
      />
      <div className="add-course__second-step--header">
        <p>{t('select_or_add')}</p>
        <Button
          className="departments__add-new--button"
          onClick={() => setOpenArticleModal(true)}
        >
          <AddIcon /> {t('add_new')}
        </Button>
      </div>
      <div></div>
      <div className="add-course__second-step--content">
        <TableContainer className="departments__table">
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{t('Category')}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ textAlign: 'center', paddingTop: '5rem' }}
                  >
                    <div>
                      <CircularProgress style={{ color: config.colorPrimary }} />
                    </div>
                  </TableCell>
                </TableRow>
              ) : !loading && !articles?.length ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NothingFound>
                        {t('nothing_found_article')}
                    </NothingFound>
                  </TableCell>
                </TableRow>
              ) : (
                articles?.map((row) => (
                  <TableRow key={row.id} className="departments__table-row">
                    <TableCell component="th" scope="row">
                      {row.no + '.'}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {row.categories
                        .map((cat, i) => (i < 2 ? cat.name : '...'))
                        .join(', ')}
                    </TableCell>
                    <TableCell align="center">
                      <GreenCheckbox
                        checked={
                          selectedArticles.includes(row.id) ||
                          editArticles.includes(row.id)
                            ? true
                            : false
                        }
                        onChange={(e) => handleChecked(e, row.id)}
                        name="checkedG"
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!articles?.length ? null : (
          <Pagination
            params={params}
            getPageNumber={getPageNumber}
            defaultPage={false}
          />
        )}
      </div>
    </div>
  );
};

export default SecondStep;
