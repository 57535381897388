import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@mui/material/Button";
import "./ProfilePicUploadModal.scss";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { uploadProfilePic } from "../../redux/users/action";
import SnackbarError from "../../components/alert/SnackbarError";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";

const ProfilePicUploadModal = (props) => {
  const dispatch = useDispatch();
  const { open, closeModal, userId, profileImg } = props;

  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    closeModal();
  };

  const uploadHandler = async (event) => {
    setOpenAlert(false);
    if (event.target.files.length <= 0) return;

    if (!event.target.files[0].type.includes("image")) {
      setErrorMessage("Please upload image");
      setOpenAlert(true);
      return;
    }
    // let url = URL.createObjectURL(event.target.files[0])
    await getProfileImageUrl(event.target.files[0]);
  };

  const getProfileImageUrl = async (file) => {
    let imageUrl = "";
    const formData = new FormData();
    formData.append("File", file);
    await dispatch(uploadProfilePic({ formData: formData, userId: userId }))
      .then((res) => {
        if (res.value?.data?.id) {
          imageUrl = `${config.resourcesUrl+res.value.data.name}`;
        }
      })
      .catch(function (err) {
        logError(err);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className="upload-profile-modal"
      >
        <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
          {errorMessage}
        </SnackbarError>
        <DialogActions>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogTitle id="dialog-title" className="text-center">
          {"Profilbild ändern"}
        </DialogTitle>
        <DialogContent id="dialog-description" className="text-center">
          <img
            src={profileImg}
            alt="user"
            style={{
              borderRadius: 70,
              height: 120,
              width: 120,
              objectFit: "cover",
            }}
          />
          <label htmlFor="contained-button-file" style={{ display: "block" }}>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={uploadHandler}
            />
            <Button
              variant="contained"
              component="span"
              className="departments__add-new--button upload-btn"
            >
              Bild hochladen
            </Button>
          </label>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(ProfilePicUploadModal);
