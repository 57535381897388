/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import NewButton from "../../components/buttons/NewButton.js";
import Logo from "../../layout/logo/logo.js";
import PersonalEmail from "../../assets/images/personalEmail.png";
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const ForgotPasswordSecondPage = ({ email }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleClick = () => {
    history.push("/login");
  };

  const ResendPassword = async () => {
    await axios
      .post(`${config.apiURL}/api/Account/forgot-password`, { email })
      .then((res) => {
        setOpenAlert(true);
      })
      .catch((err) => logError(err));
  };

  return (
    <div className="login2">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          style={{ borderRadius: "25px", width: "435px", height: "56px" }}
        >
          {t("Erfolgreich versendet")}
        </Alert>
      </Snackbar>
      <div className="headerp">
        <Logo />
      </div>
      <div className="main2">
        {" "}
        <h2
          style={{
            marginTop: "0",
            marginBottom: "3.5rem",
            textAlign: "center",
          }}
        >
          {t("Link wurde an deine Email versendet")}
        </h2>
        <div
          className="main2"
          style={{ width: "24.875rem", height: "20.438rem" }}
        >
          <img src={PersonalEmail} alt="personal-email-pic" />
        </div>
        <p style={{ fontSize: "18px", marginTop: "80px" }}>
          <span style={{ marginTop: "20px" }}>
            {t("Keinen Link erhalten? ")}
          </span>
          <Link onClick={ResendPassword}>{t("Noch mal versenden")}</Link>
        </p>
        <NewButton
          name={t("Zurück zur Anmeldung")}
          onClick={handleClick}
          className="login__button forgotPassword__secondPage--button"
          valid={true}
        ></NewButton>
        {/* <p className="f-linkLabel">
        <span style={{color: config.colorPrimary}}>{t('any_account')}</span>
        <Link to="/register">{t('signup')}</Link>
      </p> */}
      </div>
    </div>
  );
};

export default ForgotPasswordSecondPage;
