import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowDropup } from "react-icons/io";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Avatar from "@material-ui/core/Avatar";
import { Button } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import config from "../../config/api.config.js";
import auth from "../../auth";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./popupMenu.scss";
import ProfilePicUploadModal from "../../pages/Members/ProfilePicUploadModal";

const PopupMenu = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [_user, _setUser] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [profileImg, setProfileImg] = useState("");

  useEffect(() => {
    _setUser(props.currentUser);
    const _userId = auth.getId();
    setUserId(_userId);
  }, [props.currentUser]);

  useEffect(() => {
    refreshProfileImg();
  }, [props.members]);

  const refreshProfileImg = () => {
    if (props.members && props.members[auth.getId()]) {
      if (props.members[auth.getId()]?.image) {
        let imgUrl = `${config.resourcesUrl+props.members[auth.getId()].image.name}`;
        setProfileImg(imgUrl);
      } else {
        setProfileImg("");
      }
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const closeUploadModalHandler = () => {
    setOpenUploadModal(false);
    refreshProfileImg();
  };

  return (
    <Fragment>
      <ProfilePicUploadModal
        open={openUploadModal}
        closeModal={closeUploadModalHandler}
        userId={userId}
        profileImg={profileImg ? profileImg : "/profile.jpg"}
      />
      <div ref={anchorRef} onClick={() => setOpen(!open)}>
        <Avatar variant="rounded" className="popup-menu__avatar">
          <img
            src={profileImg ? profileImg : "/profile.jpg"}
            /* { _user?.user.image} */ alt="profile-pic"
          />
        </Avatar>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="popup-menu__popper"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "0 0 0" }}>
            <div className="popup-menu">
              <IoMdArrowDropup />
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="popup-menu__content">
                  <Avatar variant="rounded" className="popup-menu__img">
                    <img
                      src={profileImg ? profileImg : "/profile.jpg"}
                      /* {_user?.user.image} */ alt="profile-pic"
                    />
                  </Avatar>
                  <div className="popup-menu__user">
                    <p>{_user?.firstName + " " + _user?.lastName}</p>
                    {/* <p>{_user?.email}</p> */}
                    {/* <p>{auth.getType() ?? ''}</p> */}
                    {/* <p>{_user?.role}</p> */}
                  </div>
                  <a
                    className="popup-menu__settings"
                    style={{ color: config.colorSecondary }}
                    href="#"
                    onClick={() => {
                      setOpen(false);
                      setOpenUploadModal(true);
                    }}
                  >
                    {t("Einstellungen")}
                  </a>
                  <Button
                    variant="outlined"
                    className="popup-menu__content-button"
                    onClick={() => {
                      auth.logout(() => {
                        history.push("/login");
                      });
                    }}
                  >
                    {t("Abmelden")}
                  </Button>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(PopupMenu);
