import config from "../config/api.config.js";

export function getFileType(fileName) {
  let extension = getFileExtension(fileName);
  let fileType = "";
  if (config.allowedImageExtensions.includes(extension)) {
    fileType = "Image";
  } else if (config.allowedDocumentExtensions.includes(extension)) {
    fileType = "Document";
  } else if (config.allowedVideoExtensions.includes(extension)) {
    fileType = "Video";
  } else if (config.allowedAudioExtensions.includes(extension)) {
    fileType = "Audio";
  }
  return fileType;
}

export function getFileExtension(filename) {
  return filename.split(".").pop();
}

export function logError(msg) {
  if (config.consoleActivated) {
    console.error(msg);
  }
}

export function handleValidateEmail(email) {
  const EMAIL_REGEX = new RegExp(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return String(email).toLowerCase().match(EMAIL_REGEX);
}
