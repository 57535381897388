import React, { useEffect, useState } from 'react';
import axios from 'axios';

// import { withStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Checkbox from '@material-ui/core/Checkbox';

// import Pagination from '../../components/pagination/pagination';
import NothingFound from '../../components/searchNothingFound/searchNothingFound';

// import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import config from '../../config/api.config.js';
import { logError } from "../../helpers/helpers";

import { useTranslation } from 'react-i18next'

// const GreenCheckbox = withStyles({
//   root: {
//     'color': green[400],
//     '&$checked': {
//       color: green[600]
//     }
//   },
//   checked: {}
// })((props) => <Checkbox color="default" {...props} />);

const FourthStep = (props) => {
  const { t } = useTranslation()
  const {
    // currentUser,
    selectedArticles,
    // setSelectedArticles,
    editArticles,
    videos,
    // setVideos,
    editVideos,
    // setEditVideos
  } = props;
  const [loading, setLoading] = useState(false);

  // const [params, setParams] = useState({
  //   currentPage: 1,
  //   totalPages: 1,
  //   pageSize: 5,
  //   totalCount: 0,
  //   hasNext: false,
  //   hasPrevious: false
  // });
  const [articles, setArticles] = useState([]);

  const getArticles = async (params) => {
    setLoading(true);
    let articleNo = 0;
    const articleTemp = [];
    [...selectedArticles, ...editArticles].forEach(async (a) => {
      await axios
        .get(`${config.apiURL}/api/Article/${a}`)
        .then((res) => {
          articleTemp.push({
            no: ++articleNo,
            id: res.data.id,
            name: res.data.title,
            categories: res.data.categories
          });
        })
        .catch((err) => {
          setLoading(false);
          logError(err);
        });
      setArticles([...articles, articleTemp]);
    });
    setLoading(false);
    // await axios
    //   .get(`${config.apiURL}/api/Article`, {
    //     params: {
    //       WorkspaceId: currentUser.workspaceId,
    //       PageSize: 3,
    //       ...params
    //     }
    //   })
    //   .then((res) => {
    //     const {
    //       currentPage,
    //       totalPages,
    //       pageSize,
    //       totalCount,
    //       hasNext,
    //       hasPrevious
    //     } = res.data;
    //     setParams({
    //       currentPage,
    //       totalPages,
    //       pageSize,
    //       totalCount,
    //       hasNext,
    //       hasPrevious
    //     });
    //     let articleNo = currentPage * 3 - 3;
    //     setArticles(
    //       res.data.articles.map((dep, i) => {
    //         return {
    //           no: ++articleNo,
    //           id: dep.id,
    //           name: dep.title,
    //           categories: dep.categories
    //         };
    //       })
    //     );

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     logError(err);
    //   });
  };

  // const getPageNumber = (newPage) => {
  //   getArticles({
  //     PageNumber: newPage
  //   });
  // };

  // const handleChecked = (event, id) => {
  //   if (event.target.checked) setSelectedArticles([...selectedArticles, id]);
  //   else setSelectedArticles(selectedArticles.filter((a) => a !== id));
  // };

  // const deleteVideo = (file) => {
  //   const temp = videos.filter((f) => f.name != file.name);
  //   setVideos(temp);
  // };

  // useEffect(() => {
  //   const abortController = new AbortController();

  //   if (!currentUser) return;
  //   getArticles();

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [currentUser]);

  useEffect(() => {
    getArticles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="add-course__fourth-step">
      <div className="add-course__second-step--header">
        <p>{t('course_content')}</p>
      </div>
      <div className="add-course__second-step--content add-course__third-step--content">
        <TableContainer className="departments__table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('No')}.</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{t('Category')}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ textAlign: 'center', paddingTop: '5rem' }}
                  >
                    <div>
                      <CircularProgress style={{ color: config.colorPrimary }} />
                    </div>
                  </TableCell>
                </TableRow>
              ) : !loading && !articles[0]?.length ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NothingFound>
                        {t('nothing_found_article')}
                    </NothingFound>
                  </TableCell>
                </TableRow>
              ) : (
                articles[0]?.map((row) => (
                  <TableRow key={row.id} className="departments__table-row">
                    <TableCell component="th" scope="row">
                      {row.no + '.'}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {row.categories
                        .map((cat, i) => (i < 2 ? cat.name : '...'))
                        .join(', ')}
                    </TableCell>
                    {/* <TableCell align="center">
                      <GreenCheckbox
                        checked={
                          selectedArticles.includes(row.id) ||
                          editArticles.includes(row.id)
                            ? true
                            : false
                        }
                        // onChange={(e) => handleChecked(e, row.id)}
                        name="checkedG"
                      />
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!articles?.length ? null : (
          <Pagination
            params={params}
            getPageNumber={getPageNumber}
            defaultPage={false}
          />
        )} */}
      </div>
      <div className="add-course__second-step--content add-course__third-step--content">
        <TableContainer className="departments__table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('No')}.</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!videos?.length && !editVideos?.length ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <NothingFound>
                      {t('nothing_found_video')}
                    </NothingFound>
                  </TableCell>
                </TableRow>
              ) : null}
              {videos?.map((row, i) => (
                <TableRow key={row.name} className="departments__table-row">
                  <TableCell component="th" scope="row">
                    {++i + '.'}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>

                  {/* <TableCell align="center">
                      <div>
                        <DeleteIcon onClick={() => deleteVideo(row)} />
                      </div>
                    </TableCell> */}
                </TableRow>
              ))}

              {editVideos?.map((row, i) => (
                <TableRow key={row.name} className="departments__table-row">
                  <TableCell component="th" scope="row">
                    {++i + '.'}
                  </TableCell>
                  <TableCell align="left">{row.originalName}</TableCell>

                  {/* <TableCell align="center">
                      <div>
                        <DeleteIcon onClick={() => deleteEditVideo(row)} />
                      </div>
                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default FourthStep;
