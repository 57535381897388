import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import { useTranslation } from "react-i18next";
import App from "../../App";
import auth from "../../auth";
import { logError } from "../../helpers/helpers";
import { searchUsers } from "../../redux/users/action";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import SnackbarError from "../../components/alert/SnackbarError";
import CreateProcess from "./CreateProcess";
import AssignTasks from "./AssignTasks";
import AssignUsers from "./AssignUsers";
import {
  addProcess,
  uploadProcessImage,
  updateProcess,
  getProcessById,
} from "../../redux/process/action";

const AddProcess = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    processName: "",
    processDes: "",
    processRes: "",
    processTasks: [],
  });
  const [processImg, setProcessImg] = useState(null);
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    search: { value: null, label: "All" },
    add: "",
  });
  const [buttonClicked, setButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [membersData, setMembersData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState(null);
  const steps = ["create", "assignTask", "assignUser"];
  const [allowUserToEdit, setAllowUserToEdit] = useState(false);

  const processInfoHandler = () => {
    if (
      props.location.state?.type === "create" ||
      props.location.state?.type === "useTemplate"
    ) {
      processCreateHandler();
    }
    if (props.location.state?.type === "edit") {
      processUpdateHandler();
    }
  };

  const processUpdateHandler = async () => {
    let id = props.match.params.id;
    const state = { ...history.location.state };
    state.id = id;
    state.step = "assignTask";
    if (id) {
      if (
        values.processName === props.processes[id].name &&
        values.processDes === props.processes[id].description &&
        values.processRes.add.value === props.processes[id].responsible &&
        !selectedFile
      ) {
        state.process = {
          id: id,
          name: props.processes[id].name,
          tasks: props.processes[id].tasks,
        };
        history.replace({ ...history.location, state });
      } else {
        let reqData = {
          processId: id,
          name: values.processName,
          tasks: props.processes[id].tasks,
          description: values.processDes,
          responsible: values.processRes.add.value,
          isTemplate: props.processes[id].isTemplate,
        };
        await EditProcess(reqData).then(() => {
          setTitle(values.processName);
          state.process = {
            id: id,
            name: values.processName,
            tasks: props.processes[id].tasks,
          };
          history.replace({ ...history.location, state });
        });
      }
    }
    setButtonClicked(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const BackToAssignTaskHandler = () => {
    const state = { ...history.location.state };
    state.step = "assignTask";
    const id = state.process.id;
    state.process = {
      id: state.id,
      name: props.processes[id].name,
      tasks: props.processes[id].tasks,
    };
    history.replace({ ...history.location, state });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const processCreateHandler = async () => {
    let obj = {
      name: values.processName,
      description: values.processDes,
      responsible: values.processRes.add.value,
      creator: auth.getId(),
      tasks: [],
      image: processImg,
    };

    await setButtonClicked(true);
    await createNewProcess(obj);
    setButtonClicked(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const createNewProcess = async (reqData, type = null) => {
    let _processTasks = [];
    if (type === "saveAsTemplate") {
      _processTasks = reqData.tasks;
    } else {
      _processTasks = values.processTasks;
    }

    let obj = { ...reqData, tasks: [] };
    await dispatch(addProcess(obj))
      .then(async (res) => {
        if (res.value) {
          setTitle(res.value.name);
          if (selectedFile) {
            const formData = new FormData();
            formData.append("File", selectedFile);
            await dispatch(
              uploadProcessImage({
                formData: formData,
                processId: res.value.id,
              })
            ).catch(function (err) {
              logError(err);
            });
          }
          const state = { ...history.location.state };
          if (!type) {
            state.id = res.value.id;
            state.step = "assignTask";
          }

          if (_processTasks.length > 0) {
            let _tasks = [];
            for (const item of _processTasks) {
              item.processId = res.value.id;
              item.dateCreated = new Date();
              _tasks.push(item);
            }

            let updateReq = {
              processId: res.value.id,
              name: res.value.name,
              description: res.value.description,
              responsible: res.value.responsible,
              tasks: _tasks,
            };

            if (type === "saveAsTemplate") {
              updateReq.isTemplate = true;
            }

            await dispatch(updateProcess(updateReq)).then((updateRes) => {
              if (!type) {
                state.process = {
                  id: res.value.id,
                  name: res.value.name,
                  tasks: _tasks,
                };
              }
            });
          } else {
            if (!type) {
              state.process = {
                id: res.value.id,
                name: res.value.name,
                tasks: res.value.tasks,
              };
            }
          }
          if (!type) {
            history.replace({ ...history.location, state });
          }
        }
        if (type === "saveAsTemplate") {
          successMsgHandler("Process created as Master");
          history.push(`/master-processes`);
        } else {
          successMsgHandler("Prozess wurde erfolgreich erstell.");
        }
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeitung des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        errorMsgHandler(errMsg);
      });
  };

  const assignUserHandler = () => {
    const state = { ...history.location.state };
    state.step = "assignUser";
    state.assignUser = {
      process: state.id,
      processName: props.processes[state.id].name,
      processTasks: props.processes[state.id].tasks,
      des: props.processes[state.id].description,
      responsible: props.processes[state.id].responsible,
      membersData: membersData,
    };
    history.replace({ ...history.location, state });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const saveAsTemplateHandler = async () => {
    const state = { ...history.location.state };
    let reqData = {
      name: props.processes[state.id].name,
      tasks: props.processes[state.id].tasks,
      description: props.processes[state.id].description,
      responsible: props.processes[state.id].responsible,
      isTemplate: true,
    };
    if (props.location.state?.type === "edit") {
      reqData.image = props.processes[state.id].image;
      await createNewProcess(reqData, "saveAsTemplate");
    } else {
      reqData.processId = state.id;
      await EditProcess(reqData, "saveAsTemplate");
    }
  };

  const EditProcess = async (reqData, type = null) => {
    setOpenAlert(false);
    await setButtonClicked(true);
    await dispatch(updateProcess(reqData))
      .then(async () => {
        setButtonClicked(false);
        setTitle(reqData.name);
        if (type == "saveAsTemplate") {
          successMsgHandler("Process saved as Master");
          history.push(`/master-processes`);
        } else {
          if (selectedFile) {
            const formData = new FormData();
            formData.append("File", selectedFile);
            await dispatch(
              uploadProcessImage({
                formData: formData,
                processId: reqData.processId,
              })
            ).catch(function (err) {
              logError(err);
            });
          }
          successMsgHandler("Prozess wurde erfolgreich bearbeitet.");
        }
      })
      .catch((err) => {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeiten des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        errorMsgHandler(errMsg);
      });
  };

  const getStepContent = (stepIndex, user) => {
    switch (stepIndex) {
      case 0:
        return (
          <CreateProcess
            currentUser={user}
            values={values}
            setValues={setValues}
            processImg={processImg}
            setProcessImg={setProcessImg}
            members={members}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onClick={processInfoHandler}
            showSuccessMsg={successMsgHandler}
            showErrorMsg={errorMsgHandler}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            allowUserToEdit={allowUserToEdit}
          />
        );
      case 1:
        return (
          <AssignTasks
            currentUser={user}
            assignUserHandler={assignUserHandler}
            saveAsTemplateHandler={saveAsTemplateHandler}
            showSuccessMsg={successMsgHandler}
            showErrorMsg={errorMsgHandler}
          />
        );
      case 2:
        return (
          <AssignUsers
            currentUser={user}
            showSuccessMsg={successMsgHandler}
            showErrorMsg={errorMsgHandler}
            setOpenAlert={setOpenAlert}
            BackToAssignTask={BackToAssignTaskHandler}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  useEffect(() => {
    async function fetchData() {
      const abortController = new AbortController();
      if (props.allMemberIds.length === 0) {
        await dispatch(searchUsers({ GetAll: true }));
      }

      if (!props.location.state?.type) {
        history.push(`/processes`);
      }
      return () => {
        abortController.abort();
      };
    }
    fetchData();
  }, []);

  useEffect(() => {
    const _members = [];
    const _memberTasks = [];
    if (props.allMemberIds.length > 0) {
      props.allMemberIds.forEach((id) => {
        _members.push({
          value: props.members[id].userId,
          label: props.members[id].firstName + " " + props.members[id].lastName,
        });
        _memberTasks.push(props.members[id].tasks);
      });
      setMembersData(_memberTasks);
      setMembers(_members);
    } else {
      setMembers([]);
    }

    if (props.location.state?.type === "edit" && props.match.params.id) {
      getProcess(props.match.params.id).then((process) => {
        if (process) {
          let temp = _members.find(
            (item) => item.value === process.responsible
          );
          if (temp) {
            setSelectedUser({ add: temp });
          }

          setValues((prevState) => {
            return {
              ...prevState,
              processName: process.name,
              processDes: process.description,
              processRes: { add: temp },
            };
          });
          setProcessImg(process.image);
          setTitle(process.name);
          if (
            process.responsible === auth.getId() ||
            process.creator === auth.getId() ||
            props.currentUser?.role === "Admin" ||
            process.isTemplate
          ) {
            setAllowUserToEdit(true);
          } else {
            setAllowUserToEdit(false);
          }
        }
      });
    }
    if (props.location.state?.type === "create") {
      setTitle("Create New Process");
      setAllowUserToEdit(true);
    }
    if (props.location.state?.type === "useTemplate") {
      let process = props.location.state?.templateData;
      if (process) {
        setValues((prevState) => {
          return {
            ...prevState,
            processName: process.name,
            processDes: process.description,
            // processRes: { add: temp}
            processTasks: process.tasks,
          };
        });
        setProcessImg(process.image);
        setTitle(process.name);
      }
      setAllowUserToEdit(true);
    }
  }, [props.allMemberIds.length]);

  useEffect(() => {
    if (
      props.location.state?.step === "createProcess" ||
      props.location.state?.step === "editProcess" ||
      props.location.state?.step === "useTemplate"
    ) {
      setActiveStep(0);
    } else if (props.location.state?.step === "assignTask") {
      setActiveStep(1);
    } else if (props.location.state?.step === "assignUser") {
      setActiveStep(2);
    }
  }, []);

  const successMsgHandler = (msg) => {
    setSuccessMessage(msg);
    setOpenAlert(true);
  };

  const errorMsgHandler = (msg) => {
    setErrorMessage(msg);
    setOpenAlert(true);
  };

  const getProcess = async (id) => {
    let _process = [];
    if (props.processes && props.processes[id]) {
      _process = props.processes[id];
    } else {
      await dispatch(getProcessById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            _process = res.value.data;
          }
        })
        .catch((err) => logError(err));
    }

    return _process;
  };

  return (
    <App>
      <div className="add-course">
        {props.location.state?.step !== "assignTask" ? (
          <Breadcrumbs navigations={[`Nutzer zuweisen | ${title}`]} />
        ) : (
          <Breadcrumbs navigations={[`Aufgaben zuweisen | ${title}`]} />
        )}
        <div>
          {errorMessage && (
            <SnackbarError
              open={openAlert}
              onClose={() => {
                setOpenAlert(false);
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </SnackbarError>
          )}
          {successMessage && (
            <SnackbarSuccess
              open={openAlert}
              onClose={() => {
                setOpenAlert(false);
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </SnackbarSuccess>
          )}
          <div style={{ marginTop: "2rem" }}>
            {activeStep === steps.length ? null : (
              <>{getStepContent(activeStep, props.currentUser)}</>
            )}
          </div>
        </div>
      </div>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  allProcessIds: state.process.allIds,
  processes: state.process.byId,
  processIds: state.process.searchIds,
  allMemberIds: state.user.allIds,
  members: state.user.byId,
  memberIds: state.user.searchIds,
});

export default connect(mapStateToProps, null)(AddProcess);
