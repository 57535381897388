import React, { Fragment, useState } from "react";
import { Checkbox } from "@material-ui/core";
import InputField from "../../components/inputField/inputField";
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

const QuestionBox = (props) => {
    // const { t } = useTranslation();
    const [buttonClicked] = useState(false);
    const [check1BOX, setCheck1BOX] = useState(false);
    const [check2BOX, setCheck2BOX] = useState(false);
    const [check3BOX, setCheck3BOX] = useState(false);
    const [check4BOX, setCheck4BOX] = useState(false);
    


 
    const handlecheckBOX1 = (x)=>{

      if(check1BOX===false){
        setCheck1BOX(true);
        setCheck2BOX(false);
        setCheck3BOX(false);
        setCheck4BOX(false);
      }else{
        setCheck1BOX(false);
      }
    
    }
    const handlecheckBOX2 = (x)=>{
      if(check2BOX===false){
        setCheck1BOX(false);
        setCheck2BOX(true);
        setCheck3BOX(false);
        setCheck4BOX(false);
      }else{
        setCheck2BOX(false);
      }
    
    }
    const handlecheckBOX3 = (x)=>{
      if(check3BOX===false){
        setCheck1BOX(false);
        setCheck2BOX(false);
        setCheck3BOX(true);
        setCheck4BOX(false);
      }else{
        setCheck3BOX(false);
      }
    
    }
    const handlecheckBOX4 = (x)=>{
      if(check4BOX===false){
        setCheck1BOX(false);
        setCheck2BOX(false);
        setCheck3BOX(false);
        setCheck4BOX(true);
      }else{
        setCheck4BOX(false);
      }
    
    }
  
  


 

    return(
        <Fragment>
        <div className='article-modal__content-input customq'>  
        <InputField 
          label="Question" 
        
          buttonClicked={buttonClicked}
        />
        {
          props.data!=='no' ?
          <DeleteIcon style={{marginTop:'25px',cursor:'pointer'}} onClick={props.deleteHandeler}/>
          :
          <></>
        }
        
        </div>
        <div className="article-modal__content-input">
        
        <InputField
          label="Answer"
         
        
          buttonClicked={buttonClicked}
        />
        <Checkbox
           checked={check1BOX}
           onChange={handlecheckBOX1}
        ></Checkbox>

        <InputField
          label="Answer"
         
        
          buttonClicked={buttonClicked}
        />
           <Checkbox
           checked={check2BOX}
             onChange={handlecheckBOX2}
           ></Checkbox>
        </div> 
      

        <div className="article-modal__content-input">
        
        <InputField
          label="Answer"
         
         
          buttonClicked={buttonClicked}
        />
        <Checkbox
        checked={check3BOX}
         onChange={handlecheckBOX3}
        ></Checkbox>

        <InputField
          label="Answer"
          
         
          buttonClicked={buttonClicked}
        />
           <Checkbox
           checked={check4BOX}
            onChange={handlecheckBOX4}
           ></Checkbox>
        </div> 

         
        </Fragment>
    );
}

export default QuestionBox;


