import Dashboard from '../pages/Dashboard/Dashboard';
import Departments from '../pages/Departments/Departments';
import Categories from '../pages/Categories/Categories';
import Articles from '../pages/Articles/Articles';
import ArticleDetails from '../pages/Articles/ArticleDetails';
import Courses from '../pages/Courses/Courses';
import AddCourse from '../pages/Courses/AddCourse';
import CourseDetails from '../pages/Courses/CourseDetails';
import Members from '../pages/Members/Members';
import MemberDetails from '../pages/Members/MemberDetails';
import Quizzes from '../pages/Quizzes/Quizzes';
// import News from '../pages/News/News';
import Events from '../pages/Events/Events';
import Knowledge from '../pages/Knowledge/Knowledge';
import Favourites from '../pages/Favourites/Favourites';
import WatchLater from '../pages/WatchLater/WatchLater';
import LastDone from '../pages/LastDone/LastDone';
import Locations from '../pages/Locations/Locations';
import Teams from '../pages/Teams/Teams';
import ChartOrganization from '../pages/Chart/ChartOrganization';
import Processes from '../pages/Processes/Processes';
import Tasks from '../pages/Tasks/Tasks'
import Medias from '../pages/Medias/Medias';
import TaskDetails from '../pages/Tasks/TaskDetails'
import ProcessDetails from '../pages/Processes/ProcessDetails';
import MyTasks from '../pages/Tasks/MyTasks';
import MyProcesses from '../pages/Processes/MyProcesses';
import MyApproval from '../pages/Tasks/MyApproval';
import AddProcess from '../pages/Processes/AddProcess';
import MasterProcesses from '../pages/Processes/MasterProcesses';


export const routes = [
      { path: '/dashboard', Component: Dashboard },
      // { path: '/news', Component: News },
      { path: '/events', Component: Events },
      { path: '/knowledge', Component: Knowledge },
      { path: '/departments', Component: Departments },
      { path: '/locations', Component: Locations },
      { path: '/teams', Component: Teams },
      { path: '/processes', Component: Processes },
      { path: '/tasks', Component: Tasks },
      { path: '/chart', Component: ChartOrganization },
      { path: '/members', Component: Members },
      { path: '/member-details/:id', Component: MemberDetails },
      { path: '/categories', Component: Categories },
      { path: '/articles', Component: Articles },
      { path: '/article/:id', Component: ArticleDetails },
      { path: '/courses', Component: Courses },
      { path: '/add-course', Component: AddCourse },
      { path: '/edit-course/:id', Component: AddCourse },
      { path: '/course/:id', Component: CourseDetails },
      { path: '/quizzes', Component: Quizzes },
      { path: '/favourites', Component: Favourites },
      { path: '/watch_later', Component: WatchLater },
      { path: '/last_done', Component: LastDone },
      { path: '/medias', Component: Medias },
      { path: '/task/:id', Component: TaskDetails },
      { path: '/process/:id', Component: ProcessDetails },
      { path: '/my-tasks', Component: MyTasks },
      { path: '/my-processes', Component: MyProcesses },
      { path: '/my-approval', Component: MyApproval},
      { path: '/add-process', Component: AddProcess },
      { path: '/edit-process/:id', Component: AddProcess },
      { path: '/master-processes', Component: MasterProcesses }  
      
];
