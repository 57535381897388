import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import Redux from 'redux-thunk';
import rootReducer from './rootReducer';
import logger from 'redux-logger';

const middlewares = [Redux];
const store = createStore(
    rootReducer, 
    applyMiddleware(...middlewares, logger, promiseMiddleware)
);
export default store;
