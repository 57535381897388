import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../layout/logo/logo.js';
import NewButton from '../../components/buttons/NewButton';
import { useTranslation } from 'react-i18next'
import NewInput from '../../components/inputField/NewInput.js';
import config from '../../config/api.config.js';

const RegisterSecondPage = ({
  firstPage,
  secondPage,
  thirdPage,
  values,
  setValues
}) => {
  const { t } = useTranslation()
  const [valid, ] = useState(true);
  const [buttonClicked, ] = useState(false);
  
  let history = useHistory();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


  // const handleBack = () => {
  //   secondPage(false);
  //   firstPage(true);
  // };

 
      const handleSubmit = async () => {

          history.push({
            pathname :'/confirm-your-email',
            state : {
              showNotification :true
            }});
           
      };
       
      
  return (
    <div>
    <div className="main2">
     <div className=''>
      <div className='headerp'>
        <div>
          <Logo />
          </div>
      </div>
      <h2>{t('welcome_message')}</h2>

      <form className="main2">
        <p>{t('Confirm Your Email')}</p>
        <NewInput
          label="Email"
          value={values.email}
          onChange={handleChange('Email')}
          errorMessage={'Please insert your email'}
          buttonClicked={buttonClicked}
        />
      </form>
      </div>
      </div>
      <div className='main2'>
      <NewButton
        name={t('Confirm')}
        onClick={handleSubmit}
        className="register__button"
        valid={valid}
        buttonClicked={buttonClicked}
      >
       
      </NewButton>
      </div>
     <div className='main2'>
        <p className="f-linkLabel">
        <span style={{color: config.colorPrimary}}>{t('already_have_account')}</span>
        <Link to="/login">{t('LOGIN')}</Link>
      </p>
      </div>
    </div>
    
    
  );
  
  }

export default RegisterSecondPage;
