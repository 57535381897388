import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { getUserById } from "../../redux/users/action";
import moment from "moment";

const Comment = (props) => {
  const dispatch = useDispatch();
  const { userId, comment, date  } = props;
  const [user, setUser] = useState("");

  useEffect(async() => {
    if(props.members && props.members[userId]){
      const _user = await getUser(userId);
      setUser(_user);
    }
  }, []);

  const getUser = async(id) => {
    let user = "";
    if(props.members && props.members[id]){
      user = props.members[id].firstName + " " + props.members[id].lastName;
    } else {
      await dispatch(getUserById({id: id}))
      .then((res) => {
        if(res.value?.data){
          user = res.value.data.firstName + " " + res.value.data.lastName;
        }
      });
    }
    return user;
  };

  return(
    <Paper style={{ padding: "8px",boxShadow:"none" }}>
      <Grid alignItems="flex-start" container wrap="nowrap">
        <Grid justifycontent="flex-start" >
          <h6 className="task-details__comment--user" style={{ margin: 0, textAlign: "left" }}>{user}</h6>
          <p style={{marginBottom:0.5}}>{comment}</p>
          <p style={{ textAlign: "left", color: "gray" }}> {moment(date).fromNow()}</p>
        </Grid>
      </Grid>
    </Paper>
  ); 
};

const mapStateToProps = (state) => ({
  memberIds: state.user.allIds,
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(Comment);
