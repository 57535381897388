import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SEARCH_DEPARTMENTS,
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  TEAMS_BY_DEPARTMENT
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {},
  teams: {},
};
  
export default typeToReducer(
  {
    [SEARCH_DEPARTMENTS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { departments, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allDepartments = payload.params?.GetAll ? payload.params.GetAll : false;

        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let departmentNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(departments.length > 0){
            departments.forEach(department => {
            department['no'] = ++departmentNo;
            if(allIds.indexOf(department.id) === -1){
              allIds.push(department.id);
            }
            if(!allDepartments){
              searchIds.push(department.id); 
            }                     
            byId[department.id] = department;
          })
        }

        if(!allDepartments){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_DEPARTMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let department = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        department['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: department};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];           
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_DEPARTMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { departmentId, name, locationId, headId } = payload.params;
        let byId = state.byId;
        if(byId[departmentId]){
          byId[departmentId].name = name;         
          byId[departmentId].locationId = locationId;
          byId[departmentId].headId = headId;
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_DEPARTMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { departmentId } = payload.params;
        let byId = state.byId;
        let allIds = state.allIds;
        if(payload.data){
          const index = allIds.indexOf(departmentId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[departmentId]){
            delete byId[departmentId];
          }
        }

        return {
          ...state,
          byId,
          allIds,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [TEAMS_BY_DEPARTMENT]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { teams } = payload.data;
        const { DepartmentId } = payload.params;

        let team = {
          ...state.teams, 
          [DepartmentId]: teams
        };

        return {
          ...state,
          teams: team,
          isLoading: false,
        };       
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },

  },
  INITIAL_STATE
);
  