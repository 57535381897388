import React from 'react'
import './labelcss.scss';
const NewLabel=({
    name
})=>{
   
return (
<div className='labeldesign'>
    {name}
</div>
)
}
export default NewLabel;