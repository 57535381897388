import React from "react";
import ReactPlayer from "react-player";

const Player = ({ mediaUrl, customClass }) => {
  return(
    <ReactPlayer
      className={`${customClass ? customClass : ''}`}
      url={mediaUrl}
      controls={true}
      width="100%"
      height="100%"
    />
  )
};
export default Player;
