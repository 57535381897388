//import {getRandomUser} from './service';
import config from '../../config/api.config.js';
import axios from 'axios';

import { FETCH_LOGIN, } from '../actionTypes';
import auth from '../../auth';
import jwt from 'jwt-decode';



export const loginUserAction=(loginState,values)=>async(dispatch,getState)=>{
     await axios
    .post(`${config.apiURL}/api/Account/login`, values)
    .then((res) => {
        const  {data} = res;
        dispatch({type:FETCH_LOGIN,payload:data.token});

        const token = data.token;
        const dataJwt = jwt(token);
        auth.login(token, dataJwt.exp, dataJwt.UserId, dataJwt.role);

        loginState(true)

    })
    .catch((err) => {
        loginState(false)
     return err;
  
    });
   
}



