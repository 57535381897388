import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../components/modal/modal";
import FilePreview from "../../components/filePreview/filePreview";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import OutlinedButton from "../../components/buttons/outlinedButton";
import moment from "moment";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import Comment from "../Tasks/Comment";
import { getUserById } from "../../redux/users/action";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TaskDetailsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {open, closeModal, onAttachmentClick, onMediaClick, selectedTask, isProcessTask = false} = props;

  const [articles, setArticles] = useState([]);
  const [medias, setMedias] = useState([]);
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [nextStep, setNextStep] = useState("");
  const [creator, setCreator] = useState("")

  useEffect(() => {    
    const fetchData = async () => {
      if (selectedTask?.articles?.length > 0) {
        let _articles = [];
        selectedTask.articles.forEach((id) => {
          if (props.articles && props.articles[id]) {
            _articles.push(props.articles[id]);
          }
        });
        setArticles(_articles);
      } else {
        setArticles([]);
      }
  
      if (selectedTask?.medias?.length > 0) {
        let _medias = [];
        selectedTask.medias.forEach((id) => {
          if (props.medias && props.medias[id]) {
            _medias.push(props.medias[id]);
          }
        });
        setMedias(_medias);
      } else {
        setMedias([]);
      }
  
      if (selectedTask?.comments?.length > 0) {
        setComments(selectedTask.comments);
      } else {
        setComments([]);
      }
  
      if (selectedTask?.uploadedAttachments?.length > 0) {
        setAttachments(selectedTask?.uploadedAttachments);
      } else {
        setAttachments([]);
      }
  
      let _nextStep = "";
      if (selectedTask?.compeleted && selectedTask?.approved) {
        _nextStep =
          "Diese Aufgabe ist abgeschloßen. Keine weiteren Schritte notwendig.";
      } else if (selectedTask?.compeleted && !selectedTask?.approved) {
        _nextStep =
          "Diese Aufgabe wartet auf Genehmigung durch Prozessverantwortlichen.";
      } else if (!selectedTask?.compeleted && !selectedTask?.approved) {
        if (moment().isAfter(selectedTask?.dateBeDone)) {
          _nextStep = "Eskalation! Die Deadline wurde überfahren.";
        } else {
          _nextStep =
            "Diese Aufgabe wartet auf Bearbeitung, durch Aufgabenverantwortlichen.";
        }
      }
      setNextStep(_nextStep);
  
      if(selectedTask.creator){
        let _creator = await getUserName(selectedTask.creator);
        setCreator(_creator);
      }
    };

    fetchData();

  }, [selectedTask]);

  const attachmentClickHandler = (fileName) => {
    onAttachmentClick(fileName);
  };

  const handleMediaDisplay = (id) => {
    onMediaClick(id);
  };

  const closeModaHandler = () => {
    closeModal();
    setMedias([]);
    setArticles([]);
    setComments([]);
    setAttachments([]);
    setNextStep("");
  }

  const getUserName = async(id) => {
    let name = "";
    if(props.members && props.members[id]){
      name = props.members[id].firstName  + " " + props.members[id].lastName;
    }else{
      await dispatch(getUserById({ id: id }))
        .then((res) => {
          if(res.value?.data){
            name = res.value.data.firstName + " " + res.value.data.lastName;
          }
        })
        .catch((err) => logError(err));
        }
    return name;
  }

  const ColorSwitch = withStyles({
    switchBase: {
      color: config.colorActive,
      "&$checked": {
        color: config.colorSecondary,
      },
      "&$checked + $track": {
        backgroundColor: config.colorSecondary,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const StyledFormControlLabel = withStyles(() => ({
    label: {
      fontSize: '1rem',
      marginLeft: '0px !important',
      fontWeight: 'bolder',
      color: '#212529'
    }
  }))(FormControlLabel);

  return(
    <Modal
      title={"Aufgabendetails"}
      open={open}
      closeModal={closeModaHandler}
    >
      <div className="article-modal__content">
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Name:"}&nbsp;&nbsp;&nbsp;</strong>
          <span>{selectedTask?.name}</span>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Beschreibung:"}&nbsp;&nbsp;&nbsp;</strong>
          <span style={{ justifyContent: "center" }}>
            {selectedTask?.description}
          </span>
        </div>

        {isProcessTask &&
          <>
            <div style={{ marginBottom: "10px" }}>
              <strong>{"Verantwortlich:"}&nbsp;&nbsp;&nbsp;</strong>
              <span>
                {props.members && props.members[selectedTask?.responsible]
                  ? props.members[selectedTask?.responsible].firstName +
                    " " +
                    props.members[selectedTask?.responsible].lastName
                  : ""}
              </span>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <strong>{"Für wen:"}&nbsp;&nbsp;&nbsp;</strong>
              <span>
                {selectedTask?.benefactor &&
                props.members &&
                props.members[selectedTask?.benefactor]
                  ? props.members[selectedTask?.benefactor].firstName +
                    " " +
                    props.members[selectedTask?.benefactor].lastName
                  : ""}
              </span>
            </div>                    
          </>
        }
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Erstellt von:"}&nbsp;&nbsp;&nbsp;</strong>
          <span>{creator}</span>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Wurde erstellt am:"}&nbsp;&nbsp;&nbsp;</strong>
          <span>
            {moment(selectedTask?.dateCreated).format(
              `${t("momentjs_datum")}`
            )}
          </span>
        </div>
        {isProcessTask && <div style={{ marginBottom: "10px" }}>
          <strong>{"Erledigen bis:"}&nbsp;&nbsp;&nbsp;</strong>
          <span>
            {moment(selectedTask?.dateBeDone).format(`${t("momentjs_datum")}`)}
          </span>
        </div>}
        {isProcessTask && selectedTask?.preTask && <div style={{ marginBottom: "10px" }} className="process-toggle">
          <StyledFormControlLabel
            control={
              <ColorSwitch
                disabled={false}
                checked={selectedTask?.toggle}
              />
            }
            labelPlacement="start"
            label="Aufgabe kann parallel bearbeitet werden"
          />
        </div>}
        {isProcessTask &&<div style={{ marginBottom: "10px" }}>
          <strong>{"Wird informiert:"}&nbsp;&nbsp;&nbsp;</strong>
          {selectedTask?.notifies?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "fit-content",
                marginTop: 10,
              }}
            >
              {selectedTask?.notifies.map((person, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: 5,
                      marginLeft: 10,
                      border: "1px solid #c9d2d2",
                      borderRadius: 5,
                    }}
                  >
                    {person && props.members && props.members[person]
                      ? props.members[person].firstName +
                        " " +
                        props.members[person].lastName +
                        " "
                      : ""}
                  </div>
                );
              })}
            </div>
          ) : (
            <span
              style={{
                color: "#c9d2d2",
                fontSize: "1.1rem",
                marginTop: "1.3rem",
              }}
            ></span>
          )}
        </div>}
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Attachments:"}&nbsp;&nbsp;&nbsp;</strong>
          {selectedTask?.files?.length > 0 &&
            selectedTask?.files.map((file, i) => (
              <div
                onClick={() => attachmentClickHandler(file?.name)}
                key={file?.name}
              >
                <FilePreview
                  name={file.originalName}
                  customClass="modalFilePreview"
                  edit={true}
                  allowDelete={false}
                />
              </div>
            ))}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Artikel:"}&nbsp;&nbsp;&nbsp;</strong>
          <br />
          {articles.length > 0 &&
            articles.map((article, i) => (
              <React.Fragment key={i}>
                <Link
                  to={`/article/${article.id}`}
                  target="_blank"
                  style={{ color: config.colorSecondary }}
                >
                  {article.title}
                </Link>
                &nbsp;&nbsp;&nbsp;
              </React.Fragment>
            ))}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong>{"Medien:"}&nbsp;&nbsp;&nbsp;</strong>
          <br />
          {medias.length > 0 &&
            medias.map((media, i) => (
              <React.Fragment key={i}>
                <a
                  href="#"
                  onClick={() => handleMediaDisplay(media.id)}
                  style={{ color: config.colorSecondary }}
                >
                  {media.title}
                </a>
                &nbsp;&nbsp;&nbsp;
              </React.Fragment>
            ))}
        </div>
        {isProcessTask && 
          <>
            <div style={{ marginBottom: "10px" }}>
              <strong>{"Nächster Schritt:"}&nbsp;&nbsp;&nbsp;</strong>
              <span>{nextStep}</span>
            </div>
            <div
              className="process__details--comments"
              style={{ marginBottom: "10px" }}
            >
              <strong>{"Kommentare:"}&nbsp;&nbsp;&nbsp;</strong>
              <br />
              {comments.length > 0 ? (
                comments.map((comment, i) => {
                  return (
                    <React.Fragment key={i}>
                      {comment.message && <Comment
                        userId={comment.userId}
                        comment={comment.message}
                        date={comment.dateCreated}
                      />}
                    </React.Fragment>
                  );
                })
              ) : (
                <span style={{ textAlign: "left", color: "gray" }}>
                  {"Keine Kommentare vorhanden."}
                </span>
              )}
            </div>
            <div
              className="process__details--comments"
              style={{ marginBottom: "10px" }}
            >
              <strong>{"Anhänge:"}&nbsp;&nbsp;&nbsp;</strong>
              <br />
              {attachments.length > 0 ? (
                attachments.map((file, i) => (
                  <div
                    onClick={() => attachmentClickHandler(file?.name)}
                    key={file?.name}
                    style={{ marginBottom: "1.5rem" }}
                  >
                    <FilePreview
                      name={file.name}
                      customClass="modalFilePreview"
                      edit={true}
                      allowDelete={false}
                    />
                  </div>
                ))
              ) : (
                <span style={{ textAlign: "left", color: "gray" }}>
                  {"Keine Anhänge vorhanden."}
                </span>
              )}
            </div>
          </>
        }
      </div>
      <div className="departments__modal--buttons">
        <OutlinedButton
          onClick={closeModaHandler}
        >
          {t("Schließen")}
        </OutlinedButton>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  processes: state.process.byId,
  memberIds: state.user.allIds,
  members: state.user.byId,
  articleIds: state.article.allIds,
  articles: state.article.byId,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
});
  
export default connect(mapStateToProps, null)(TaskDetailsModal);
