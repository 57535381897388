import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import ArticleCard from './ArticleCard'
import CourseCard from './CourseCard'
import Breadcrumbs from '../breadcrumbs/breadcrumbs'

// import Typography from '@material-ui/core/Typography';
// import MenuBookIcon from '@material-ui/icons/MenuBook';
// import AutoStoriesIcon from '@material-ui/icons/AutoStories';
// import GroupIcon from '@material-ui/icons/Group';
// import SchoolIcon from '@material-ui/icons/School';
// import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useTranslation } from 'react-i18next'

import './cardSlider.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import config from '../../config/api.config';
import { logError } from "../../helpers/helpers";
import auth from '../../auth';

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", color: config.colorSecondary
// }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", color: config.colorSecondary
// }}
//       onClick={onClick}
//     />
//   );
// }

const useStyles = makeStyles((theme) => ({
  yellow: {
    // color: '#ffab2c',
    fontSize: '5rem'
  },
  green: {
    // color: '#5FC682',
    fontSize: '5rem'
  },
  blue: {
    // color: '#30AAEF',
    fontSize: '5rem'
  },
  statType: {
    paddingLeft: '12px',
    fontSize: '2rem'
  },
  showAllArrow: {
    fontSize: '1.2rem',
    paddingBottom: "2px"
  }
}));

const WORDS_PER_MINUTE = 250;

const CardSlider = (props) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [members ] = useState(null)
  const [articles, setArticles] = useState(null);
  const [courses, setCourses] = useState(null);
  const classes = useStyles()
  // const [totalWords, setTotalWords] = useState(0);

  const { t } = useTranslation()
  const ReadTime = (text) => {
    const words = text.split(" ");
    const time = Math.ceil(words.length / WORDS_PER_MINUTE);
    return time;
  };

  const getMembers = async (params) => {
    setLoading(true);
    // await axios
    //   .get(`${config.apiURL}/api/User`, {
    //     params: {
    //       WorkspaceId: props.currentUser.workspaceId,
    //       GetAll: true,
    //       ...params
    //     }
    //   })
    //   .then((res) => {
    //     setMembers(
    //       res.data.users.map((mem, i) => {
    //         return {
    //           id: mem.userId,
    //           name: mem.firstName + ' ' + mem.lastName,
    //           image: mem.image ? mem.image.url : null,
    //           email: mem.email
    //         };
    //       })
    //     );
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     logError(err);
    //   });
  };

  const getArticles = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Article`, {
        params: {
          WorkspaceId: props.currentUser.workspaceId,
          ...params
        },
          headers: {
            'Authorization': 'Bearer ' + auth.accessToken 
          }
         } )
      .then((res) => {
        setArticles(
          res.data.articles.map((dep, i) => {
            return {
              id: dep.id,
              name: dep.title,
              categories: dep.categories,
              authorId: dep.authorId,
              createdDate: moment(dep.dateCreated,).format(`${t('momentjs_datum')}`),
              lastModified: dep.dateModified,
              content: dep.content,
              readTime: ReadTime(dep.content)
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  const getCourses = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Course`, {
        params: {
          WorkspaceId: props.currentUser.workspaceId,
          ...params
        }
      })
      .then((res) => {
        setCourses(
          res.data.courses.map((dep, i) => {
            return {
              id: dep.id,
              name: dep.title,
              authorId: dep.author.authorId,
              authorImg: dep.author.imageUrl,
              authorName: dep.author.firstName + ' ' + dep.author.lastName,
              date: moment(dep.dateCreated).format('MMMM DD, YYYY'),
              description: dep.description
            };
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };
  var settings2 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;
    getMembers();
    getArticles();
    getCourses();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  return (
    <div className="slider-wrapper">
      {/* <div className="statistics-wrapper">
        <Breadcrumbs navigations={['Statistics']} />
        <div className='dashboard-statistics'>
          <div className='dashboard-statistics-content' onClick={(event) => history.push(`/courses`)} >
            <SchoolIcon className={classes.yellow} />
            <div className={`${classes.statType} ${classes.yellow}`}>
              <Typography className={classes.statType}>
                {t('Courses')}
              </Typography>
              <Typography align="center" className={classes.statType}>
                {courses && courses.length}
              </Typography>
            </div>
          </div>
          <div className='dashboard-statistics-content' onClick={(event) => history.push(`/members`)}>
            <GroupIcon className={classes.blue} />
            <div className={`${classes.statType} ${classes.blue}`}>
              <Typography className={classes.statType}>
                {t('Members')}
              </Typography>
              <Typography align="center" className={classes.statType}>
                {members && members.length}
              </Typography>
            </div>
          </div>
          <div className='dashboard-statistics-content' onClick={(event) => history.push(`/articles`)}>
            <MenuBookIcon className={classes.green} />
            <div className={`${classes.statType} ${classes.green}`}>
              <Typography className={classes.statType}>
                {t('Articles')}
              </Typography>
              <Typography align="center" className={classes.statType}>
                {articles && articles.length}
              </Typography>
            </div>
          </div>
        </div>
      </div> */}

      <div className="article-slider" /* style={{ border: "2px solid #5FC68250"}} */>
        <Breadcrumbs navigations={['newest_articles']} />
        <div className="all-articles-wrapper">
          <span className="all-articles-link" onClick={(event) => history.push(`/articles`)}>{t('show_all')} <ArrowForwardIcon className={classes.showAllArrow} /></span>
        </div>
        <Slider {...settings}>
          {articles === null || articles.length === 0 ? (
            <div>
              {t('nothing_found_department')}
            </div>
          ) : (articles.sort((a, b) => {
            let datea = moment(a.createdDate, 'x')
            let dateb = moment(b.createdDate, 'x')
            return dateb - datea
          }).slice(0, 4).map((article, i) => {
            return (
              <ArticleCard
                key={i}
                id={article.id}
                name={article.name}
                authorName={members && members.filter(mem => mem.id === article.authorId)[0].name}
                authorId={article.authorId}
                date={article.createdDate}
                categories={article.categories}
                content={article.content}
                readTime={article.readTime}
              />
            )
          }))}
        </Slider>
      </div>
      <div className="course-slider" /* style={{ border: "2px solid #E1921C50" }} */>
        <Breadcrumbs navigations={['newest_courses']} />
        <div className="all-articles-wrapper">
          <span className="all-articles-link" onClick={(event) => history.push(`/courses`)}>{t('show_all')} <ArrowForwardIcon className={classes.showAllArrow} /></span>
        </div>
        <Slider {...settings2}>
          {courses === null || courses.length === 0 ? (
            <div>
              {t('nothing_found_department')}
            </div>
          ) :
            (courses.sort((a, b) => {
              let datea = moment(a.date, 'x')
              let dateb = moment(b.date, 'x')
              return dateb - datea
            }).slice(0, 4).map((course, i) => {
              return (
                <CourseCard
                  key={i}
                  id={course.id}
                  name={course.name}
                  authorId={course.authorId}
                  authorImg={course.authorImg}
                  authorName={course.authorName}
                  date={course.date}
                  description={course.description}
                />
              )
            }))}
        </Slider>
      </div>
    </div>

  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser
});

export default connect(mapStateToProps, null)(CardSlider);