import { FETCH_LOGIN, } from '../actionTypes';

const initState={
    logininfo:{}
}

const loginReducer=(state=initState,action)=>{
    switch(action.type){
        case FETCH_LOGIN: 
            return{
            ...state,
            logininfo: action.payload
        }
        default:
            return state;

    }   
}



export default loginReducer;

// export default createStore(rootReducer,applyMiddleware(thunk))