import React, { useState, useEffect } from 'react';

import FirstPage from './firstPage';
import SecondPage from './secondPage';
import config from '../../config/api.config.js';
import '../../assets/css/_scss-variables.scss';



import '../../assets/css/pages/forgotPassword.scss';

const ForgotPassword = () => {
  const [firstPageVisible, setFirstPageVisible] = useState(true);
  const [secondPageVisible, setSecondPageVisible] = useState(true);
  const [email, setEmail] = useState('');
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      config.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      config.colorSecondary
    );
    root?.style.setProperty(
      "--color-active",
      config.colorActive
    );
    root?.style.setProperty(
      "--color-hover",
      config.colorHover
    );
    setReadyToRender(true);
  }, []);

  return (
    <>
      {readyToRender && <div className="login2">
        <div className="">
          <div className="">
        
          </div>
        </div>
        <div className="">
          {firstPageVisible ? (
            <FirstPage
              firstPage={setFirstPageVisible}
              secondPage={setSecondPageVisible}
              email={email}
              setEmail={setEmail}
            />
          ) : secondPageVisible ? (
            <SecondPage email={email} />
          ) : (
            <FirstPage
              firstPage={setFirstPageVisible}
              secondPage={setSecondPageVisible}
              email={email}
              setEmail={setEmail}
            />
          )}
        </div>
      </div>}
    </>
  );
};

export default ForgotPassword;
