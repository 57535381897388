import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { LightTooltip } from "../../components/tooltip/tooltip";

const TaskCard = (props) => {
  const { color, onCardClick, text, des, shortDes, status, resPerson } = props;

  const cardClickHandler = () => {
    onCardClick();
  };

  return (
    <div
      style={{
        display: "inline-block",
        backgroundColor: "white",
        borderRadius: "5px",
        border: props.borderWidth
          ? `${props.borderWidth} solid ${color}`
          : `2px solid ${color}`,
      }}
    >
      <Card
        sx={{
          display: "flex",
          padding: "15px 10px",
          minWidth: "150px",
          cursor: "pointer",
        }}
        onClick={cardClickHandler}
      >
        <CardMedia
          component="img"
          sx={{ width: "80px", margin: "0 10px" }}
          image="https://www.flex-pos.de/images/technik-haende-werkzeug.svg"
          alt="User profile picture"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LightTooltip arrow title={status ? status : ""} placement="right">
            <CardContent
              sx={{
                flex: "1 0 auto",
                marginRight: "10px",
              }}
            >
              {text && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  align="left"
                >
                  <strong>{text}</strong>
                </Typography>
              )}
              <Typography
                variant="string"
                color="text.secondary"
                component="div"
                align="left"
              >
                {des && (
                  <strong>
                    {des.length > 50 ? des.substring(0, 50) + "..." : des}
                  </strong>
                )}
                {shortDes && (
                  <p className="mb-0">
                    <strong>{shortDes}</strong>
                  </p>
                )}
              </Typography>
              {resPerson && (
                <Typography
                  variant="string"
                  color="text.secondary"
                  component="div"
                  align="left"
                >
                  {"Verantwortlich:"} <strong>{resPerson}</strong>
                </Typography>
              )}
            </CardContent>
          </LightTooltip>
        </Box>
      </Card>
    </div>
  );
};

export default TaskCard;
