import React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./button.scss";
import config from "../../config/api.config.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0.2),
      fontSize: "1.375rem",
      color: "#fff",
    },
    textTransform: "unset",
    width: "17.2rem",
    fontFamily: " SFProText-Semibold",
  },
}));

const ColoredButton = withStyles({
  root: {
    backgroundColor: config.colorSecondary,
    "&:hover": {
      backgroundColor: config.colorHover,
    },
  },
})(Button);

const AuthButton = ({
  onClick,
  className,
  valid,
  buttonClicked = false,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {valid && !buttonClicked ? (
        <ColoredButton
          onClick={buttonClicked ? null : onClick}
          variant="contained"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          className={`button__valid ${classes.root}`}
          {...otherProps}
        >
          {children}
        </ColoredButton>
      ) : (
        <Button
          variant="contained"
          disabled
          className={classes.root}
          {...otherProps}
        >
          {buttonClicked ? (
            <CircularProgress disableShrink color="inherit" size="2.2rem" />
          ) : (
            children
          )}
        </Button>
      )}
    </div>
  );
};

export default AuthButton;
