import React from 'react';
import { useHistory } from 'react-router-dom';
// import clsx from 'clsx';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import CommentIcon from '@material-ui/icons/Comment';
import WatchLaterIcon from '@material-ui/icons/WatchLater';



export default function CourseCard(props) {
  const history = useHistory();

  return (
    props &&
    <div className="article-card">
      <div onClick={(event) => history.push(`/course/${props.id}`)}>
        <div className="article-card-img-wrapper">
          <img src='https://img.freepik.com/vektoren-kostenlos/user-experience-isometrische-konzept-ux-ui-wireframe_107791-550.jpg?size=626&ext=jpg&ga=GA1.2.2099647507.1627689600' alt={props.name} />
        </div>
        <div className="article-card-details-wrapper">
          <span className="course-card-symbol">Course</span>
          <div>
            <p className="article-card-author">by {props.authorName}</p>
            <p className="article-card-date">{props.date}</p>
          </div>
        </div>
        <h4 className="article-card-header-wrapper">
          <span>{props.name}</span>
        </h4>
        <p className="article-card-content">
          {/* <Markup content={props.content.p}/> */}
          {props.description}
        </p>
        <div className="article-card-footer">
          <span className="article-card-date">{props.date}</span>
        </div>
      </div>
      <div className="article-card-footer">
        {/* <BookmarkIcon style={{ color: 'black' }}/> */}
        <div><CommentIcon style={{ color: 'black' }} /><span>120</span></div>
        <WatchLaterIcon style={{ color: 'black' }} />
        <BookmarkBorderIcon style={{ color: 'black' }} />
      </div>
    </div>
  );
}
