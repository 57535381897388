import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SET_CURRENT_USER,
  SET_CHART_DATA,
  SEARCH_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  USER_CSV_UPLOAD,
  USER_BY_ID,
  ASSIGN_TASK_TO_USER,
  REASSIGN_TASK_TO_USER,
  UPLOAD_PROFILE_PIC,
  UPLOAD_MEMBER_PROFILE_PIC,
  FORGOT_PASSWORD
} from '../actionTypes';

const INITIAL_STATE = {
  currentUser: null,
  isLoading: false,
  chartData: null,
  byId: {},
  allIds: [],
  searchIds: [],
  pageParams: {},
};
  
export default typeToReducer(
  {
    [SET_CURRENT_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {       
        const { email, firstName, lastName, role, userId } = payload.data;
        let currentUser = {
          email,
          firstName,
          lastName,
          role,
        };
        let byId = state.byId;
        byId[userId] = payload.data;

        return {
          ...state,
          currentUser,
          byId,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [SET_CHART_DATA]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {       
        const chartData = payload.data;       
        return {
          ...state,
          chartData,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [SEARCH_USERS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { users, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allUsers = payload.params?.GetAll ? payload.params.GetAll : false;

        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let userNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(users.length > 0){        
          users.forEach(user => {
            user['id'] = user.userId;
            user['no'] = ++userNo;
            if(allIds.indexOf(user.id) === -1){
              allIds.push(user.id);
            }
            if(!allUsers){
              searchIds.push(user.id);
            }                       
            byId[user.id] = user;
          })
        }
        if(!allUsers){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        }; 
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { userId } = payload;
        let user = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(userId) === -1){
          allIds =  [...state.allIds, userId];          
        }
        user['no'] = state.pageParams.totalCount + 1;
        user['id'] = userId;
        byId = {...state.byId, [payload.userId]: user};

        if((state.searchIds.indexOf(userId) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, userId];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { userId } = payload.params;
        let user = payload.data;
        user['id'] = userId;
        let byId = state.byId;
        if(payload.data && byId[userId]){
          user['no'] = byId[userId].no;
          byId[userId] = user;
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { userId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(userId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[userId]){
            delete byId[userId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [USER_CSV_UPLOAD]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {     
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [USER_BY_ID]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) { 
        let user = payload.data;
        let allIds = state.allIds;
        let byId = state.byId;
        if(payload.data){
          user['id'] = user.userId;
          if(allIds.length > 0){
            if(allIds.indexOf(user.id) === -1){
              allIds.push(user.id);
            }
          }               
          if(byId[user.userId]){
            user['no'] = byId[user.userId].no;           
          }else{
            user['no'] = allIds.length + 1;
          }
          byId[user.userId] = user;
        }   
        return {
          ...state,
          allIds,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [ASSIGN_TASK_TO_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const userId = payload.data.userId;
        let user = payload.data;
        user['id'] = userId;
        let byId = state.byId;
        if(payload.data && byId[userId]){
          user['no'] = byId[userId].no;
          byId[userId] = user;
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },    
    [REASSIGN_TASK_TO_USER]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const prevUserId = payload.params.task.prevUser;
        const taskId = payload.params.task.id;
        const userId = payload.data.userId;
        let byId = state.byId;
        let user = payload.data;
        if(prevUserId){
          //remove task from prev user
          if(byId[prevUserId].tasks.length > 0){
            let tasks = byId[prevUserId].tasks;
            const index = tasks.findIndex(task => task.id === taskId);
            if(index !== -1){
              (byId[prevUserId].tasks).splice(index, 1);
            }
          }
        }
        user['id'] = userId;        
        if(payload.data && byId[userId]){
          user['no'] = byId[userId].no;
          byId[userId] = user;
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPLOAD_PROFILE_PIC]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const userId = payload.id;
        let byId = state.byId;

        if(payload.data){
          if(byId[userId]){
            byId[userId].image = payload.data;
          }
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPLOAD_MEMBER_PROFILE_PIC]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const userId = payload.id;
        let byId = state.byId;

        if(payload.data){
          if(byId[userId]){
            byId[userId].image = payload.data;
          }
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [FORGOT_PASSWORD]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {      
        return {
          ...state,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
  },
  INITIAL_STATE
);
