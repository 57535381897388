import React, {useState, useEffect}  from "react";
import { history } from "../..";

import './newpopup.scss'
import config from '../../config/api.config.js'



const NewPopup =()=>{
    const [ setButtonClicked] = useState(false);
    const btnClicked = ()=>{
        setButtonClicked(true);
        history.push('/dashboard');
        window.location.reload();
    }
    const [readyToRender, setReadyToRender] = useState(false);

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
          "--color-primary",
          config.colorPrimary
        );
        root?.style.setProperty(
          "--color-secondary",
          config.colorSecondary
        );
        root?.style.setProperty(
          "--color-active",
          config.colorActive
        );
        root?.style.setProperty(
          "--color-hover",
          config.colorHover
        );
        setReadyToRender(true);
      }, []);
  
    return(
        <>
        
        {readyToRender && <div className="holder">

            <div className="popupbox8">
                <div className="title2">Title</div>
                <div className="popuptxt">
               
                1. GELTUNG, VERTRAGSABSCHLUSS

1.1 Die Mosaik Management GmbH (im Folgenden «Agentur»
genannt) erbringt Ihre Leistungen ausschließlich auf der Grundlage
der nachstehenden Allgemeinen Geschäftsbedingungen (AGB).
Diese gelten für alle Rechtsbeziehungen zwischen der Agentur
und dem Vertragspartner (im Folgenden «Kunden» genannt).
Von diesen Geschäftsbedingungen abweichende Bedingungen
des Kunden werden von der Agentur nur nach gesonderter und
schriftlicher Anerkennung akzeptiert.

1.2 Alle Vereinbarungen, die zwischen der Agentur und dem Kunden zwecks Ausführung eines Auftrages getroffen werden, sind in
schriftlicher Form zu vereinbaren. Änderungen, Ergänzungen und
Nebenabreden bedürfen der Schriftform.

1.3 Diese Geschäftsbedingungen gelten auch für alle künftigen
Geschäftsbeziehungen mit dem Kunden, auch wenn sie nicht nochmals ausdrücklich vereinbart werden.

1.4 Die Agentur erbringt Dienstleistungen aus den Bereichen Konzeption, Gestaltung, Umsetzung von Veranstaltung, Mediendesign
und PR- / Kommunikationsmarketing. Die detaillierte Beschreibung
der zu erbringenden Dienstleistungen ergeben sich aus den Ausschreibungsunterlagen, Projektverträgen, und Leistungsbeschreibungen der Agentur.

2. EIGENTUMS- UND URHEBERRECHT

2.1 Das erarbeitete Konzept untersteht in seinen grafischen und
sprachlichen Teilen dem Schutz des Urheberrechts. Alle Bestandteile des Konzeptes, die einzigartig sind und das Konzept prägen,
sind geschützt. Der (potentielle) Kunde verpflichtet sich, das
Konzept oder Teile dieses Konzepts nicht anderweitig umsetzen zu
lassen oder zu benutzen.
2.2 Der Kunde erwirbt durch Zahlung des Honorars nur das Recht
zur Nutzung zum vereinbarten Zweck.
2.3 Bei Vertragsbruch kann die Agentur eine angemessene Entschädigung verlangen.
2.4 Änderungen oder Weiterentwicklungen der Leistungen der
Agentur sind nach Ende des Vertrags nur mit ausdrücklicher Zustimmung der Agentur erlaubt.
3. LEISTUNGSUMFANG / MITWIRKUNGSPFLICHT DES KUNDEN
Die zu erbringenden Leistungen ergeben sich aus der Auftragsbestätigung. Nachträgliche Änderungen der Leistungen sind schriftlich
zu bestätigen. Innerhalb des vorgegeben Rahmens besteht Gestaltungsfreiheit für die Agentur. Der Kunde ist verpflichtet, die zur Verfügung gestellten Unterlagen, Texte, Bilder und andere Materialien
auf Urheber- und Markenrecht und ergänzende Rechte zu prüfen.
Die Agentur haftet nicht bei Rechtsstreitigkeiten diesbezüglich.
4. HAFTUNG
Die Agentur verpflichtet sich zur gewissenhaften Vorbereitung,
Auswahl und Überwachung der Leistungsträger. Die Haftung der
Agentur richtet sich ausschließlich nach den schriftlichen Vereinbarungen der Parteien. Alle hierin nicht ausdrücklich zugestandenen
Ansprüche - auch Schadenersatzansprüche, gleich aus welchem
Rechtsgrund - sind ausgeschlossen, es sei denn, sie beruhen
auf einer vorsätzlichen oder grob fahrlässigen Vertragsverletzung
durch die Agentur, durch einen gesetzlichen Vertreter oder Erfüllungsgehilfen. Darüber hinaus vereinbaren die Vertragsparteien,
dass ein Schadenersatzanspruch gegen die Agentur der Höhe
nach, gleich aus welchem Rechtsgrunde, auf das vereinbarte
Honorar beschränkt ist. Die Agentur haftet im Rahmen dieses
Vertrages nicht für die Folgen höherer Gewalt. Dazu gehören
Anordnungen von Behörden, Kriege, innere Unruhen, Flugzeugentführungen, Terroranschläge, Feuer, Überschwemmungen,
Stromausfälle, Unfälle, Sturm, Streiks, Aussperrungen oder andere
Arbeitskampfmaßnahmen, von denen die Dienste der Agentur oder
deren Lieferanten beeinflusst werden. Gleiches gilt für das Eintreten von Umständen, die die Veranstaltung unmöglich machen, aber
von der Agentur nicht verursacht wurden oder in anderer Weise zu
vertreten sind.
5. BEAUFTRAGUNG DRITTER / FREMDLEISTUNGEN
5.1 Die Agentur ist nach freiem Ermessen berechtigt, die Leistung
selbst auszuführen oder sich bei der Erbringung von vertragsgegenständlichen Leistungen sachkundiger Dritter zu bedienen.
5.2 Die Beauftragung von Dritten im Rahmen einer Fremdleistung
erfolgt entweder im eigenen Namen oder im Namen des Kunden. Die
Agentur wird diesen Dritten sorgfältig auswählen und darauf achten,
dass dieser über die erforderliche fachliche Qualifikation verfügt.
5.3 Soweit die Agentur notwendige oder vereinbarte Fremdleistungen in Auftrag gibt, sind die jeweiligen Auftragnehmer keine
Erfüllungsgehilfen der Agentur.
ALLGEMEINE GESCHÄFTSBEDINGUNGEN DER MOSAIK MANAGEMENT GMBH
Prinz-Friedrich-Karl-Straße 34, 44137 Dortmund · Tel. 0231- 28219960 Fax. 0231- 28219961
Vertreten durch: Bernd Baringhorst, Mathias Kolta, Sebastian Schalkau
Amtsgericht Dortmund HRB 26338 · Steuernummer 317/5918/1536
6. HONORAR/ ZAHLUNGSBEDINGUNGEN
6.1 Bei Auftragserteilung gilt es, einen Abschlag von 25 % der
vereinbarten Rechnungssumme zu zahlen. Die restlichen 75 % sind
in drei gleichen Teilen nach Auftragsfortschritten fällig.
6.2 Soweit nicht anders vertraglich festgehalten, gilt ein Zahlungsziel von 7 Kalendertagen ab Rechnungsdatum.
6.3 Die Agentur wird bei Zahlungsverzug die geltenden Sätze für
Verzugszinsen gem. § 280 BGB berechnen.
6.4 Das angegebene Honorar ist ein Nettohonorar, es ist zuzüglich
die gesetzliche Umsatzsteuer zu entrichten.
6.5 Die angegebene Summe des Kostenvoranschlags kann abweichen. Bei einer Abweichung von mehr als 20 % wird die Agentur
mit dem Kunden Rücksprache halten.
6.6 Die angegebenen Kosten sind Agenturkosten. Kosten von Drittanbietern, Künstlern, Photocredits usw. werden separat aufgeführt
und gesondert berechnet.
7. VERWERTUNGSGESELLSCHAFTEN
Der Kunde verpflichtet sich, anfallende Gebühren für Verwertungsgesellschaften und Künstlerabgaben abzuführen, wie z.B. die
GEMA-Gebühr. Verauslagt die Agentur diese Ausgaben, so hat der
Kunde gegen Nachweis diese an die Agentur zu erstatten. Dies
kann auch nach Vertragsende erfolgen.
8. KÜNDIGUNG
8.1 Eine Kündigung bedarf der Schriftform.
8.2 Eine vorzeitige Kündigung des Vertrages durch den Kunden ist
unzulässig. Das Recht des Kunden, aus wichtigem Grund unter den
Voraussetzungen des § 626 BGB zu kündigen bleibt unberührt.
8.3 Kündigt der Kunde den Vertrag vorzeitig, ohne dass ein von
der Agentur zu vertretender wichtiger Grund (§ 626 BGB) zur Kündigung oder ein gesetzlicher Grund des Rücktritts vorliegt, oder
verweigert der Kunde die Festsetzung des Vertrages oder die Abnahme des Vertragsgegenstandes / der Veranstaltung endgültig,
so sind alle bis dahin angefallenen Kosten zu zahlen.
8.4 Bei Veranstaltungen sind die folgenden Prozentsätze
zuzüglich der Umsatzsteuer verbindlich:
· bis 60 Tage vor Veranstaltungsbeginn: 50 %
· bis 40 Tage vor Veranstaltungsbeginn: 65 %
· bis 10 Tage vor Veranstaltungsbeginn: 90 %
· weniger als 10 Tage vor Veranstaltungsbeginn: 100 %
9. GEHEIMHALTUNGSPFLICHT
Die Agentur sowie alle Erfüllungsgehilfen verpflichten sich, alle
Kenntnisse eines Auftrags geheim zu halten und vertraulich zu
behandeln.
10. SOCIAL MEDIA KANÄLE
10.1 Die Agentur weist den Kunden vor Auftragserteilung ausdrücklich darauf hin, dass die Anbieter von «Social-Media-Kanälen»
(z.B. Facebook, im Folgenden «Anbieter» genannt) es sich in ihren
Nutzungsbedingungen vorbehalten, Inhalte zu löschen oder nicht
zu veröffentlichen. Die Agentur ist bestrebt, das bestmögliche
Ergebnis für Ihre Kunden zu erzielen, ist aber nicht verantwortlich
für das Handeln der Anbieter.
10.2 Der Kunde ist gemäß § 4 BDSG für die Zulässigkeit der Erhebung, Verarbeitung, Löschung und Nutzung der Daten sowie die
Wahrung der Rechte der Betroffenen verantwortlich.
Der Umgang mit den Daten erfolgt ausschließlich im Rahmen der
Beauftragung und den vom Kunden erteilten und mit diesem abgestimmten Weisungen.
11. GERICHTSSTAND / SALVATORISCHE KLAUSEL
Der Erfüllungsort ist der Sitz der Agentur. Der Gerichtsstand
entspricht dem Ort des Firmensitzes und dem damit für Dortmund
zuständigen Gericht. Sollten einzelne Bestimmungen dieser Bedingungen unwirksam sein oder werden, so wird die Wirksamkeit
der übrigen Bedingungen hierdurch nicht berührt. An die Stelle
der unwirksamen Bestimmung tritt eine Ersatzregelung, die dem
mit der unwirksamen Bestimmung angestrebten Zweck möglichst
nahe kommt.

                </div>
            </div>



            <div className="btndiv">
                <button className="popbtn" onClick={btnClicked}>I Agree </button>
            </div>  
      
        </div>}
    </>
      
    )
}

export default NewPopup;