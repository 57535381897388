import React,  { useEffect, useState } from 'react';
import './myinput.scss';

const NewInput = ({
  
  onChange,
  value,
  type,
  label,
  onKeyUp,
  buttonClicked = false,
  valid = true,
  errorMessage = null
}) => {
  
 


  const [emptyInput,setEmptyInput] = useState(false);
  useEffect(() => {
    if (value) setEmptyInput(true);
    else setEmptyInput(false);
  }, [value]);

  return (
      <input
        className='inputform1'
        type={type}
        onChange={onChange}
        onKeyUp={onKeyUp}
    />
  );
};

export default NewInput;