import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import ArticleCard from '../../components/card/ArticleCard'
import CourseCard from '../../components/card/CourseCard'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import moment from 'moment';
import config from '../../config/api.config';
import { logError } from "../../helpers/helpers";
import '../../assets/css/pages/favourites.scss';
import App from '../../App';

const WORDS_PER_MINUTE = 250;

const LastDone = (props) => {  
      const [members] = useState(null);
      const [articles, setArticles] = useState(null);
      const [courses, setCourses] = useState(null);
      const [ setLoading] = useState(true);
      const { t } = useTranslation()

      const ReadTime = (text) => {
            const words = text.split(" ");
            const time = Math.ceil(words.length / WORDS_PER_MINUTE);
            return time;
      };

      const getMembers = async (params) => {
            setLoading(true);
            // await axios
            //       .get(`${config.apiURL}/api/User`, {
            //             params: {
            //                   WorkspaceId: props.currentUser.workspaceId,
            //                   GetAll: true,
            //                   ...params
            //             }
            //       })
            //       .then((res) => {
            //             setMembers(
            //                   res.data.users.map((mem, i) => {
            //                         return {
            //                               id: mem.userId,
            //                               name: mem.firstName + ' ' + mem.lastName,
            //                               image: mem.image ? mem.image.url : null,
            //                               email: mem.email
            //                         };
            //                   })
            //             );
            //             setLoading(false);
            //       })
            //       .catch((err) => {
            //             setLoading(false);
            //             logError(err);
            //       });
      };

      const getArticles = async (params) => {
            setLoading(true);
            await axios
                  .get(`${config.apiURL}/api/Article`, {
                        params: {
                              WorkspaceId: props.currentUser.workspaceId,
                              ...params
                        }
                  })
                  .then((res) => {
                        setArticles(
                              res.data.articles.map((dep, i) => {
                                    return {
                                          id: dep.id,
                                          name: dep.title,
                                          categories: dep.categories,
                                          authorId: dep.authorId,
                                          createdDate: moment(dep.dateCreated,).format(`${t('momentjs_datum')}`),
                                          lastModified: dep.dateModified,
                                          content: dep.content,
                                          readTime: ReadTime(dep.content)
                                    };
                              })
                        );
                        setLoading(false);
                  })
                  .catch((err) => {
                        setLoading(false);
                        logError(err);
                  });
      };

      const getCourses = async (params) => {
            setLoading(true);
            await axios
                  .get(`${config.apiURL}/api/Course`, {
                        params: {
                              WorkspaceId: props.currentUser.workspaceId,
                              ...params
                        }
                  })
                  .then((res) => {
                        setCourses(
                              res.data.courses.map((dep, i) => {
                                    return {
                                          id: dep.id,
                                          name: dep.title,
                                          authorId: dep.author.authorId,
                                          authorImg: dep.author.imageUrl,
                                          authorName: dep.author.firstName + ' ' + dep.author.lastName,
                                          date: moment(dep.dateCreated).format('MMMM DD, YYYY'),
                                          description: dep.description
                                    };
                              })
                        );

                        setLoading(false);
                  })
                  .catch((err) => {
                        setLoading(false);
                        logError(err);
                  });
      };


      useEffect(() => {
            const abortController = new AbortController();

            if (!props.currentUser) return;
            getMembers();
            getArticles();
            getCourses();

            return () => {
                  abortController.abort();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.currentUser]);


      return (
            <App>
 <div className="favourite-seite-all-wrapper">
                  <div>
                        <div className='knowledge-favourites-heading-wrapper'>
                              <Breadcrumbs navigations={['last_done_articles']} />
                        </div>
                        <div className="favourites-articles-wrapper">
                              {articles === null || articles.length === 0 ? (
                                    <div>
                                          {t('nothing_found_article')}
                                    </div>
                              ) : (articles.map((article, i) => {
                                    return (
                                          <ArticleCard
                                                key={i}
                                                id={article.id}
                                                name={article.name}
                                                authorName={members && members.filter(mem => mem.id === article.authorId)[0].name}
                                                authorId={article.authorId}
                                                date={article.createdDate}
                                                categories={article.categories}
                                                content={article.content}
                                                readTime={article.readTime}
                                          />
                                    )
                              }))}
                        </div>
                  </div>
                  <div>
                        <div className='knowledge-later-heading-wrapper'>
                              <Breadcrumbs navigations={['last_done_courses']} />
                        </div>
                        <div className="favourites-courses-wrapper">
                              {courses === null || courses.length === 0 ? (
                                    <div>
                                          {t('nothing_found_course')}
                                    </div>
                              ) : (courses.map((course, i) => {
                                    return (
                                          <CourseCard
                                                key={i}
                                                id={course.id}
                                                name={course.name}
                                                authorId={course.authorId}
                                                authorImg={course.authorImg}
                                                authorName={course.authorName}
                                                date={course.date}
                                                description={course.description}
                                          />
                                    )
                              }))}
                        </div>
                  </div>
            </div>
            </App>
           
      )
}

const mapStateToProps = (state) => ({
      currentUser: state.user.currentUser
});

export default connect(mapStateToProps, null)(LastDone);



