import typeToReducer from "type-to-reducer";
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from "../../helpers/reduxPromiseMiddleware";
import { 
  SEARCH_TEAMS,
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {}
};

export default typeToReducer(
  {
    [SEARCH_TEAMS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { teams, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;
        let allTeams = payload.params?.GetAll ? payload.params.GetAll : false;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let teamNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(teams.length > 0){
          teams.forEach(team => {
            team['no'] = ++teamNo;
            if(allIds.indexOf(team.id) === -1){
              allIds.push(team.id);
            }
            if(!allTeams){
              searchIds.push(team.id);     
            }                 
            byId[team.id] = team;
          })
        }

        if(!allTeams){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        }; 
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_TEAM]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let team = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        team['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: team};

        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_TEAM]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { teamId, name, headId } = payload.params;
        let byId = state.byId;
        if(payload.data && byId[teamId]){
          byId[teamId].name = name;
          byId[teamId].headId = headId;
        }

        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_TEAM]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { teamId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(teamId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[teamId]){
            delete byId[teamId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
  },
  INITIAL_STATE
);
  