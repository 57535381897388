import React from 'react';
import { useHistory } from 'react-router-dom';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CommentIcon from '@material-ui/icons/Comment';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { useTranslation } from 'react-i18next';



function ArticleCard(props) {
  const history = useHistory();
  const { t } = useTranslation()
  return (
    props && (
      <div className={props.articleKlein ? `${props.articleKlein}`:'article-card'}>
        <div onClick={(event) => history.push(`/article/${props.id}`)}>
          <div className="article-card-img-wrapper">
            <img src='https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F138979855%2F450511843274%2F1%2Foriginal.20201118-093339?w=512&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C2160%2C1080&s=29ecf3636d3371ab0019420c7ea36b5d' alt={props.name} />
          </div>
          <div className="article-card-details-wrapper">
            <span className="article-card-symbol">Article</span>
            <div>
              <p className="article-card-author">by {props.authorName}</p>
              <p className="article-card-date">{props.date}</p>
            </div>
          </div>
          <h4 className="article-card-header-wrapper">
            <span>{props.name}</span>
          </h4>
          {props.articleKlein ? '' : (
            <p className="article-card-content">
              {/* <Markup content={props.content.p}/> */}
              Unsere Sprache hinkt dem gesellschaftlichen Wandel hinterher, sagt Diversity-Expertin Veronika Hucke. Ihr Appell an Führungskräfte: Ändern Sie Ihre Sprache, gestalten Sie sie fair. Es lohnt sich.
            </p>
          )} 
          <span className="article-card-readtime">{t('read_time')}: {props.readTime} Min.</span>
          {/* <div><a >Read more</a></div> */}
        </div>
        <div className="article-card-footer">
          {/* <BookmarkIcon style={{ color: 'black' }}/> */}
          <div>
            <CommentIcon style={{ color: 'black' }} fontSize={props.articleKlein ? 'small' : 'medium'}/>
            <span>120</span>
          </div>
          <WatchLaterIcon style={{ color: 'black' }} fontSize={props.articleKlein ? 'small' : 'medium'} />
          <BookmarkBorderIcon style={{ color: 'black' }} fontSize={props.articleKlein ? 'small' : 'medium'} />
          

        </div>
      </div>
    )
  );
}

export default ArticleCard;