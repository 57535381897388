import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { getUserById } from "../../redux/users/action";
import { connect, useDispatch } from "react-redux";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
}));

const ArticleCardOverview = (props) => {
  const {
    id,
    customClass = "",
    title,
    authorId,
    content,
    hasMarkUp,
    onClick,
    index,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [authorImg, setAuthorImg] = useState("");
  const [cardContent, setCardContent] = useState(content);
  const [isMounted, setIsMounted] = useState(true);
  const [author, setAuthor] = useState("");

  useEffect(() => {
    if (isMounted) {
      getAuthor(authorId);
      if (hasMarkUp) {
        setCardContent(content.replace(/<\/?[^>]+(>|$)/g, ""));
      }
    }
  }, [authorId]);

  useEffect(() => {
    return () => {
      setAuthorImg("");
      setIsMounted(false);
      setCardContent("");
    }
  }, []);

  const getAuthor = async (userId) => {  
    let image = "/profile.jpg"; 
    if(userId) {   
      if(props.members && props.members[userId]?.image){    
        image = `${config.resourcesUrl+props.members[userId].image.name}`; 
        setAuthor(props.members[userId].firstName + " " + props.members[userId].lastName);     
      }else{
        await dispatch(getUserById({ id: userId }))
        .then((res) => {
          if (res.value?.data) {
            let user = res.value.data;
            setAuthor(user.firstName + " " + user.lastName); 
            if(user.image?.name){
              image = `${config.resourcesUrl+user.image.name}`; 
            }
          }  
        })
        .catch((err) => {
          logError(err);
        });
      } 
    }   
    setAuthorImg(image);
  };

  const cardClickHandler = () => {
    onClick(id, index);
  }; 

  return (
    <Card
      className={`${classes.root} ${customClass} articleCard`}
      onClick={cardClickHandler}
    >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <img src={authorImg} className={"userphoto"} />
          </Avatar>
        }
        title={title}
        subheader={author}
      />
      <CardContent className="article-content">
        <Typography variant="body2" color="textSecondary" component="p">
          {cardContent/* .substring(0, 80) */}
        </Typography>         
      </CardContent>
      <span className="fade-out"></span>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(ArticleCardOverview);

