import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SnackbarError from "../../components/alert/SnackbarError";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import InputField from "../../components/inputField/inputField";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import FilePreview from "../../components/filePreview/filePreview";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import { connect, useDispatch } from "react-redux";
import { addMedia, updateMedia } from "../../redux/media/action";
import auth from "../../auth";
import moment from "moment";
import config from "../../config/api.config";

const MediaModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, closeModal, mediaId, selectedMedia, showSuccessMsg } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: "",
    shortDescription: "",
    length: "",
    size: "",
    type: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [editMedia, setEditMedia] = useState(null);
  const fileRef = useRef();

  useEffect(() => {
    if (mediaId && selectedMedia?.id === mediaId) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          title: selectedMedia.title,
          shortDescription: selectedMedia.description,
        };
      });
      setDisableUpload(true);
      setFile(selectedMedia.name);
      setEditMedia(selectedMedia);
    }
  }, [mediaId, selectedMedia]);

  const handleChange = (prop) => (event) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        [prop]: event.target.value,
      };
    });
  };

  const resetValues = () => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        title: "",
        shortDescription: "",
        length: "",
        size: "",
        type: "",
        duration: "",
      };
    });
    setUploadedFile(null);
    setDisableUpload(false);
    setErrorMessage("");
    setButtonClicked(false);
    setTypeError("");
    setSizeError("");
    setFile(null);
    setEditMedia(null);
  };

  const onFileChange = (event) => {
    let file = event.target.files[0];
    if (file && file.size > 10000000000 * 1024 * 1024) {
      setSizeError("Please upload a file smaller than 1GB");
      return false;
    }

    if (!config.allowedFileExtensions.includes(getFileExtension(file.name))) {
      setTypeError("File type not support");
      return false;
    }

    if (file) {
      setUploadedFile(file);
    }

    if (
      getFileExtension(file.name) === "mp4" ||
      getFileExtension(file.name) === "mp3"
    ) {
      var reader = new FileReader();
      reader.onload = function () {
        var aud = new Audio(reader.result);
        aud.onloadedmetadata = function () {
          var duration = moment.utc(aud.duration * 1000).format("mm:ss");
          setInputValues((prevState) => {
            return {
              ...prevState,
              type: getFileExtension(file.name),
              size: file.size,
              duration: duration,
            };
          });
        };
      };
      reader.readAsDataURL(file);
    } else {
      setInputValues((prevState) => {
        return {
          ...prevState,
          type: getFileExtension(file.name),
          size: file.size,
          duration: "",
        };
      });
    }
    setDisableUpload(true);

    setSizeError(null);
    setTypeError(null);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const addMediaHandler = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);
    const formData = new FormData();
    formData.append("File", uploadedFile);
    formData.append("Description", inputValues.shortDescription);
    formData.append("Title", inputValues.title);
    formData.append("creator", auth.getId());
    formData.append("duration", inputValues.duration);

    dispatch(addMedia(formData))
      .then((res) => {
        setButtonClicked(false);
        closeModal();
        resetValues();
        showSuccessMsg("Datei wurde erfolgreich hinzugefügt.");
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Hinzufügen von Media.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const editMediaHandler = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);
    let reqData = {
      mediaId: editMedia.id,
      title: inputValues.title,
      description: inputValues.shortDescription,
      name: editMedia.name,
    };

    dispatch(updateMedia(reqData))
      .then((res) => {
        setButtonClicked(false);
        closeModal();
        resetValues();
        showSuccessMsg("Datei wurde erfolgreich bearbeitet.");
      })
      .catch(function (err) {
        setButtonClicked(false);
        let errMsg = "Fehler bei Bearbeiten der Datei.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const deleteFilePreview = (file) => {
    setUploadedFile(null);
    setDisableUpload(false);
    fileRef.current.value = "";
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetValues();
        closeModal();
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="department-members articles-modal"
    >
      <SnackbarError open={openAlert} onClose={() => setOpenAlert(false)}>
        {errorMessage}
      </SnackbarError>
      <div className="department-members__header article-modal__header">
        <Breadcrumbs
          navigations={[
            `Medien`,
            mediaId ? `${t("Medien bearbeiten")}` : `${t("Hinzufügen")}`,
          ]}
        />
      </div>
      <div className="article-modal__content">
        <div className="article-modal__content-input">
          <InputField
            label={t("Medientitel")}
            value={inputValues.title}
            onChange={handleChange("title")}
          />
        </div>
        <div className="article-modal__content-input">
          <InputField
            label={t("Beschreibung")}
            value={inputValues.shortDescription}
            onChange={handleChange("shortDescription")}
          />
        </div>
        <div className="article-modal__content-input">
          <label
            htmlFor="file-upload"
            className="custom-file-upload"
            style={{ cursor: disableUpload ? "not-allowed" : "pointer" }}
          >
            <Upload />
            {t("Hier klicken um Datei hochzuladen")}
          </label>
          <input
            id="file-upload"
            className="article-modal__file-upload"
            type="file"
            ref={fileRef}
            onChange={onFileChange}
            disabled={disableUpload}
          />
        </div>
        <div className="article-modal__uploaded-files">
          {typeError ? <span style={{ color: "red" }}>{typeError}</span> : null}
          {sizeError ? <span style={{ color: "red" }}>{sizeError}</span> : null}
          <p>{t("Hochgeladene Datei")}</p>
          {file && (
            <div>
              <FilePreview
                name={file}
                customClass="modalFilePreview"
                allowDelete={false}
                edit={false}
              />
            </div>
          )}
          {uploadedFile && (
            <div>
              <FilePreview
                name={uploadedFile?.name}
                onClick={deleteFilePreview}
                customClass="modalFilePreview"
                edit={false}
              />
            </div>
          )}
          {uploadedFile == null && file == null && (
            <span>{t("Es wurde noch keine Datei hochgeladen")}</span>
          )}
        </div>
        <div className="departments__modal--buttons">
          <OutlinedButton
            style={{ height: "40px" }}
            onClick={() => {
              resetValues();
              closeModal();
            }}
          >
            {t("Abbrechen")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={
              inputValues.title &&
              inputValues.shortDescription &&
              (uploadedFile || file)
            }
            buttonClicked={buttonClicked}
            onClick={mediaId ? editMediaHandler : addMediaHandler}
          >
            {mediaId ? `${t("Speichern")}` : `${t("Hinzufügen")}`}
          </AddButton>
        </div>
      </div>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, null)(MediaModal);
