import React from 'react';
import Button from '@material-ui/core/Button';

import './outlinedButton.scss';

const OutlinedButton = ({ className, onClick, children, ...otherProps }) => {
  return (
    <Button
      variant="outlined"
      className={'button--outlined ' + className}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default OutlinedButton;
