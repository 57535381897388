import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next'

import './breadcrumbs.scss';

const CustomBreadcrumbs = ({ navigations }) => {
  const { t } = useTranslation()
  const renderNavigation = () =>
    navigations.map((nav, index) => (     
      <Typography
        key={index}
        className={
          navigations.length > 1 && index === navigations.length - 1
            ? 'breadcrumbs__nav--last'
            : 'breadcrumbs__nav'
        }
      >
        {nav.indexOf(':') > -1 ?  nav : t(nav)}
      </Typography>
    ));

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {renderNavigation()}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
