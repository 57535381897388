import React from "react";
import "./newError.scss";

const NewError = () => {
  return (
    <div className="errorstyles">
      Email oder Passwort ist falsch. Bitte noch mal anmelden.{" "}
    </div>
  );
};
export default NewError;
