import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import App from "../../App";
import auth from "../../auth";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getProcessById, getProcessTasks } from "../../redux/process/action";
import { Grid } from "@material-ui/core";
import { getUserById } from "../../redux/users/action";
import { getArticleById } from "../../redux/article/action";
import { searchMedias } from "../../redux/media/action";
import {
  completeTask,
  insertTaskAttachment,
  approveTask,
  rejectTask,
  deleteTaskAttachment,
  changeTaskDeadline,
  changeTaskResponsible,
} from "../../redux/task/action";
import "./tasks.scss";
import FilePreview from "../../components/filePreview/filePreview";
import { Link } from "react-router-dom";
import config from "../../config/api.config.js";
import { getFileType, getFileExtension, logError } from "../../helpers/helpers";
import MediaDisplayModal from "../Medias/MediaDisplayModal";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import AddButton from "../../components/auth/button";
import SnackbarError from "../../components/alert/SnackbarError";
import Comment from "./Comment";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import MembersSelect from "../../components/dropdown/membersSelect";
import DatePicker from "react-datepicker";

const TaskDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [task, setTask] = useState([]);
  const [member, setMember] = useState([]);
  const [process, setProcess] = useState([]);
  const [responsible, setResponsible] = useState("");
  const [benefactor, setBenefactor] = useState("");
  const [creator, setCreator] = useState("");
  const [notifies, setNotifies] = useState([]);
  const [articles, setArticles] = useState([]);
  const [medias, setMedias] = useState([]);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  const [comment, setComment] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [sizeError, setSizeError] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [allowComplete, setAllowComplete] = useState(false);
  const [userId, setUserId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [userType, setUserType] = useState("");
  const [comments, setComments] = useState([]);
  const [rejectButtonClicked, setRejectButtonClicked] = useState(false);
  const fileRef = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedRes, setSelectedRes] = useState("");
  const [actionType, setActionType] = useState("");
  const [selectedDeadline, setSelectedDeadline] = useState(null);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [updateComment, setUpdateComment] = useState("");
  const [taskIsActive, setTaskIsActive] = useState(false);

  useEffect(async () => {
    const abortController = new AbortController();
    if (!props.currentUser) return;
    setUserId(auth.getId());
    setUserType(props.location.state?.userType);

    if (props.location?.state?.task) {
      setTask(props.location.state.task);
    }

    if (props.location?.state?.member) {
      setMember(props.location.state.member);
    }

    if (props.location?.state?.isActive) {
      setTaskIsActive(props.location.state.isActive);
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(async () => {
    if (task?.medias?.length > 0 && props.mediaIds.length === 0) {
      await dispatch(searchMedias({ GetAll: true }));
    }
    await setTaskDetails();
    if (task.processId) {
      const processId = props.location.state.task.processId;
      let _process = [];

      if (props.processes && props.processes[processId]) {
        _process = props.processes[processId];
      } else {
        await dispatch(getProcessById({ id: processId }))
          .then((res) => {
            if (res.value?.data) {
              _process = res.value.data;
            }
          })
          .catch((err) => {
            logError(err);
            setErrorMessage("Fehler bei Laden des Prozesses.");
            setOpenAlert(true);
          });
      }
      setProcess(_process);
    } else {
      setProcess([]);
    }
  }, [task]);

  const setTaskDetails = async () => {
    setResponsible("");
    setBenefactor("");
    setCreator("");
    setNotifies([]);
    setComments([]);
    setUploadedAttachments([]);
    if (task) {
      if (
        task.responsible === userId &&
        !task.compeleted &&
        userType !== "task_benefactor" &&
        taskIsActive
      ) {
        setAllowComplete(true);
      } else {
        setAllowComplete(false);
      }
      if (task.compeleted) {
        setTaskCompleted(true);
      } else {
        setTaskCompleted(false);
      }
      if (task.responsible) {
        let user = await getUser(task.responsible);
        setResponsible(user);
      }
      if (task.benefactor) {
        let user = await getUser(task.benefactor);
        setBenefactor(user);
      }
      if (task.creator) {
        let user = await getUser(task.creator);
        setCreator(user);
      }

      if (task.notifies?.length > 0) {
        let _notifies = [];
        task.notifies.forEach((personId) => {
          getUser(personId).then((data) => {
            if (data) {
              _notifies.push(data);
            }
          });
          setNotifies(_notifies);
        });
      }

      if (task.articles?.length > 0) {
        let _articles = [];
        task.articles.forEach((articleId) => {
          getArticle(articleId).then((data) => {
            if (data) {
              _articles.push(data);
            }
          });
          setArticles(_articles);
        });
      }

      if (task.medias?.length > 0) {
        let _medias = [];
        task.medias.forEach((mediaId) => {
          getMedia(mediaId).then((data) => {
            if (data) {
              _medias.push(data);
            }
          });
          setMedias(_medias);
        });
      }

      if (task.comments?.length > 0) {
        setComments(task.comments);
      }

      if (task?.uploadedAttachments?.length > 0) {
        setUploadedAttachments(task.uploadedAttachments);
      }
    }
  };

  const getUser = async (id) => {
    let user = "";
    if (props.members && props.members[id]) {
      user = props.members[id].firstName + " " + props.members[id].lastName;
    } else if (props.members[id] === member?.id) {
      user = member.firstName + " " + member.lastName;
    } else {
      await dispatch(getUserById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            user = res.value.data.firstName + " " + res.value.data.lastName;
          }
        })
        .catch((err) => {
          setErrorMessage("Fehler bei Laden des Nutzers.");
          setOpenAlert(true);
        });
    }
    return user;
  };

  const getArticle = async (id) => {
    let article = "";
    if (props.articles && props.articles[id]) {
      article = props.articles[id];
    } else {
      await dispatch(getArticleById({ id: id, includeOwner: true }))
        .then((res) => {
          if (res.value?.data) {
            article = res.value.data;
          }
        })
        .catch((err) => {
          setErrorMessage("Fehler bei Laden des Artikels.");
          setOpenAlert(true);
        });
    }
    return article;
  };

  const getMedia = async (id) => {
    let media = "";
    if (props.medias && props.medias[id]) {
      media = props.medias[id];
    }
    return media;
  };

  const handleMediaDisplay = (id) => {
    let media = props.medias[id];
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl+media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  const attachmentClickHandler = (filename) => {
    window.open(`${config.resourcesUrl+filename}`, "_blank");
  };

  const onFileChange = (event) => {
    let file = event.target.files[0];

    if (file.size > 20 * 1024 * 1024) {
      setSizeError("Please upload a file smaller than 20 MB");
      return false;
    }
    if (!config.allowedFileExtensions.includes(getFileExtension(file.name))) {
      setTypeError("File type not support");
      return false;
    }

    if (event.target.files.length <= 0) return;

    // eslint-disable-next-line eqeqeq
    if (!selectedFile.find((f) => f.name == event.target.files[0].name))
      setSelectedFile([...selectedFile, event.target.files[0]]);
    setSizeError(null);
    setTypeError(null);
  };

  const deleteFilePreview = (file) => {
    // eslint-disable-next-line eqeqeq
    const tempFiles = selectedFile.filter((f) => f.name != file.name);
    setSelectedFile(tempFiles);
    fileRef.current.value = "";
  };

  const taskDoneHandler = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);

    await dispatch(
      completeTask({
        taskid: task.id,
        processid: process.id,
        comment: comment,
      })
    )
      .then(async (res) => {
        let isUploadError = false;
        let isDeleteError = false;

        if (selectedFile.length !== 0) {
          isUploadError = await uploadTaskAttachments(selectedFile);
        }

        if (attachmentsToDelete.length > 0) {
          isDeleteError = await deleteAttachments(attachmentsToDelete);
        }

        if (isUploadError && isDeleteError) {
          setErrorMessage("Fehler beim Hochladen und Löschen der Anhänge");
        } else if (isUploadError) {
          setErrorMessage("Fehler beim Hochladen der Anhänge");
        } else if (isDeleteError) {
          setErrorMessage("Fehler bei Löschen der Anhänge");
        } else {
          setSuccessMessage("Aufgabe wurde erfolgreich als erledigt markiert.");
        }
        setOpenAlert(true);
        setAllowComplete(false);
        setButtonClicked(false);
        resetValues();
        if (props.members && props.members[userId]) {
          dispatch(getUserById({ id: userId }));
        }
        if (props.processTasks && props.processTasks[process.id]) {
          dispatch(getProcessTasks({ processId: process.id }));
        }

        setTimeout(() => {
          history.push(`/dashboard`);
        }, 500);
      })
      .catch((err) => {
        logError(err);
        setOpenAlert(true);
        setErrorMessage("Fehler beim Senden.");
        setButtonClicked(false);
        resetValues();
      });
  };

  const resetValues = () => {
    setComment("");
    setSelectedFile([]);
    setSizeError("");
    setTypeError("");
    setAttachmentsToDelete([]);
    setUploadedAttachments([]);
    fileRef.current.value = "";
  };

  const taskApproveHandler = async () => {
    setOpenAlert(false);
    await setButtonClicked(true);

    await dispatch(
      approveTask({
        taskId: task.id,
        processId: process.id,
        comment: comment,
        userId: userId,
        taskResId: task.responsible,
      })
    )
      .then(async (res) => {
        let isUploadError = false;
        let isDeleteError = false;
        if (selectedFile.length !== 0) {
          isUploadError = await uploadTaskAttachments(selectedFile);
        }
        if (attachmentsToDelete.length > 0) {
          isDeleteError = await deleteAttachments(attachmentsToDelete);
        }
        if (isUploadError && isDeleteError) {
          setErrorMessage("Fehler beim Hochladen und Löschen der Anhänge");
        } else if (isUploadError) {
          setErrorMessage("Fehler beim Hochladen der Anhänge");
        } else if (isDeleteError) {
          setErrorMessage("Fehler bei Löschen der Anhänge");
        } else {
          setSuccessMessage("Aufgabe erfolgreich abgeschlossen.");
        }

        setButtonClicked(false);
        resetValues();
        setOpenAlert(true);
        setAllowComplete(false);
        if (props.members && props.members[userId]) {
          dispatch(getUserById({ id: userId }));
        }
        if (props.processTasks && props.processTasks[process.id]) {
          dispatch(getProcessTasks({ processId: process.id }));
        }

        setTimeout(() => {
          history.push(`/dashboard`);
        }, 500);
      })
      .catch((err) => {
        logError(err);
        setOpenAlert(true);
        setErrorMessage("Fehler bei Fertigmeldung der Aufgabe");
        setButtonClicked(false);
        resetValues();
      });
  };

  const sendToTaskOwnerHandler = async () => {
    setOpenAlert(false);
    await setRejectButtonClicked(true);

    await dispatch(
      rejectTask({
        taskId: task.id,
        processId: process.id,
        comment: comment,
        userId: userId,
        taskResId: task.responsible,
      })
    )
      .then(async (res) => {
        let isUploadError = false;
        let isDeleteError = false;

        if (selectedFile.length !== 0) {
          isUploadError = await uploadTaskAttachments(selectedFile);
        }

        if (attachmentsToDelete.length > 0) {
          isDeleteError = await deleteAttachments(attachmentsToDelete);
        }

        if (isUploadError && isDeleteError) {
          setErrorMessage("Fehler beim Hochladen und Löschen der Anhänge");
        } else if (isUploadError) {
          setErrorMessage("Fehler beim Hochladen der Anhänge");
        } else if (isDeleteError) {
          setErrorMessage("Fehler bei Löschen der Anhänge");
        } else {
          setSuccessMessage("Task send back to its owner.");
        }

        setOpenAlert(true);
        setRejectButtonClicked(false);
        resetValues();
        setAllowComplete(false);
        if (props.members && props.members[userId]) {
          dispatch(getUserById({ id: userId }));
        }
        if (props.processTasks && props.processTasks[process.id]) {
          dispatch(getProcessTasks({ processId: process.id }));
        }
        setTimeout(() => {
          history.push(`/dashboard`);
        }, 500);
      })
      .catch((err) => {
        logError(err);
        setOpenAlert(true);
        setErrorMessage("Fehler bei Rückgabe der Aufgabe");
        setRejectButtonClicked(false);
        resetValues();
      });
  };

  const deleteAttachments = async (files) => {
    let deleteError = false;
    for (const file of files) {
      await dispatch(
        deleteTaskAttachment({
          TaskId: task.id,
          ProcessId: process.id,
          taskOwner: task.responsible,
          fileName: file,
        })
      ).catch((err) => {
        deleteError = true;
        logError(err);
      });
    }
    return deleteError;
  };

  const uploadTaskAttachments = async (files) => {
    let uploadError = false;
    for (const file of files) {
      const formData = new FormData();
      formData.append("File", file);
      await dispatch(
        insertTaskAttachment({
          TaskId: task.id,
          ProcessId: process.id,
          taskOwner: task.responsible,
          formData: formData,
        })
      ).catch((err) => {
        uploadError = true;
        logError(err);
      });
    }
    return uploadError;
  };

  const deleteAttachmentHandler = (e, file) => {
    e.stopPropagation();
    let attachments = uploadedAttachments.filter((item) => {
      return item.id !== file.id;
    });
    setUploadedAttachments(attachments);
    setAttachmentsToDelete([...attachmentsToDelete, file.name]);
  };

  const resChangeHandler = (selectedVal) => {
    setSelectedRes(selectedVal);
    if (selectedVal === task.responsible) {
      setAllowUpdate(false);
    } else {
      setAllowUpdate(true);
    }
  };

  const updateHandler = async () => {
    await setButtonClicked(true);
    if (actionType === "changeRes") {
      updateResponsiblePerson();
    }
    if (actionType === "changeDeadline") {
      updateTaskDeadline();
    }
  };

  const deadLineHandler = (date) => {
    setSelectedDeadline(date);
    if (moment(task.dateBeDone).isSame(date)) {
      setAllowUpdate(false);
    } else {
      setAllowUpdate(true);
    }
  };

  const updateResponsiblePerson = async () => {
    let _comment =
      props.members[auth.getId()]?.firstName +
      " " +
      props.members[auth.getId()]?.lastName +
      " hat Namen für verantwortliche Person zu " +
      props.members[selectedRes]?.firstName +
      " " +
      props.members[selectedRes]?.lastName +
      " geändert";
    _comment = updateComment ? _comment + ". " + updateComment : _comment;
    await dispatch(
      changeTaskResponsible({
        taskId: task.id,
        processId: task.processId,
        userId: auth.getId(),
        responsible: selectedRes,
        prevRes: task.responsible,
        comment: _comment,
      })
    )
      .then(async (res) => {
        resetUpdateModal();
        setButtonClicked(false);
        setOpenAlert(true);
        setSuccessMessage("Responsible Person updated successfully.");
        setTimeout(() => {
          history.push(`/dashboard`);
        }, 500);
      })
      .catch((err) => {
        logError(err);
        setOpenAlert(true);
        setErrorMessage("Fehler bei Bearbeitung der Aufgabe");
        setButtonClicked(false);
      });
  };

  const updateTaskDeadline = async () => {
    let oldDate = moment(task.dateBeDone).format(`${t("momentjs_datum")}`);
    let newDate = moment(selectedDeadline).format(`${t("momentjs_datum")}`);
    let _comment =
      props.members[auth.getId()]?.firstName +
      " " +
      props.members[auth.getId()]?.lastName +
      " hat Fälligkeitsdatum verschoben von " +
      oldDate +
      " auf " +
      newDate;
    _comment = updateComment ? _comment + ". " + updateComment : _comment;
    await dispatch(
      changeTaskDeadline({
        taskId: task.id,
        processId: task.processId,
        responsible: task.responsible,
        userId: auth.getId(),
        deadline: selectedDeadline,
        comment: _comment,
      })
    )
      .then(async (res) => {
        resetUpdateModal();
        setButtonClicked(false);
        setOpenAlert(true);
        setSuccessMessage("Bearbeitung des Fälligkeitsdatums erfolgreich.");
        setTimeout(() => {
          history.push(`/dashboard`);
        }, 500);
      })
      .catch((err) => {
        logError(err);
        setOpenAlert(true);
        setErrorMessage("Fehler bei Bearbeitung der Aufgabe");
        setButtonClicked(false);
      });
  };

  const resetUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelectedRes("");
    setActionType("");
    setSelectedDeadline(null);
    setAllowUpdate(false);
    setUpdateComment("");
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <MediaDisplayModal
        open={displayMedia}
        closeModal={() => {
          setDisplayMedia(false);
          setDisplayUrl("");
          setDisplayMediaName("");
          setDisplayMediaTitle("");
        }}
        mediaUrl={displayUrl}
        title={displayMediaTitle}
        name={displayMediaName}
      />
      <div
        className="article-details-wrapper px-2"
        style={{ backgroundColor: "white" }}
      >
        <div className="article-details">
          <div className="article-details__header">
            <div>
              <span>{"Aufgabe:"} </span>&nbsp;&nbsp;{task.name}
            </div>
          </div>
          <div className="article-details__content">
            <Grid container className="mt-4 task-details__content">
              <Grid
                item
                xs={12}
                md={6}
                className="p-4"
                style={{ borderRight: "1px solid #ccc" }}
              >
                <div className="task-details__comment">
                  <h5>Kommentarverlauf:</h5>
                  {comments.length > 0 ? (
                    comments.map((comment, i) => {
                      return (
                        <React.Fragment key={i}>
                          {comment.message && (
                            <Comment
                              userId={comment.userId}
                              comment={comment.message}
                              date={comment.dateCreated}
                            />
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <p className="task-details__nocomment">
                      {"Keine Kommentare vorhanden"}
                    </p>
                  )}
                </div>
                <div className="task-details__comment">
                  <h5>Anhänge</h5>
                  {uploadedAttachments.length > 0 ? (
                    uploadedAttachments.map((file, i) => (
                      <div
                        onClick={() => attachmentClickHandler(file?.name)}
                        key={file?.name}
                        style={{ marginBottom: "1.5rem" }}
                      >
                        <FilePreview
                          name={file.name}
                          customClass="modalFilePreview"
                          edit={true}
                          allowDelete={
                            (taskCompleted && userType !== "process_owner") ||
                            userType === "task_benefactor"
                              ? false
                              : true
                          }
                          onClick={(e) => deleteAttachmentHandler(e, file)}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="task-details__nocomment">
                      {"Keine Anhänge vorhanden"}
                    </p>
                  )}
                </div>
                <p>Kommentar:</p>
                <textarea
                  type="text"
                  value={comment}
                  multiple={true}
                  rows={5}
                  style={{
                    borderRadius: "0.625rem",
                    border: "solid 1px #cfcfcf",
                    width: "100%",
                    paddingTop: 10,
                    outline: "none",
                    paddingLeft: "0.6rem",
                    color: "#535353",
                    fontSize: "1rem",
                    marginBottom: "10px",
                    cursor:
                      (task.compeleted && userType !== "process_owner") ||
                      userType === "task_benefactor"
                        ? "not-allowed"
                        : "auto",
                  }}
                  onChange={({ target }) => setComment(target.value)}
                  disabled={
                    (taskCompleted && userType !== "process_owner") ||
                    userType === "task_benefactor"
                      ? true
                      : false
                  }
                />

                <div className="task-details__content-input">
                  <label
                    htmlFor="file-upload"
                    className="custom-file-upload"
                    style={{
                      cursor:
                        (task.compeleted && userType !== "process_owner") ||
                        userType === "task_benefactor"
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <Upload />
                    {t("Hier klicken um Datei hochzuladen")}
                  </label>
                  <input
                    id="file-upload"
                    className="article-modal__file-upload"
                    type="file"
                    ref={fileRef}
                    onChange={onFileChange}
                    disabled={
                      (taskCompleted && userType !== "process_owner") ||
                      userType === "task_benefactor"
                        ? true
                        : false
                    }
                  />
                </div>

                <div className="article-modal__uploaded-files">
                  {typeError ? (
                    <span style={{ color: "red" }}>{typeError}</span>
                  ) : null}
                  {sizeError ? (
                    <span style={{ color: "red" }}>{sizeError}</span>
                  ) : null}
                  {selectedFile.length > 0 ? (
                    <div>
                      {selectedFile.map((file) => (
                        <FilePreview
                          key={file?.name}
                          name={file?.name}
                          onClick={() => deleteFilePreview(file)}
                        />
                      ))}
                    </div>
                  ) : (
                    <span>{t("Aktuell keine Anhänge vorhanden")}</span>
                  )}
                </div>
                {(userType === "task_owner" ||
                  userType === "task_benefactor") && (
                  <AddButton
                    className="task-details__done task-details__btn"
                    valid={allowComplete ? true : false}
                    buttonClicked={buttonClicked}
                    onClick={() => {
                      if (task.responsible === process.responsible) {
                        taskApproveHandler();
                      } else {
                        taskDoneHandler();
                      }
                    }}
                  >
                    {t("Aufgabe als erledigt markieren")}
                  </AddButton>
                )}

                {userType === "process_owner" && (
                  <AddButton
                    className="task-details__approve task-details__btn"
                    valid={true}
                    buttonClicked={buttonClicked}
                    onClick={taskApproveHandler}
                    style={{ width: "14.175rem !important" }}
                  >
                    {t("task_completed")}
                  </AddButton>
                )}

                {userType === "process_owner" && (
                  <AddButton
                    className="task-details__reject task-details__btn"
                    valid={true}
                    buttonClicked={rejectButtonClicked}
                    onClick={sendToTaskOwnerHandler}
                  >
                    {t("back_to_task_owner")}
                  </AddButton>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="p-4 task-details__content">
                <p>
                  {" "}
                  <span>{t("Prozessname")}:</span> {process.name}
                </p>
                <p>
                  {" "}
                  <span>{t("date_created")}:</span>{" "}
                  {moment(task.dateCreated).format(`${t("momentjs_datum")}`)}
                </p>
                <p>
                  {" "}
                  <span>{t("responsible")}:</span> {responsible}
                </p>
                <p>
                  {" "}
                  <span>{t("benefactor_name")}:</span> {benefactor}
                </p>
                <p>
                  {" "}
                  <span>{t("task_creator")}:</span> {creator}
                </p>
                <p>
                  {" "}
                  <span>{t("notifies")}:</span>{" "}
                  {notifies.length > 0 ? notifies.join(", ") : ""}
                </p>
                <p>
                  {" "}
                  <span>{t("to_be_completed_until")}:</span>{" "}
                  {moment(task.dateBeDone).format(`${t("momentjs_datum")}`)}
                </p>
                <p>
                  <span>{t("description")}:</span> <br />
                  {task.description}
                </p>
                <p>
                  <span>{t("articles")}:</span> <br />
                  {articles.length > 0 &&
                    articles.map((article, i) => (
                      <React.Fragment key={i}>
                        <Link
                          to={`/article/${article.id}`}
                          target="_blank"
                          style={{ color: config.colorSecondary }}
                        >
                          {article.title}
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                      </React.Fragment>
                    ))}
                </p>
                <p>
                  <span>{t("medias")}:</span> <br />
                  {medias.length > 0 &&
                    medias.map((media, i) => (
                      <React.Fragment key={i}>
                        <a
                          href="#"
                          onClick={() => handleMediaDisplay(media.id)}
                          style={{ color: config.colorSecondary }}
                        >
                          {media.title}
                        </a>
                        &nbsp;&nbsp;&nbsp;
                      </React.Fragment>
                    ))}
                </p>
                <p>
                  <span>{t("attachments")}:</span> <br />
                </p>
                <div>
                  {task.files?.length > 0 &&
                    task.files.map((file, i) => (
                      <div
                        onClick={() => attachmentClickHandler(file?.name)}
                        key={file?.name}
                      >
                        <FilePreview
                          name={file.originalName}
                          customClass="modalFilePreview"
                          edit={true}
                          allowDelete={false}
                        />
                      </div>
                    ))}
                </div>
                {(userType !== "process_owner" || userType !== "task_owner") && (
                  <div style={{ marginTop: "50px" }}>
                    <AddButton
                      className="task-details__done task-details__btn"
                      valid={!buttonClicked && !rejectButtonClicked}
                      buttonClicked={false}
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setActionType("changeRes");
                      }}
                    >
                      {t("change_responsible")}
                    </AddButton>
                    <AddButton
                      className="task-details__reject task-details__btn"
                      valid={!buttonClicked && !rejectButtonClicked}
                      buttonClicked={false}
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setActionType("changeDeadline");
                      }}
                    >
                      {t("change_deadline")}
                    </AddButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Modal
        title={
          actionType === "changeRes"
            ? "Change Responsible Person"
            : "Change Deadline"
        }
        open={openUpdateModal}
        closeModal={resetUpdateModal}
      >
        {actionType === "changeRes" && (
          <MembersSelect
            className="sort-input positions__select-department"
            placeholder={"Verantwortliche Person auswählen"}
            selectedOption={selectedRes ? selectedRes : task?.responsible}
            onChange={resChangeHandler}
          />
        )}
        {actionType === "changeDeadline" && (
          <DatePicker
            selected={
              selectedDeadline ? selectedDeadline : new Date(task?.dateBeDone)
            }
            onChange={(date) => deadLineHandler(date)}
            placeholderText="Datum"
            minDate={new Date()}
          />
        )}
        <div style={{ marginBottom: "10px" }}>
          <strong>Kurzbeschreibung angeben:</strong>
        </div>
        <textarea
          type="text"
          placeholder={t("Kurzbeschreibung des Prozesses:")}
          value={updateComment}
          multiple={true}
          rows={5}
          style={{
            borderRadius: "0.625rem",
            border: "solid 1px #cfcfcf",
            width: "100%",
            paddingTop: 10,
            outline: "none",
            paddingLeft: "0.6rem",
            color: "#535353",
            fontSize: "1rem",
            marginBottom: "10px",
          }}
          onChange={({ target }) => setUpdateComment(target.value)}
        />
        <div className="departments__modal--buttons">
          <OutlinedButton onClick={resetUpdateModal}>
            {t("Abbrechen")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={allowUpdate}
            buttonClicked={buttonClicked}
            onClick={updateHandler}
          >
            {"Bearbeiten"}
          </AddButton>
        </div>
      </Modal>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  processes: state.process.byId,
  memberIds: state.user.allIds,
  members: state.user.byId,
  articles: state.article.byId,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
  processTasks: state.process.tasks,
});

export default connect(mapStateToProps, null)(TaskDetails);
