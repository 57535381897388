import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import "../../assets/css/pages/login.scss";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import NewButton from "../../components/buttons/NewButton.js";
import Logo from "../../layout/logo/logo.js";
import NewInput from "../../components/inputField/NewInput.js";
import NewLabel from "../../components/label/NewLabel.js";

const ResetPasswordFirstPage = ({ firstPage, secondPage }, props) => {
  const [valid, setValid] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);

  const query_string = queryString.parse(location.search);
  const [values, setValues] = useState({
    token: query_string.token,
    email: query_string.email,
    confirmPassword: "",
    password: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // [] **************************************************************************************

  useEffect(() => {
    if (values.password && values.confirmPassword) setValid(true);
    else setValid(false);
  }, [values.password, values.confirmPassword]);

  useEffect(() => {
    setPasswordValid(true);
  }, [values.password]);

  useEffect(() => {
    setConfirmPasswordValid(true);
  }, [values.confirmPassword]);

  const handleSubmit = async () => {
    let valid = true;

    if (values.password.length < 6) {
      setPasswordValid(false);
      valid = false;
    }

    if (values.password !== values.confirmPassword) {
      setConfirmPasswordValid(false);
      valid = false;
    }

    if (valid) {
      await setButtonClicked(true);

      await axios
        .post(`${config.apiURL}/api/Account/change-password`, values)
        .then((res) => {
          setButtonClicked(false);
          firstPage(false);
          secondPage(true);
        })
        .catch((err) => {
          setButtonClicked(false);
          logError(err);
        });
    }
  };

  return (
    <div className="main2">
      <div className="login">
        <div className="headerp">
          <Logo />
        </div>

        <div className="Login2">
          <h2 style={{ marginTop: "4rem" }}>Neues Passwort vergeben</h2>
          <div className="main">
            <form>
              <NewLabel name={"Passwort"} style={{ marginRight: "250px" }} />
              <NewInput
                type={"password"}
                onChange={handleChange("password")}
                value={values.password}
                label={"Password"}
                valid={passwordValid}
                errorMessage={"Passwort muß mind. 6 Zeichen lang sein."}
              />
              <NewLabel name={"Passwort bestätigen"} />
              <NewInput
                type={"password"}
                onChange={handleChange("confirmPassword")}
                value={values.confirmPassword}
                label={"Confirm password"}
                valid={confirmPasswordValid}
                errorMessage={"Password confirmation and Password must match."}
              />
            </form>

            <NewButton
              style={{ marginTop: "40px" }}
              name={t("Passwort ändern")}
              onClick={handleSubmit}
              className="login__button forgotPassword__button"
              valid={valid}
              buttonClicked={buttonClicked}
            ></NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordFirstPage;
