import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import config from "../../config/api.config.js";
import { getFileType, logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import App from "../../App";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import ATT from "array-to-tree";
import { Tree, TreeNode } from "react-organizational-chart";
import {
  getProcessById,
  getProcessTasks,
  completeProcess,
} from "../../redux/process/action";
import { searchUsers } from "../../redux/users/action";
import TaskCard from "../../components/card/TaskCard";
import moment from "moment";
import { searchMedias } from "../../redux/media/action";
import { searchArticles } from "../../redux/article/action";
import MediaDisplayModal from "../Medias/MediaDisplayModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddButton from "../../components/auth/button";
import TaskDetailsModal from "../Tasks/TaskDetailsModal";
import SnackbarError from "../../components/alert/SnackbarError";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import auth from "../../auth";
import { useHistory } from "react-router";

const ProcessDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [process, setProcess] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [sort, setSort] = useState([]);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isAllTasksCompleted, setIsAllTasksCompleted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [isProcessOwner, setIsProcessOwner] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;

    if (props.memberIds.length === 0) {
      dispatch(searchUsers({ GetAll: true }));
    }

    if (props.mediaIds.length === 0) {
      dispatch(searchMedias({ GetAll: true }));
    }

    if (props.articleIds.length === 0) {
      dispatch(searchArticles({ Include_owner: true }));
    }

    getProcess(props.match.params.id);
    getTasks(props.match.params.id);

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser, props.match.params.id]);

  const StyledNodeR = () => <div></div>;

  const CustomCard = (propss) => {
    return (
      <>
        {propss.data.map((item, index) => {
          let color = "";
          let resPerson = "";
          let status = "";
          let comment = "";
          if (!item.responsible) {
            color = "red";
            status = "Status: Aufgabe hat keine Zuweisung";
            comment = "Aufgabe wurde noch keiner Person zugewiesen!";
          } else if (item.compeleted && item.approved) {
            color = "#B4D264";
            status = "Status: Aufgabe erledigt und genehmigt!";
          } else if (item.compeleted && !item.approved) {
            color = "#FF6FCC";
            status = "Status: Aufgabe erledigt aber nicht genehmigt!";
          } else if (!item.compeleted && !item.approved) {
            if (moment().isAfter(item.dateBeDone)) {
              color = "red";
              status = "Status: Kritisch! Termine überschritten.";
            } else {
              color = "#D076FF";
              status = "Status: Aufgabe wurde noch nicht erledigt.";
            }
          }

          if (props.members && props.members[item.responsible]) {
            resPerson =
              props.members[item.responsible].firstName +
              " " +
              props.members[item.responsible].lastName;
          }

          return (
            <TreeNode
              key={index}
              label={
                <StyledNode
                  keepId={item.id}
                  text={item.name}
                  des={item.description}
                  key={index}
                  color={color}
                  status={status}
                  resPerson={resPerson}
                  comment={comment}
                />
              }
            >
              {item.children?.length && (
                <CustomCard data={item.children} key={index} />
              )}
            </TreeNode>
          );
        })}
      </>
    );
  };

  const StyledNode = ({
    text,
    des,
    color,
    status,
    resPerson,
    keepId,
    comment,
  }) => {
    const cardClickHandler = async () => {
      const _task = tasks.filter((item) => item.id === keepId);
      if (_task.length > 0) {
        setSelectedTask(_task[0]);
      } else {
        setSelectedTask([]);
      }
      setOpenTaskModal(true);
    };
    return (
      <TaskCard
        color={config.colorSecondary}
        text={text}
        des={des}
        //color={color}
        status={status}
        resPerson={resPerson}
        onCardClick={cardClickHandler}
        borderWidth="3px"
        shortDes={comment}
      />
    );
  };

  const getProcess = async (id) => {
    let _process = [];
    if (props.processes && props.processes[id]) {
      _process = props.processes[id];
    } else {
      await dispatch(getProcessById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            _process = res.value.data;
          }
        })
        .catch((err) => logError(err));
    }
    setProcess(_process);
    if (_process?.responsible === auth.getId()) {
      setIsProcessOwner(true);
    } else {
      setIsProcessOwner(false);
    }
  };

  const getTasks = async (processId) => {
    let _tasks = [];
    setisLoading(true);
    await dispatch(getProcessTasks({ processId: processId }))
      .then((res) => {
        if (res.value?.data) {
          _tasks = res.value.data.tasks;
          setisLoading(false);
        }
      })
      .catch((err) => {
        logError(err);
        setisLoading(false);
        setErrorMessage("Fehler bei Laden des Prozesses Tasks.");
        setOpenAlert(true);
      });
    setTasks(_tasks);
    if (_tasks.length > 0) {
      const completedTasks = _tasks.filter((item) => {
        return item.compeleted === true && item.approved === true;
      });
      if (completedTasks.length === _tasks.length) {
        setIsAllTasksCompleted(true);
      } else {
        setIsAllTasksCompleted(false);
      }
    }

    setSort(
      ATT(_tasks, {
        parentProperty: "preTask",
        customID: "id",
      })
    );
  };

  const resetValues = () => {
    setOpenTaskModal(false);
    setSelectedTask([]);
  };

  const attachmentClickHandler = (filename) => {
    window.open(`${config.resourcesUrl+filename}`, "_blank");
  };

  const handleMediaDisplay = (id) => {
    let media = props.medias[id];
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl+media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  const processCompleteHandler = async (processId) => {
    await setButtonClicked(true);
    await dispatch(completeProcess({ processId: processId }))
      .then((res) => {
        setButtonClicked(false);
        setSuccessMessage("Prozess wurde erfolgreich abgeschlossen.");
        setOpenAlert(true);
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        let errMsg = "Fehler bei Abschluss des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Breadcrumbs navigations={[process.name ? process.name : ""]} />
      <MediaDisplayModal
        open={displayMedia}
        closeModal={() => {
          setDisplayMedia(false);
          setDisplayUrl("");
          setDisplayMediaName("");
          setDisplayMediaTitle("");
        }}
        mediaUrl={displayUrl}
        title={displayMediaTitle}
        name={displayMediaName}
      />
      <br></br>
      <br></br>
      {isLoading ? (
        <div>
          <CircularProgress style={{ color: config.colorPrimary }} />
        </div>
      ) : !isLoading && tasks.length === 0 ? (
        <>
          <div
            style={{
              width: "100%",
              height: 100,
              display: "flex",
              border: `1px solid ${config.colorSecondary}`,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              color: `${config.colorSecondary}`,
              fontSize: 22,
            }}
          >
            Aktuell sind diesem Prozess keine Aufgaben zugewiesen.
          </div>
        </>
      ) : (
        <>
          <Tree
            lineWidth={"2px"}
            lineColor={"green"}
            lineBorderRadius={"10px"}
            label={<StyledNodeR />}
          >
            <CustomCard data={sort} />
          </Tree>

          {!process.isTemplate && (
            <AddButton
              className="departments__modal--add ml-0"
              valid={isAllTasksCompleted && !process.compeleted}
              onClick={() => {
                processCompleteHandler(process.id);
              }}
              buttonClicked={buttonClicked}
              disabled={process?.compeleted || !isAllTasksCompleted}
              style={{
                cursor: process?.compeleted ? "not-allowed" : "pointer",
                float: "right",
                margin: "50px 0 0 0",
              }}
            >
              {!process.compeleted && "Prozess abschließen"}
              {process.compeleted && "Prozess erfolgreich abgeschloßen"}
            </AddButton>
          )}

          {(isProcessOwner || process.isTemplate) && (
            <AddButton
              className="departments__modal--add "
              valid={!process.compeleted}
              onClick={() =>
                history.push(`/edit-process/${process.id}`, {
                  type: "edit",
                  step: "editProcess",
                })
              }
              buttonClicked={false}
              style={{
                float: "right",
                margin: "50px 20px 0 0",
              }}
            >
              Prozess bearbeiten
            </AddButton>
          )}
        </>
      )}

      <TaskDetailsModal
        open={openTaskModal}
        closeModal={() => {
          resetValues();
        }}
        onAttachmentClick={attachmentClickHandler}
        onMediaClick={handleMediaDisplay}
        selectedTask={selectedTask}
        isProcessTask={!process.isTemplate}
      />
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  processes: state.process.byId,
  memberIds: state.user.allIds,
  members: state.user.byId,
  articleIds: state.article.allIds,
  articles: state.article.byId,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
});

export default connect(mapStateToProps, null)(ProcessDetails);
