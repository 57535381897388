export class ApiConfig {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.apiURL = process.env.REACT_APP_API_URL_2;
    this.publicUrl = process.env.PUBLIC_URL;
    this.resourcesUrl = process.env.RESOURCES_URL ? process.env.RESOURCES_URL
                      : process.env.REACT_APP_API_URL + '/Resources/';
    this.colorPrimary = process.env.REACT_APP_COLOR_PRIMARY? process.env.REACT_APP_COLOR_PRIMARY : '#009EA4';
    this.colorSecondary = process.env.REACT_APP_COLOR_SECONDARY? process.env.REACT_APP_COLOR_SECONDARY : '#009EA4';
    this.colorActive = process.env.REACT_APP_COLOR_ACTIVE? process.env.REACT_APP_COLOR_ACTIVE : '#D2E298';
    this.colorHover = process.env.REACT_APP_COLOR_HOVER? process.env.REACT_APP_COLOR_HOVER : '#009EA4';
    this.company = process.env.REACT_APP_COMPANY;
    this.consoleActivated = process.env.CONSOLE_ACTIVATED ? process.env.CONSOLE_ACTIVATED : false;

    this.allowedAudioExtensions = ['mp3'];
    this.allowedDocumentExtensions = ['csv', 'doc', 'docx', 'dot', 'dotx', 'pdf', 'xlsm', 'xlsx'];
    this.allowedFileExtensions = ['csv', 'doc', 'docx', 'dot', 'dotx', 'gif', 'jpeg', 'jpg', 'mp3', 'mp4', 'mpeg', 'pdf', 'png', 'webp', 'xlsm', 'xlsx'];
    this.allowedImageExtensions = ['gif', 'jpeg', 'jpg', 'png', 'webp'];
    this.allowedVideoExtensions = ['mp4', 'mpeg'];
  }
}
const config = new ApiConfig();
export default config;
