import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import Modal from "../../components/modal/modal";

import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import FourthStep from "./fourthStep";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers.js";
import auth from "../../auth";

import "../../assets/css/pages/courses.scss";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const AddCourse = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  function getSteps() {
    return [
      `${t("Profile")}`,
      `${t("Articles")}`,
      `${t("Videos")}`,
      `${t("Submit")}`,
    ];
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    name: "",
    description: "",
  });
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [editArticles, setEditArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [editVideos, setEditVideos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOptionsDepartments, setSelectedOptionsDepartments] = useState(
    []
  );
  const [selectedOptionsCategories, setSelectedOptionsCategories] = useState(
    []
  );
  const [courseId, setCourseId] = useState(null);
  const steps = getSteps();
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--color-primary", config.colorPrimary);
    root?.style.setProperty("--color-secondary", config.colorSecondary);
    root?.style.setProperty("--color-active", config.colorActive);
    root?.style.setProperty("--color-hover", config.colorHover);
    setReadyToRender(true);
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex, user) => {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            currentUser={user}
            values={values}
            setValues={setValues}
            setSelectedDepartments={setSelectedDepartments}
            setSelectedCategories={setSelectedCategories}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedOptionsDepartments={selectedOptionsDepartments}
            selectedOptionsCategories={selectedOptionsCategories}
          />
        );
      case 1:
        return (
          <SecondStep
            currentUser={user}
            selectedArticles={selectedArticles}
            setSelectedArticles={setSelectedArticles}
            editArticles={editArticles}
            setEditArticles={setEditArticles}
            courseId={courseId}
          />
        );
      case 2:
        return (
          <ThirdStep
            videos={videos}
            setVideos={setVideos}
            editVideos={editVideos}
            setEditVideos={setEditVideos}
            courseId={courseId}
          />
        );
      case 3:
        return (
          <FourthStep
            currentUser={user}
            selectedArticles={selectedArticles}
            setSelectedArticles={setSelectedArticles}
            editArticles={editArticles}
            videos={videos}
            setVideos={setVideos}
            editVideos={editVideos}
            setEditVideos={setEditVideos}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleSubmit = async () => {
    await setButtonClicked(true);
    await axios
      .post(`${config.apiURL}/api/Course`, {
        title: values.name,
        visibleDepartments: selectedDepartments.map((dep) => dep.value),
        categories: selectedCategories.map((cat) => {
          return { id: cat.value, name: cat.label };
        }),
        workspaceId: props.currentUser.workspaceId,
        description: values.description,
        author: {
          userId: auth.getId(),
          firstName: props.currentUser.firstName,
          lastName: props.currentUser.lastName,
          imageUrl: props.currentUser.image
            ? props.currentUser.image.url
            : "/profile.jpg",
        },
      })
      .then(async (res) => {
        const { id } = res.data;
        let uploadImage = null;
        let articles = null;
        let files = null;
        if (selectedFile) {
          const formData = new FormData();
          formData.append("File", selectedFile);
          uploadImage = await axios.put(
            `${config.apiURL}/api/Course/update-image/${id}`,
            formData
          );
        }

        if (selectedArticles.length) {
          articles = await Promise.all(
            selectedArticles.map(async (article) => {
              return await axios.put(
                `${config.apiURL}/api/Course/insert-article/${id}`,
                {
                  articleId: article,
                }
              );
            })
          );
        }

        if (videos.length) {
          files = await Promise.all(
            videos.map(async (file) => {
              const formData = new FormData();
              formData.append("File", file);
              return await axios.put(
                `${config.apiURL}/api/Course/insert-video/${id}`,
                formData
              );
            })
          );
        }

        await Promise.allSettled([uploadImage, articles, files])
          .then((res) => {
            setOpenModal(true);
            setButtonClicked(false);
          })
          .catch((err) => {
            logError(err);
            setOpenModal(false);
            setButtonClicked(false);
          });
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
      });
  };

  const handleEdit = async (id) => {
    await setButtonClicked(true);
    await axios
      .put(`${config.apiURL}/api/Course/${id}`, {
        title: values.name,
        visibleDepartments: selectedDepartments.map((dep) => dep.value),
        categories: selectedCategories.map((cat) => {
          return { id: cat.value, name: cat.label };
        }),
        description: values.description,
      })
      .then(async (res) => {
        let uploadImage = null,
          articles = null,
          files = null;
        if (selectedFile && !selectedFile.url) {
          const formData = new FormData();
          formData.append("File", selectedFile);
          uploadImage = await axios.put(
            `${config.apiURL}/api/Course/update-image/${id}`,
            formData
          );
        }

        if (selectedArticles.length) {
          articles = await Promise.all(
            selectedArticles.map(async (article) => {
              return await axios.put(
                `${config.apiURL}/api/Course/insert-article/${id}`,
                {
                  articleId: article,
                }
              );
            })
          );
        }

        if (videos.length) {
          files = await Promise.all(
            videos.map(async (file) => {
              const formData = new FormData();
              formData.append("File", file);
              return await axios.put(
                `${config.apiURL}/api/Course/insert-video/${id}`,
                formData
              );
            })
          );
        }

        await Promise.allSettled([files, uploadImage, articles])
          .then((res) => {
            setOpenModal(true);
            setButtonClicked(false);
          })
          .catch((err) => {
            logError(err);
            setOpenModal(false);
            setButtonClicked(false);
          });
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
      });
  };

  const GetCourseById = async (id) => {
    await axios
      .get(`${config.apiURL}/api/Course/${id}`)
      .then((res) => {
        setValues({ name: res.data.title, description: res.data.description });
        setSelectedFile(res.data.image);
        setSelectedOptionsDepartments(res.data.visibleDepartments);
        setSelectedOptionsCategories(res.data.categories.map((cat) => cat.id));
        setEditArticles(res.data.articles.map((article) => article.id));
        setEditVideos(res.data.videos);
      })
      .catch((err) => logError(err));
  };
  useEffect(() => {
    if (location.pathname.includes("edit-course")) {
      GetCourseById(props.match.params.id);
      setCourseId(props.match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      {readyToRender && (
        <div className="article-details add-course">
          <Modal
            title={""}
            open={openModal}
            closeModal={() => {
              setOpenModal(false);
              history.push("/courses");
            }}
          >
            {courseId ? (
              <Fragment>
                <p>{t("succesfully_edited_course")} </p>
                <div className="departments__modal--buttons">
                  <OutlinedButton
                    onClick={() => {
                      setOpenModal(false);
                      history.push("/courses");
                    }}
                  >
                    {t("back")}
                  </OutlinedButton>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <p>{t("succesfully_added_course")} </p>
                <p>{t("want_add_quiz_for_course")} </p>
                <div className="departments__modal--buttons">
                  <OutlinedButton
                    onClick={() => {
                      setOpenModal(false);
                      history.push("/courses");
                    }}
                  >
                    {t("Abbrechen")}
                  </OutlinedButton>
                  <AddButton
                    className="departments__modal--add courses_modal--add-quiz"
                    valid={true}
                    buttonClicked={buttonClicked}
                    onClick={() => history.push("/courses")}
                  >
                    Add quiz
                  </AddButton>
                </div>
              </Fragment>
            )}
          </Modal>
          <div className="article-details__header">
            <div>
              <KeyboardArrowLeftIcon onClick={() => history.push("/courses")} />
              <span>{t("Courses")}</span>
            </div>
            <Breadcrumbs
              navigations={[
                courseId ? `${t("edit_course")}` : `${t("add_new_course")}`,
              ]}
            />
            <div />
          </div>

          <div className={classes.root}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="course-step"
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{ marginTop: "2rem" }}>
              {activeStep === steps.length ? null : (
                <Fragment>
                  {getStepContent(activeStep, props.currentUser)}
                  <div className="add-course__buttons">
                    {activeStep !== 0 ? (
                      <OutlinedButton
                        onClick={!buttonClicked ? handleBack : null}
                      >
                        {t("previous")}
                      </OutlinedButton>
                    ) : (
                      <div style={{ width: "8rem" }} />
                    )}
                    <AddButton
                      className="departments__modal--add"
                      valid={
                        values.name &&
                        values.description &&
                        selectedDepartments.length &&
                        selectedCategories.length
                          ? true
                          : false
                      }
                      onClick={
                        activeStep === steps.length - 1
                          ? location.pathname.includes("edit-course")
                            ? () => handleEdit(courseId)
                            : handleSubmit
                          : handleNext
                      }
                      buttonClicked={
                        activeStep === steps.length - 1 && buttonClicked
                      }
                    >
                      {activeStep === steps.length - 1
                        ? location.pathname.includes("edit-course")
                          ? `${t("Edit")}`
                          : `${t("Submit")}`
                        : `${t("next")}`}
                    </AddButton>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(AddCourse);
