import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Pagination from "../../components/pagination/pagination";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Backdrop from "../../components/backdrop/backdrop";
import SnackbarError from "../../components/alert/SnackbarError";
import auth from "../../auth";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import config from "../../config/api.config.js";
import { getFileType, logError } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/departments.scss";
import App from "../../App";
import FilePreview from "../../components/filePreview/filePreview";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import { searchTasks, addTask, updateTask, deleteTask, insertTaskFile, deleteTaskFile } from "../../redux/task/action";
import { searchArticles } from "../../redux/article/action";
import { searchMedias } from "../../redux/media/action";
import TaskDetailsModal from "./TaskDetailsModal";
import MediaDisplayModal from "../Medias/MediaDisplayModal";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";

const Tasks = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [taskEditId, setTaskEditId] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedSort, setSelectedSort] = useState(1);
  const [defaultPage, setDefaultPage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [arts, setArts] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [articles, setArticles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  //media states
  const [mds, setMds] = useState([]);
  const [selectedMedias, setSelectedMedias] = useState({
    search: { value: null, label: "All" },
    add: null,
  });
  const [medias, setMedias] = useState([]);
  //end media state

  const [sizeError, setSizeError] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [mustdelete, setMustdelete] = useState([]);
  const [editselectedFile, setEditselectedFile] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  const [selectedTask, setSelectedTask] = useState([]);

  const DeleteFromArts = (id) => {
    const newArrr2 = arts.filter((item) => {
      return item.id !== id;
    });
    setArts(newArrr2);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const onFileChange = (event) => {
    let file = event.target.files[0];

    if (file.size > 20 * 1024 * 1024) {
      setSizeError("Please upload a file smaller than 20 MB");
      return false;
    }
    if (!config.allowedFileExtensions.includes(getFileExtension(file.name))) {
      setTypeError("File type not support");
      return false;
    }

    if (event.target.files.length <= 0) return;

    // eslint-disable-next-line eqeqeq
    if (!selectedFile.find((f) => f.name == event.target.files[0].name)) setSelectedFile([...selectedFile, event.target.files[0]]);
    setSizeError(null);
    setTypeError(null);
  };

  const deleteFilePreview = (file) => {
    // eslint-disable-next-line eqeqeq
    const tempFiles = selectedFile.filter((f) => f.name != file.name);
    setSelectedFile(tempFiles);
  };

  const deleteEditFilePreview = (file) => {
    // eslint-disable-next-line eqeqeq
    const tempFiles = editselectedFile.filter((f) => f.name != file.name);
    setEditselectedFile(tempFiles);
    setMustdelete([...mustdelete, file.name]);
  };

  const DeleteFromMds = (id) => {
    const newArrr = mds.filter((item) => {
      return item.id !== id;
    });
    setMds(newArrr);
  };

  const AddTask = async () => {
    let artsArr = [];
    arts.map((item) => {
      artsArr.push(item.id);
    });

    let mdsArr = [];
    mds.map((item) => {
      mdsArr.push(item.id);
    });
    let data = {
      name: taskName,
      description: taskDescription,
      creator: auth.getId(),
      articles: artsArr,
      medias: mdsArr,
      compeleted: false,
    };

    dispatch(addTask(data))
      .then((res) => {
        let _errMsg = "";
        if (selectedFile.length !== 0) {
          Promise.all([
            selectedFile?.forEach(async (file) => {
              const formData = new FormData();
              formData.append("File", file);
              return await dispatch(
                insertTaskFile({
                  taskId: res.value.id,
                  formData: formData,
                })
              ).catch((err) => {
                _errMsg = "Fehler beim Hochladen der Anhänge.";
              });
            }),
          ])
            .then((res) => {
              setButtonClicked(false);
              setOpenModal(false);
              setTaskName("");
              setTaskDescription("");
              setSelectedFile([]);
              setArts([]);
              setMds([]);
              setTimeout(() => {
                if (_errMsg) {
                  setErrorMessage(_errMsg);
                  setOpenAlert(true);
                } else {
                  setSuccessMessage("Aufgabe wurde erfolgreich hinzugefügt.");
                  setOpenAlert(true);
                }
              }, 1000);
            })
            .catch((err) => {
              logError(err);
            });
        } else {
          setButtonClicked(false);
          setOpenModal(false);
          setTaskName("");
          setTaskDescription("");
          setSelectedFile([]);
          setArts([]);
          setMds([]);
          setSuccessMessage("Aufgabe wurde erfolgreich hinzugefügt.");
          setOpenAlert(true);
        }
      })
      .catch((err) => {
        logError(err);
        setButtonClicked(false);
        setOpenModal(false);
        setTaskName("");
        setTaskDescription("");
        let errMsg = "Fehler bei Hinzufügen der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const EditTaskModal = (id, name, description, mediass, articless, files) => {
    if (!isAdmin) return;

    setEditselectedFile(files);
    let filteredArray = medias.filter(function (n) {
      if (!mediass || !Array.isArray(mediass)) return false;
      for (var i = 0; i < mediass.length; i++) {
        if (n.value === mediass[i]) {
          return true;
        }
      }
      return false;
    });

    let mdsArr = [];
    filteredArray.map((item) => {
      mdsArr.push({ id: item.value, name: item.label });
    });

    let filteredArray2 = articles.filter(function (n) {
      if (!articless || !Array.isArray(articless)) return false;
      for (var i = 0; i < articless.length; i++) {
        if (n.value === articless[i]) {
          return true;
        }
      }
      return false;
    });

    let artArr = [];
    filteredArray2.map((item) => {
      artArr.push({ id: item.value, name: item.label });
    });

    setArts(artArr);
    setMds(mdsArr);
    setTaskEditId(id);
    setTaskName(name);
    setTaskDescription(description);
    setOpenModal(true);
  };

  const EditTask = async () => {
    let artsArr = [];
    arts.map((item) => {
      artsArr.push(item.id);
    });

    let mdsArr = [];
    mds.map((item) => {
      mdsArr.push(item.id);
    });

    let data = {
      taskId: taskEditId,
      name: taskName,
      description: taskDescription,
      articles: artsArr,
      medias: mdsArr,
    };

    setOpenAlert(false);
    await setButtonClicked(true);
    dispatch(updateTask(data))
      .then(() => {
        let _uploadErrMsg = "";
        let _deleteErrMsg = "";
        if (selectedFile.length !== 0) {
          Promise.all([
            selectedFile?.forEach(async (file) => {
              const formData = new FormData();
              formData.append("File", file);
              return await dispatch(
                insertTaskFile({
                  taskId: taskEditId,
                  formData: formData,
                })
              ).catch((err) => {
                _uploadErrMsg = "Fehler beim Hochladen der Anhänge.";
              });
            }),
          ])
            .then(() => {
              if (mustdelete.length !== 0) {
                Promise.all([
                  mustdelete.forEach(async (file) => {
                    await dispatch(
                      deleteTaskFile({
                        taskId: taskEditId,
                        fileName: file,
                      })
                    ).catch((err) => {
                      _deleteErrMsg = "Fehler bei Löschen der Anhänge.";
                    });
                  }),
                ])
                  .then(() => {
                    setButtonClicked(false);
                    setOpenModal(false);
                    setTaskEditId(null);
                    setTaskName("");
                    setTaskDescription("");
                    setArts([]);
                    setMds([]);
                    setSelectedFile([]);
                    setEditselectedFile([]);
                    setMustdelete([]);
                    setTimeout(() => {
                      if (_deleteErrMsg) {
                        setErrorMessage(_deleteErrMsg);
                        setOpenAlert(true);
                      } else if (_uploadErrMsg) {
                        setErrorMessage(_uploadErrMsg);
                        setOpenAlert(true);
                      } else {
                        setSuccessMessage("Aufgabe erfolgreich bearbeitet.");
                        setOpenAlert(true);
                      }
                    }, 1000);
                  })
                  .catch((err) => {
                    logError(err);
                  });
              } else {
                setButtonClicked(false);
                setOpenModal(false);
                setTaskEditId(null);
                setTaskName("");
                setTaskDescription("");
                setArts([]);
                setMds([]);
                setSelectedFile([]);
                setEditselectedFile([]);
                setMustdelete([]);
                setTimeout(() => {
                  if (_uploadErrMsg) {
                    setErrorMessage(_uploadErrMsg);
                    setOpenAlert(true);
                  } else {
                    setSuccessMessage("Aufgabe erfolgreich bearbeitet.");
                    setOpenAlert(true);
                  }
                }, 1000);
              }
            })
            .catch((err) => {
              logError(err);
            });
        } else {
          if (mustdelete.length !== 0) {
            Promise.all([
              mustdelete.forEach(async (file) => {
                await dispatch(
                  deleteTaskFile({
                    taskId: taskEditId,
                    fileName: file,
                  })
                ).catch((err) => {
                  _deleteErrMsg = "Fehler bei Löschen der Anhänge.";
                });
              }),
            ])
              .then(() => {
                setButtonClicked(false);
                setOpenModal(false);
                setTaskEditId(null);
                setTaskName("");
                setTaskDescription("");
                setArts([]);
                setMds([]);
                setSelectedFile([]);
                setEditselectedFile([]);
                setMustdelete([]);
                setTimeout(() => {
                  if (_deleteErrMsg) {
                    setErrorMessage(_deleteErrMsg);
                    setOpenAlert(true);
                  } else {
                    setSuccessMessage("Aufgabe erfolgreich bearbeitet.");
                    setOpenAlert(true);
                  }
                }, 1000);
              })
              .catch((err) => {
                logError(err);
              });
          } else {
            setButtonClicked(false);
            setOpenModal(false);
            setTaskEditId(null);
            setTaskName("");
            setTaskDescription("");
            setArts([]);
            setMds([]);
            setSelectedFile([]);
            setEditselectedFile([]);
            setMustdelete([]);
            setTimeout(() => {
              if (_deleteErrMsg) {
                setErrorMessage(_deleteErrMsg);
                setOpenAlert(true);
              } else if (_uploadErrMsg) {
                setErrorMessage(_uploadErrMsg);
                setOpenAlert(true);
              } else {
                setSuccessMessage("Aufgabe erfolgreich bearbeitet.");
                setOpenAlert(true);
              }
            }, 1000);
          }
        }
      })
      .catch((err) => {
        setButtonClicked(false);
        setOpenModal(false);
        setTaskEditId(null);
        setTaskName("");
        setTaskDescription("");
        setArts([]);
        setMds([]);
        setSelectedFile([]);
        setEditselectedFile([]);
        setMustdelete([]);

        let errMsg = "Fehler bei Bearbeiten des Teams.";
        if (err.response?.data?.errors) {
          errMsg = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const DeleteTaskModal = (id) => {
    if (!isAdmin) return;

    setTaskEditId(id);
    setConfirmDeleteModal(true);
  };

  const DeleteTask = async () => {
    await setButtonClicked(true);
    dispatch(
      deleteTask({
        taskId: taskEditId,
      })
    )
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setTaskEditId(null);
        dispatch(
          searchTasks({
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
          })
        );
        setSuccessMessage("Aufgabe wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setTaskEditId(null);
        let errMsg = "Fehler bei Löschen der Aufgabe.";
        if (err.response?.data?.errors) {
          errMsg = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchTasks({
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;

    if (props.currentUser.role === "Admin") {
      setIsAdmin(true);
    }

    if (props.allTaskIds.length === 0) {
      dispatch(searchTasks({ GetAll: true }));
    }

    if (props.mediaIds.length === 0) {
      dispatch(searchMedias({ GetAll: true }));
    }

    if (props.articleIds.length === 0) {
      dispatch(searchArticles({ Include_owner: true }));
    }

    dispatch(
      searchTasks({
        Sort: 1,
        PageNumber: 1,
      })
    );

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    if (props.mediaIds.length > 0) {
      const _medias = [];
      props.mediaIds.forEach((id) => {
        _medias.push({
          value: id,
          label: props.medias[id].title,
          url: props.medias[id].name,
        });
      });
      setMedias(_medias);
    } else {
      setMedias([]);
    }
  }, [props.mediaIds.length]);

  useEffect(() => {
    if (props.articleIds.length > 0) {
      const _articles = [];
      props.articleIds.forEach((id) => {
        _articles.push({
          value: id,
          label: props.articles[id].title,
        });
      });
      setArticles(_articles);
    } else {
      setArticles([]);
    }
  }, [props.articleIds.length]);

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchTasks({
          PageNumber: 1,
          Sort: selectedSort,
          Search: search.replace(/\s+/g, " ").trim(),
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchTasks({
        PageNumber: newPage,
        Search: search.replace(/\s+/g, " ").trim(),
        Sort: selectedSort,
      })
    );
  };

  const taskClickHandler = (id) => {
    setSelectedTask(props.tasks[id]);
    setOpenTaskModal(true);
  };

  const resetValues = () => {
    setOpenTaskModal(false);
    setSelectedTask([]);
  };

  const attachmentClickHandler = (filename) => {
    window.open(`${config.resourcesUrl + filename}`, "_blank");
  };

  const handleMediaDisplay = (id) => {
    let media = props.medias[id];
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl + media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Backdrop open={openBackdrop} />
      <Breadcrumbs navigations={["Aufgaben"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button className={`departments__add-new--button ${!isAdmin ? "disabled" : ""}`} style={{ height: "2.8rem" }} onClick={() => setOpenModal(true)} disabled={!isAdmin}>
            <AddIcon />
            {t("Neu")}
          </Button>
          <Modal
            title={taskEditId ? "Aufgabe ändern" : "Neue Aufgabe"}
            open={openModal}
            closeModal={() => {
              setButtonClicked(false);
              setOpenModal(false);
              setTaskEditId(null);
              setTaskName("");
              setTaskDescription("");
              setArts([]);
              setMds([]);
              setSelectedFile([]);
              setEditselectedFile([]);
              setMustdelete([]);
            }}
          >
            <input type="text" placeholder={t("Aufgabe")} value={taskName} onChange={({ target }) => setTaskName(target.value)} />
            <textarea
              type="text"
              placeholder={t("Kurzbeschreibung")}
              value={taskDescription}
              multiple={true}
              rows={5}
              style={{
                borderRadius: "0.625rem",
                border: "solid 1px #cfcfcf",
                width: "100%",
                paddingTop: 10,
                outline: "none",
                paddingLeft: "0.6rem",
                color: "#535353",
                fontSize: "1rem",
                marginBottom: "10px",
              }}
              onChange={({ target }) => setTaskDescription(target.value)}
            />

            <div className="article-modal__content-input">
              <label htmlFor="file-upload" className="custom-file-upload">
                <Upload />
                {t("Hier klicken um Anhänge hochzuladen")}
              </label>
              <input id="file-upload" className="article-modal__file-upload" type="file" onChange={onFileChange} />
            </div>

            <div className="article-modal__uploaded-files">
              {typeError ? <span style={{ color: "red" }}>{typeError}</span> : null}
              {sizeError ? <span style={{ color: "red" }}>{sizeError}</span> : null}
              {editselectedFile.length || selectedFile.length ? (
                <div>
                  {editselectedFile?.map((file) => (
                    <FilePreview key={file?.name} name={file?.name} onClick={() => deleteEditFilePreview(file)} />
                  ))}
                  {selectedFile?.map((file) => (
                    <FilePreview key={file?.name} name={file?.name} onClick={() => deleteFilePreview(file)} />
                  ))}
                </div>
              ) : (
                <span>{t("Aktuell keine Anhänge vorhanden")}</span>
              )}
            </div>

            <div style={{ marginTop: 15 }}>
              {/* Choosiing related articles */}
              <div style={{ marginBottom: "10px" }}>
                <strong>Relevante Artikel:</strong>
              </div>

              <Select
                className="sort-input positions__select-department"
                value={selectedArticles.add}
                placeholder="Artikel auswählen"
                onChange={(e) => {
                  setArts([...arts, { id: e.value, name: e.label }]);
                }}
                options={articles}
                isSearchable={true}
                style={{ marginTop: "10px" }}
              />

              {arts.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "fit-content",
                    marginTop: 10,
                  }}
                >
                  {arts.map((person, index) => {
                    return index !== 0 ? (
                      <div
                        key={index}
                        style={{
                          padding: 5,
                          marginLeft: 10,
                          border: "1px solid #c9d2d2",
                          borderRadius: 5,
                        }}
                      >
                        {person.name} <DeleteIcon style={{ cursor: "pointer" }} onClick={() => DeleteFromArts(person.id)} width={30} height={30} />
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={{
                          padding: 5,
                          border: "1px solid #c9d2d2",
                          borderRadius: 5,
                        }}
                      >
                        {person.name} <DeleteIcon style={{ cursor: "pointer" }} onClick={() => DeleteFromArts(person.id)} width={30} height={30} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span
                  style={{
                    color: "#c9d2d2",
                    fontSize: "1.1rem",
                    marginTop: "1.3rem",
                  }}
                ></span>
              )}
            </div>

            <div style={{ marginTop: 15 }}>
              {/* Choosiing related medias */}
              <div style={{ marginBottom: "10px" }}>
                <strong>Related Medias:</strong>
              </div>

              <Select
                className="sort-input positions__select-department"
                value={selectedMedias.add}
                placeholder="Select Media"
                onChange={(e) => {
                  setMds([...mds, { id: e.value, name: e.label, url: e.url }]);
                }}
                options={medias}
                isSearchable={true}
                style={{ marginTop: "10px" }}
              />

              {mds.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "fit-content",
                    marginTop: 10,
                  }}
                >
                  {mds.map((person, index) => {
                    return index !== 0 ? (
                      <div
                        key={index}
                        style={{
                          padding: 5,
                          marginLeft: 10,
                          border: "1px solid #c9d2d2",
                          borderRadius: 5,
                        }}
                      >
                        {person.name} <DeleteIcon style={{ cursor: "pointer" }} onClick={() => DeleteFromMds(person.id)} width={30} height={30} />
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={{
                          padding: 5,
                          border: "1px solid #c9d2d2",
                          borderRadius: 5,
                        }}
                      >
                        {person.name} <DeleteIcon style={{ cursor: "pointer" }} onClick={() => DeleteFromMds(person.id)} width={30} height={30} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span
                  style={{
                    color: "#c9d2d2",
                    fontSize: "1.1rem",
                    marginTop: "1.3rem",
                  }}
                ></span>
              )}
            </div>

            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setButtonClicked(false);
                  setOpenModal(false);
                  setTaskEditId(null);
                  setTaskName("");
                  setTaskDescription("");
                  setArts([]);
                  setMds([]);
                  setSelectedFile([]);
                  setEditselectedFile([]);
                  setMustdelete([]);
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton className="departments__modal--add" valid={taskName ? true : false} buttonClicked={buttonClicked} onClick={taskEditId ? EditTask : AddTask}>
                {taskEditId ? "Ändern" : "Hinzufügen"}
              </AddButton>
            </div>
          </Modal>

          <Modal title={""} open={confirmDeleteModal} closeModal={() => setConfirmDeleteModal(false)}>
            <p>{t("Aufgabe entfernen?")} </p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>{t("Abbrechen")}</OutlinedButton>
              <AddButton className="departments__modal--add" valid={true} buttonClicked={buttonClicked} onClick={DeleteTask}>
                {t("Löschen")}
              </AddButton>
            </div>
          </Modal>

          <MediaDisplayModal
            open={displayMedia}
            closeModal={() => {
              setDisplayMedia(false);
              setDisplayUrl("");
              setDisplayMediaName("");
              setDisplayMediaTitle("");
            }}
            mediaUrl={displayUrl}
            title={displayMediaTitle}
            name={displayMediaName}
          />

          <TaskDetailsModal
            open={openTaskModal}
            closeModal={() => {
              resetValues();
            }}
            onAttachmentClick={attachmentClickHandler}
            onMediaClick={handleMediaDisplay}
            selectedTask={selectedTask}
            isProcessTask={false}
          />
        </div>

        <SortInput handleSort={handleSort} />
      </div>

      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left">{t("Beschreibung")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: "center", paddingTop: "5rem" }}>
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !props.isLoading && !props.taskIds?.length ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_task")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  props.taskIds.length > 0 &&
                  props.taskIds?.map((id) => {
                    return (
                      props.tasks[id] && (
                        <TableRow key={id} className="departments__table-row" style={{ backgroundColor: "white" }}>
                          <TableCell component="th" scope="row" onClick={() => taskClickHandler(id)}>
                            {props.tasks[id].no + "."}
                          </TableCell>
                          <TableCell align="left" onClick={() => taskClickHandler(id)}>
                            {props.tasks[id].name}
                          </TableCell>
                          <TableCell align="left" onClick={() => taskClickHandler(id)}>
                            {props.tasks[id].description.length > 50 ? props.tasks[id].description.substring(0, 50) + "..." : props.tasks[id].description}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <EditIcon
                                className={`${!isAdmin ? "disabled" : ""}`}
                                onClick={() => {
                                  EditTaskModal(id, props.tasks[id].name, props.tasks[id].description, props.tasks[id].medias, props.tasks[id].articles, props.tasks[id].files);
                                }}
                              />
                              <DeleteIcon className={`${!isAdmin ? "disabled" : ""}`} onClick={() => DeleteTaskModal(id)} />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.taskIds?.length && search ? null : <>{Object.keys(props.pageParams).length > 0 && <Pagination params={props.pageParams} getPageNumber={getPageNumber} defaultPage={defaultPage} />}</>}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.task.isLoading,
  allTaskIds: state.task.allIds,
  tasks: state.task.byId,
  taskIds: state.task.searchIds,
  pageParams: state.task.pageParams,
  mediaIds: state.media.allIds,
  medias: state.media.byId,
  articleIds: state.article.allIds,
  articles: state.article.byId,
});

export default connect(mapStateToProps, null)(Tasks);
