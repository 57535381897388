import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import Pagination from "../../components/pagination/pagination";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import ArticleModal from "../Quizzes/QuizModal";

import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";

import "../../assets/css/pages/articles.scss";
import App from "../../App";

import { useTranslation } from "react-i18next";

const Articles = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalCount: 0,
    hasNext: false,
    hasPrevious: false,
  });
  const [articles, setArticles] = useState(null);
  const [search, setSearch] = useState("");
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [articleEditId, setArticleEditId] = useState(null);
  const [openArticleModal, setOpenArticleModal] = useState(false);

  const getArticles = async (params) => {
    setLoading(true);
    await axios
      .get(`${config.apiURL}/api/Article`, {
        params: {
          WorkspaceId: props.currentUser.workspaceId,
          PageSize: 10,
          ...params,
        },
      })
      .then((res) => {
        const {
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        } = res.data;
        setParams({
          currentPage,
          totalPages,
          pageSize,
          totalCount,
          hasNext,
          hasPrevious,
        });
        let articleNo = currentPage * 5 - 5;
        setArticles(
          res.data.articles.map((dep, i) => {
            return {
              no: ++articleNo,
              id: dep.id,
              name: dep.title,
              categories: dep.categories,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  const DeleteArticleModal = (id) => {
    setArticleEditId(id);
    setConfirmDeleteModal(true);
  };
  const DeleteArticle = async () => {
    await setButtonClicked(true);
    await axios
      .delete(`${config.apiURL}/api/Article/${articleEditId}`, {
        data: { workspaceId: props.currentUser.workspaceId },
      })
      .then((res) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setArticleEditId(null);
        getArticles();
      })
      .catch((err) => {
        setButtonClicked(false);
        setConfirmDeleteModal(false);
        setArticleEditId(null);
        // setErrorMessage(err.response.data.ERROR[0]);
        // setOpenAlert(true);
      });
  };

  const EditArticleModal = (id) => {
    setArticleEditId(id);
    setOpenArticleModal(true);
  };

  const getSearchValue = (search) => {
    setSearch(search);
    if (props.currentUser) {
      getArticles({ PageNumber: 1, Title: search });
    }
    setDefaultPage(!defaultPage);
  };
  const handleSort = (e) => {
    setSelectedSort(e.value);
    getArticles({ PageNumber: params.currentPage, Sort: e.value });
  };
  const getPageNumber = (newPage) => {
    getArticles({
      PageNumber: newPage,
      Name: search,
      Sort: selectedSort,
    });
  };
  useEffect(() => {
    const abortController = new AbortController();

    if (!props.currentUser) return;
    getArticles();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);
  return (
    <App>
      <Breadcrumbs navigations={["Quizzes"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Button
            className="departments__add-new--button"
            onClick={() => {
              return setOpenArticleModal(true);
              // resetValues()
            }}
          >
            <AddIcon /> {t("add_new")}
          </Button>
          <Modal
            title={""}
            open={confirmDeleteModal}
            closeModal={() => setConfirmDeleteModal(false)}
          >
            <p>{t("sure_delete_article")}</p>
            <div className="departments__modal--buttons">
              <OutlinedButton onClick={() => setConfirmDeleteModal(false)}>
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={true}
                buttonClicked={buttonClicked}
                onClick={DeleteArticle}
              >
                {t("delete")}
              </AddButton>
            </div>
          </Modal>
          <ArticleModal
            open={openArticleModal}
            closeModal={() => {
              setOpenArticleModal(false);
              setArticleEditId(null);
            }}
            getArticles={getArticles}
            articleId={articleEditId}
          />
        </div>
        <SortInput handleSort={handleSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">{t("Category")}</TableCell>
              <TableCell align="right">{t("Options")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : /*search &&*/ !loading && !articles?.length ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_article")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              articles?.map((row) => (
                <TableRow key={row.id} className="departments__table-row">
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={(event) => history.push(`/article/${row.id}`)}
                  >
                    {row.no + "."}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(event) => history.push(`/article/${row.id}`)}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(event) => history.push(`/article/${row.id}`)}
                  >
                    {row.categories
                      .map((cat, i) => (i < 2 ? cat.name : "..."))
                      .join(", ")}
                  </TableCell>
                  <TableCell align="right">
                    <div>
                      <EditIcon onClick={() => EditArticleModal(row.id)} />
                      <DeleteIcon onClick={() => DeleteArticleModal(row.id)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!articles?.length && search ? null : (
        <Pagination
          params={params}
          getPageNumber={getPageNumber}
          defaultPage={defaultPage}
        />
      )}
    </App>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(Articles);
