import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config/api.config.js';

export const GreenCheckbox = withStyles({
  root: {
    'color': config.colorPrimary,
    '&$checked': {
      color: config.colorPrimary
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
