import typeToReducer from 'type-to-reducer';
import {
  PENDING,
  REJECTED,
  FULFILLED,
} from '../../helpers/reduxPromiseMiddleware';
import { 
  SEARCH_MEDIAS, 
  ADD_MEDIA,
  DELETE_MEDIA,
  UPDATE_MEDIA
} from '../actionTypes';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  searchIds: [],
  isLoading: false,
  pageParams: {}
};
  
export default typeToReducer(
  {
    [SEARCH_MEDIAS]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { medias, hasNext, hasPrevious, pageSize, totalCount, totalPages, currentPage } = payload.data;

        let allMedias = payload.params?.GetAll ? payload.params.GetAll : false;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = [];
        let mediaNo = currentPage * pageSize - pageSize;
        let pageParams = {};

        if(medias.length > 0){
          medias.forEach(media => {
            media['no'] = ++mediaNo;
            if(allIds.indexOf(media.id) === -1){
              allIds.push(media.id);
            }
            if(!allMedias){
              searchIds.push(media.id);     
            }                 
            byId[media.id] = media;
          })
        }

        if(!allMedias){
          pageParams.hasNext = hasNext;
          pageParams.hasPrevious = hasPrevious;
          pageParams.pageSize = pageSize;
          pageParams.totalCount = totalCount;
          pageParams.totalPages = totalPages;
          pageParams.currentPage = currentPage;
        }

        return {
          ...state,
          allIds,
          byId,
          searchIds,
          pageParams,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false,
        };
      },
    },
    [ADD_MEDIA]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { id } = payload;
        let media = payload;
        let allIds = state.allIds;
        let byId = state.byId;
        let searchIds = state.searchIds;
        let pageParams = state.pageParams;

        if(state.allIds.indexOf(id) === -1){
          allIds =  [...state.allIds, id];          
        }
        media['no'] = state.pageParams.totalCount + 1;
        byId = {...state.byId, [payload.id]: media};
        if((state.searchIds.indexOf(id) === -1 && 
          state.searchIds.length < state.pageParams.pageSize) || 
          state.searchIds.length === 0) {
            searchIds =  [...state.searchIds, id];
        }

        if(state.searchIds.length === state.pageParams.pageSize){       
          let totalCount = state.pageParams.totalCount + 1;
          let totalPages = state.pageParams.totalPages;
          let hasNext = state.pageParams.hasNext;
          if(totalCount > pageParams.pageSize * totalPages ){
            hasNext = state.pageParams.totalPages !== 0 ? true : false;            
            totalPages = totalPages + 1;
          }
          pageParams = {
            ...state.pageParams, 
            totalCount: totalCount,
            totalPages : totalPages,
            hasNext : hasNext
          }
        }else{
          pageParams = {
            ...state.pageParams, 
            totalCount: state.pageParams.totalCount + 1
          };
        }

        return {
          ...state,
          byId,
          allIds,
          searchIds,
          pageParams,
          isLoading: false
        }
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [DELETE_MEDIA]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { videoId } = payload.params;

        let byId = state.byId;
        let allIds = state.allIds;

        if(payload.data){
          const index = allIds.indexOf(videoId);
          if (index > -1) {
            allIds.splice(index, 1);
          }

          if(byId[videoId]){
            delete byId[videoId];
          }
        }
     
        return {
          ...state,
          byId,
          allIds,
          isLoading: false,
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
    [UPDATE_MEDIA]: {
      [PENDING](state) {
        return {
          ...state,
          isLoading: true,
        };
      },
      [FULFILLED](state, { payload, meta }) {
        const { mediaId, name, title, description } = payload.params;
        let byId = state.byId;
        if(payload.data){         
          if(payload.data && byId[mediaId]){
            byId[mediaId].name = name;
            byId[mediaId].title = title;
            byId[mediaId].description = description;
          }
        }
        return {
          ...state,
          byId,
          isLoading: false
        };
      },
      [REJECTED](state, { payload }) {
        return {
          ...state,
          isLoading: false
        };
      },
    },
  },
  INITIAL_STATE
);
  