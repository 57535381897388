import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import auth from "../../auth";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import App from "../../App";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import { getTasksWaitingForApproval } from "../../redux/task/action";
import { getProcessById } from "../../redux/process/action";
import { getUserById, searchUsers } from "../../redux/users/action";
import Pagination from "../../components/pagination/pagination";
import SortInput from "../../components/sortInput/sortInput";
import SnackbarError from "../../components/alert/SnackbarError";
import Search from "../../components/search/search";
import moment from "moment";

const MyApproval = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [tasksForApproval, setTasksForApproval] = useState([]);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPage, setDefaultPage] = useState(false);
  const [selectedSort, setSelectedSort] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;
    const userId = auth.getId();

    const fetchData = async () => {
      if (props.memberIds.length === 0) {
        await dispatch(searchUsers({ GetAll: true }));
      }
      dispatch(getTasksWaitingForApproval({ userId: userId })).catch((err) => {
        setIsLoading(false);
        logError(err);
        setErrorMessage("Fehler bei Laden der Aufgabes.");
        setOpenAlert(true);
      });

      if (props.members[userId]) {
        setMember(props.members[userId]);
      } else {
        await dispatch(getUserById({ id: userId }))
          .then((res) => {
            if (res.value?.data) {
              let user = res.value.data;
              user["id"] = res.value.data.userId;
              setMember(user);
            }
          })
          .catch((err) => logError(err));
      }
    };
    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  useEffect(() => {
    if (props.tasksForApproval.length > 0) {
      formatTasks(props.tasksForApproval);
    } else {
      setTasksForApproval([]);
    }
  }, [props.tasksForApproval]);

  const formatTasks = async (tasks) => {
    let _tasks = [];
    for (const task of tasks) {
      await getProcess(task.processId).then((process) => {
        task.processName = process && process.name ? process.name : "";
        _tasks.push(task);
      });
    }
    setTasksForApproval(_tasks);
  };

  const getProcess = async (id) => {
    if (props.processes[id]) {
      return props.processes[id];
    } else {
      return await dispatch(getProcessById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            return res.value.data;
          }
        })
        .catch((err) => {
          logError(err);
          setErrorMessage("Fehler bei Laden des Prozesses.");
          setOpenAlert(true);
        });
    }
  };

  const taskClickHandler = (task, userType) => {
    history.push(`/task/${task.id}`, {
      task: task,
      member: member,
      userType: userType,
      isActive: true,
    });
  };

  const getPageNumber = (newPage) => {
    dispatch(
      getTasksWaitingForApproval({
        userId: auth.getId(),
        PageNumber: newPage,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      getTasksWaitingForApproval({
        userId: auth.getId(),
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        getTasksWaitingForApproval({
          userId: auth.getId(),
          PageNumber: props.pageParams.currentPage,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      <Breadcrumbs navigations={["Zum Genehmigen"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
        </div>
        <SortInput handleSort={handleSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Aufgabe")}</TableCell>
              <TableCell align="left">{t("Verantwortlich")}</TableCell>
              <TableCell align="left">{t("Verantwortlich")}</TableCell>
              <TableCell align="left">{t("Process")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : !props.isLoading && tasksForApproval?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NothingFound>{t("nothing_found_task")}</NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  tasksForApproval?.length > 0 &&
                  tasksForApproval.map((task, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="departments__table-row"
                        style={{ backgroundColor: "white" }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1 + "."}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() =>
                            taskClickHandler(task, "process_owner")
                          }
                        >
                          {task.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.members[task.responsible]?.firstName +
                            " " +
                            props.members[task.responsible]?.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(task.dateBeDone).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell align="left">{task.processName}</TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!tasksForApproval?.length ? null : (
        <>
          {tasksForApproval.length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  members: state.user.byId,
  memberIds: state.user.allIds,
  processes: state.process.byId,
  tasksForApproval: state.task.approveTasks,
  isLoading: state.task.isLoading,
  pageParams: state.task.pageParams,
});

export default connect(mapStateToProps, null)(MyApproval);
